import React from 'react'
// @src imports
import { useEffect, useSelector, useState } from 'src/hooks'

import styles from 'src/dashboard/styles/account.module.scss'
import {
  Div,
  Flex,
  // PromotionWidget,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

// import { isCountryAffiliaetEligible } from 'src/helpers/isCountryAffiliateEligible'

// import AffiliateUpsale from '../../../components/AffiliateUpsale'
import PromptingsUpcomingEvents from '../../../components/PromptingsUpcomingEvents'
import {
  CollapsibleBanner,
  CollapsibleBannerProps,
} from 'src/saleor/components/collapsable_banner/CollapsableBanner'
import LeaderBoard from '../../../components/Leaderboard/LeaderBoard'
// import { navigateAndScroll } from 'src/pricing_page/utils'
import { AccountSubscriptionChanger } from 'src/SubscriptionChanger/AccountSubscriptionChanger'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { AffiliateCreationForm } from 'src/saleor/components/affiliate_creation_form/AffiliateCreationForm'
import { SendingMetricsWidget } from 'src/dashboard/components/Widgets/SendingMetrics/SendingMetricsWidget'
import { CardFeatureWidget } from 'src/dashboard/components/Widgets/CardFeature/CardFeatureWidget'
import { SubscriptionWidget } from 'src/dashboard/components/Widgets/Subscription/SubscriptionWidget'
import { CardHistoryWidget } from 'src/dashboard/components/Widgets/History/CardHistoryWidget'
import { AccountSettingsWidget } from 'src/dashboard/components/Widgets/Settings/AccountSettingsWidget'
import { DraftWidget } from 'src/dashboard/components/Widgets/Drafts/DraftWidget'
import { RemindersListWidget } from 'src/dashboard/components/Widgets/Reminders/RemindersListWidget'
import { FullAccessFeatureType, Label } from 'src/graphql/generated/graphql'
import {
  useAccountQuery,
  useLeaderBoards,
  useMarketingContent,
} from 'src/react_query'
import {
  defaultSubscriptionIds,
  premiereSubscriptionIds,
} from 'src/helpers/supportedUpsaleProducts'

type DashboardHomeViewProps = {
  toggleIsAccountDrawerOpen: () => void
}

const DashboardHomeView: React.FC<DashboardHomeViewProps> = ({
  toggleIsAccountDrawerOpen,
}) => {
  const windowWidth = useSelector(state => state.window.width)

  // Responsive Breakpoints
  const isLeaderboardMobile = useSelector(state => state.window.width <= 1500)
  const shouldColumnWrap = windowWidth <= 1243
  const isTabletBreakPoint = windowWidth <= 1087
  const isMobileBreakPoint = windowWidth <= 600

  // Logic

  const { data: account } = useAccountQuery()
  const [isSelectingSubscription, setIsSelectingSubscription] = useState(false)
  const [shouldShowAffiliateForm, setShouldShowAffiliateForm] = useState(false)
  const [shouldShowAffiliateBanner, setShouldShowAffiliateBanner] = useState<
    boolean | undefined
  >()

  useEffect(() => {
    if (account && shouldShowAffiliateBanner === undefined) {
      setShouldShowAffiliateBanner(
        account.labels.includes(Label.NeedsAffiliateAccInfo),
      )
    }
  }, [account, shouldShowAffiliateBanner])

  // const isSparseOrSample =
  //   account.type === UserType.SP123 || account.type === UserType.S

  const { data: marketingContent } = useMarketingContent()

  const getBannerData = (): CollapsibleBannerProps | undefined => {
    if (!account || !marketingContent) {
      return undefined
    }
    const profileWarningTitle =
      marketingContent.affiliateProfileWarningTitle.content
    const profileWarningBody =
      marketingContent.affiliateProfileWarningBody.content

    const profileCompleteTitle =
      marketingContent.affiliateProfileCompleteTitle.content

    const bannerBodyStyles = {
      fontSize: '14px',
      color: '#6B7280',
    }

    return account.labels.includes(Label.NeedsAffiliateAccInfo)
      ? {
          type: 'warning',
          title: profileWarningTitle,
          bannerActions: {
            primaryAction: {
              title: 'Complete Affiliate Profile',
              onClick: () => {
                setShouldShowAffiliateForm(true)
                setShouldShowAffiliateBanner(false)
              },
            },
          },
          children: (
            <Text
              type="body"
              content={profileWarningBody}
              style={{ ...bannerBodyStyles }}
            />
          ),
        }
      : {
          type: 'success',
          title: profileCompleteTitle,
          onClose: () => setShouldShowAffiliateBanner(false),
        }
  }

  const bannerData = getBannerData()

  // Get leaderboard from backend
  const { data: leaderBoards } = useLeaderBoards()
  const [currentLeaderBoard] = leaderBoards ?? [undefined]

  const shouldShowLeaderBoard =
    currentLeaderBoard && currentLeaderBoard.challenges.length > 0
  const LeaderBoardWrapper = () =>
    currentLeaderBoard ? <LeaderBoard {...currentLeaderBoard} /> : <></>

  // const [affiliateCountries] = useQueryFutures(getAffiliateCountries())
  // const isAffiliateEligible =
  //   affiliateCountries.value &&
  //   account.shippingAddress !== null &&
  //   account.shippingAddress.address1.trim() !== ''
  //     ? isCountryAffiliaetEligible(
  //         affiliateCountries.value,
  //         account.shippingAddress.country,
  //       )
  //     : false

  // const handleNavigation = (hash: string) => {
  //   actions.openPricing()
  //   navigateAndScroll(hash)
  // }

  const { hasFeatureAccess } = useFeatureAccess([
    FullAccessFeatureType.PremiumSubscriptionOptions,
  ])

  const subscriptionIds = hasFeatureAccess
    ? [...defaultSubscriptionIds, ...premiereSubscriptionIds]
    : defaultSubscriptionIds

  const isMobile = useSelector(state => state.window.width <= 474)
  const isSmallMobile = useSelector(state => state.window.width <= 400)

  return (
    <Div>
      {shouldShowAffiliateBanner && bannerData && (
        <CollapsibleBanner
          onClose={() => {
            setShouldShowAffiliateBanner(false)
          }}
          {...bannerData}
        />
      )}
      {shouldShowAffiliateForm && (
        <AffiliateCreationForm
          onSucces={() => {
            setShouldShowAffiliateForm(false)
            setShouldShowAffiliateBanner(true)
          }}
          onCancel={() => {
            setShouldShowAffiliateForm(false)
            setShouldShowAffiliateBanner(true)
          }}
        />
      )}

      <Flex
        id="accountWrapper"
        width="100%"
        columnGap="x2"
        flexWrap={shouldColumnWrap ? 'wrap' : 'nowrap'} // Start column queries
        flexDirection={shouldColumnWrap ? 'column' : 'row'}
      >
        <Flex
          flexDirection="column"
          style={styles.order2}
          width={shouldColumnWrap ? '100%' : 'calc(100% - 474px)'}
          order={shouldColumnWrap ? 2 : 1}
          justifyContent={shouldColumnWrap ? 'center' : 'initial'}
        >
          <Div
            display="grid"
            width="100%"
            outset={shouldColumnWrap ? { top: 'x2' } : {}}
            style={{
              gridTemplateRows: isMobileBreakPoint
                ? '1fr 1fr 1fr'
                : isTabletBreakPoint
                ? '1fr 1fr'
                : undefined,
              gridTemplateColumns: isMobileBreakPoint
                ? '1fr'
                : isTabletBreakPoint
                ? '1fr 280px'
                : 'minmax(150px, 260px) minmax(300px, 100%) minmax(150px, 280px)',

              gap: '12px',
            }}
          >
            {/* Card Feature */}
            <Flex
              style={
                isTabletBreakPoint && !isMobileBreakPoint
                  ? { gridRowStart: 2, gridRowEnd: 3, gridColumnStart: 1 }
                  : {}
              }
            >
              <CardFeatureWidget />
            </Flex>
            {/* Sending Metrics */}
            <Flex
              style={
                isMobileBreakPoint
                  ? { gridRowStart: 1 }
                  : isTabletBreakPoint
                  ? { gridRowStart: 1, gridColumnStart: 1, gridColumnEnd: 3 }
                  : {}
              }
            >
              <SendingMetricsWidget />
            </Flex>
            {/* Subscription */}
            <Flex
              style={
                isTabletBreakPoint && !isMobileBreakPoint
                  ? {
                      gridRowStart: 2,
                      gridRowEnd: 3,
                      gridColumnStart: 2,
                      gridColumnEnd: 3,
                    }
                  : {}
              }
            >
              <SubscriptionWidget
                maxWidth={isMobileBreakPoint ? '100%' : '280px'}
                onClick={() =>
                  setIsSelectingSubscription(!isSelectingSubscription)
                }
              />
            </Flex>
          </Div>
          <Spacer space="x2_5" />
          {isSelectingSubscription && (
            <AccountSubscriptionChanger
              subscriptionPlanIds={subscriptionIds}
              isOpen={true}
              onClose={() => setIsSelectingSubscription(false)}
            />
          )}
          <Spacer space="x2_5" />
          <CardHistoryWidget />
          <Flex
            width="100%"
            flexDirection={isMobileBreakPoint ? 'column' : 'row'}
            rowGap={isMobileBreakPoint ? 'x2_5' : ''}
            columnGap={'x2_5'}
            outset={{ vertical: 'x3' }}
          >
            <AccountSettingsWidget
              onToggleAccountDrawer={toggleIsAccountDrawerOpen}
            />
            <DraftWidget />
          </Flex>
          {isTabletBreakPoint && <PromptingsUpcomingEvents />}
          {shouldShowLeaderBoard && isLeaderboardMobile && (
            <LeaderBoardWrapper />
          )}
          {/* TODO: Need Designs for sales content
          
          <Spacer space="x4" />
          <Text
            type="largeBody"
            weight="bold"
            color="primaryHeading"
            content="Account Add-ons"
            style={{ letterSpacing: '.3px', marginLeft: 4 }}
          />
          <Spacer space="x2_5" /> */}
          {/* {!isSparseOrSample && isAffiliateEligible && <AffiliateUpsale />}
          {!hasStreamVASubscription && (
            <Div outset={{ bottom: 'x1_5' }} data-mktcontent="streamVaPromo">
              <PromotionWidget
                title={marketingContent.streamVaPromo.title}
                description={marketingContent.streamVaPromo.content}
                primaryAction={{
                  title: 'Shop Now',
                  onClick: () => handleNavigation('Blocks'),
                }}
                secondaryAction={{
                  title: 'Learn More',
                  href: 'https://streamvirtualassistant.com/',
                }}
                capsuleTitle="New"
                width="100%"
                minWidth="320px"
                type="success"
              />
            </Div>
          )} */}
          {/* <Spacer space="x2_5" /> */}
        </Flex>
        <Flex
          flexDirection={
            shouldColumnWrap && !isTabletBreakPoint ? 'row' : 'column'
          }
          inset={{ horizontal: isMobile ? '' : 'x1' }}
          style={styles.order1}
          width={shouldColumnWrap ? '100%' : '450px'}
          order={shouldColumnWrap ? 1 : 2}
          columnGap="x4"
          alignItems={shouldColumnWrap ? 'center' : 'initial'}
        >
          <Flex
            width={isMobile ? '' : '100%'}
            style={{
              transform: isSmallMobile
                ? 'scale(.85)'
                : isMobile
                ? 'scale(.9)'
                : '',
            }}
          >
            <RemindersListWidget firstName={account?.firstName} />
          </Flex>
          {shouldShowLeaderBoard && !isLeaderboardMobile && (
            <LeaderBoardWrapper />
          )}
          {!isTabletBreakPoint && (
            <>
              {!shouldColumnWrap && <Spacer space="x4" />}
              <PromptingsUpcomingEvents />
            </>
          )}
        </Flex>
      </Flex>
    </Div>
  )
}

export default DashboardHomeView
