import {
  ContactFragment,
  getContactsIds,
  getMinimalContacts,
  getPaginatedContacts,
  MinimalCampaignFragment,
} from 'src/legacy_graphql'
import { SearchFilterType } from 'src/chrome/FilteredSearchBar/types'
import { Group } from '../contactTypes'
import { createContactInput, exportContactsCsvFrom } from 'src/app/api'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'
import {
  useAccount,
  useActions,
  useCallback,
  useMemo,
  useMutations,
  useQueries,
  useState,
} from 'src/hooks'

import { FormContact } from '../components/AddressForm/AddressForm'
import { DEFAULT_CONTACT_SEARCH, generateContactFilterPayload } from './filters'
import { Step, Steps } from './types'
import uuid from 'src/utils/uuid'
import { Set } from 'immutable'
import RelationshipSharedHooks from './RelationshipSharedHooks'
import { parseError } from 'src/utils/parseError'
import { useCreateOrder } from 'src/react_query'

const Hooks = () => {
  const sharedHooks = RelationshipSharedHooks()
  const [selected, setSelected] = useState(Set<string>())
  const reset = useCallback(
    () => sharedHooks.setSearch(DEFAULT_CONTACT_SEARCH),
    [sharedHooks],
  )
  const [step, setStep] = useState<Step>({ type: Steps.View })
  const account = useAccount()
  const actions = useActions()
  const mutations = useMutations()
  const createOrderMutation = useCreateOrder()
  const [loading, setLoading] = useState(0)

  const resetStep = useCallback(() => setStep({ type: Steps.View }), [])
  const reload = useCallback(() => {
    setSelected(Set())
  }, [])

  const [
    allContactsIds,
    filteredContacts,
    { results: paginatedContacts, count: contactsCount },
  ] = useQueries(
    getContactsIds({
      search: sharedHooks.search.terms,
      showSecondaryContacts: true,
    }),
    getMinimalContacts({
      search: sharedHooks.search.terms || undefined,
      ...generateContactFilterPayload(sharedHooks.search.filters),
    }),
    getPaginatedContacts({
      page: sharedHooks.page,
      search: sharedHooks.search.terms || undefined,
      ...generateContactFilterPayload(sharedHooks.search.filters),
    }),
  )

  const selectionMap = useMemo(() => {
    const mutableMap: Record<string, true> = {}

    selected.forEach(x => (mutableMap[x] = true))
    return mutableMap
  }, [selected])

  const isEveryFilteredContactSelected = useMemo(
    () => filteredContacts.every(contact => selectionMap[contact.id]),
    [filteredContacts, selectionMap],
  )

  const toggleSelectAll = useCallback(() => {
    if (isEveryFilteredContactSelected) {
      // Use a map here to avoid O(N^2) as there can be over 10k contacts
      const mutableLoadedContactsMap: Record<string, true> = {}
      filteredContacts.forEach(x => (mutableLoadedContactsMap[x.id] = true))
      // Remove from selected all contacts from allContacts
      setSelected(selected.filter(id => !mutableLoadedContactsMap[id]))
    } else {
      setSelected(selected.union(filteredContacts.map(x => x.id)))
    }
  }, [filteredContacts, selected, isEveryFilteredContactSelected])

  const toggleSelect = useCallback((contact: ContactFragment) => {
    setSelected(x =>
      x.contains(contact.id) ? x.delete(contact.id) : x.add(contact.id),
    )
  }, [])

  const confirmShare = useCallback(
    () => setStep({ type: Steps.ConfirmShare }),
    [],
  )

  const shareSelectedContacts = useCallback(
    async (username: string) => {
      setStep({
        type: Steps.ShareProgress,
        deleted: 0,
        errors: [],
        done: false,
      })

      try {
        await Promise.all(
          selected.map(async id => {
            try {
              await mutations.shareContact({ contact: id, username })
              setStep(step => {
                if (step.type !== Steps.ShareProgress) return step
                return {
                  ...step,
                  deleted: step.deleted + 1,
                }
              })
            } catch (error) {
              const err = parseError(error)

              if (/^User matching query/.test(err)) {
                throw new Error(`Username "${username}" not found`)
              } else {
                setStep(step => {
                  if (step.type !== Steps.ShareProgress) return step
                  return {
                    ...step,
                    errors: step.errors.concat([err]),
                  }
                })
              }
            }
          }),
        )
        reload()
      } catch (error) {
        console.error(error)
      } finally {
        setStep(step => {
          if (step.type !== Steps.ShareProgress) return step

          return {
            ...step,
            done: true,
          }
        })
      }
    },
    [selected, mutations, reload],
  )

  const confirmDelete = () => setStep({ type: Steps.ConfirmDelete })

  const deleteSelectedContacts = () => {
    resetStep()
    mutations.deleteContacts({ contacts: selected.toArray() })
    setSelected(Set())
  }

  const viewHistory = useCallback(
    (contact: ContactFragment) => {
      actions.openCardHistory(undefined, [
        {
          type: SearchFilterType.CONTACT,
          value: [contact],
          name: 'Recipient',
        },
      ])
    },
    [actions],
  )
  const editContact = useCallback(
    (contactId: string) => {
      setStep({
        type: Steps.EditContact,
        contactId,
        groups: sharedHooks.groups,
      })
      actions.openEditContact(contactId)
    },
    [sharedHooks.groups, actions],
  )

  const doneEditing = useCallback(() => {
    resetStep()
    reload()
    actions.closeEditContact()
  }, [resetStep, reload, actions])

  const sendCard = useCallback(
    async (recipients?: string[]) => {
      setLoading(x => x + 1)
      const contacts = recipients ?? selected.toArray()
      try {
        const {
          createOrder: { order },
        } = await mutations.createOrder({ order: { contacts } })
        actions.openOrder(order.id, AddOrderCardRoute())
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(x => x - 1)
      }
    },
    [mutations, actions, selected],
  )

  const selectCampaign = useCallback((recipients?: string[]) => {
    setStep({
      type: Steps.SelectCampaign,
      recipients,
    })
  }, [])

  const sendCampaign = useCallback(
    async (campaign: MinimalCampaignFragment, recipients?: string[]) => {
      setLoading(x => x + 1)
      resetStep()
      const contacts = recipients ?? selected.toArray()

      try {
        const {
          createOrder: { order },
        } = await createOrderMutation.mutateAsync({
          order: {
            campaign: campaign.id,
            contacts,
          },
        })

        actions.createdOrder(order)
        actions.openOrder(order.id)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(x => x - 1)
      }
    },
    [resetStep, createOrderMutation, actions, selected],
  )

  const addToGroup = useCallback(() => {
    setStep({ type: Steps.AddToGroup })
  }, [])

  const createGroup = useCallback(
    async (group: Omit<Group, 'id'>) => {
      const { createGroup: createdGroup } = await mutations.createGroup({
        group: {
          name: group.name,
          members: selected.toArray(),
          description: group.description,
        },
      })

      setSelected(Set())
      createdGroup.group.id ? setStep({ type: Steps.Success }) : resetStep()
    },
    [selected, resetStep, mutations],
  )

  const addSelectedContactsToGroup = useCallback(
    (minimalGroup: Group, newMembers: string[]) => {
      mutations.updateGroup({
        group: {
          id: minimalGroup.id,
          newMembers: newMembers,
        },
      })

      setSelected(Set())
      setStep({ type: Steps.Success })
    },
    [mutations],
  )

  const confirmExport = useCallback(() => {
    setStep({
      type: Steps.ConfirmExport,
      email: account.email,
    })
  }, [account])

  const exportContacts = useCallback(async () => {
    setStep({
      type: Steps.ExportProgress,
    })
    await exportContactsCsvFrom('contacts', selected)
  }, [selected])

  const saveContact = (contact: FormContact) => {
    mutations.createContact({
      contact: { ...createContactInput(contact), id: contact.id || uuid() },
    })
    actions.toggleIsNewContactModalOpen()
  }

  return {
    step,
    setStep,
    resetStep,
    paginatedContacts,
    contactsCount,
    deleteSelectedContacts,
    shareSelectedContacts,
    confirmShare,
    viewHistory,
    toggleSelectAll,
    setPage: sharedHooks.setPage,
    page: sharedHooks.page,
    setTerms: sharedHooks.setTerms,
    setSearchDuplicate: sharedHooks.setSearchDuplicate,
    setFilters: sharedHooks.setFilters,
    clearSearch: sharedHooks.clearSearch,
    toggleSelect,
    isEveryContactSelected: isEveryFilteredContactSelected,
    selectionMap,
    editContact,
    isLoading: loading > 0,
    search: sharedHooks.search,
    doneEditing,
    confirmDelete,
    selectedContacts: allContactsIds.filter(contact =>
      selected.contains(contact.id),
    ),
    countries: sharedHooks.countries,
    actions,
    sendCard,
    sendCampaign,
    addToGroup,
    groups: sharedHooks.groups,
    createGroup,
    addSelectedContactsToGroup,
    selectCampaign,
    confirmExport,
    exportContacts,
    saveContact,
    reset,
  }
}

export default Hooks
