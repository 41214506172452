import { css } from '@emotion/core'
import { Div, Icon } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CoachingGoals } from 'src/coaching/components/CoachingGoals'

interface Props {
  graphicURL?: string
  onClick: () => void
  isActive: boolean
  title: string
  coachTitle: string
  isLocked: boolean
}

export const QualificationTab: React.FC<Props> = props => {
  const { onClick, isActive, title, coachTitle, isLocked, graphicURL } = props
  return (
    <Div
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={isLocked ? undefined : () => onClick()}
      position="relative"
      overflow="hidden"
      borderRadius={{ top: 'default' }}
    >
      {isLocked && (
        <Div
          position="absolute"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={10}
        >
          <div
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              backdrop-filter: blur(4px);
            `}
          >
            <Div zIndex={1}>
              <Icon name="lock" size="medium" primaryColor="warning" />
            </Div>
          </div>
        </Div>
      )}
      <CoachingGoals
        isActive={isActive}
        graphicURL={graphicURL}
        title={title}
        coachTitle={coachTitle}
      />
    </Div>
  )
}
