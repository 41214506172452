import { Button, Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { InfoAvatar, InfoAvatarType } from './components/InfoAvatar'
import { PricingStamp, PricingStampProps } from './components/PricingStamp'
import { UpsaleItem, UpsaleItemProps } from './components/UpsaleItem'
import { UpsaleBackground } from './constants'

type UpsaleBannerProps = {
  background?: string
  title: string
  titleSize?: string
  titleColor?: string
  description?: string
  descriptionSize?: string
  descriptionColor?: string
  upsaleItem: UpsaleItemProps
  primaryAction?: {
    title: string
    onClick: () => void
    backgroundColor: string
    textColor?: string
  }
  secondaryAction?: {
    title: string
    onClick: () => void
    backgroundColor: string
    textColor?: string
  }
  pricingStamp?: PricingStampProps
  infoAvatar?: InfoAvatarType
}

export const UpsaleBanner: React.FC<UpsaleBannerProps> = props => {
  const {
    background = `data:image/png;base64, ${UpsaleBackground}`,
    title,
    description,
    upsaleItem,
    primaryAction,
    secondaryAction,
    pricingStamp,
    infoAvatar,
  } = props

  const containerWidth = document.getElementById('banner-container')
    ?.clientWidth

  return (
    <Flex
      id="banner-container"
      backgroundImage={background}
      backgroundSize="cover"
      backgroundPosition="center"
      borderRadius="default"
      overflow="hidden"
      position="relative"
      flexDirection={
        containerWidth && containerWidth < 800 ? 'column' : undefined
      }
    >
      {pricingStamp && (
        <Div
          position="absolute"
          bottom={containerWidth && containerWidth > 800 ? '-28px' : undefined}
          top={containerWidth && containerWidth < 800 ? '-28px' : undefined}
          left={containerWidth && containerWidth > 800 ? '-25px' : undefined}
          right={containerWidth && containerWidth < 800 ? '-25px' : undefined}
        >
          <PricingStamp {...pricingStamp} />
        </Div>
      )}
      <Flex
        id="upsale-content"
        width="100%"
        justifyContent="center"
        flexWrap="wrap"
        inset={{ top: 'x_5', horizontal: 'x2' }}
      >
        <Flex
          justifyContent="center"
          outset={
            pricingStamp && containerWidth && containerWidth < 800
              ? { right: '100px', top: 'x1_5', left: 'x2' }
              : { horizontal: 'x2', top: 'x1_5' }
          }
        >
          <Text
            type="body"
            color="inverseBody"
            style={{ fontSize: '15px', fontFamily: 'montserrat' }}
            alignment={
              pricingStamp && containerWidth && containerWidth < 800
                ? 'left'
                : 'center'
            }
            lineHeight={1.3}
          >
            <span
              style={{
                color: '#fff',
                fontWeight: 'bold',
                paddingRight: '4px',
                fontSize: '15px',
                fontFamily: 'montserrat',
              }}
            >
              {title.toUpperCase()}
            </span>
            {description?.toUpperCase()}
          </Text>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <UpsaleItem {...upsaleItem} containerWidth={containerWidth} />
        </Flex>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        rowGap="x1"
        backgroundColor="#00000042"
      >
        {infoAvatar && <InfoAvatar {...infoAvatar} />}
        <Flex
          id="action-item-container"
          inset="x2"
          justifyContent="center"
          alignItems="center"
          flexDirection={
            containerWidth && containerWidth > 800 ? 'column' : undefined
          }
          columnGap="x1"
          rowGap="x1"
          width="100%"
        >
          <Button
            onClick={primaryAction?.onClick}
            backgroundColorOverride={primaryAction?.backgroundColor}
            title={primaryAction?.title}
            textColorOverride={primaryAction?.textColor}
            fullWidth={true}
          />
          {secondaryAction && (
            <Button
              onClick={secondaryAction?.onClick}
              backgroundColorOverride={secondaryAction?.backgroundColor}
              title={secondaryAction.title}
              textColorOverride={secondaryAction?.textColor}
              fullWidth={true}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
