import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

export type PricingStampProps = {
  title: string
  titleColor?: string
  titleSize?: string
  price: string
  priceColor?: string
  priceSize?: string
  stampSize?: string
}

export const PricingStamp: React.FC<PricingStampProps> = props => {
  const {
    title,
    titleColor = '#F8924F',
    titleSize = '12px',
    price,
    priceColor = '#383d74',
    priceSize = '24px',
    stampSize = '140px',
  } = props

  const containerWidth = document.getElementById('banner-container')
    ?.clientWidth
  return (
    <Div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={stampSize}
      height={stampSize}
      style={{
        padding: ' 0.25rem',
      }}
      borderRadius="circle"
      transform={
        containerWidth && containerWidth > 800
          ? 'rotate(345deg)'
          : 'rotate(11deg)'
      }
      inset="x1"
    >
      <svg
        width={451}
        height={451}
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M184.221 3.71101C186.888 0.512565 191.749 -0.164987 195.287 2.16843L231.08 25.7709C233.549 27.3992 236.773 27.6085 239.457 26.3146L277.345 8.04667C281.131 6.22146 285.772 7.44908 287.992 10.8627L311.722 47.3584C313.243 49.6963 315.976 51.0906 318.888 51.0135L361.711 49.8798C365.807 49.7714 369.349 52.5471 369.976 56.3566L377.117 99.7683C377.543 102.356 379.346 104.559 381.897 105.608L422.5 122.303C426.128 123.795 428.087 127.527 427.139 131.143L415.994 173.686C415.345 176.162 416.05 178.779 417.874 180.665L448.837 212.678C451.479 215.41 451.669 219.527 449.288 222.464L421.878 256.283C420.219 258.33 419.765 261.024 420.671 263.453L436.166 305.016C437.496 308.584 435.856 312.531 432.305 314.304L393.906 333.481C391.393 334.736 389.76 337.136 389.6 339.81L387.015 383.224C386.781 387.151 383.42 390.271 379.243 390.439L336.762 392.147C333.791 392.267 331.126 393.902 329.813 396.412L310.122 434.046C308.222 437.678 303.642 439.288 299.675 437.719L260.038 422.044C257.244 420.94 254.029 421.388 251.696 423.207L218.595 449.022C215.282 451.605 210.402 451.313 207.463 448.356L177.017 417.711C174.987 415.667 171.932 414.829 169.052 415.525L127.87 425.479C123.82 426.458 119.642 424.388 118.208 420.694L102.349 379.851C101.373 377.336 99.0697 375.485 96.277 374.969L53.6162 367.092C49.672 366.363 46.8851 363.033 47.0581 359.256L49.0739 315.251C49.1916 312.68 47.927 310.225 45.7084 308.719L9.04973 283.831C5.86624 281.67 4.7788 277.677 6.46515 274.342L26.2276 235.258C27.3918 232.956 27.26 230.259 25.8761 228.067L2.09379 190.392C0.0724489 187.19 0.796191 183.084 3.80797 180.667L37.5196 153.612C39.6372 151.913 40.6837 149.312 40.2933 146.72L33.7445 103.225C33.1699 99.4087 35.7041 95.786 39.6578 94.7723L80.9894 84.1754C83.8003 83.4547 85.9865 81.3746 86.7206 78.7225L98.1804 37.3213C99.2523 33.4489 103.316 31.0079 107.501 31.722L149.393 38.869C152.36 39.3752 155.382 38.2953 157.243 36.0634L184.221 3.71101Z"
          fill="white"
        />
      </svg>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="absolute"
      >
        <Text
          type="body"
          content={price}
          style={{ color: priceColor, fontSize: priceSize }}
          weight="extraBold"
          isItalic={true}
        />
        <Div width="80%">
          <Text
            type="body"
            content={title}
            style={{ color: titleColor, fontSize: titleSize }}
            alignment="center"
            lineHeight={0.8}
            isItalic={true}
            whiteSpace="normal"
          />
        </Div>
      </Flex>
    </Div>
  )
}
