import { getSponsorBySlug, getSponsors } from 'src/legacy_graphql'
import useQueryFutures from './useQueryFutures'
import useQueryParams from './useQueryParams'

const useSponsorFromQueryParam = () => {
  const { sponsor: qPSponsor } = useQueryParams()
  const sponsorFromQPGenId = qPSponsor && parseInt(qPSponsor, 10)

  const [fetchSponsor] = useQueryFutures(
    sponsorFromQPGenId
      ? getSponsors({ search: sponsorFromQPGenId.toString() })
      : qPSponsor
      ? getSponsorBySlug({ slug: qPSponsor })
      : undefined,
  )

  const sponsor = Array.isArray(fetchSponsor?.value)
    ? fetchSponsor?.value[0]
    : fetchSponsor?.value

  return {
    hasSponsorQP: !!qPSponsor,
    fetchSponsor,
    sponsor,
  }
}

export default useSponsorFromQueryParam
