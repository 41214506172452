import React from 'react'
import {
  Div,
  Flex,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import FourOFour from 'src/404/404'
import { useSaleorOrder } from 'src/react_query/queries/hooks'
import { ConfirmOrderRoute } from '../routes/ConfirmOrderRoute'
import { ProductSummary } from '../components/product_summary/ProductSummary'
import { PriceBreakdown } from '../components/price_breakdown/PriceBreakdown'
import { Button } from 'src/design_system/components/Button/Button'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { PaymentMethodCard } from '../components/payment_method_card/PaymentMethodCard'
import { BillingAddressCard } from '../components/billing_address_card/BillingAddressCard'
import { useActions, useSelector } from 'src/hooks'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PDFDocument } from './PDFDocument'

type Props = {
  route: ConfirmOrderRoute
}

export const ConfirmOrderPage = (props: Props) => {
  const { route } = props
  const { data, isLoading, error } = useSaleorOrder({ id: route.id })

  const actions = useActions()
  const { width } = useSelector(state => state.window)
  const isTabletBreakPoint = width <= 900
  const isMobile = width < 500
  const decodeOrderId = (id: string): [string | null, string | null] => {
    const parts = atob(id).split(':')
    if (parts.length >= 2) {
      return [parts[0], parts[1]]
    }
    return [null, null]
  }

  const [, orderIdDetails] = decodeOrderId(data?.id || '')

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingSpinner size="xLarge" />
        <Text type="subtitle" alignment="center">
          Loading Order, please wait...
        </Text>
      </div>
    )
  }

  if (error || !data) {
    return <FourOFour />
  }

  return (
    <>
      {data && (
        <Flex
          id="printableArea"
          flexDirection="column"
          rowGap="x6"
          width="100%"
          position="relative"
          maxWidth="1064px"
          height="100%"
          style={{ fontFamily: 'Montserrat, san serif', margin: '0 auto' }}
          inset={{ top: '64px', bottom: '108px', horizontal: '24px' }}
        >
          <Flex
            alignItems={isMobile ? 'flex-start' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
            columnGap="x1"
            inset={{ horizontal: 'x1_5' }}
          >
            <Icon name="smile_card_w_hearts" color="#059669" size={40} />
            <Text type="body" style={{ fontSize: '20px', color: '#059669' }}>
              <b style={{ fontFamily: 'Montserrat, san serif' }}>Awesome! </b>
              Way to go, your Order is Confirmed
            </Text>
          </Flex>
          <Flex
            width="100%"
            flexDirection={width <= 700 ? 'column' : 'row'}
            rowGap="3rem"
          >
            <Flex
              flexDirection="column"
              rowGap="x1_5"
              maxWidth="600px"
              outset={{ right: 'x4' }}
            >
              <Text
                type="body"
                content={`Hi, ${data.user?.firstName} ${data.user?.lastName}`}
                style={{ fontSize: '16px', fontWeight: 700, color: '#171717' }}
              />
              <Text
                inset={{ right: !isTabletBreakPoint ? 'x4' : undefined }}
                type="body"
                content="Thank you for your order, and don't worry! We have sent you all the details to your email along with the invoice of this order"
                lineHeight={1.4}
                style={{ fontSize: '16px', color: '#6B7280' }}
              />
            </Flex>
          </Flex>
          <Flex
            justifyContent="flex-start"
            rowGap="x1"
            alignItems={isMobile ? 'center' : 'stretch'}
            inset={{ horizontal: 'x1_5' }}
            flexDirection={isTabletBreakPoint ? 'column' : 'row'}
          >
            <Flex
              flexDirection="column"
              justifyContent="flex-start"
              width="100%"
              alignItems="flex-start"
            >
              <Flex
                flexDirection="row"
                alignItems={isTabletBreakPoint ? 'flex-start' : 'center'}
                justifyContent="flex-start"
              >
                <Icon name="promptings_logo" size={28} color="#404040" />
                <Text
                  type="body"
                  content="Order No. 879798"
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  }}
                  whiteSpace="nowrap"
                >
                  Order No
                </Text>
              </Flex>
              <Text
                type="body"
                weight="bold"
                style={{
                  overflowWrap: 'anywhere',
                  fontSize: '14px',
                  color: '#000',
                  fontFamily: 'Montserrat, san serif',
                  paddingLeft: '2px',
                }}
              >
                {orderIdDetails}
              </Text>
            </Flex>
            <Div
              display="flex"
              flexDirection="row"
              columnGap="x1"
              justifyContent="center"
              alignItems="center"
              backgroundColor="#fff"
              inset={{ vertical: '6px', horizontal: '14px' }}
              style={{ borderRadius: '6px', whiteSpace: 'nowrap' }}
            >
              <Icon name="file" size={15} color="#000" />
              <PDFDownloadLink
                document={<PDFDocument {...data} />}
                fileName={`order-${data.id}.pdf`}
                style={{
                  color: '#000',
                  fontSize: '12px',
                  fontWeight: 600,
                  fontFamily: 'Montserrat, san serif',
                }}
              >
                {({ loading }) =>
                  loading ? 'Preparing document...' : 'Download PDF receipt'
                }
              </PDFDownloadLink>
            </Div>
          </Flex>
          {/* breakdowns */}
          <ProductSummary products={data?.lines} label="Purchased Product(s)" />
          <PriceBreakdown totalPrice={data?.total} />
          {/* payment and billing */}
          <Flex flexDirection="row" width="100%" columnGap="x2_5">
            {data.payments.map((payment, i) => (
              <>
                {payment.creditCard && (
                  <Flex key={i} flexDirection="column" width="50%" rowGap="x2">
                    <Text
                      type="body"
                      content="Payment Method"
                      style={{
                        fontSize: '18px',
                        fontWeight: 700,
                        color: '#6B7280',
                      }}
                    />
                    <PaymentMethodCard cardDetail={payment} />
                  </Flex>
                )}
              </>
            ))}
            {data.isShippingRequired && data?.shippingAddress && (
              <Flex flexDirection="column" width="50%" rowGap="x2">
                <Text
                  type="body"
                  content="Shipping Address"
                  style={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#6B7280',
                  }}
                />
                <BillingAddressCard address={data?.shippingAddress} />
              </Flex>
            )}
          </Flex>
          {/*  app download  */}
          <Flex
            flexDirection={isTabletBreakPoint ? 'column-reverse' : 'row'}
            justifyContent="space-between"
          >
            <Flex flexDirection="column" maxWidth="368px">
              <Text
                type="body"
                content="Did you know? We have an App"
                style={{ fontSize: '16px', fontWeight: 700, color: '#171717' }}
              />
              <Text
                type="body"
                content="Download now to access cards and gifts and tons of other stuff that I cant read right now"
                lineHeight={1.3}
                style={{ fontSize: '12px', color: '#323232' }}
              />
              <Flex flexDirection="row" inset={{ top: 'x2' }} columnGap="x2">
                <Div
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/us/app/sendoutcards/id878652134',
                    )
                  }
                  cursor="pointer"
                >
                  <Icon name="apple_store" size={130} />
                </Div>
                <Div
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/search?q=sendoutcards+android+app&c=apps&hl=en&gl=US',
                    )
                  }
                  cursor="pointer"
                >
                  <Icon name="google_play_store" size={140} />
                </Div>
              </Flex>
            </Flex>
            <Flex
              justifyContent="flex-end"
              width="100%"
              flexDirection={isMobile ? 'column' : 'row'}
              outset={{ bottom: isTabletBreakPoint ? 'x1_5' : '0' }}
              columnGap="x1_5"
              rowGap="x1_5"
            >
              <Button
                width={isMobile ? '100%' : undefined}
                title="Go to Dashboard"
                backgroundColor="transparent"
                border="2px solid #D4D4D8"
                textSize="16px"
                textColor="#000"
                textWeight={500}
                onClick={() => actions.openAccount()}
              />
              <Button
                width={isMobile ? '100%' : undefined}
                title="Send a Card"
                backgroundColor="#00AEEF"
                textSize="16px"
                textColor="#fff"
                padding="13px 64px"
                textWeight={500}
                onClick={actions.openCatalog}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}
