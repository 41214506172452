import {
  Div,
  Icon,
  LoadingSpinner,
  Span,
  Text,
  UserCard,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { formatLocation } from 'src/helpers'
import { useQueryFutures, useState } from 'src/hooks'
import { getDetailedCountries, getSponsor } from 'src/legacy_graphql'
import FindMySponsor from 'src/onboarding/components/FindMySponsor'

export const CheckoutFindMySponsor = (props: {
  onSelectedSponsor: (id: string) => void
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  presetSponsorId?: string
}) => {
  const { isExpanded, setIsExpanded, presetSponsorId } = props

  const [sponsorId, setSponsorId] = useState<string | undefined>(
    presetSponsorId,
  )

  const [detailedCountries, sponsor] = useQueryFutures(
    getDetailedCountries(),
    sponsorId ? getSponsor({ id: sponsorId }) : undefined,
  )

  const sponsorCountry = detailedCountries?.value?.find(
    country => country.postalName === sponsor?.value?.shippingAddress?.country,
  )

  const handleSelectSponsor = (spId: string, spGenId: string) => {
    setIsExpanded(false)
    setSponsorId(spId)
    props.onSelectedSponsor(spGenId)
  }

  return (
    <Div
      display={'flex'}
      flexDirection="column"
      style={{ gap: '8px' }}
      width="100%"
      maxWidth={'640px'}
    >
      {sponsor?.isUnresolved && <LoadingSpinner size="medium" />}
      {sponsorId && sponsor?.value && (
        <Div
          onClick={() => {
            setIsExpanded(true)
            setSponsorId(undefined)
          }}
          style={{
            backgroundColor: '#f5f6f7',
            padding: '0.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
          }}
        >
          <Text type="caption" weight="semiBold">
            <UserCard
              profileImage={sponsor.value.storefront?.mugshot?.url}
              firstName={sponsor.value.firstName}
              lastName={sponsor.value.lastName}
              size={'small'}
              caption={
                sponsor.value.shippingAddress
                  ? formatLocation(
                      sponsor.value.shippingAddress.city,
                      sponsor.value.shippingAddress.state,
                      sponsorCountry
                        ? sponsorCountry?.alpha2
                        : sponsor.value.shippingAddress.country,
                    )
                  : ''
              }
              country={
                sponsorCountry
                  ? { isoCode: sponsorCountry.alpha2, callingCode: 1 }
                  : undefined
              }
            />
          </Text>
        </Div>
      )}
      {!sponsorId && (
        <>
          <Div
            backgroundColor={'background'}
            display="flex"
            justifyContent={'space-between'}
            inset={'x1'}
            borderRadius="small"
            style={{ gap: '16px' }}
            width="100%"
          >
            <Div display={'flex'} style={{ gap: '16px' }}>
              <Div
                backgroundColor={'foreground'}
                inset="x_5"
                borderRadius={'small'}
                display="flex"
                justifyContent={'center'}
                alignItems="center"
              >
                <Icon name="socLogo" primaryColor={'primaryBrand'} size={24} />
              </Div>
              <Div display="flex" flexDirection="column" style={{ gap: '4px' }}>
                <Text
                  type="caption"
                  color="primaryHeading"
                  weight="semiBold"
                  lineHeight={1}
                >
                  Someone show you this product?
                </Text>
                <Text type="footnote" lineHeight={1}>
                  Optional*
                </Text>
              </Div>
            </Div>

            <Div>
              <Div
                backgroundColor={'foreground'}
                inset="x_5"
                borderRadius={'small'}
                display="flex"
                justifyContent={'center'}
                alignItems="center"
                cursor={'pointer'}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <Span
                  cursor="pointer"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  variants={{
                    expanded: {
                      transform: 'rotate(270deg)',
                    },
                    collapsed: { transform: 'rotate(90deg)' },
                  }}
                  initial={isExpanded ? 'expanded' : 'collapsed'}
                  animate={isExpanded ? 'expanded' : 'collapsed'}
                >
                  <Icon
                    name="chevron"
                    primaryColor="default"
                    orientation="right"
                    size={24}
                  />
                </Span>
              </Div>
            </Div>
          </Div>
          {isExpanded && (
            <Div
              inset="x1"
              display={'flex'}
              flexDirection="column"
              style={{ gap: '16px' }}
            >
              <Text type="caption">
                You can also hear the name “Sponsor”, to refer at the person who
                show you this product, we just want to make sure we give thanks
                to the right person.
              </Text>
              <FindMySponsor
                shouldHideHeading={true}
                onSelectedSponsor={handleSelectSponsor}
              />
            </Div>
          )}
        </>
      )}
    </Div>
  )
}
