import React from 'react'

import { FontFragment } from 'src/graphql/generated/graphql'
import { useSelector } from 'src/hooks'

const fontListStyles = {
  height: '200px',
  overflow: 'auto',
  marginTop: '15px',
  padding: '0 18px',
}

type FontListProps = {
  fontId?: string
  selectFont: (fontId: string) => void
  fonts: { fonts: FontFragment[]; signatures: FontFragment[] }
}

const FontList: React.FC<FontListProps> = props => {
  const { fontId, selectFont } = props
  const { isMobile } = useSelector(state => state.window)

  const fonts = [...props.fonts.fonts, ...props.fonts.signatures].sort(
    (a, b) => Number(b.isCustomFont) - Number(a.isCustomFont),
  )

  const currentFont = fonts.length
    ? fonts.find(font => font.id === fontId) || fonts[0]
    : {
        id: 'f631775b-a440-48f7-8115-bbfb0124728f',
        fontName: 'HelveticaV2',
        displayName: 'Arial',
      }
  const selectedFont = {
    position: 'absolute',
    top: 30,
    fontSize: '.95em',
    background:
      'linear-gradient(135deg, rgba(248, 78, 129, 0.7), rgba(204, 120, 225, 0.7))',
    borderBottom: 0,
    color: '#fff',
    padding: '0 15px',
    fontFamily: currentFont.fontName,
  }
  return (
    <div
      style={
        !isMobile ? fontListStyles : { marginTop: '15px', padding: '0 18px' }
      }
    >
      {!isMobile && (
        <h6 style={selectedFont as React.CSSProperties}>
          <span
            style={{
              fontFamily: '"Source Sans Pro", sans-serif',
              fontWeight: 500,
            }}
          >
            Selected:
          </span>
          {currentFont.displayName}
        </h6>
      )}
      <ul style={{ listStyleType: 'none' }}>
        {fonts.map((font, index) => {
          return (
            <li
              key={index}
              style={{
                fontFamily: font.fontName,
                cursor: 'pointer',
                marginBottom: 8,
                color:
                  currentFont.fontName === font.fontName ? '#e841b3' : '#000',
              }}
              onClick={() => {
                selectFont(font.id)
              }}
            >
              {font.displayName}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default FontList
