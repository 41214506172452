import * as fragments from 'src/legacy_graphql/fragments'
import * as queries from 'src/legacy_graphql/queries'
import { ListQuery } from 'src/legacy_graphql/ListQuery'
import { PaginatedQuery } from 'src/legacy_graphql/PaginatedQuery'
import { InfiniteQuery } from 'src/legacy_graphql/InfiniteQuery'
import { Query } from './Query'
import { Set } from 'immutable'
import { isNotNullOrUndefined, objectSize } from 'src/helpers'
import moment from 'moment'
import { IsUnresolved } from 'src/utils/Future'

export const campaignsPageSize = 9

type Tokens = {
  matchAny: (...values: (string | null | undefined)[]) => boolean
}

const tokens = (search: string | null | undefined): Tokens => {
  if (!search) {
    return {
      matchAny: () => true,
    }
  }

  const searchTokens = search.toLowerCase().split(' ')

  return {
    matchAny: (...values) => {
      const valueTokens = Set(
        values
          .filter(isNotNullOrUndefined)
          .flatMap(value => value.toLowerCase().split(' ')),
      )
      return searchTokens.every(searchToken =>
        valueTokens.contains(searchToken),
      )
    },
  }
}

const descendingIntegerId = (id: string): string =>
  id
    .padStart(20, '0')
    .split('')
    .map(digit => parseInt(digit, 10))
    .map(digit => 9 - digit)
    .map(digit => digit.toString())
    .join('')

const digits = '0123456789'.split('')

const descendingDateTime = (dateTime: string): string =>
  dateTime
    .split('')
    .map(character =>
      digits.includes(character)
        ? (9 - parseInt(character, 10)).toString()
        : character,
    )
    .join('')

const dateIsBetween = (
  date: fragments.OptionalYearDateFragment | null | undefined,
  start: string | null | undefined,
  end: string | null | undefined,
) =>
  !start ||
  !end ||
  (date &&
    moment({
      day: date.day,
      month: date.month,
      year: date.year ?? undefined,
    }).isBetween(moment(start), moment(end), 'day'))

const contactPredicate = (
  contact: fragments.ContactFragment,
  {
    search,
    contacts,
    groups,
    birthdayStartDate,
    birthdayEndDate,
    anniversaryStartDate,
    anniversaryEndDate,
    showSecondaryContacts,
    hasNoGroup,
  }: queries.GetMinimalContactsVariables,
) => {
  if (
    // No way to do this without querying groups
    groups?.length ||
    // No way to do this without querying groups
    typeof hasNoGroup === 'boolean'
  ) {
    throw new IsUnresolved()
  }
  return [
    tokens(search).matchAny(
      contact.firstName,
      contact.lastName,
      contact.company,
      contact.address1,
      contact.address2,
      contact.city,
      contact.state,
      contact.postalCode,
      contact.country,
    ),
    dateIsBetween(
      contact.anniversary,
      anniversaryStartDate,
      anniversaryEndDate,
    ),
    dateIsBetween(contact.birthday, birthdayStartDate, birthdayEndDate),
    !contacts?.length || contacts.includes(contact.id),
    showSecondaryContacts || contact.isPrimary,
  ].every(p => p)
}

const contactSortKey = ({
  id,
  firstName,
  lastName,
}: fragments.MinimalContactFragment) => `${lastName}${firstName}${id}`

const layoutsPredicate = (
  layout: fragments.DetailedLayoutFragment,
  variables: Omit<queries.GetDetailedLayoutsVariables, 'offset' | 'limit'>,
) =>
  layout.category === variables.category &&
  (variables.owner === undefined || layout.owner === variables.owner) &&
  (variables.dimensions === undefined ||
    layout.dimensions === variables.dimensions)

const layoutsSortKey = ({ id }: fragments.DetailedLayoutFragment) =>
  descendingIntegerId(id)

export * from 'src/legacy_graphql/queries'

// WRITE DECLARATIONS IN ALPHABETICAL ORDER

// DEPRECATED! Do NOT add anymore mutations declarations
// See the new Networking documentation

export const getAccounCreatedContents = Query<
  queries.GetAccountCreatedContentsData,
  undefined
>({
  operation: queries.GetAccountCreatedContents,
  mapData: data => data,
})

export const getAffiliateJoinMarketingContent = Query<
  queries.GetAffiliateJoinMarketingContentData,
  undefined
>({
  operation: queries.GetAffiliateJoinMarketingContent,
  mapData: data => data,
  hitCDNCache: true,
})

export const getAffiliateUpsaleMarketingContent = Query<
  queries.GetAffiliateUpsaleMarketingContentData,
  undefined
>({
  operation: queries.GetAffiliateUpsaleMarketingContent,
  mapData: data => data,
  hitCDNCache: true,
})

export const getAffiliateBonusInfo = Query<
  queries.GetAffiliateBonusInfoData,
  undefined
>({
  operation: queries.GetAffiliateBonusInfo,
  mapData: data => data,
  hitCDNCache: true,
})

export const getAffiliateUpsaleDialogMarketingContent = Query<
  queries.GetAffiliateUpsaleDialogMarketingContentData,
  undefined
>({
  operation: queries.GetAffiliateUpsaleDialogMarketingContent,
  mapData: data => data,
  hitCDNCache: true,
})

export const getAllContacts = ListQuery<
  queries.GetMinimalContactsData,
  'Contact',
  fragments.MinimalContactFragment,
  queries.GetAllContactsVariables
>({
  operation: queries.GetAllContacts,
  mapData: ({ paginatedContacts: { allContacts } }) => allContacts,
  resultTypename: 'Contact',
  isValidResult: fragments.isMinimalContactFragment,
  filter: () => true,
  orderBy: contactSortKey,
})

export const getContactsIds = ListQuery<
  queries.GetContactsIdsData,
  'Contact',
  fragments.OnlyIdsContactFragment,
  queries.GetContactsIdsVariables
>({
  operation: queries.GetContactsIds,
  mapData: ({ paginatedContacts: { allContacts } }) => allContacts,
  resultTypename: 'Contact',
  isValidResult: fragments.isOnlyIdsContactFragment,
  filter: () => true,
  orderBy: ({ id }) => `${id}`,
})

export const getAllGroups = ListQuery<
  queries.GetAllGroupsData,
  'Group',
  fragments.GroupFragment,
  undefined
>({
  operation: queries.GetAllGroups,
  mapData: ({ groups }) => groups,
  resultTypename: 'Group',
  isValidResult: fragments.isGroupFragment,
  filter: () => true,
  orderBy: ({ id, name }) => `${name}${id}`,
})

export const getAvailableDailyHeartfelt = Query<
  queries.GetAvailableDailyHeartfeltData,
  undefined,
  queries.GetAvailableDailyHeartfeltData['account']
>({
  operation: queries.GetAvailableDailyHeartfelt,
  mapData: data => data.account,
})

export const getAvailableUnlimitedDailyHeartfelt = Query<
  queries.GetAvailableUnlimitedDailyHeartfeltData,
  undefined,
  queries.GetAvailableUnlimitedDailyHeartfeltData['account']
>({
  operation: queries.GetAvailableUnlimitedDailyHeartfelt,
  mapData: data => data.account,
})

export const getAddPaymentSettingsDailyCap = Query<
  queries.GetAddPaymentSettingsDailyCapData,
  undefined,
  queries.GetAddPaymentSettingsDailyCapData['account']
>({
  operation: queries.GetAddPaymentSettingsDailyCap,
  mapData: data => data.account,
})

export const getCardCatalog = Query<queries.GetCatalogData, undefined>({
  operation: queries.GetCatalog,
  mapData: data => data,
})

export const getCardCategory = Query<
  queries.GetCardCategoryData,
  queries.GetCardCategoryVariables,
  fragments.CardCategoryFragment
>({
  operation: queries.GetCardCategory,
  mapData: data => data.cardCategory,
})

export const getCardCategories = Query<
  queries.GetCardCategoriesData,
  undefined,
  fragments.CardCategoryFragment[]
>({
  operation: queries.GetCardCategories,
  mapData: data => data.cardCategories,
})

export const getDesignColorsBySendableCardID = Query<
  queries.GetDesignColorsBySendableCardIDData,
  queries.GetDesignColorsBySendableCardIDVariables,
  queries.GetDesignColorsBySendableCardIDData['sendableCard']['designColors']
>({
  operation: queries.GetDesignColorsBySendableCardID,
  mapData: data => data.sendableCard.designColors,
})

export const getItemCategory = Query<
  queries.GetItemCategoryData,
  queries.GetItemCategoryVariables,
  queries.GetItemCategoryData['itemCategory']
>({
  operation: queries.GetItemCategory,
  mapData: data => data.itemCategory,
})

export const getCollection = Query<
  queries.GetCollectionData,
  queries.GetCollectionVariables,
  fragments.CollectionFragment
>({
  operation: queries.GetCollection,
  mapData: data => data.collection,
})

export const getContact = Query<
  queries.GetContactData,
  queries.GetContactVariables,
  fragments.DetailedContactFragment
>({
  operation: queries.GetContact,
  mapData: data => data.contact,
})

export const getDetailedReferralCredits = Query<
  queries.GetDetailedReferralCreditsData,
  undefined,
  queries.GetDetailedReferralCreditsData['account']['detailedReferralCredits']
>({
  operation: queries.GetDetailedReferralCredits,
  mapData: data => data.account.detailedReferralCredits,
})

export const getCustomCards = InfiniteQuery<
  queries.GetCustomCardsData,
  'Card',
  fragments.CustomCardFragment,
  queries.GetCustomCardsVariables
>({
  operation: queries.GetCustomCards,
  mapData: data => data.cards,
  resultTypename: 'Card',
  pageSize: 20,
  isValidResult: fragments.isCustomCardFragment,
  filter: () => true,
  orderBy: ({ id }) => descendingIntegerId(id),
})

export const getDetailedCountries = Query<
  queries.GetDetailedCountriesData,
  undefined,
  queries.GetDetailedCountriesData['detailedCountries']
>({
  operation: queries.GetDetailedCountries,
  mapData: data => data.detailedCountries,
})

export const getDetailedLayouts = ListQuery<
  queries.GetDetailedLayoutsData,
  'Layout',
  fragments.DetailedLayoutFragment,
  queries.GetDetailedLayoutsVariables
>({
  operation: queries.GetDetailedLayouts,
  mapData: ({ layouts }) => layouts,
  resultTypename: 'Layout',
  isValidResult: fragments.isDetailedLayoutFragment,
  filter: layoutsPredicate,
  orderBy: layoutsSortKey,
})

export const getDraftedCards = InfiniteQuery<
  queries.GetDraftsData,
  'Card',
  fragments.DraftFragment,
  queries.GetDraftsVariables
>({
  operation: queries.GetDrafts,
  mapData: data => data.drafts,
  resultTypename: 'Card',
  pageSize: 20,
  isValidResult: fragments.isDraftFragment,
  filter: () => true,
  orderBy: ({ updatedAt }) => descendingDateTime(updatedAt),
})

export const getFairUseMarketingContent = Query<
  queries.GetFairUseMarketingContentData,
  undefined
>({
  operation: queries.GetFairUseMarketingContent,
  mapData: data => data,
  hitCDNCache: true,
})

export const getPaymentSettingsMarketingContent = Query<
  queries.GetPaymentSettingsMarketingContentData,
  undefined
>({
  operation: queries.GetPaymentSettingsMarketingContent,
  mapData: data => data,
  hitCDNCache: true,
})

export const getCreditCardManagerMarketingContent = Query<
  queries.GetCreditCardManagerMarketingContentData,
  undefined
>({
  operation: queries.GetCreditCardManagerMarketingContent,
  mapData: data => data,
  hitCDNCache: true,
})

export const getFeaturedCards = Query<
  queries.GetFeaturedCardsData,
  undefined,
  fragments.MinimalSendableCardFragment[]
>({
  operation: queries.GetFeaturedCards,
  mapData: data => data.featuredCards,
})

export const getFlags = Query<
  queries.GetFlagsData,
  undefined,
  fragments.FlagFragment[]
>({
  operation: queries.GetFlags,
  mapData: data => data.flags,
})

export const getGiftCategories = Query<
  queries.GetGiftCategoriesData,
  undefined,
  queries.GetGiftCategoriesData['giftCategories']
>({
  operation: queries.GetGiftCategories,
  mapData: data => data.giftCategories,
})

export const getGift = Query<
  queries.GetGiftData,
  queries.GetGiftVariables,
  queries.GetGiftData['gift']
>({
  operation: queries.GetGift,
  mapData: data => data.gift,
})

export const getGroup = Query<
  queries.GetGroupData,
  queries.GetGroupVariables,
  fragments.DetailedGroupFragment
>({
  operation: queries.GetGroup,
  mapData: data => data.group,
})

export const getImages = InfiniteQuery<
  queries.GetImagesData,
  'Image',
  fragments.UserImageFragment,
  queries.GetImagesVariables
>({
  operation: queries.GetImages,
  mapData: data => data.images,
  resultTypename: 'Image',
  pageSize: 20,
  isValidResult: fragments.isUserImageFragment,
  filter: () => true,
  orderBy: ({ createdAt }) => descendingDateTime(createdAt),
})

export const getInfiniteDetailedLayouts = InfiniteQuery<
  queries.GetDetailedLayoutsData,
  'Layout',
  fragments.DetailedLayoutFragment,
  queries.GetDetailedLayoutsVariables
>({
  operation: queries.GetDetailedLayouts,
  mapData: ({ layouts }) => layouts,
  resultTypename: 'Layout',
  isValidResult: fragments.isDetailedLayoutFragment,
  pageSize: 20,
  filter: layoutsPredicate,
  orderBy: layoutsSortKey,
})

export const getInfiniteGifts = InfiniteQuery<
  queries.GetGiftsData,
  'Gift',
  fragments.GiftFragment,
  queries.GetGiftsVariables
>({
  operation: queries.GetGifts,
  mapData: data => data.gifts,
  resultTypename: 'Gift',
  pageSize: 20,
  isValidResult: fragments.isGiftFragment,
  filter: () => true,
  orderBy: ({ id }) => `${id}`,
})

export const getLegacyPicturePlusCards = InfiniteQuery<
  queries.GetLegacyPicturePlusCardsData,
  'SendableCard',
  fragments.MinimalSendableCardFragment,
  queries.GetLegacyPicturePlusCardsVariables
>({
  operation: queries.GetLegacyPicturePlusCards,
  mapData: data => data.legacyPicturePlusCards,
  resultTypename: 'SendableCard',
  pageSize: 20,
  isValidResult: fragments.isMinimalSendableCardFragment,
  filter: () => true,
  orderBy: ({ id }) => descendingIntegerId(id),
})

export const getMarketingContent = Query<
  queries.GetMarketingContentData,
  undefined
>({
  operation: queries.GetMarketingContent,
  mapData: data => data,
  hitCDNCache: true,
})

export const getMarketingParagraphById = Query<
  queries.GetMarketingParagraphByIdData,
  queries.GetMarketingParagraphByIdVariables,
  fragments.MarketingParagraphFragment
>({
  operation: queries.GetMarketingParagraphById,
  mapData: data => data.paragraph,
})

export const getMinimalContacts = ListQuery<
  queries.GetMinimalContactsData,
  'Contact',
  fragments.MinimalContactFragment,
  queries.GetMinimalContactsVariables
>({
  operation: queries.GetMinimalContacts,
  mapData: data => data.paginatedContacts.allContacts,
  resultTypename: 'Contact',
  isValidResult: fragments.isMinimalContactFragment,
  filter: (contact, variables) => {
    if (fragments.isContactFragment(contact)) {
      return contactPredicate(contact, variables)
    } else if (objectSize(variables) !== 0) {
      throw new IsUnresolved()
    } else {
      return true
    }
  },
  orderBy: contactSortKey,
})

export const getInfiniteMinimalContacts = InfiniteQuery<
  queries.GetPaginatedMinimalContactsData,
  'Contact',
  fragments.MinimalContactFragment,
  queries.GetPaginatedMinimalContactsVariables
>({
  operation: queries.GetPaginatedMinimalContacts,
  mapData: data => data.paginatedContacts.contacts,
  resultTypename: 'Contact',
  pageSize: 20,
  isValidResult: fragments.isMinimalContactFragment,
  filter: (contact, variables) => {
    if (fragments.isContactFragment(contact)) {
      return contactPredicate(contact, variables)
    } else if (objectSize(variables) !== 0) {
      throw new IsUnresolved()
    } else {
      return true
    }
  },
  orderBy: contactSortKey,
})

export const getPaginatedCampaigns = PaginatedQuery<
  queries.GetPaginatedCampaignsData,
  'Campaign',
  fragments.MinimalCampaignFragment,
  queries.GetPaginatedCampaignsVariables
>({
  operation: queries.GetPaginatedCampaigns,
  mapData: ({ paginatedCampaigns: { campaigns: results, count } }) => ({
    results,
    count,
  }),
  resultTypename: 'Campaign',
  pageSize: campaignsPageSize,
  isValidResult: fragments.isMinimalCampaignFragment,
  filter: (campaign, { search, shareable }) => {
    if (shareable !== undefined && shareable !== campaign.isShareable) {
      return false
    }

    return tokens(search).matchAny(campaign.name)
  },
  orderBy: ({ createdAt }) => createdAt,
})

export const getPaginatedContacts = PaginatedQuery<
  queries.GetPaginatedContactsData,
  'Contact',
  fragments.ContactFragment,
  queries.GetPaginatedContactsVariables
>({
  operation: queries.GetPaginatedContacts,
  mapData: ({ paginatedContacts: { contacts: results, count } }) => ({
    results,
    count,
  }),
  resultTypename: 'Contact',
  pageSize: 20,
  isValidResult: fragments.isContactFragment,
  filter: contactPredicate,
  orderBy: contactSortKey,
})

export const getPaginatedDraftedOrders = PaginatedQuery<
  queries.GetDraftedOrdersData,
  'Order',
  fragments.MinimalOrderFragment,
  queries.GetDraftedOrdersVariables
>({
  operation: queries.GetDraftedOrders,
  mapData: ({ paginatedOrders: { orders: results, count } }) => ({
    results,
    count,
  }),
  resultTypename: 'Order',
  pageSize: 20,
  isValidResult: fragments.isMinimalOrderFragment,
  filter: () => true,
  orderBy: ({ updatedAt }) => descendingDateTime(updatedAt),
})

export const getPaginatedGroups = PaginatedQuery<
  queries.GetPaginatedGroupsData,
  'Group',
  fragments.GroupFragment,
  queries.GetPaginatedGroupsVariables
>({
  operation: queries.GetPaginatedGroups,
  mapData: ({ paginatedGroups: { groups: results, count } }) => ({
    results,
    count,
  }),
  resultTypename: 'Group',
  pageSize: 20,
  isValidResult: fragments.isGroupFragment,
  filter: (group, { search, groups, contacts }) => {
    if ((groups && groups.length > 0) || (contacts && contacts.length > 0)) {
      throw new IsUnresolved()
    }
    return tokens(search).matchAny(group.name, group.description)
  },
  orderBy: ({ id, name }) => `${name}${id}`,
})

export const getPaginatedRecipients = PaginatedQuery<
  queries.GetPaginatedRecipientsData,
  'Recipient',
  fragments.RecipientFragment,
  queries.GetPaginatedRecipientsVariables
>({
  operation: queries.GetPaginatedRecipients,
  mapData: ({ paginatedRecipients: { recipients: results, count } }) => ({
    results,
    count,
  }),
  resultTypename: 'Recipient',
  pageSize: 20,
  isValidResult: fragments.isRecipientFragment,
  filter: () => true,
  orderBy: ({ id }) => `${id}`,
})

export const getPhoneNumberCountries = Query<
  queries.GetPhoneNumberCountriesData,
  undefined,
  fragments.PhoneNumberCountryFragment[]
>({
  operation: queries.GetPhoneNumberCountries,
  mapData: data => data.phoneNumberCountries,
  hitCDNCache: true,
})

export const getPlans = ListQuery<
  queries.GetPlansData,
  'Plan',
  fragments.PlanFragment,
  undefined
>({
  operation: queries.GetPlans,
  mapData: data => data.plans,
  resultTypename: 'Plan',
  isValidResult: fragments.isPlanFragment,
  filter: () => true,
  orderBy: ({ id }) => `${id}`,
})

export const getPlansDescriptions = ListQuery<
  queries.GetPlansDescriptionsData,
  'PlansDescription',
  fragments.PlanDescriptionFragment,
  undefined
>({
  operation: queries.GetPlansDescriptions,
  mapData: data => data.plansDescriptions,
  resultTypename: 'PlansDescription',
  isValidResult: fragments.isPlanDescriptionFragment,
  filter: () => true,
  orderBy: ({ id }) => `${id}`,
})

export const getPlanAddonsDescriptions = ListQuery<
  queries.GetPlanAddonsDescriptionsData,
  'PlansDescription',
  fragments.PlanDescriptionFragment,
  undefined
>({
  operation: queries.GetPlanAddonsDescriptions,
  mapData: data => data.planAddonsDescriptions,
  resultTypename: 'PlansDescription',
  isValidResult: fragments.isPlanDescriptionFragment,
  filter: () => true,
  orderBy: ({ id }) => `${id}`,
})

export const getPossibleSponsors = ListQuery<
  queries.GetPossibleSponsorsData,
  'User',
  fragments.SponsorFragment,
  undefined
>({
  operation: queries.GetPossibleSponsors,
  mapData: data => data.account.possibleSponsors,
  resultTypename: 'User',
  isValidResult: fragments.isSponsorFragment,
  filter: () => true,
  orderBy: ({ firstName, lastName, id }) => `${lastName}${firstName}${id}`,
})

export const getBasicPostageCosts = Query<
  queries.GetBasicPostageCostsData,
  undefined
>({
  operation: queries.GetBasicPostageCosts,
  mapData: data => data,
})

export const getProductionInfo = Query<
  queries.GetProductionInfoData,
  queries.GetProductionInfoVariables,
  fragments.ProductionInfoFragment
>({
  operation: queries.GetProductionInfo,
  mapData: data => data.productionInfo,
})

export const getPrompitingsCoachTrainees = Query<
  queries.GetPrompitingsCoachTraineesData,
  undefined,
  fragments.TraineeFragment[] | undefined
>({
  operation: queries.GetPrompitingsCoachTrainees,
  mapData: data => data.account.promptingsCoach?.trainees,
})

export const getReceivedPendingMembershipInvites = Query<
  queries.GetReceivedPendingMembershipInvitesData,
  undefined,
  fragments.MembershipInviteFragment[]
>({
  operation: queries.GetReceivedPendingMembershipInvites,
  mapData: ({ receivedPendingMembershipInvites }) =>
    receivedPendingMembershipInvites,
})

export const getSendableCards = InfiniteQuery<
  queries.GetSendableCardsData,
  'SendableCard',
  fragments.MinimalSendableCardFragment,
  queries.GetSendableCardsVariables
>({
  operation: queries.GetSendableCards,
  mapData: data => data.sendableCards,
  resultTypename: 'SendableCard',
  pageSize: 20,
  isValidResult: fragments.isMinimalSendableCardFragment,
  filter: () => true,
  orderBy: ({ id }) => descendingIntegerId(id),
})

export const getSentCards = PaginatedQuery<
  queries.GetSentCardsData,
  'ProductionInfo',
  fragments.ProductionInfoFragment,
  queries.GetSentCardsVariables
>({
  operation: queries.GetSentCards,
  mapData: ({ paginatedSentCards: { sentCards: results, count } }) => ({
    results,
    count,
  }),
  resultTypename: 'ProductionInfo',
  pageSize: 20,
  isValidResult: fragments.isProductionInfoFragment,
  filter: (
    { dateToSend, recipient, rejectedCards, hold, fulfilled },
    { startDate, endDate, contacts, status, search },
  ) => {
    if (dateToSend && startDate && dateToSend < startDate) {
      return false
    }

    if (dateToSend && endDate && dateToSend > endDate) {
      return false
    }

    if (
      contacts &&
      recipient &&
      recipient.contact &&
      !contacts.includes(recipient.contact.id)
    ) {
      return false
    }

    if (status) {
      if (
        status !== 'PAYMENT_ERROR' &&
        recipient &&
        !recipient.paymentFailure
      ) {
        return false
      }

      if (status === 'REJECTED' && rejectedCards) {
        for (const rejectedCard in rejectedCards) {
          if (!rejectedCard) {
            return false
          }
        }
      } else {
        for (const rejectedCard in rejectedCards) {
          if (!rejectedCard) {
            return true
          }
        }
      }

      if (status === 'HELD' && (!hold || fulfilled)) {
        return false
      } else if (status === 'FULFILLED' && !fulfilled) {
        return false
      } else if (status === 'AWAITING_FULFILLMENT' && (hold || fulfilled)) {
        return false
      } else if (status === 'PENDING' && (hold || fulfilled)) {
        return false
      } else if (status === 'PAYMENT_ERROR' && fulfilled) {
        return false
      }
    }

    return tokens(search).matchAny(
      recipient?.contact?.firstName,
      recipient?.contact?.lastName,
      recipient?.address?.firstName,
      recipient?.address?.lastName,
      recipient?.address?.company,
      recipient?.address?.address1,
      recipient?.address?.city,
      recipient?.address?.state,
      recipient?.address?.postalCode,
      recipient?.address?.country,
      recipient?.card?.sendableCard?.title,
    )
  },
  orderBy: ({ dateToSend, recipient, id }) =>
    `${dateToSend && descendingDateTime(dateToSend)}
    ${recipient?.contact?.lastName ?? ''}
    ${recipient?.contact?.firstName ?? ''}
    ${id}`,
})

export const getSponsor = Query<
  queries.GetSponsorData,
  queries.GetSponsorVariables,
  fragments.SponsorFragment
>({
  operation: queries.GetSponsor,
  mapData: data => data.sponsor,
})

export const getSponsors = ListQuery<
  queries.GetSponsorsData,
  'User',
  fragments.SponsorFragment,
  queries.GetSponsorsVariables
>({
  operation: queries.GetSponsors,
  mapData: data => data.sponsors,
  resultTypename: 'User',
  isValidResult: fragments.isSponsorFragment,
  filter: (sponsor, { search }) => {
    return tokens(search).matchAny(sponsor.firstName, sponsor.lastName)
  },
  orderBy: ({ firstName, lastName }) => `${lastName}${firstName}`,
})

export const getSponsorBySlug = Query<
  queries.GetStorefrontData,
  queries.GetStorefrontVariables,
  fragments.SponsorFragment
>({
  operation: queries.GetStorefront,
  mapData: data => data.user,
})

export const getCoach = Query<
  queries.GetCoachData,
  queries.GetCoachVariables,
  fragments.CoachFragment
>({
  operation: queries.GetCoach,
  mapData: data => data.user,
})

export const getPricingPage = Query<queries.GetPricingPageData, undefined>({
  operation: queries.GetPricingPage,
  mapData: data => data,
})

export const getDoubleBonusReports = Query<
  queries.GetDoubleBonusReportsData,
  undefined
>({
  operation: queries.GetDoubleBonusReports,
  mapData: data => data,
})

export const getUserCardTokens = Query<
  queries.GetUserCardTokensData,
  undefined,
  queries.GetUserCardTokensData['account']
>({
  operation: queries.GetUserCardTokens,
  mapData: data => data.account,
})

// WRITE DECLARATIONS IN ALPHABETICAL ORDER
