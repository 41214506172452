import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { getPlans } from 'src/legacy_graphql'
import { getIconFromPlan } from '../pricingTile/getPlanIcon'
import { Features } from '../pricingTile/components/Tile'
import { PlanDetail } from '../pricingTile/PlanTiles'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useQueries, useSelector } from 'src/hooks'

type LegacyPlanType = {
  planId?: string
  maxWidth?: string
  minWidth?: string
}

export const LegacyPlanTile: FC<LegacyPlanType> = ({
  planId,
  minWidth = '60%',
  maxWidth = '775px',
}) => {
  const isTablet = useSelector(state => state.window.width <= 860)
  const responsiveStyles = {
    titleSize: isTablet ? '1.2rem' : '1.385rem',
    priceSize: isTablet ? '1.5rem' : '1.875rem',
    btnTitleSize: isTablet ? '.8rem' : '1rem',
    featuresSize: isTablet ? '.7rem' : '.89rem',
    cardPadding: isTablet ? 'x2' : 'x4',
    cardGap: isTablet ? 'x2_5' : 'x4',
  }

  const [plans] = useQueries(getPlans())
  const plan = plans.find(plan => plan.id === planId)

  const description = plan?.description
    ? JSON.parse(plan.description)
    : undefined
  const planFeatures: Features[] =
    description?.details?.map((detail: PlanDetail) => {
      if (detail.innerDetails) {
        return {
          feature: detail.detail,
          subFeatures: detail.innerDetails.map((d: PlanDetail) => d.detail),
          isNotIncluded: false,
        }
      }
      return detail.detail
    }) || []
  if (!plan) return <></>
  return (
    <Flex
      flexDirection="column"
      backgroundColor="foreground"
      borderRadius={'medium'}
      style={{
        borderTop: `4px solid ${plan.backgroundStartColor}`,
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
      }}
      inset={{ horizontal: 'x4', vertical: 'x3' }}
      justifyContent="center"
      alignItems="flex-start"
      minWidth={isTablet ? '225px' : minWidth}
      maxWidth={isTablet ? '350px' : maxWidth}
      flexWrap="wrap"
    >
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isTablet ? 'center' : 'flex-start'}
        width="100%"
        flexWrap={isTablet ? 'wrap' : 'nowrap'}
      >
        <Flex
          alignItems="center"
          width="100%"
          rowGap="x1"
          columnGap="x1"
          order={isTablet ? 2 : 1}
        >
          {getIconFromPlan(plan, false)}
          <Text
            weight="bold"
            content="Your Current Subscription"
            type="body"
            style={{ color: plan.backgroundStartColor }}
          />
        </Flex>

        <Div
          order={isTablet ? 1 : 2}
          backgroundColor="#fff"
          borderRadius="circle"
          boxShadow="light"
          height="fit-content"
          inset={{ horizontal: 'x1_5' }}
          outset={{ bottom: 'auto' }}
          style={{ marginLeft: isTablet ? 0 : 'auto' }}
          minWidth="fit-content"
        >
          <Text
            type="body"
            content={'legacy plan'}
            lineHeight={2}
            style={{
              fontSize: '.625rem',
              fontWeight: 700,
              color: plan.backgroundStartColor,
            }}
          />
        </Div>
      </Flex>
      <Spacer space="x1" />
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        rowGap="x3"
        columnGap="x3"
        alignItems="center"
        flexWrap="wrap"
      >
        <Flex flexDirection="column">
          <Text content={plan.title} type="subtitle" weight="bold" />
          <Spacer space="x1" />
          <Text
            content={`$${(plan.price.amount / 100).toString()}/mo`}
            type="title"
            color="primaryBody"
            weight="semiBold"
          />
        </Flex>
        <Spacer space="x3" orientation="horizontal" />
        <Flex flexDirection="column" rowGap="x_5">
          {planFeatures.map((feature, index) => (
            <Flex key={index} flexDirection="column">
              <Flex flexDirection="row" alignItems="flex-start" columnGap="x_5">
                <Div width="fit-content" height="fit-content">
                  <Icon
                    name="check"
                    color={plan.color ?? '#404040'}
                    size={14}
                  />
                </Div>
                <Text
                  type="caption"
                  content={
                    typeof feature === 'string' ? feature : feature.feature
                  }
                  color={'primaryBody'}
                  style={{
                    fontSize: `${responsiveStyles.featuresSize}`,
                    fontWeight: 500,
                  }}
                />
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
