import React from 'react'
import { PaymentMethod } from '../payment_method/PaymentMethod'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { SaleorPaymentFragment } from 'src/saleor_graphql/generated/graphql'

type PaymentMethodCardProps = {
  cardDetail: SaleorPaymentFragment
  onEdit?: () => void
  onSelect?: () => void
}

export const PaymentMethodCard = (props: PaymentMethodCardProps) => {
  const { cardDetail, onEdit, onSelect } = props
  const { creditCard } = cardDetail

  return (
    <Flex
      width="100%"
      onClick={onSelect}
      style={{
        cursor: 'pointer',
        padding: '1rem',
        backgroundColor: '#fff',
        borderRadius: '16px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Flex
        style={{ flexDirection: 'row', gap: '0.75rem', alignItems: 'center' }}
      >
        <PaymentMethod
          brand={creditCard?.brand}
          ccLastFour={creditCard?.lastDigits}
        />
        <Div>
          <Text
            type="body"
            lineHeight={1.3}
            style={{ color: '#404040', fontSize: '16px', fontWeight: 700 }}
          >
            Ending {creditCard?.lastDigits}
          </Text>
          <Text type="body" style={{ color: '"#718096"', fontSize: '10px' }}>
            <b>Exp:</b> {creditCard?.expMonth}/{creditCard?.expYear}
          </Text>
        </Div>
      </Flex>
      {onEdit && (
        <Flex
          onClick={onEdit}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.25rem',
            cursor: 'pointer',
          }}
        >
          <Icon name="edit" color="#000000" size={12} />
          <Text type="body" style={{ color: '#000000', fontSize: '10px' }}>
            edit
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
