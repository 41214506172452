import React, { FC } from 'react'
import {
  ComparisonItem,
  Div,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Transition } from 'src/chrome'
import { PromptingsLogo447SVG, PromptingsLogo497SVG } from './PromptingsLogoSVG'
import { UpsaleBackground } from 'src/coaching/components/UpsaleBanners/constants'
import { Button } from 'src/design_system/components/Button/Button'
import useCoachingPotentialBoost from 'src/hooks/useCoachingPotentialBoost'

const styles = {
  desktopGrid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 500px) max(400px)',
    gridTemplateAreas: `
    "header header"
    "features image"
    "features actions"
    `,
    columnGap: 'max(50px, 5vw)',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (max-width: 1200px)': {
      gridTemplateColumns: '60% 40%',
    },
    '@media (max-width: 850px)': {
      gridTemplateColumns: '100%',
      gridTemplateAreas: `
        "header"
        "image"
        "features"
        "actions"
      `,
    },
  },
  header: {
    gridArea: 'header',
  },
  features: {
    gridArea: 'features',
    '@media (max-width: 850px)': {
      justifySelf: 'center',
    },
  },
  image: {
    gridArea: 'image',
    alignSelf: 'center',
    justifySelf: 'center',
    width: 'min(100%, 360px)',
    '@media (max-width: 850px)': {
      width: '90%',
      marginBottom: '2rem',
    },
  },
  actions: {
    gridArea: 'actions',
    '@media (max-width: 1200px)': {
      flexDirection: 'column' as const,
      rowGap: '1rem',
      justifySelf: 'center',
      alignItems: 'center',
    },
    '@media (max-width: 850px)': {
      marginTop: '2rem',
      flexDirection: 'row' as const,
    },
    '@media (max-width: 400px)': {
      flexDirection: 'column' as const,
    },
  },
}

export const PromptingsPackageUpsale: FC = () => {
  const coachingPotentialBoost = useCoachingPotentialBoost()

  const standardButtonProps = {
    textSize: '0.8125rem',
    textWeight: 700,
    width: '160px',
    padding: '1rem',
  }

  const features = [
    {
      title: 'Unlimited Heartfelt Prompting Cards',
      description: 'With contact manager',
    },
    {
      title: '100 System Cards',
      description: '',
    },
    {
      title: 'Campaign Store',
      description: 'Featuring professionally designed Campaign cards',
    },
    {
      title: 'Virtual Assistant',
      description: '',
    },
    {
      title: 'Premier Gift Sending',
      description: 'Lowest delivered pricing',
    },
    {
      title: 'Relationship Marketing Online Training Course',
      description: '',
    },
    {
      title: 'Promptings Personal Coach',
      description: '',
    },
    {
      title: 'Level 1 Training:3 Promptings Seminar Tickets',
      description: 'For each 2023 seminar event',
    },
  ]

  return (
    <Div
      css={styles.desktopGrid}
      backgroundImage={`data:image/png;base64, ${UpsaleBackground}`}
      inset="x6"
    >
      {coachingPotentialBoost.isTransitioning && <Transition />}
      <Div css={styles.header}>
        <Text
          type="title"
          content="OR BUNDLE AND SAVE WITH"
          alignment="center"
          weight="extraBold"
          color="accent"
        />
        <Spacer space="x6" />
      </Div>
      <Div
        inset="x4"
        borderRadius="medium"
        backgroundColor="foreground"
        maxWidth="500px"
        css={styles.features}
      >
        <Text
          type="largeBody"
          weight="bold"
          content="Package Includes"
          color="primaryHeading"
        />
        <Spacer space="x4" />
        <Div display="grid" rowGap="1.25em">
          {features.map(feature => (
            <ComparisonItem
              key={feature.title}
              title={feature.title}
              description={feature.description}
              isIncluded={true}
            />
          ))}
        </Div>
      </Div>
      <Div css={styles.image} position="relative">
        {coachingPotentialBoost.shouldDisplay447 ? (
          <PromptingsLogo447SVG />
        ) : (
          <PromptingsLogo497SVG />
        )}
      </Div>
      <Div
        css={styles.actions}
        display="flex"
        columnGap="x2"
        justifyContent="center"
      >
        <Button
          {...standardButtonProps}
          title="GET STARTED"
          onClick={coachingPotentialBoost.handleOnGetStarted}
          backgroundColor="#5D4FAA"
        />
        <Button
          {...standardButtonProps}
          title="MORE INFO"
          onClick={coachingPotentialBoost.handleOnMoreInfo}
          backgroundColor="white"
          textColor="#242424"
          shouldDisplayBoxShadow={true}
        />
      </Div>
    </Div>
  )
}
