import { REHYDRATE } from 'redux-persist'

import { Action } from 'src/redux/action'
import { BaseRoute } from 'src/routes/BaseRoute'
import { loadedUser, loginDone, logout } from 'src/redux/actions/user'
import {
  clearRouteArgs,
  openRoute,
  removeRouteArg,
  removeRouteArgs,
} from 'src/redux/actions/routes'
import { StateWithoutRoute } from 'src/redux/reducers'

const routes = (
  route: BaseRoute = BaseRoute(),
  action: Action,
  state: StateWithoutRoute,
): BaseRoute => {
  switch (action.type) {
    case openRoute.type:
      return { ...action.route, args: { ...route.args, ...action.args } }
    case removeRouteArg.type:
      const keyToRemove = action.queryParam
      const { [keyToRemove]: _, ...newArgs } = route.args
      return { ...route, args: newArgs }
    case removeRouteArgs.type:
      const keysToRemove = action.queryParams
      const newObj = Object.fromEntries(
        keysToRemove.map(value => [value, undefined]),
      )
      const newRouteArgs = { ...route.args, ...newObj }
      return { ...route, args: newRouteArgs }
    case clearRouteArgs.type:
      return { ...route, args: {} }
    case loadedUser.type:
    case loginDone.type:
    case logout.type:
    case REHYDRATE:
      return route
    default:
      return BaseRoute.reducer(route, action, state) || BaseRoute()
  }
}

export default routes
