import React from 'react'

import {
  Button,
  Confirm,
  DefaultError,
  Icon,
  Modal,
  Transition,
} from 'src/chrome'
import {
  useActions,
  useCallback,
  useEffect,
  useLoading,
  useMutations,
  useQueries,
  useState,
} from 'src/hooks'
import {
  ChildContactFragment,
  DetailedContactFragment,
  GetChildren,
  GetChildrenData,
  GetChildrenVariables,
  getContact,
  NoteFragment,
  NoteInput,
  RelatedContactFragment,
  UpdateContactInput,
} from 'src/legacy_graphql'
import { Group } from 'src/enhanced_contact_manager/contactTypes'
import { CompanyShenanigans } from '../../types'
import ContactGroupsForm from '../forms/ContactGroupsForm'
import ContactForm from '../ContactForm/ContactForm'
import NoteForm from '../NoteForm/NoteForm'
import ContactDetails from '../ContactDetails/ContactDetails'
import DetailHeader from '../details/DetailHeader'
import { ContactNote } from '../details/DetailViews'

import styles from '../ContactDetails/contactDetails.module.scss'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import uuid from 'src/utils/uuid'
import { noop } from 'src/helpers/appHelpers'
import { Icon as QDSIcon, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { CircularProgress } from '@material-ui/core'
import { perform } from 'src/app/api'
import { Button as DSButton } from 'src/design_system/molecules/button/Button'

const floatingLabelStyle: React.CSSProperties = {
  color: 'black',
  fontWeight: 'bold',
}

const field = {
  style: { marginTop: 15 },
  floatingLabelFixed: true,
  floatingLabelStyle,
}

enum Steps {
  Idle,
  AddSpouse,
  AddChild,
  RemoveChild,
  RemoveSpouse,
  RemoveParent,
  Edit,
  AddNote,
  EditNote,
  EditGroups,
  SeparateContact,
}

interface IdleStep {
  type: Steps.Idle
}

interface AddSpouseStep {
  type: Steps.AddSpouse
  spouse: UpdateContactInput
}

interface AddChildStep {
  type: Steps.AddChild
  child: UpdateContactInput
}

interface RemoveChildStep {
  type: Steps.RemoveChild
  id: string
}

interface RemoveSpouseStep {
  type: Steps.RemoveSpouse
}

interface RemoveParentStep {
  type: Steps.RemoveParent
}

interface EditStep {
  type: Steps.Edit
  contact: AnyContact
  linkedContacts?: AnyContact[]
}

interface AddNoteStep {
  type: Steps.AddNote
}

interface EditNoteStep {
  type: Steps.EditNote
  note: NoteFragment
}
interface EditGroupsStep {
  type: Steps.EditGroups
  groups: AnyContact['groups']
}

interface SeparateContactStep {
  type: Steps.SeparateContact
  separateContact: { childId?: string; spouseId?: string }
}

type Step =
  | IdleStep
  | AddSpouseStep
  | AddChildStep
  | RemoveChildStep
  | RemoveSpouseStep
  | RemoveParentStep
  | EditStep
  | AddNoteStep
  | EditNoteStep
  | EditGroupsStep
  | SeparateContactStep

type Props = {
  contactId: string
  groups?: Group[]
  isReadOnly?: boolean
  goToContact?: (id: string) => void
  handleSendCardClick?: () => void
  handleCampaignClick?: () => void
}

type AnyContact = RelatedContactFragment & {
  groups?: DetailedContactFragment['groups']
} & CompanyShenanigans

const cleanDate = ({
  day,
  month,
  year,
}: {
  day?: number | null
  month?: number | null
  year?: number | null
}): { day: number; month: number; year?: number | null } | undefined | null => {
  if (day && month) {
    return { day, month, year }
  } else if ((!day && month) || (!month && day)) {
    return undefined
  } else {
    return null
  }
}

const isMobile = window.innerWidth <= 450

const cleanContact = (data: AnyContact): UpdateContactInput => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  address1: data.address1,
  address2: data.address2,
  companyName: data.company ?? data.companyName,
  city: data.city,
  state: data.state,
  postalCode: data.postalCode,
  country: data.country,
  birthday: cleanDate(data.birthday ?? {}),
  anniversary: cleanDate(data.anniversary ?? {}),
  homePhone: data.homePhone,
  cellPhone: data.cellPhone,
  workPhone: data.workPhone,
  faxNumber: data.faxNumber,
  website: data.website,
  emailAddress: data.emailAddress,
  groups: data.groups?.map(group => group.id),
})

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

const ContactInfo: React.FC<Props> = ({
  contactId,
  goToContact = () => {},
  groups,
  handleSendCardClick,
  handleCampaignClick,
  isReadOnly = false,
}) => {
  const [contact] = useQueries(getContact({ id: contactId }))
  const actions = useActions()

  const [step, setStep] = useState<Step>({ type: Steps.Idle })
  const resetStep = () => setStep({ type: Steps.Idle })
  const [childrenQueryStatus, setChildrenQueryStatus] = useState<
    'init' | 'loading' | 'loaded' | 'error'
  >('init')

  const loading = useLoading()

  const mutations = useMutations()

  const [children, setChildren] = useState<ChildContactFragment[]>([])

  const handleContactGroupsOnSave = (contact: DetailedContactFragment) =>
    updateContact(cleanContact(contact))

  const updateContact = (
    contact: UpdateContactInput,
    additionalContactsToUpdate: string[] = [],
    onSaved: () => void = noop,
  ) => {
    mutations.updateContact({ contact })
    additionalContactsToUpdate.map(id =>
      mutations.updateContact({
        contact: {
          id,
          address1: contact.address1,
          address2: contact.address2,
          city: contact.city,
          state: contact.state,
          postalCode: contact.postalCode,
          country: contact.country,
        },
      }),
    )
    resetStep()
    onSaved()
  }

  const createContact = (contact: UpdateContactInput & CompanyShenanigans) => {
    mutations.createContact({
      contact: {
        ...contact,
        companyName: contact.companyName ?? contact.company,
      },
    })
    resetStep()
  }

  const createSpouse = (spouse: UpdateContactInput & CompanyShenanigans) => {
    mutations.updateContact({ contact: { id: contact.id, spouse: spouse.id } })
    createContact(spouse)
  }

  const deleteContact = async (id: string) => {
    mutations.deleteContact({ id })
    resetStep()
  }

  const addSpouse = () =>
    setStep({
      type: Steps.AddSpouse,
      spouse: {
        id: uuid(),
        lastName: contact.lastName,
        address1: contact.address1,
        address2: contact.address2,
        city: contact.city,
        state: contact.state,
        postalCode: contact.postalCode,
        country: contact.country,
        anniversary: contact.anniversary,
        isPrimary: false,
        spouse: contact.id,
      },
    })

  const confirmRemoveSpouse = () => setStep({ type: Steps.RemoveSpouse })

  const removeSpouse = () => {
    if (contact.spouse) {
      deleteContact(contact.spouse.id)
      mutations.updateContact({ contact: { id: contact.id, spouse: null } })
    }
  }

  const addChild = () =>
    setStep({
      type: Steps.AddChild,
      child: {
        id: uuid(),
        lastName: contact.lastName,
        address1: contact.address1,
        address2: contact.address2,
        city: contact.city,
        state: contact.state,
        postalCode: contact.postalCode,
        country: contact.country,
        parent: contact.id,
        isPrimary: false,
      },
    })

  const removeChild = (childId: string) => deleteContact(childId)

  const confirmDeleteChild = (id: string) =>
    setStep({ type: Steps.RemoveChild, id })

  const confirmSeparateContact = (separateContact: object) =>
    setStep({ type: Steps.SeparateContact, separateContact })

  const removeParent = () => {
    if (contact.parent) {
      deleteContact(contact.parent.id)
    }
  }

  const confirmDeleteParent = (id: string) =>
    setStep({ type: Steps.RemoveParent })

  const addNote = () => setStep({ type: Steps.AddNote })

  const createNote = (note: NoteInput) => {
    mutations.updateContact({
      contact: {
        id: contact.id,
        notes: [note, ...contact.notes],
      },
    })
    resetStep()
  }

  const updateNote = (note: NoteInput) => {
    mutations.updateContact({
      contact: {
        id: contact.id,
        notes: contact.notes.map(otherNote =>
          otherNote.id === note.id ? note : otherNote,
        ),
      },
    })
    resetStep()
  }

  const separateContact = async (separateContact: {
    spouseId?: string
    childId?: string
  }) => {
    await mutations.separateContact({
      ...separateContact,
    })
    resetStep()
  }

  const deleteNote = (id: string) => {
    mutations.updateContact({
      contact: {
        id: contact.id,
        notes: contact.notes.filter(note => note.id !== id),
      },
    })
  }

  const editGroups = () =>
    setStep({ type: Steps.EditGroups, groups: contact.groups })

  const linkedContacts = [contact.spouse, ...children].filter(notEmpty)

  const { spouse, parent } = contact

  const getChildrenQuery = useCallback(async () => {
    setChildrenQueryStatus('loading')
    try {
      const result = await perform<GetChildrenData, GetChildrenVariables>(
        GetChildren({ contactId }),
      )
      setChildrenQueryStatus('loaded')
      setChildren(result.contact.children)
    } catch (e) {
      setChildrenQueryStatus('error')
    }
  }, [contactId])

  useEffect(() => {
    if (childrenQueryStatus === 'init') {
      getChildrenQuery()
    }
  }, [childrenQueryStatus, getChildrenQuery])

  return (
    <div className={styles.container}>
      {loading.isLoading && <Transition message="One Moment..." />}
      <div className={styles.innerContainer}>
        <div className={styles.center}>
          <DetailHeader contact={contact} />
          {isReadOnly && (
            <Button
              title={'Edit Contact'}
              buttonColor={'pink'}
              iconSize={15}
              iconColor={'#fff'}
              style={{
                margin: '20px 0',
              }}
              onClick={() => actions.openContact(contactId)}
            />
          )}
          <ContactDetails
            isExpanded={true}
            isExpandingAllowed={false}
            contact={contact}
            onEdit={() =>
              setStep({ type: Steps.Edit, contact, linkedContacts })
            }
            isReadOnly={isReadOnly}
          />
          <section>
            {parent && (
              <>
                <div className={styles.header}>
                  <Text type="largeBody">Parent</Text>
                </div>

                <ContactDetails
                  contact={parent}
                  isExpandingAllowed={true}
                  isExpanded={false}
                  onDelete={confirmDeleteParent}
                  onGoToContact={goToContact}
                  onEdit={() => setStep({ type: Steps.Edit, contact: parent })}
                  isReadOnly={isReadOnly}
                />
              </>
            )}
            <div className={styles.header}>
              <Text type="body" weight="bold">
                Spouse
              </Text>
              {!isReadOnly && !contact.spouse && (
                <DSButton
                  id={'add_spouse_btn'}
                  title={{
                    content: 'Add Spouse',
                    fontSize: '14px',
                    fontColor: '#e841b3',
                  }}
                  onClick={addSpouse}
                  padding="7px 25px"
                  borderRadius="10px"
                  fill="#e841b3"
                  isOutlined={true}
                />
              )}
            </div>
            {spouse && (
              <ContactDetails
                contact={spouse}
                isExpandingAllowed={true}
                isExpanded={false}
                onDelete={confirmRemoveSpouse}
                onGoToContact={goToContact}
                onEdit={() => setStep({ type: Steps.Edit, contact: spouse })}
                onSeparateContact={spouseId =>
                  confirmSeparateContact({ spouseId })
                }
                contactCategory="spouse"
                isReadOnly={isReadOnly}
              />
            )}
            <div className={styles.header}>
              <Text type="body" weight="bold">
                Children
              </Text>
              {!isReadOnly && (
                <DSButton
                  title={{
                    content: 'Add Child',
                    fontSize: '14px',
                    fontColor: '#e841b3',
                  }}
                  onClick={addChild}
                  padding="8px 25px"
                  borderRadius="10px"
                  fill="#e841b3"
                  isOutlined={true}
                />
              )}
            </div>
            {childrenQueryStatus === 'loading' ? (
              <CircularProgress />
            ) : childrenQueryStatus === 'error' ? (
              <div />
            ) : (
              children.map(child => (
                <ContactDetails
                  key={child.id}
                  contact={child}
                  isExpanded={false}
                  isExpandingAllowed={true}
                  onDelete={confirmDeleteChild}
                  onGoToContact={goToContact}
                  onEdit={() => setStep({ type: Steps.Edit, contact: child })}
                  onSeparateContact={childId =>
                    confirmSeparateContact({ childId })
                  }
                  isReadOnly={isReadOnly}
                />
              ))
            )}
          </section>
          <section>
            <div className={styles.header}>
              <Text type="body" weight="bold">
                Notes
              </Text>
              {!isReadOnly && (
                <DSButton
                  title={{
                    content: 'Add New Note',
                    fontSize: '14px',
                    fontColor: '#e841b3',
                  }}
                  onClick={addNote}
                  padding="8px 25px"
                  borderRadius="10px"
                  fill="#e841b3"
                  isOutlined={true}
                />
              )}
            </div>
            {contact.notes?.map(note => (
              <ContactNote
                key={note.id}
                note={note}
                onEdit={() => setStep({ type: Steps.EditNote, note })}
                onDelete={() => deleteNote(note.id)}
              />
            ))}
          </section>
          <section>
            <div className={styles.header}>
              <Text type="body" weight="bold">
                Groups
              </Text>
              {!isReadOnly && groups && (
                <DSButton
                  title={{
                    content: contact.groups?.length
                      ? 'Edit Groups'
                      : 'Add to Groups',
                    fontSize: '14px',
                    fontColor: '#e841b3',
                  }}
                  onClick={editGroups}
                  padding="8px 25px"
                  borderRadius="10px"
                  fill="#e841b3"
                  isOutlined={true}
                />
              )}
            </div>
            {contact.groups?.map(group => (
              <div key={group.id}>
                <Text type="caption">{group.name}</Text>
              </div>
            ))}
          </section>
          {!isReadOnly && handleCampaignClick && handleSendCardClick && (
            <div className={styles.buttons}>
              <DSButton
                title={{
                  content: 'Send Card',
                  fontColor: '#fff',
                  fontSize: '15px',
                }}
                padding="6px 25px"
                borderRadius="10px"
                fill="#e841b3"
                onClick={() => handleSendCardClick()}
              >
                <Icon icon="SEND" size={16} color="#fff" />
              </DSButton>
              <Spacer
                orientation={isMobile ? 'vertical' : 'horizontal'}
                space="x2"
              />
              <DSButton
                title={{
                  content: 'Campaign',
                  fontColor: '#fff',
                  fontSize: '15px',
                }}
                padding="10px 25px"
                borderRadius="10px"
                fill="#e841b3"
                onClick={() => handleCampaignClick()}
              >
                <QDSIcon name="catalog" size="xSmall" primaryColor="#fff" />
              </DSButton>
            </div>
          )}
          {isReadOnly && (
            <Button
              title={'Edit Contact'}
              buttonColor={'pink'}
              iconSize={15}
              style={{ margin: '0 auto' }}
              iconColor={'#fff'}
              onClick={() => actions.openContact(contactId)}
            />
          )}
          {step.type === Steps.EditGroups && groups && (
            <Modal
              title="Edit Groups"
              onClose={resetStep}
              bodyStyles={{
                maxHeight: 600,
              }}
              bodyChildren={
                <ContactGroupsForm
                  groups={groups}
                  contact={{ ...contact, groups: step.groups ?? [] }}
                  isSaving={loading.isLoading}
                  isSaveRequired={true}
                  onSave={handleContactGroupsOnSave}
                  onAddGroup={group =>
                    setStep({
                      ...step,
                      groups: (step.groups ?? []).concat([
                        { __typename: 'Group', ...group },
                      ]),
                    })
                  }
                  onRemoveGroup={group =>
                    setStep({
                      ...step,
                      groups: (step.groups ?? []).filter(
                        x => x.id !== group.id,
                      ),
                    })
                  }
                  onCancel={resetStep}
                />
              }
            />
          )}

          {step.type === Steps.AddSpouse && (
            <ContactForm
              isMinimal={true}
              onCancel={resetStep}
              onSave={createSpouse}
              contact={step.spouse}
            />
          )}
          {step.type === Steps.AddChild && (
            <ContactForm
              isMinimal={true}
              onCancel={resetStep}
              onSave={createContact}
              contact={step.child}
            />
          )}
          {step.type === Steps.SeparateContact && (
            <Confirm
              id={'separate_relationship_confirm'}
              title={'Separate Contact'}
              message={'Are you sure you want to separate this relationship?'}
              confirmTitle={'Yes'}
              onConfirm={() => separateContact(step.separateContact)}
              onDecline={resetStep}
              isModalEnabled={false}
            />
          )}
          {step.type === Steps.RemoveSpouse && (
            <Confirm
              id={'remove_relationship_confirm'}
              title={'Remove Relationship'}
              message={'Are you sure you want to remove this relationship?'}
              confirmTitle={'Yes'}
              onConfirm={removeSpouse}
              onDecline={resetStep}
              isModalEnabled={false}
            />
          )}
          {step.type === Steps.RemoveChild && (
            <Confirm
              id={'remove_relationship_confirm'}
              title={'Remove Relationship'}
              message={'Are you sure you want to remove this relationship?'}
              confirmTitle={'Yes'}
              onConfirm={() => removeChild(step.id)}
              onDecline={resetStep}
              isModalEnabled={false}
            />
          )}
          {step.type === Steps.RemoveParent && (
            <Confirm
              id={'remove_relationship_confirm'}
              title={'Remove Relationship'}
              message={'Are you sure you want to remove this relationship?'}
              confirmTitle={'Yes'}
              onConfirm={removeParent}
              onDecline={resetStep}
              isModalEnabled={false}
            />
          )}
          {step.type === Steps.Edit && (
            <ContactForm
              isMinimal={false}
              onCancel={resetStep}
              onSave={updateContact}
              contact={cleanContact(step.contact)}
              linkedContacts={step.linkedContacts}
            />
          )}
          {step.type === Steps.AddNote && (
            <Modal
              onClose={resetStep}
              title="New Note"
              bodyChildren={
                <NoteForm
                  onCancel={resetStep}
                  onSubmit={createNote}
                  fields={field}
                />
              }
            />
          )}
          {step.type === Steps.EditNote && (
            <Modal
              onClose={resetStep}
              title="Edit Note"
              bodyChildren={
                <NoteForm
                  note={step.note}
                  onSubmit={updateNote}
                  onCancel={resetStep}
                  fields={field}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default suspenseBoundary({
  component: ContactInfo,
  unresolved: <Transition message={'Loading contact...'} />,
  failure: DefaultError,
})
