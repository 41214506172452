import { Maybe, CardStatus } from '../../../__generated__/globalTypes'

import { RecipientFragment, isRecipientFragment } from './RecipientFragment'

import productionInfoFragmentRawString from '../ProductionInfoFragment.graphql'

export const productionInfoFragmentString = productionInfoFragmentRawString

export type ProductionInfoFragment = {
  __typename: 'ProductionInfo'
  id: string
  dateToSend: Maybe<string>
  status: CardStatus
  hold: boolean
  fulfilled: Maybe<string>
  rejectedCards: {
    __typename: 'RejectedCard'
    id: string
  }[]
  rejectionReason: Maybe<string>
  frontPreviewUrl: string
  trackingInfo: Maybe<{
    trackingNumber: string
    url: Maybe<string>
  }>
  recipient: Maybe<RecipientFragment>
}

export const isProductionInfoFragment = (
  fragment: any,
): fragment is ProductionInfoFragment =>
  fragment &&
  fragment.__typename === 'ProductionInfo' &&
  typeof fragment.id === 'string' &&
  (fragment.dateToSend === null || typeof fragment.dateToSend === 'string') &&
  (fragment.status === 'PENDING' ||
    fragment.status === 'FULFILLED' ||
    fragment.status === 'REJECTED' ||
    fragment.status === 'HELD' ||
    fragment.status === 'PAYMENT_ERROR' ||
    fragment.status === 'AWAITING_FULFILLMENT' ||
    fragment.status === 'REFUNDED' ||
    fragment.status === 'CANCELLED') &&
  typeof fragment.hold === 'boolean' &&
  (fragment.fulfilled === null || typeof fragment.fulfilled === 'string') &&
  Array.isArray(fragment.rejectedCards) &&
  fragment.rejectedCards
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'RejectedCard' &&
        typeof next.id === 'string',
      true,
    ) &&
  (fragment.rejectionReason === null ||
    typeof fragment.rejectionReason === 'string') &&
  typeof fragment.frontPreviewUrl === 'string' &&
  (fragment.trackingInfo === null ||
    (fragment.trackingInfo &&
      typeof fragment.trackingInfo.trackingNumber === 'string' &&
      (fragment.trackingInfo.url === null ||
        typeof fragment.trackingInfo.url === 'string'))) &&
  (fragment.recipient === null ||
    (isRecipientFragment(fragment.recipient) as boolean))
