import {
  CampaignFragment as LegacyCampaignFragment,
  CampaignLineFragment as LegacyCampaignLineFragment,
  CardFragment as LegacyCardFragment,
  CardPaperType as LegacyCardPaperType,
  CardSendType as LegacyCardSendType,
  CardStatus as LegacyCardStatus,
  CardType as LegacyCardType,
  Currency as LegacyCurrency,
  CurrencyType as LegacyCurrencyType,
  DetailedOrderFragment as LegacyDetailedOrderFragment,
  ElementFragment as LegacyElementFragment,
  ElementTextFragment as LegacyElementTextFragment,
  GiftVariationFragment as LegacyGiftVariationFragment,
  ItemCategoryFragment as LegacyItemCategoryFragment,
  ItemFragment as LegacyItemFragment,
  ItemizedCostFragment as LegacyItemizedCostFragment,
  LabeledPriceFragment as LegacyLabeledPriceFragment,
  LayoutCategory as LegacyLayoutCategory,
  LayoutDimensions as LegacyLayoutDimensions,
  LayoutFragment as LegacyLayoutFragment,
  LineFragment as LegacyLineFragment,
  Owner as LegacyOwner,
  PanelFragment as LegacyPanelFragment,
  ParagraphFragment as LegacyParagraphFragment,
  PlanFragment as LegacyPlanFragment,
  PlansPlanIconChoices as LegacyPlansPlanIconChoices,
  PriceFragment as LegacyPriceFragment,
  ProductionInfoFragment as LegacyProductionInfoFragment,
  SendableCardFragment as LegacySendableCardFragment,
  SendableCardWithTemplateFragment as LegacySendableCardWithTemplateFragment,
  SendDelayDelayType as LegacySendDelayDelayType,
  SendDelayFragment as LegacySendDelayFragment,
  SendDelayTimeType as LegacySendDelayTimeType,
  SendDelayType as LegacySendDelayType,
  TextAlignment as LegacyTextAlignment,
} from 'src/legacy_graphql'
import {
  CampaignFragment,
  CampaignLineFragment,
  CardFragment,
  CardPaperType,
  CardSendType,
  CardStatus,
  CardType,
  Currency,
  CurrencyType,
  DetailedOrderFragment,
  ElementFragment,
  ElementTextFragment,
  GiftVariationFragment,
  ItemCategoryFragment,
  ItemFragment,
  ItemizedCostFragment,
  LabeledPriceFragment,
  LayoutCategory,
  LayoutDimensions,
  LayoutFragment,
  LineFragment,
  Owner,
  PanelFragment,
  ParagraphFragment,
  PlanFragment,
  PlansPlanIconChoices,
  PriceFragment,
  ProductionInfoFragment,
  SendableCardFragment,
  SendableCardWithTemplateFragment,
  SendDelayDelayType,
  SendDelayFragment,
  SendDelayTimeType,
  SendDelayType,
  TextAlignment,
} from 'src/graphql/generated/graphql'

export const currencyFromLegacy = (legacy: LegacyCurrency): Currency => {
  switch (legacy) {
    case LegacyCurrency.SOC:
      return Currency.Soc
    case LegacyCurrency.UNAVAILABLE:
      return Currency.Unavailable
    case LegacyCurrency.USD:
      return Currency.Usd
  }
}

export const currencyToLegacy = (next: Currency): LegacyCurrency => {
  switch (next) {
    case Currency.Soc:
      return LegacyCurrency.SOC
    case Currency.Unavailable:
      return LegacyCurrency.UNAVAILABLE
    case Currency.Usd:
      return LegacyCurrency.USD
  }
}

export const currencyTypeFromLegacy = (
  legacy: LegacyCurrencyType,
): CurrencyType => {
  switch (legacy) {
    case LegacyCurrencyType.CARDTOKEN:
      return CurrencyType.Cardtoken
    case LegacyCurrencyType.CREDIT:
      return CurrencyType.Credit
    case LegacyCurrencyType.EXPENSE:
      return CurrencyType.Expense
    case LegacyCurrencyType.FREE:
      return CurrencyType.Free
    case LegacyCurrencyType.POINT:
      return CurrencyType.Point
    case LegacyCurrencyType.USD:
      return CurrencyType.Usd
  }
}

export const giftVariationFragmentFromLegacy = (
  giftVariation: LegacyGiftVariationFragment,
): GiftVariationFragment => {
  return {
    ...giftVariation,
    points: {
      ...giftVariation.points,
      currency: currencyFromLegacy(giftVariation.points.currency),
    },
    price: {
      ...giftVariation.price,
      currency: currencyTypeFromLegacy(giftVariation.price.currency),
    },
  }
}

export const cardTypeFromLegacy = (legacy: LegacyCardType): CardType => {
  switch (legacy) {
    case LegacyCardType.FLATCARD:
      return CardType.Flatcard
    case LegacyCardType.POSTCARD:
      return CardType.Postcard
    case LegacyCardType.THREE_PANEL:
      return CardType.ThreePanel
    case LegacyCardType.TWO_PANEL:
      return CardType.TwoPanel
    case LegacyCardType.TWO_PANEL_BIG:
      return CardType.TwoPanelBig
  }
}

export const cardPaperTypeFromLegacy = (
  legacy: LegacyCardPaperType,
): CardPaperType => {
  switch (legacy) {
    case LegacyCardPaperType.HVY:
      return CardPaperType.Hvy
    case LegacyCardPaperType.PRE:
      return CardPaperType.Pre
    case LegacyCardPaperType.STD:
      return CardPaperType.Std
  }
}

export const sendDelayTypeFromLegacy = (
  legacy: LegacySendDelayType,
): SendDelayType => {
  switch (legacy) {
    case LegacySendDelayType.ANN:
      return SendDelayType.Ann
    case LegacySendDelayType.BIR:
      return SendDelayType.Bir
    case LegacySendDelayType.IMM:
      return SendDelayType.Imm
    case LegacySendDelayType.SPE:
      return SendDelayType.Spe
  }
}

export const sendDelayDelayTypeFromLegacy = (
  legacy: LegacySendDelayDelayType,
): SendDelayDelayType => {
  switch (legacy) {
    case LegacySendDelayDelayType.DAY:
      return SendDelayDelayType.Day
    case LegacySendDelayDelayType.MON:
      return SendDelayDelayType.Mon
    case LegacySendDelayDelayType.WEE:
      return SendDelayDelayType.Wee
  }
}

export const sendDelayTimeTypeFromLegacy = (
  legacy: LegacySendDelayTimeType,
): SendDelayTimeType => {
  switch (legacy) {
    case LegacySendDelayTimeType.AFT:
      return SendDelayTimeType.Aft
    case LegacySendDelayTimeType.BEF:
      return SendDelayTimeType.Bef
  }
}

export const sendDelayFragmentFromLegacy = (
  sendDelayFragment: LegacySendDelayFragment,
): SendDelayFragment => {
  return {
    ...sendDelayFragment,
    type: sendDelayTypeFromLegacy(sendDelayFragment.type),
    delayType: sendDelayDelayTypeFromLegacy(sendDelayFragment.delayType),
    timeType: sendDelayTimeTypeFromLegacy(sendDelayFragment.timeType),
  }
}

export const campaignLineFragmentFromLegacy = (
  campaignLineFragment: LegacyCampaignLineFragment,
): CampaignLineFragment => {
  return {
    ...campaignLineFragment,
    card: campaignLineFragment.card
      ? {
          ...campaignLineFragment.card,
          type: cardTypeFromLegacy(campaignLineFragment.card.type),
          paperType: cardPaperTypeFromLegacy(
            campaignLineFragment.card.paperType,
          ),
        }
      : undefined,
    giftVariation:
      campaignLineFragment.giftVariation &&
      giftVariationFragmentFromLegacy(campaignLineFragment.giftVariation),
    sendDelay: sendDelayFragmentFromLegacy(campaignLineFragment.sendDelay),
  }
}

export const campaignFragmentFromLegacy = (
  campaignFragment: LegacyCampaignFragment,
): CampaignFragment => {
  return {
    ...campaignFragment,
    lines: campaignFragment.lines.map(campaignLineFragmentFromLegacy),
  }
}

export const itemFragmentFromLegacy = (
  itemFragment: LegacyItemFragment,
): ItemFragment => {
  return {
    ...itemFragment,
    campaign: itemFragment.campaign
      ? campaignFragmentFromLegacy(itemFragment.campaign)
      : undefined,
  }
}

export const itemCategoryFromLegacy = (
  category: LegacyItemCategoryFragment,
): ItemCategoryFragment => {
  return {
    ...category,
    items: category.items.map(itemFragmentFromLegacy),
  }
}

export const plansPlanIconChoicesFromLegacy = (
  legacy: LegacyPlansPlanIconChoices,
): PlansPlanIconChoices => {
  switch (legacy) {
    case LegacyPlansPlanIconChoices.A_10_CARD:
      return PlansPlanIconChoices.A_10Card
    case LegacyPlansPlanIconChoices.A_15_CARD:
      return PlansPlanIconChoices.A_15Card
    case LegacyPlansPlanIconChoices.A_20_CARD:
      return PlansPlanIconChoices.A_20Card
    case LegacyPlansPlanIconChoices.A_25_CARD:
      return PlansPlanIconChoices.A_25Card
    case LegacyPlansPlanIconChoices.AUTOMATED_CARD:
      return PlansPlanIconChoices.AutomatedCard
    case LegacyPlansPlanIconChoices.BROCHURE:
      return PlansPlanIconChoices.Brochure
    case LegacyPlansPlanIconChoices.BUSINESSPRENEUR:
      return PlansPlanIconChoices.Businesspreneur
    case LegacyPlansPlanIconChoices.CAMPAIGN_CARD:
      return PlansPlanIconChoices.CampaignCard
    case LegacyPlansPlanIconChoices.CANCEL:
      return PlansPlanIconChoices.Cancel
    case LegacyPlansPlanIconChoices.CARD:
      return PlansPlanIconChoices.Card
    case LegacyPlansPlanIconChoices.CARD_ERROR:
      return PlansPlanIconChoices.CardError
    case LegacyPlansPlanIconChoices.CARD_PACK:
      return PlansPlanIconChoices.CardPack
    case LegacyPlansPlanIconChoices.CATALOG_CARD:
      return PlansPlanIconChoices.CatalogCard
    case LegacyPlansPlanIconChoices.CHECK:
      return PlansPlanIconChoices.Check
    case LegacyPlansPlanIconChoices.CHECK_MARK:
      return PlansPlanIconChoices.CheckMark
    case LegacyPlansPlanIconChoices.CONTACT:
      return PlansPlanIconChoices.Contact
    case LegacyPlansPlanIconChoices.DANGER:
      return PlansPlanIconChoices.Danger
    case LegacyPlansPlanIconChoices.DATABASE:
      return PlansPlanIconChoices.Database
    case LegacyPlansPlanIconChoices.DOUBLE_CHECK:
      return PlansPlanIconChoices.DoubleCheck
    case LegacyPlansPlanIconChoices.EDIT:
      return PlansPlanIconChoices.Edit
    case LegacyPlansPlanIconChoices.EMPTY_GIFT:
      return PlansPlanIconChoices.EmptyGift
    case LegacyPlansPlanIconChoices.ENHANCED_STORAGE:
      return PlansPlanIconChoices.EnhancedStorage
    case LegacyPlansPlanIconChoices.ENTREPRENEUR:
      return PlansPlanIconChoices.Entrepreneur
    case LegacyPlansPlanIconChoices.ESSENTIAL_PACKS:
      return PlansPlanIconChoices.EssentialPacks
    case LegacyPlansPlanIconChoices.EXP_CALENDAR:
      return PlansPlanIconChoices.ExpCalendar
    case LegacyPlansPlanIconChoices.GIFT:
      return PlansPlanIconChoices.Gift
    case LegacyPlansPlanIconChoices.GROUP_SEND_CARD:
      return PlansPlanIconChoices.GroupSendCard
    case LegacyPlansPlanIconChoices.HEARTFELT:
      return PlansPlanIconChoices.Heartfelt
    case LegacyPlansPlanIconChoices.INFO:
      return PlansPlanIconChoices.Info
    case LegacyPlansPlanIconChoices.MULTI_TOUCH_CARD:
      return PlansPlanIconChoices.MultiTouchCard
    case LegacyPlansPlanIconChoices.MULTIPLE_CARDS:
      return PlansPlanIconChoices.MultipleCards
    case LegacyPlansPlanIconChoices.PACKAGE:
      return PlansPlanIconChoices.Package
    case LegacyPlansPlanIconChoices.PAPER_PLANE:
      return PlansPlanIconChoices.PaperPlane
    case LegacyPlansPlanIconChoices.PAPER_PLANE_FLYING:
      return PlansPlanIconChoices.PaperPlaneFlying
    case LegacyPlansPlanIconChoices.PAPER_PLANE_WITH_HEART:
      return PlansPlanIconChoices.PaperPlaneWithHeart
    case LegacyPlansPlanIconChoices.PERSONAL_HAND_WRITING:
      return PlansPlanIconChoices.PersonalHandWriting
    case LegacyPlansPlanIconChoices.PERSONAL_SIGNATURE:
      return PlansPlanIconChoices.PersonalSignature
    case LegacyPlansPlanIconChoices.PHOTO_DROP_CARD:
      return PlansPlanIconChoices.PhotoDropCard
    case LegacyPlansPlanIconChoices.PREMIUM_STORAGE:
      return PlansPlanIconChoices.PremiumStorage
    case LegacyPlansPlanIconChoices.REPLACE:
      return PlansPlanIconChoices.Replace
    case LegacyPlansPlanIconChoices.SCHEDULE_CARD:
      return PlansPlanIconChoices.ScheduleCard
    case LegacyPlansPlanIconChoices.SEARCH:
      return PlansPlanIconChoices.Search
    case LegacyPlansPlanIconChoices.SHOPPING_CART:
      return PlansPlanIconChoices.ShoppingCart
    case LegacyPlansPlanIconChoices.SMILE_CARD:
      return PlansPlanIconChoices.SmileCard
    case LegacyPlansPlanIconChoices.SMILE_CARD_W_HEART:
      return PlansPlanIconChoices.SmileCardWHeart
    case LegacyPlansPlanIconChoices.SMILE_CARD_W_HEARTS:
      return PlansPlanIconChoices.SmileCardWHearts
    case LegacyPlansPlanIconChoices.SOLOPRENEUR:
      return PlansPlanIconChoices.Solopreneur
    case LegacyPlansPlanIconChoices.TICKET:
      return PlansPlanIconChoices.Ticket
    case LegacyPlansPlanIconChoices.TIME_BLOCK:
      return PlansPlanIconChoices.TimeBlock
    case LegacyPlansPlanIconChoices.UPLOAD:
      return PlansPlanIconChoices.Upload
    case LegacyPlansPlanIconChoices.USER:
      return PlansPlanIconChoices.User
    case LegacyPlansPlanIconChoices.V_CHECK:
      return PlansPlanIconChoices.VCheck
    case LegacyPlansPlanIconChoices.X:
      return PlansPlanIconChoices.X
  }
}

export const plansPlanIconChoicesToLegacy = (
  next: PlansPlanIconChoices,
): LegacyPlansPlanIconChoices => {
  switch (next) {
    case PlansPlanIconChoices.A_10Card:
      return LegacyPlansPlanIconChoices.A_10_CARD
    case PlansPlanIconChoices.A_15Card:
      return LegacyPlansPlanIconChoices.A_15_CARD
    case PlansPlanIconChoices.A_20Card:
      return LegacyPlansPlanIconChoices.A_20_CARD
    case PlansPlanIconChoices.A_25Card:
      return LegacyPlansPlanIconChoices.A_25_CARD
    case PlansPlanIconChoices.AutomatedCard:
      return LegacyPlansPlanIconChoices.AUTOMATED_CARD
    case PlansPlanIconChoices.Brochure:
      return LegacyPlansPlanIconChoices.BROCHURE
    case PlansPlanIconChoices.Businesspreneur:
      return LegacyPlansPlanIconChoices.BUSINESSPRENEUR
    case PlansPlanIconChoices.CampaignCard:
      return LegacyPlansPlanIconChoices.CAMPAIGN_CARD
    case PlansPlanIconChoices.Cancel:
      return LegacyPlansPlanIconChoices.CANCEL
    case PlansPlanIconChoices.Card:
      return LegacyPlansPlanIconChoices.CARD
    case PlansPlanIconChoices.CardError:
      return LegacyPlansPlanIconChoices.CARD_ERROR
    case PlansPlanIconChoices.CardPack:
      return LegacyPlansPlanIconChoices.CARD_PACK
    case PlansPlanIconChoices.CatalogCard:
      return LegacyPlansPlanIconChoices.CATALOG_CARD
    case PlansPlanIconChoices.Check:
      return LegacyPlansPlanIconChoices.CHECK
    case PlansPlanIconChoices.CheckMark:
      return LegacyPlansPlanIconChoices.CHECK_MARK
    case PlansPlanIconChoices.Contact:
      return LegacyPlansPlanIconChoices.CONTACT
    case PlansPlanIconChoices.Danger:
      return LegacyPlansPlanIconChoices.DANGER
    case PlansPlanIconChoices.Database:
      return LegacyPlansPlanIconChoices.DATABASE
    case PlansPlanIconChoices.DoubleCheck:
      return LegacyPlansPlanIconChoices.DOUBLE_CHECK
    case PlansPlanIconChoices.Edit:
      return LegacyPlansPlanIconChoices.EDIT
    case PlansPlanIconChoices.EmptyGift:
      return LegacyPlansPlanIconChoices.EMPTY_GIFT
    case PlansPlanIconChoices.EnhancedStorage:
      return LegacyPlansPlanIconChoices.ENHANCED_STORAGE
    case PlansPlanIconChoices.Entrepreneur:
      return LegacyPlansPlanIconChoices.ENTREPRENEUR
    case PlansPlanIconChoices.EssentialPacks:
      return LegacyPlansPlanIconChoices.ESSENTIAL_PACKS
    case PlansPlanIconChoices.ExpCalendar:
      return LegacyPlansPlanIconChoices.EXP_CALENDAR
    case PlansPlanIconChoices.Gift:
      return LegacyPlansPlanIconChoices.GIFT
    case PlansPlanIconChoices.GroupSendCard:
      return LegacyPlansPlanIconChoices.GROUP_SEND_CARD
    case PlansPlanIconChoices.Heartfelt:
      return LegacyPlansPlanIconChoices.HEARTFELT
    case PlansPlanIconChoices.Info:
      return LegacyPlansPlanIconChoices.INFO
    case PlansPlanIconChoices.MultiTouchCard:
      return LegacyPlansPlanIconChoices.MULTI_TOUCH_CARD
    case PlansPlanIconChoices.MultipleCards:
      return LegacyPlansPlanIconChoices.MULTIPLE_CARDS
    case PlansPlanIconChoices.Package:
      return LegacyPlansPlanIconChoices.PACKAGE
    case PlansPlanIconChoices.PaperPlane:
      return LegacyPlansPlanIconChoices.PAPER_PLANE
    case PlansPlanIconChoices.PaperPlaneFlying:
      return LegacyPlansPlanIconChoices.PAPER_PLANE_FLYING
    case PlansPlanIconChoices.PaperPlaneWithHeart:
      return LegacyPlansPlanIconChoices.PAPER_PLANE_WITH_HEART
    case PlansPlanIconChoices.PersonalHandWriting:
      return LegacyPlansPlanIconChoices.PERSONAL_HAND_WRITING
    case PlansPlanIconChoices.PersonalSignature:
      return LegacyPlansPlanIconChoices.PERSONAL_SIGNATURE
    case PlansPlanIconChoices.PhotoDropCard:
      return LegacyPlansPlanIconChoices.PHOTO_DROP_CARD
    case PlansPlanIconChoices.PremiumStorage:
      return LegacyPlansPlanIconChoices.PREMIUM_STORAGE
    case PlansPlanIconChoices.Replace:
      return LegacyPlansPlanIconChoices.REPLACE
    case PlansPlanIconChoices.ScheduleCard:
      return LegacyPlansPlanIconChoices.SCHEDULE_CARD
    case PlansPlanIconChoices.Search:
      return LegacyPlansPlanIconChoices.SEARCH
    case PlansPlanIconChoices.ShoppingCart:
      return LegacyPlansPlanIconChoices.SHOPPING_CART
    case PlansPlanIconChoices.SmileCard:
      return LegacyPlansPlanIconChoices.SMILE_CARD
    case PlansPlanIconChoices.SmileCardWHeart:
      return LegacyPlansPlanIconChoices.SMILE_CARD_W_HEART
    case PlansPlanIconChoices.SmileCardWHearts:
      return LegacyPlansPlanIconChoices.SMILE_CARD_W_HEARTS
    case PlansPlanIconChoices.Solopreneur:
      return LegacyPlansPlanIconChoices.SOLOPRENEUR
    case PlansPlanIconChoices.Ticket:
      return LegacyPlansPlanIconChoices.TICKET
    case PlansPlanIconChoices.TimeBlock:
      return LegacyPlansPlanIconChoices.TIME_BLOCK
    case PlansPlanIconChoices.Upload:
      return LegacyPlansPlanIconChoices.UPLOAD
    case PlansPlanIconChoices.User:
      return LegacyPlansPlanIconChoices.USER
    case PlansPlanIconChoices.VCheck:
      return LegacyPlansPlanIconChoices.V_CHECK
    case PlansPlanIconChoices.X:
      return LegacyPlansPlanIconChoices.X
  }
}

export const planFragmentFromLegacy = (
  legacy: LegacyPlanFragment,
): PlanFragment => {
  return {
    ...legacy,
    subtitle: legacy.subtitle ?? null,
    price: {
      ...legacy.price,
      currency: currencyFromLegacy(legacy.price.currency),
    },
    description: legacy.description ?? null,
    color: legacy.color ?? null,
    icon: plansPlanIconChoicesFromLegacy(legacy.icon),
  }
}

export const planFragmentToLegacy = (
  next: PlanFragment,
): LegacyPlanFragment => {
  return {
    ...next,
    subtitle: next.subtitle ?? null,
    price: {
      ...next.price,
      currency: currencyToLegacy(next.price.currency),
    },
    description: next.description ?? null,
    color: next.color ?? null,
    icon: plansPlanIconChoicesToLegacy(next.icon),
  }
}

export const layoutDimensionsToLegacy = (
  next: LayoutDimensions,
): LegacyLayoutDimensions => {
  switch (next) {
    case LayoutDimensions.Horizontal:
      return LegacyLayoutDimensions.HORIZONTAL
    case LayoutDimensions.Postcard:
      return LegacyLayoutDimensions.POSTCARD
    case LayoutDimensions.Vertical:
      return LegacyLayoutDimensions.VERTICAL
  }
}

export const layoutDimensionsFromLegacy = (
  legacy: LegacyLayoutDimensions,
): LayoutDimensions => {
  switch (legacy) {
    case LegacyLayoutDimensions.HORIZONTAL:
      return LayoutDimensions.Horizontal
    case LegacyLayoutDimensions.POSTCARD:
      return LayoutDimensions.Postcard
    case LegacyLayoutDimensions.VERTICAL:
      return LayoutDimensions.Vertical
  }
}

export const layoutCategoryFromLegacy = (
  legacy: LegacyLayoutCategory,
): LayoutCategory => {
  switch (legacy) {
    case LegacyLayoutCategory.BACK_PANELS:
      return LayoutCategory.BackPanels
    case LegacyLayoutCategory.BASIC_LAYOUTS:
      return LayoutCategory.BasicLayouts
  }
}

export const ownerFromLegacy = (legacy: LegacyOwner): Owner => {
  switch (legacy) {
    case LegacyOwner.SYSTEM:
      return Owner.System
    case LegacyOwner.USER:
      return Owner.User
  }
}

export const textAlignmentFromLegacy = (
  legacy: LegacyTextAlignment,
): TextAlignment => {
  switch (legacy) {
    case LegacyTextAlignment.CENTER:
      return TextAlignment.Center
    case LegacyTextAlignment.LEFT:
      return TextAlignment.Left
    case LegacyTextAlignment.RIGHT:
      return TextAlignment.Right
  }
}

export const paragraphFragmentFromLegacy = (
  legacy: LegacyParagraphFragment,
): ParagraphFragment => {
  return {
    ...legacy,
    alignment: textAlignmentFromLegacy(legacy.alignment),
  }
}

export const elementTextFragmentFromLegacy = (
  legacy: LegacyElementTextFragment,
): ElementTextFragment => {
  return {
    ...legacy,
    paragraphs: legacy.paragraphs.map(paragraphFragmentFromLegacy),
  }
}

export const elementFragmentFromLegacy = (
  legacy: LegacyElementFragment,
): ElementFragment => {
  return {
    ...legacy,
    text: legacy.text
      ? elementTextFragmentFromLegacy(legacy.text)
      : legacy.text,
  }
}

export const layoutFragmentFromLegacy = (
  legacy: LegacyLayoutFragment,
): LayoutFragment => {
  return {
    ...legacy,
    category: legacy.category
      ? layoutCategoryFromLegacy(legacy.category)
      : legacy.category,
    owner: ownerFromLegacy(legacy.owner),
    dimensions: legacy.dimensions
      ? layoutDimensionsFromLegacy(legacy.dimensions)
      : legacy.dimensions,
    panel: {
      ...legacy.panel,
      elements: legacy.panel.elements.map(elementFragmentFromLegacy),
    },
  }
}

export function cardStatusToLegacy(next: CardStatus): LegacyCardStatus
export function cardStatusToLegacy(
  next: CardStatus | undefined,
): LegacyCardStatus | undefined

export function cardStatusToLegacy(
  next: CardStatus | undefined,
): LegacyCardStatus | undefined {
  if (next === undefined) {
    return undefined
  }
  switch (next) {
    case CardStatus.AwaitingFulfillment:
      return LegacyCardStatus.AWAITING_FULFILLMENT
    case CardStatus.Cancelled:
      return LegacyCardStatus.CANCELLED
    case CardStatus.Fulfilled:
      return LegacyCardStatus.FULFILLED
    case CardStatus.Held:
      return LegacyCardStatus.HELD
    case CardStatus.PaymentError:
      return LegacyCardStatus.PAYMENT_ERROR
    case CardStatus.Pending:
      return LegacyCardStatus.PENDING
    case CardStatus.Refunded:
      return LegacyCardStatus.REFUNDED
    case CardStatus.Rejected:
      return LegacyCardStatus.REJECTED
  }
}

export function cardStatusFromLegacy(legacy: LegacyCardStatus): CardStatus {
  switch (legacy) {
    case LegacyCardStatus.AWAITING_FULFILLMENT:
      return CardStatus.AwaitingFulfillment
    case LegacyCardStatus.CANCELLED:
      return CardStatus.Cancelled
    case LegacyCardStatus.FULFILLED:
      return CardStatus.Fulfilled
    case LegacyCardStatus.HELD:
      return CardStatus.Held
    case LegacyCardStatus.PAYMENT_ERROR:
      return CardStatus.PaymentError
    case LegacyCardStatus.PENDING:
      return CardStatus.Pending
    case LegacyCardStatus.REFUNDED:
      return CardStatus.Refunded
    case LegacyCardStatus.REJECTED:
      return CardStatus.Rejected
  }
}

export const productionInfoFragmentFromLegacy = (
  legacy: LegacyProductionInfoFragment,
): ProductionInfoFragment => {
  return {
    ...legacy,
    status: cardStatusFromLegacy(legacy.status),
  }
}

export const cardSendTypeFromLegacy = (
  legacy: LegacyCardSendType,
): CardSendType => {
  switch (legacy) {
    case LegacyCardSendType.HEARTFELT:
      return CardSendType.Heartfelt
    case LegacyCardSendType.SYSTEM:
      return CardSendType.System
  }
}

export const labeledPriceFragmentFromLegacy = (
  legacy: LegacyLabeledPriceFragment,
): LabeledPriceFragment => {
  return {
    ...legacy,
    currency: currencyTypeFromLegacy(legacy.currency),
  }
}

export const priceFragmentFromLegacy = (
  legacy: LegacyPriceFragment,
): PriceFragment => {
  return {
    ...legacy,
    currency: currencyTypeFromLegacy(legacy.currency),
  }
}

export const itemizedCostFragmentFromLegacy = (
  legacy: LegacyItemizedCostFragment,
): ItemizedCostFragment => {
  return {
    ...legacy,
    total: legacy.total.map(priceFragmentFromLegacy),
    entries: legacy.entries.map(labeledPriceFragmentFromLegacy),
  }
}

export const sendableCardFragmentFromLegacy = (
  legacy: LegacySendableCardFragment,
): SendableCardFragment => {
  return {
    ...legacy,
    cost: itemizedCostFragmentFromLegacy(legacy.cost),
  }
}

export const panelFragmentFromLegacy = (
  legacy: LegacyPanelFragment,
): PanelFragment => {
  return {
    ...legacy,
    elements: legacy.elements.map(elementFragmentFromLegacy),
  }
}

export const sendableCardWithTemplateFragmentFromLegacy = (
  legacy: LegacySendableCardWithTemplateFragment,
): SendableCardWithTemplateFragment => {
  return {
    ...legacy,
    cost: itemizedCostFragmentFromLegacy(legacy.cost),
    cardTemplate: legacy.cardTemplate
      ? {
          ...legacy.cardTemplate,
          panels: legacy.cardTemplate.panels.map(panelFragmentFromLegacy),
        }
      : legacy.cardTemplate,
  }
}

export const cardFragmentFromLegacy = (
  legacy: LegacyCardFragment,
): CardFragment => {
  return {
    ...legacy,
    type: cardTypeFromLegacy(legacy.type),
    paperType: cardPaperTypeFromLegacy(legacy.paperType),
    detailedSendableCard: legacy.detailedSendableCard
      ? {
          ...sendableCardWithTemplateFragmentFromLegacy(
            legacy.detailedSendableCard,
          ),
          variations: legacy.detailedSendableCard?.variations.map(
            sendableCardWithTemplateFragmentFromLegacy,
          ),
        }
      : legacy.detailedSendableCard,
    cost: itemizedCostFragmentFromLegacy(legacy.cost),
    panels: legacy.panels.map(panelFragmentFromLegacy),
  }
}

export const lineFragmentFromLegacy = (
  legacy: LegacyLineFragment,
): LineFragment => {
  return {
    ...legacy,
    card: legacy.card ? cardFragmentFromLegacy(legacy.card) : legacy.card,
    giftVariation: legacy.giftVariation
      ? giftVariationFragmentFromLegacy(legacy.giftVariation)
      : legacy.giftVariation,
    sendDelay: sendDelayFragmentFromLegacy(legacy.sendDelay),
    cost: itemizedCostFragmentFromLegacy(legacy.cost),
    baseCost: itemizedCostFragmentFromLegacy(legacy.baseCost),
  }
}

export const detailedOrderFragmentFromLegacy = (
  legacy: LegacyDetailedOrderFragment,
): DetailedOrderFragment => {
  return {
    ...legacy,
    sendType: cardSendTypeFromLegacy(legacy.sendType),
    lines: legacy.lines.map(lineFragmentFromLegacy),
    cost: itemizedCostFragmentFromLegacy(legacy.cost),
  }
}
