import { Route } from 'src/routes/Route'
import { CatalogSubroute } from 'src/catalog/routes/CatalogRoute'

export const AddCardToGiftRoute = Route({
  path: '/line/:lineIndex/add-card-to-gift',
  init: (lineIndex?: string, subroute?: CatalogSubroute) => ({
    lineIndex,
    subroute,
  }),
  fromPath: ({ lineIndex }) => ({ lineIndex }),
  subroute: CatalogSubroute,
})

export type AddCardToGiftRoute = Route<typeof AddCardToGiftRoute>
