import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Widget } from 'src/dashboard/components/Widgets/Widget'
import { Button } from 'src/design_system/components/Button/Button'
import { useActions, useContainerWidth } from 'src/hooks'

export const CardFeatureWidget = () => {
  const actions = useActions()
  const [containerRef, containerWidth] = useContainerWidth()
  const shouldShrink = containerWidth <= 163
  return (
    <Widget
      isEmpty={false}
      emptyState={{
        title: 'Nothings featured',
        description: 'Were working on making things actually cool',
        icon: 'drafts',
        contentMaxWidth: '280px',
        action: {
          title: 'Send a card today',
          onClick: () => actions.openCatalog(),
        },
      }}
    >
      <Div
        ref={containerRef}
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          type="body"
          content="Featured Card"
          alignment="left"
          style={{
            fontSize: shouldShrink ? '.925rem' : '1.125rem',
            fontWeight: 700,
            fontFamily: 'Montserrat, san serif',
            color: '#404040',
          }}
        />
        <Flex id="slider-header" width="100%" position="relative" bottom="18px">
          <Flex id="slider-controls" columnGap="x1" display="none">
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="circle"
              cursor="pointer"
              style={{ backgroundColor: '#F9FAFB', padding: 6 }}
              onClick={() => alert('featuredCard_goback')}
            >
              <Icon
                name="leftChevron"
                size="xSmall"
                primaryColor="primaryHeadingText"
              />
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="circle"
              cursor="pointer"
              style={{ backgroundColor: '#F9FAFB', padding: 6 }}
              onClick={() => alert('featuredCard_goForward')}
            >
              <Icon
                name="rightChevron"
                size="xSmall"
                primaryColor="primaryHeadingText"
              />
            </Flex>
          </Flex>
        </Flex>
        <Div
          style={{
            backgroundRepeat: 'no-repeat',
            height: 210,
            width: 150,
            backgroundSize: 'cover',
            backgroundImage:
              ' url(https://www.sendoutcards.com/thumbnail/zC7UlHrtUeiyKpeOfQOxv0ctEuo=/fit-in/700x0/system_images/2022/8/12/98fbc467-e441-41bf-ac26-9904d8c04f99.png)',
            transform: shouldShrink ? 'scale(.85)' : '',
          }}
        />
        <Flex width="100%" position="relative" top="22px">
          <Button
            title="Customize This Card"
            background="linear-gradient(90deg,#E7F1F7,#FDFAF5)"
            textColor="#404040"
            textSize={shouldShrink ? '10px' : '14px'}
            width="100%"
            whiteSpace="nowrap"
            onClick={() =>
              actions.openCatalog({
                path: '/preview/:sendableCardId',
                sendableCardId: '3675282',
                cardType: undefined,
                paperType: undefined,
              })
            }
          />
        </Flex>
      </Div>
    </Widget>
  )
}
