import {
  Anchor,
  Div,
  Flex,
  Icon,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Button } from 'src/design_system/components/Button/Button'
import { useQueries, useSelector, useState } from 'src/hooks'
import {
  useGetPlanMove,
  useProductVariant,
} from 'src/react_query/queries/hooks'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { getPlans, PlanFragment } from 'src/legacy_graphql'
import { Transition } from 'src/chrome'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { Portal } from 'src/portal/portal'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import { PackageBundler } from '../../packageBundler/PackageBundler'

type FeatureWithSubFeaturesType = {
  feature: string
  subFeatures: string[]
  isNotIncluded?: boolean
}
export type Features = FeatureWithSubFeaturesType | string

type ActionType = {
  title: string
  onClick?: () => void
  isDisabled?: boolean
  shouldTextMatchBackground?: boolean
}

type TileProps = {
  onSelectTile?: () => void
  itemId: string
  icon: React.ReactNode
  title: string
  description?: string
  isDescriptionLarge?: boolean
  price?: string
  priceSubtext?: string
  primaryAction: ActionType
  features: Features[]
  textColor: string
  background: string
  accentColor?: string
  learnMoreAction?: {
    title: string
    onClick: () => void
  }
  pillTitle?: string
  isActive?: boolean
  minWidth?: number
  currentPlanId?: string
  isOnCart?: boolean
  onChangePlan: () => void
  onPurchaseNow: (variantId?: string) => void
  onRemoveFromCart: () => void
  isAdded?: boolean
  isCurrentPlan: boolean
  plan: PlanFragment
  isEligiblePPremium?: boolean
}

export const Tile = (props: TileProps) => {
  const {
    onSelectTile,
    title,
    description,
    isDescriptionLarge,
    price,
    priceSubtext,
    primaryAction,
    features,
    learnMoreAction,
    isActive,
    background,
    textColor,
    accentColor,
    pillTitle,
    icon,
    minWidth = 350,
    itemId,
    currentPlanId,
    isOnCart,
    onChangePlan,
    onPurchaseNow,
    onRemoveFromCart,
    isAdded,
    isCurrentPlan,
    plan,
    isEligiblePPremium,
  } = props

  const [isPurchaseDrawerOpen, setIsPurchaseDrawerOpen] = useState(false)
  const shouldNotGetPlanMove = !!!currentPlanId
  const { data: planMoveData } = useGetPlanMove(
    {
      planIdFrom: Number(currentPlanId),
      planIdTo: Number(itemId),
    },
    shouldNotGetPlanMove,
  )
  const [plans] = useQueries(getPlans())

  const [packagePlan, setPackagePlan] = useState<PlanFragment | undefined>()
  const [packageVariantId, setPackageVariantId] = useState<string | undefined>()

  const newPrimaryActionTitlePrefix =
    planMoveData && planMoveData.planMove.type === 'DOWN'
      ? 'Downgrade to'
      : planMoveData && planMoveData.planMove.type === 'UP'
      ? 'Upgrade to'
      : 'Purchase'

  const hasNonBasicPlan = currentPlanId && currentPlanId !== '1'

  // const isFeatureWithSubFeaturesType = (
  //   valueToCheck: Features,
  // ): valueToCheck is FeatureWithSubFeaturesType => {
  //   const value = valueToCheck as FeatureWithSubFeaturesType
  //   return value.feature !== undefined && value.subFeatures !== undefined
  // }

  // id: 6, heartfelt
  // id: 42, unlimited hearfelt

  // TODO: Remove/refactor busisness logic out of this tile so that it can re-used in other areas such as Addons/Packages.

  const isTablet = useSelector(state => state.window.width <= 820)

  const responsiveStyles = {
    titleSize: isTablet ? '1.2rem' : '1.385rem',
    priceSize: isTablet ? '1.5rem' : '1.875rem',
    btnTitleSize: isTablet ? '.8rem' : '1rem',
    featuresSize: isTablet ? '.7rem' : '.89rem',
    cardPadding: isTablet ? 'x2' : 'x4',
    cardGap: isTablet ? 'x2_5' : 'x4',
  }
  const planDescriptions = getPlanDescriptions(plans)

  const variantIdOnPlan = plan
    ? planDescriptions[plan?.id]?.saleorVariantId
    : ''

  const { data } = useProductVariant({ sku: plan?.stripeId ?? '' })
  const variantId = variantIdOnPlan ?? data?.id

  const premiumSubscriptionIds = data?.parsedAttributes?.premiumSubscriptionIds
  const addonVariantIds = data?.parsedAttributes?.affiliateTrial ?? []

  const premiumSubVariantIds =
    premiumSubscriptionIds?.filter(
      (id): id is string => id !== null && id !== undefined,
    ) ?? []

  const {
    addToCart,
    hasCheckoutError,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
  } = useHandleProductActions(plan?.stripeId ?? '')

  const isLoading = isCreatingCheckout || isUpdatingCheckout

  return (
    <>
      <Portal>
        {(isUpdatingCheckout || isCreatingCheckout) && (
          <Transition message={'Creating checkout...'} />
        )}
      </Portal>
      {packagePlan && packageVariantId && premiumSubVariantIds && (
        <PackageBundler
          packagePlan={packagePlan}
          packageVariantId={packageVariantId}
          premiumSubVariantIds={premiumSubVariantIds}
          addonVariantIds={addonVariantIds}
          onClose={() => {
            setPackagePlan(undefined)
            setPackageVariantId(undefined)
          }}
        />
      )}
      {hasCheckoutError && checkoutErrorMessage && (
        <AnimatePresence>
          <ToasterNotification
            backgroundColor={{
              swatch: 'danger',
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: 'information',
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: checkoutErrorMessage,
            }}
          />
        </AnimatePresence>
      )}
      <Flex
        cursor={onSelectTile ? 'pointer' : 'initial'}
        onClick={onSelectTile}
        flexDirection="column"
        rowGap={responsiveStyles.cardGap}
        inset={responsiveStyles.cardPadding}
        minWidth={`${minWidth}px`}
        style={{
          background: background,
          borderRadius: '.75rem',
          boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
          borderTop: `3px solid  ${accentColor} `,
          transition: 'ease 0.3s',
          paddingBottom: '24px',
        }}
        position="relative"
        overflow="hidden"
      >
        <Flex justifyContent="space-between" alignItems="center">
          {icon}
          <Div
            backgroundColor="#fff"
            borderRadius="circle"
            boxShadow="light"
            height="fit-content"
            inset={{ horizontal: 'x_75' }}
            outset={{ bottom: 'auto' }}
          >
            <Text
              type="body"
              content={pillTitle}
              lineHeight={2}
              style={{
                fontSize: '.625rem',
                fontWeight: 700,
                color: textColor,
              }}
            />
          </Div>
        </Flex>
        <Flex flexDirection="column">
          <Text
            type="body"
            content={description}
            lineHeight={1.3}
            color={isActive ? 'inverseBody' : 'primaryBody'}
            style={{
              fontSize: isDescriptionLarge ? '1.1rem' : '0.85rem',
              fontWeight: isDescriptionLarge ? 600 : 500,
              lineHeight: '2.185',
            }}
          />
          <Flex flexDirection="row" alignItems="center" columnGap="x_5">
            <Text
              type="body"
              content={title}
              color={isActive ? 'inverseHeading' : 'primaryHeading'}
              style={{
                fontSize: `${responsiveStyles.titleSize}`,
                fontWeight: 700,
              }}
            />
            {learnMoreAction && (
              <Div
                display="flex"
                alignItems="center"
                cursor="pointer"
                onClick={learnMoreAction.onClick}
                height="fit-content"
                width="fit-content"
              >
                <Icon
                  name="information"
                  primaryColor={
                    isActive ? 'inverseBodyText' : 'primaryBodyText'
                  }
                  size="xSmall"
                />
              </Div>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="row" columnGap="x_75">
          <Text
            type="body"
            content={price}
            color={isActive ? 'inverseHeading' : 'primaryHeading'}
            style={{
              fontSize: `${responsiveStyles.priceSize}`,
              fontWeight: 500,
            }}
          />
          <Text
            type="body"
            content={priceSubtext}
            style={{
              fontSize: '.875rem',
              fontWeight: 500,
              color: isActive ? '#fcfcfc66' : '#6B7280',
            }}
          />
        </Flex>
        <Flex flexDirection="column" rowGap="x_5">
          {features.map((feature, index) => (
            <Flex key={index} flexDirection="column">
              <Flex flexDirection="row" alignItems="flex-start" columnGap="x_5">
                <Div
                  width="fit-content"
                  height="fit-content"
                  inset={{ top: 'x_5' }}
                >
                  <Icon
                    name="check"
                    primaryColor={isActive ? 'inverseBodyText' : textColor}
                    size="xSmall"
                  />
                </Div>
                <Text
                  type="body"
                  content={
                    typeof feature === 'string' ? feature : feature.feature
                  }
                  color={isActive ? 'inverseBody' : 'primaryBody'}
                  style={{
                    fontSize: `${responsiveStyles.featuresSize}`,
                    fontWeight: 500,
                  }}
                />
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Flex
          width="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          outset={{ top: 'auto' }}
        >
          {!isOnCart && (
            <Button
              title={
                planMoveData && !shouldNotGetPlanMove
                  ? `${newPrimaryActionTitlePrefix} ${title}`
                  : primaryAction.title
              }
              textColor={textColor}
              backgroundColor="#fff"
              isDisabled={isCurrentPlan}
              width="100%"
              boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
              onClick={() => {
                if (primaryAction.onClick) {
                  primaryAction.onClick()
                } else if (hasNonBasicPlan) {
                  onChangePlan()
                } else {
                  setIsPurchaseDrawerOpen(true)
                }
              }}
              border={`2px solid ${isActive ? '#fff' : textColor}`}
              textSize={responsiveStyles.btnTitleSize}
            />
          )}
          {isOnCart && (
            <Button
              title="Remove From Cart"
              textColor="rgba(248, 113, 113, 1)"
              textWeight={500}
              backgroundColor="#F9FAFB"
              width="100%"
              boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
              onClick={onRemoveFromCart}
              textSize={responsiveStyles.btnTitleSize}
              icon={{ name: 'trash', size: 16, color: '#F87171' }}
            />
          )}
          {learnMoreAction && (
            <Anchor
              title={learnMoreAction.title}
              onClick={learnMoreAction.onClick}
              isDecorated={true}
              color={isActive ? 'inverseHeading' : 'primaryBody'}
              outset={{ top: 'x2' }}
              size="xSmall"
            />
          )}
        </Flex>
        <AnimatePresence>
          {isPurchaseDrawerOpen && isActive && (
            <motion.div
              initial={{
                y: 250,
                opacity: 0,
              }}
              animate={{
                y: 10,
                opacity: 1,
                transition: { type: 'spring', bounce: 0.25 },
              }}
              exit={{ y: 250, opacity: 0 }}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                background: background,
                boxShadow: '0px -8px 8px 0px rgba(0, 0, 0, 0.04)',
              }}
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                rowGap="x2"
                width="100%"
                height="100%"
                backgroundColor="rgba(230,230,230, 0.75)"
                inset={{
                  top: 'x6',
                  horizontal: 'x4',
                  bottom: isLoading ? 'x6' : 'x4',
                }}
              >
                {isLoading ? (
                  <>
                    <LoadingSpinner size="large" hasNoColor={true} />
                  </>
                ) : (
                  <>
                    <Button
                      title={isAdded ? 'Added to your Cart' : 'Add to Cart'}
                      textColor="#fff"
                      textWeight={500}
                      background={background}
                      width="100%"
                      boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
                      onClick={() => {
                        if (
                          premiumSubVariantIds.length > 0 &&
                          isEligiblePPremium
                        ) {
                          setPackagePlan(plan)
                          setPackageVariantId(variantId)
                        } else {
                          addToCart(variantId)
                        }
                      }}
                      textSize={responsiveStyles.btnTitleSize}
                      icon={{
                        name: isAdded ? 'check_mark' : 'bag_plus',
                        color: '#FFF',
                        size: 15,
                      }}
                      isDisabled={isAdded}
                    />
                    <Button
                      title="Purchase Now"
                      textColor="hsl(0,0%,8.1%)"
                      textWeight={500}
                      backgroundColor="#fff"
                      width="100%"
                      boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
                      onClick={() => {
                        if (isAdded) {
                          onPurchaseNow()
                          return
                        }
                        if (isCurrentPlan) {
                          return
                        }
                        if (
                          premiumSubVariantIds.length > 0 &&
                          isEligiblePPremium
                        ) {
                          setPackagePlan(plan)
                          setPackageVariantId(variantId)
                        } else {
                          addToCart(variantId, onPurchaseNow)
                        }
                      }}
                      textSize={responsiveStyles.btnTitleSize}
                      icon={{ name: 'shopping_cart_2', size: 16 }}
                    />
                    <div onClick={() => setIsPurchaseDrawerOpen(false)}>
                      <DSIcon name="cancel" size={10} color="black" />
                      <Anchor
                        title="Close"
                        isDecorated={true}
                        color="primaryHeading"
                        size="xSmall"
                        outset={{ left: 'x_5' }}
                      />
                    </div>
                  </>
                )}
              </Flex>
            </motion.div>
          )}
        </AnimatePresence>
      </Flex>
    </>
  )
}
