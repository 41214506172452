import React from 'react'

import CardPicker from 'src/card_picker/CardPicker'
import { useActions, useMutations, useSelector } from 'src/hooks'
import { getTotal } from 'src/helpers'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import AnnouncementBanners from 'src/marketing/AnnouncementBanners/AnnouncementBanners'
import removeUsersnap from 'src/helpers/removeUsersnap'
import { Flex } from '@sendoutcards/quantum-design-ui'

type Props = {
  route: CatalogRoute
}

const CatalogPage: React.SFC<Props> = props => {
  const { route } = props
  const actions = useActions()
  const mutations = useMutations()

  const isMobile = useSelector(state => state.window.isMobile)

  const handleCreateOrder = async (cardId: string) => {
    const {
      createOrder: { order },
    } = await mutations.createOrder({ order: { lines: [{ card: cardId }] } })
    removeUsersnap()
    actions.openOrder(order.id)
  }

  return (
    <Flex
      flexDirection="column"
      position="relative"
      top={isMobile ? '75px' : 0}
    >
      <AnnouncementBanners />
      <CardPicker
        onCatalogNavigate={actions.openCatalog}
        onAfterSave={handleCreateOrder}
        catalogSubroute={route.subroute}
        saveButtonLabel="Proceed to Cart"
        saveButtonBackground="#32b769"
        saveButtonColor="#FFFFFF"
        editorSecondaryAction={({ closeEditor }) => ({
          label: 'Save & Close Card Editor',
          onClick: closeEditor,
        })}
        context={{
          footerLabel: card => <>Total: {getTotal(card.cost)}</>,
        }}
      />
    </Flex>
  )
}

export default CatalogPage
