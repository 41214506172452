import { Div, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'

export const yellow = '242, 158, 60'
export const red = '231, 81, 93'
export const green = '117, 198, 36'

type ReminderPillProps = {
  type: 'warning' | 'danger' | 'completed'
  text: string
}

export const ReminderPill: React.FC<ReminderPillProps> = props => {
  const { type, text } = props

  const getStyles = () => {
    switch (type) {
      case 'warning':
        return {
          color: yellow,
          icon: 'info' as IconType,
        }
      case 'danger':
        return {
          color: red,
          icon: 'danger' as IconType,
        }
      case 'completed':
        return {
          color: green,
          icon: 'v_check' as IconType,
        }
    }
  }

  const styles = {
    pill: {
      backgroundColor: `rgba(${getStyles().color}, 0.1)`,
      padding: '0 12px',
      height: '20px',
      borderRadius: '15px',
      display: 'flex',
      columnGap: '4px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }

  return (
    <Div css={styles.pill}>
      <Icon
        name={getStyles().icon}
        size={14}
        color={`rgb(${getStyles().color})`}
      />
      <Text
        type="footnote"
        content={text}
        style={{ color: `rgb(${getStyles().color})`, fontSize: '0.6rem' }}
        weight="bold"
        lineHeight={1}
      />
    </Div>
  )
}
