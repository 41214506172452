import React from 'react'
import { Button, Text } from '@sendoutcards/quantum-design-ui'
import { FlexColumn, FlexRow } from 'src/styled'
import { useActions, useSelector } from 'src/hooks'
import { StepWrapperProps } from '../components/ProgressiveProfileActions'

type GracePeriodNotificationProps = StepWrapperProps & {
  onComplete: () => void
  dismissModal: () => void
}

const GracePeriodNotification = (props: GracePeriodNotificationProps) => {
  const { dismissModal } = props
  const { isMobile } = useSelector(s => s.window)
  const actions = useActions()

  const onNow = () => {
    actions.openPaymentSettings()
    dismissModal()
  }

  return (
    <FlexColumn
      style={{
        justifyContent: 'center',
        marginBottom: isMobile ? '65px' : undefined,
      }}
    >
      <Text type="largeBody">Payment Update Needed</Text>
      <Text type="body">
        Unfortunately, we aren’t able to renew your Affiliate subscription
        because of an issue with your payment method. We will have to downgrade
        you to customer status if you do not update your payment method.
      </Text>
      <FlexRow
        style={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      >
        <div style={{ width: '30%' }}>
          <Button
            type="primary"
            title="Fix it now"
            onClick={onNow}
            fullWidth={true}
          />
        </div>
      </FlexRow>
    </FlexColumn>
  )
}

export default GracePeriodNotification
