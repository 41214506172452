import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'

import RadioButtons from 'src/chrome/RadioButtons/RadioButtons'
import { selectableCards } from 'src/helpers'
import { CardPanelView } from 'src/orders/components'
import {
  CardHistoryEditCardRoute,
  CardHistoryResendCardRoute,
} from 'src/dashboard/routes/CardHistoryRoute'
import { cardCost } from 'src/helpers/payments'
import formatCost from 'src/helpers/formatCost'
import {
  CalendarDateRangePicker,
  Confirm,
  Dialog,
  FilteredSearchBar,
  FullWindowModal,
  Modal,
  Pagination,
  ProgressModal,
  Transition,
} from 'src/chrome'
import { AddressFormModal } from 'src/contacts/components'
import {
  CardHistoryActions,
  ProductionInfoTable,
  RecipientTable,
  Refund,
} from 'src/card_history/components'
import Editor from 'src/editor/containers/Editor/Editor'

import { useState } from 'src/hooks'
import draftStyles from '../../drafts/containers/drafts.module.scss'
import styles from './cardHistoryManager.module.scss'
import { Props, RecipientCard, Section, Steps } from '../types'
import Hooks, { PAGE_SIZE } from '../hooks'
import suspenseBoundary from '../../chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Text } from '@sendoutcards/quantum-design-ui'
import NewEditorOptionModal from 'src/chrome/NewEditorOptionModal/NewEditorOptionModal'
import {
  CardStatus,
  FullAccessFeatureType,
} from 'src/graphql/generated/graphql'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { LockedFeatureModal } from 'src/LockedFeatureModal/LockedFeatureModal'

const CardHistoryManager: React.FC<Props> = props => {
  const { route } = props
  const featureAccessArray = [FullAccessFeatureType.CardHistoryResends]
  const { lazyFeatureAccess } = useFeatureAccess(featureAccessArray)
  const {
    isSelectAllChecked,
    cardHistory,
    context,
    currentPage,
    displayedData,
    editRecipient,
    editSendDate,
    filters,
    handleEditCard,
    handleSelect,
    handleSelectAll,
    isMobile,
    marketingCopy,
    onCancelPending,
    onConfirmCancel,
    onConfirmRetry,
    onResendCard,
    onRetry,
    recipientHistory,
    resetStep,
    saveRecipient,
    saveSendDate,
    section,
    selected,
    selectName,
    setCurrentPage,
    setFilters,
    setSection,
    setTerms,
    clearSearch,
    step,
    viewCard,
    handleCardUpdated,
    handleOrderResentCard,
    goToIndex,
    canShowNewCardEditor,
  } = Hooks(props)

  const [
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
  ] = useState<boolean>(false)

  const handleResendCard = (card: RecipientCard) => {
    if (canShowNewCardEditor && !card.isNewEditorCard) {
      setShouldShowEditorChoiceModal(true)
    } else {
      onResendCard(card.id, false)
    }
  }

  // You may not want to block the entire UI, this is just a placeholder
  // If needed add a logical check inside the container to render a modal or other UI
  // EG !lazyFeatureAccess.hasAccess && <BlockingUI>
  if (!lazyFeatureAccess.hasAccess) {
    return (
      <LockedFeatureModal
        onClose={() => lazyFeatureAccess.disable()}
        actions={{
          mainAction: {
            execute: () => console.log('clicked main action'),
            title: 'Unlock This & More Features',
          },
          secondaryAction: {
            execute: () => lazyFeatureAccess.disable(),
            title: 'Not Now',
          },
        }}
        textContent={{
          title: 'Unlock Me!',
          subTitle: 'Re-sending a card is a Full System Feature.',
          description:
            'Upgrade your account from a Basic account to a Monthly Membership or any of our other plans to access this great feature!',
        }}
        image={{
          url:
            'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/locked-feature-modal/image-1.png',
          height: '100%',
          width: '100%',
          top: '38px',
          left: '0px',
        }}
        featureAccessToGrant={featureAccessArray}
      />
    )
  }

  if (route.subroute?.path === CardHistoryEditCardRoute.path) {
    return (
      /* rendering this in a modal as the dashboard layout is interfering
         with the fullscreen editor */
      <FullWindowModal isReallyFull={true}>
        {step.type === Steps.Progress && <Transition message={step.message} />}
        <Editor
          cardId={route.subroute.cardId}
          onAfterSave={handleCardUpdated}
          onClose={goToIndex}
          context={{
            headerText: `Editing pending card: ${route.subroute.cardId}`,
            footerLabel: card => <></>,
          }}
          saveButtonLabel="Update Card"
        />
      </FullWindowModal>
    )
  }

  if (route.subroute?.path === CardHistoryResendCardRoute.path) {
    const { cardId } = route.subroute

    return (
      /* rendering this in a modal as the dashboard layout is interfering
         with the fullscreen editor */
      <FullWindowModal isReallyFull={true}>
        <Editor
          cardId={route.subroute.cardId}
          onAfterSave={() => handleOrderResentCard(cardId)}
          onClose={goToIndex}
          context={{
            headerText: `Resending card: ${route.subroute.cardId}`,
            footerLabel: card => (
              <>Total: {formatCost(cardCost(card).amount)}</>
            ),
          }}
          saveButtonLabel="Proceed to Cart"
          saveButtonBackground="#32b769"
          saveButtonColor="#FFFFFF"
        />
      </FullWindowModal>
    )
  }

  return (
    <div className={styles.componentContainer}>
      <div className={styles.searchBar}>
        <FilteredSearchBar
          filters={filters}
          setFilters={setFilters}
          setTerms={setTerms}
          clearSearch={clearSearch}
          autocomplete={'off'}
        />

        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            position: 'inherit',
            top: 50,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <RadioButtons
              options={[
                'All',
                'Pending',
                'Printing',
                'Fulfilled',
                'Held',
                'Rejected',
                'Payment Error',
              ]}
              onSelect={value => setSection(value as Section)}
              selected={section}
              groupName={'context'}
              direction={'row'}
              labelPlacement={'start'}
              color={'blue'}
              size={'medium'}
            />
          </div>
        </div>
      </div>
      {displayedData && (
        <div>
          {displayedData.count > 0 && (
            <div className={styles.tableInfo}>
              <Text type="body" color="primaryHeading">
                {`Cards ${PAGE_SIZE * (currentPage - 1) + 1} - ${Math.min(
                  PAGE_SIZE * currentPage,
                  displayedData.count,
                )} of ${displayedData.count}`}
              </Text>
              <CardHistoryActions
                count={selected.size}
                isSelectAllChecked={isSelectAllChecked}
                onSelectAll={handleSelectAll}
                onCancel={selected.size > 0 ? onConfirmCancel : undefined}
                onRetryFailed={
                  selected.size > 0 && context === 'paused'
                    ? onConfirmRetry
                    : undefined
                }
              />
            </div>
          )}
          {(section === 'All' ||
            section === 'Pending' ||
            section === 'Printing') &&
            marketingCopy && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Text type="caption" color="primaryBody" inset="x2">
                  {marketingCopy.immediateSendEditingMessage.content}
                  <br />
                  {marketingCopy.futureSendEditingMessage.content}
                </Text>
              </div>
            )}
          <div className={styles.tableContainer}>
            {context === 'sent'
              ? cardHistory && (
                  <ProductionInfoTable
                    history={cardHistory.results}
                    hasSelectableCards={cardHistory.results.some(
                      selectableCards,
                    )}
                    selectName={selectName}
                    checkedItems={selected}
                    isMobile={isMobile}
                    onUpdateCheck={handleSelect}
                    onCardClicked={viewCard}
                    onSelectAllClick={handleSelectAll}
                    editRecipientAddressClicked={editRecipient}
                    onEditSendDate={editSendDate}
                  />
                )
              : recipientHistory && (
                  <RecipientTable
                    history={recipientHistory.results}
                    selectName={selectName}
                    checkedItems={selected}
                    isMobile={isMobile}
                    onUpdateCheck={handleSelect}
                    onCardClicked={viewCard}
                    onSelectAllClick={handleSelectAll}
                    context={context}
                  />
                )}
          </div>

          <div className={styles.footer}>
            <Pagination
              onPageChange={setCurrentPage}
              count={displayedData.count}
              pagesToShow={5}
              pageSize={PAGE_SIZE}
              currentPage={currentPage}
            />
          </div>
        </div>
      )}

      {step.type === Steps.RetryFailedCards && !step.userConfirmed && (
        <Confirm
          title={`Send ${step.ids.length} immediate cards`}
          confirmTitle={'Continue'}
          onDecline={resetStep}
          onConfirm={onRetry}
          message={
            'Ensure you have updated your payment method. \
                      The selected cards will be sent immediately. \
                      Any configured send delays will be ignored.'
          }
          wrapperClassName={styles.confirmWrapper}
          isModalEnabled={true}
        />
      )}

      {step.type === Steps.CancelCards && !step.userConfirmed && (
        <Dialog open={true} style={{ zIndex: 3002 }}>
          <Confirm
            title={`Cancel sending card${step.ids.length > 1 ? 's' : ''}`}
            confirmTitle={'Yes'}
            onConfirm={() => onCancelPending(step.ids)}
            onDecline={resetStep}
            message={`Are you sure you want to cancel sending the card${
              step.ids.length > 1 ? 's' : ''
            } you have selected? (You will be refunded or not charged)`}
            wrapperClassName={styles.confirmWrapper}
            actionsClassName={styles.confirmActions}
            isModalEnabled={true}
          />
        </Dialog>
      )}

      {step.type === Steps.CancelCards && (
        <Transition
          message={`Cancelling ${step.ids.length} Pending Card${
            step.ids.length > 1 ? 's' : ''
          }...`}
        />
      )}

      {step.type === Steps.CancelCardsSummary && (
        <ProgressModal
          size={100}
          isOpen={true}
          currentCount={step.ids.length}
          totalCount={step.ids.length}
          errorTitle={'The following cards could not be canceled'}
          message={' cards canceled successfully'}
          onClose={resetStep}
          isDone={true}
          errorList={[]}
          infoChild={
            <Refund
              pointsRefunded={
                (step.refundInfo.pointsAfter ?? 0) -
                (step.refundInfo.pointsBefore ?? 0)
              }
              expenseRefunded={
                parseFloat(step.refundInfo.expenseAfter ?? '0') -
                parseFloat(step.refundInfo.expenseBefore ?? '0')
              }
              isCreditCardPayment={false}
            />
          }
        />
      )}

      {step.type === Steps.View &&
        (() => {
          const { card, recipient } = step

          return (
            <>
              {canShowNewCardEditor && shouldShowEditorChoiceModal && card && (
                <NewEditorOptionModal
                  title={
                    'Try it out! Would you like to edit\nyour card in the new editor'
                  }
                  description={
                    'Since this card was created in the current card\neditor we can copy it and allow you to try editing it\nin the new editor. Would you like to try?'
                  }
                  tryNewEditor={{
                    title: 'Copy Card to New Editor',
                    onOverrideClick: () => {
                      onResendCard(card.id, true)
                      setShouldShowEditorChoiceModal(false)
                    },
                  }}
                  useCurrentEditor={{
                    title: 'Edit in Current Editor',
                    onClick: () => {
                      onResendCard(card.id, false)
                      setShouldShowEditorChoiceModal(false)
                    },
                  }}
                  isOpen={shouldShowEditorChoiceModal}
                  onClose={() => {
                    setShouldShowEditorChoiceModal(false)
                  }}
                />
              )}
              <Confirm
                title={'View your card'}
                confirmTitle={context === 'sent' ? 'Resend' : undefined}
                onConfirm={() => {
                  const hasAccess = lazyFeatureAccess.enable()
                  if (hasAccess) {
                    context === 'sent' && card
                      ? handleResendCard(card)
                      : resetStep()
                  }
                }}
                onDecline={resetStep}
                childType={'image'}
                wrapperClassName={draftStyles.confirmWrapper}
                actionsClassName={draftStyles.confirmActions}
                confirmMessageClassName={draftStyles.confirmMessage}
                isModalEnabled={true}
              >
                {card ? (
                  <div style={{ position: 'relative', width: '100%' }}>
                    <CardPanelView card={card} />
                    {recipient &&
                      (recipient.status === CardStatus.Pending ||
                        recipient.status === CardStatus.Held) && (
                        <Fab
                          onClick={() => handleEditCard(recipient.id)}
                          className={styles.customEditBtn}
                        >
                          <span style={{ fontWeight: 'bold' }}>Edit</span>
                        </Fab>
                      )}
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </div>
                )}
              </Confirm>
            </>
          )
        })()}

      {step.type === Steps.EditRecipient && (
        <AddressFormModal
          contact={step.recipient.address ?? undefined}
          close={resetStep}
          onSubmit={saveRecipient}
          shouldShowAddressOnly={true}
          checkboxText={'Update Address in Relationship Manager'}
          secondaryCheckboxText={
            'No changes. I have verified this address is correct.'
          }
          isSecondaryCheckboxChecked={!!step.recipient.address?.userVerified}
          disableCountryReason={
            'Sorry, you cannot edit the country while it is pending, you can cancel and send a new card if you would like'
          }
          errorMessages={step.recipient.address?.errorMessages}
          confirmButtonText={'Save Address and Send'}
        />
      )}

      {step.type === Steps.SetDate && (
        <Modal
          title={'Edit Send Date'}
          onClose={resetStep}
          containerStyles={{ width: 'auto' }}
          bodyStyles={{
            maxHeight: 500,
          }}
          bodyChildren={
            <CalendarDateRangePicker
              currentDate={step.info.dateToSend ?? undefined}
              isPastNotSelectable={true}
              onSubmit={saveSendDate}
              isSingleDateOnly={true}
            />
          }
        />
      )}

      {step.type === Steps.Progress && <Transition message={step.message} />}
    </div>
  )
}

export default suspenseBoundary({
  component: CardHistoryManager,
  unresolved: <Transition />,
  failure: error => `Failed loading: ${error}`,
})
