import { Set } from 'immutable'

import { ContactFragment, SendDelayType } from 'src/graphql/generated/graphql'
import { compact } from 'src/helpers'
import { PartialLine } from 'src/helpers/multitouch'

export const getDelayTypes = (lines: PartialLine[]) =>
  Set<SendDelayType>(lines.map(line => line.sendDelay.type))

export const getIssues = (
  contact: ContactFragment,
  sendDelayTypes: Set<SendDelayType>,
) => getAddressIssues(contact).concat(getSendDelay(contact, sendDelayTypes))

const getSendDelay = (
  contact: ContactFragment,
  sendDelayTypes: Set<SendDelayType>,
) =>
  compact(
    sendDelayTypes.has(SendDelayType.Bir) && !contact.birthday && 'No birthday',
    sendDelayTypes.has(SendDelayType.Ann) &&
      !contact.anniversary &&
      'No anniversary',
  )

const getAddressIssues = (contact: ContactFragment): string[] =>
  compact(
    !contact.firstName &&
      !contact.lastName &&
      !contact.company &&
      'First name, last name, and company are all blank',
    !contact.address1 && 'Missing Address 1',
    !contact.city && 'Missing City',
    !contact.postalCode && 'Missing Postal Code',
    !contact.country && 'Missing Country',
    (contact.country === 'United States' || contact.country === 'USA') &&
      !contact.state &&
      'Missing State',
  )
