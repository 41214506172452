import {
  Div,
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React, { useRef } from 'react'
import { AddressFragment } from 'src/legacy_graphql'
import { noop } from 'src/helpers/appHelpers'
import { useAccount, useActions, useSelector, useState } from 'src/hooks'
import { PaymentTab } from 'src/payments/components'
import { creditCardBrand } from 'src/payments/components/CardLogo/CreditCardLogo'
import CreditCardForm from 'src/payments/components/CreditCardForm/CreditCardForm'
import { FormHeader } from '../FormHeader'
import { Button } from 'src/design_system/molecules/button/Button'
import { Token } from '@stripe/stripe-js'
import { parseError } from 'src/utils/parseError'
import { useUpdateAccount } from 'src/react_query'
import { AccountInput } from 'src/graphql/generated/graphql'

const OrderPaymentMethod: React.FC<{
  returnAddress?: AddressFragment
}> = props => {
  const submitCardRef = useRef<HTMLButtonElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [CCFormError, setCCFormError] = useState<string | undefined>()
  const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false)
  const [isRemovingCard, setIsRemovingCard] = useState(false)

  const isInitialBreakPoint = useSelector(state => state.window.width < 820)

  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const account = useAccount()

  const stripeSource = account.stripeSource

  const shouldVerifyUser =
    !account.isEmailVerified && !account.verifiedPhoneNumber

  const updateAccount = async (
    data: AccountInput,
    success: () => void = noop,
    error: (error: Error | string) => void = noop,
  ) => {
    try {
      const {
        updateAccount: { account },
      } = await updateAccountMutation.mutateAsync({
        account: { ...data },
      })
      actions.updatedAccount(account)
      success()
    } catch (err) {
      error(parseError(err))
    }
  }

  const setCard = (stripeToken: Token) => {
    setIsLoading(true)
    updateAccount({ stripeToken: stripeToken.id }, () => {
      setIsLoading(false)
      setShowPaymentMethodForm(false)
    })
  }

  const addCard = () => {
    submitCardRef.current?.click()
  }

  const removeCard = () => {
    setIsRemovingCard(true)
    updateAccount({ stripeToken: null }, () => {
      setIsRemovingCard(false)
      setShowPaymentMethodForm(true)
    })
  }

  return (
    <Div
      width="100%"
      borderRadius="16px"
      backgroundColor="foreground"
      inset="x3"
    >
      <Flex
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexWrap={isInitialBreakPoint ? 'wrap' : 'nowrap'}
      >
        <FormHeader
          step={3}
          title="Payment Method"
          description="Choose how you would like to pay for your order"
        />
        {!showPaymentMethodForm && !stripeSource && (
          <Flex
            width={isInitialBreakPoint ? '100%' : 'unset'}
            outset={{ top: isInitialBreakPoint ? 'x2' : 'x0', right: 'x_5' }}
            justifyContent={isInitialBreakPoint ? 'center' : undefined}
          >
            <Button
              fill="#f5f6f7"
              gap="x_5"
              padding={'12px 16px'}
              borderRadius="small"
              title={{ content: 'Add Payment Method', fontSize: '12px' }}
              onClick={() => setShowPaymentMethodForm(true)}
            />
          </Flex>
        )}
      </Flex>
      {showPaymentMethodForm && !stripeSource && (
        <>
          <Spacer orientation="vertical" space="x2" />
          <CreditCardForm
            returnAddress={props.returnAddress}
            onSuccess={token => setCard(token)}
            onError={err => setCCFormError(err)}
            isCCFormComplete={isComplete => {
              setIsFormComplete(isComplete)
            }}
            isLoading={isLoading}
            shouldValidateEmail={shouldVerifyUser}
          >
            <button
              form="addCreditCardForm"
              type="submit"
              ref={submitCardRef}
              style={{ display: 'none' }}
            />
            <Button
              fill="#f5f6f7"
              gap="x_5"
              padding={'12px 16px'}
              borderRadius="small"
              onClick={addCard}
              isDisabled={!isFormComplete}
              title={{ content: 'Add Card', fontSize: '12px' }}
            />
          </CreditCardForm>
          {CCFormError && (
            <Text
              type="caption"
              color="danger"
              content={CCFormError}
              inset={{ top: 'x_5' }}
            />
          )}
        </>
      )}
      {stripeSource && stripeSource?.__typename === 'CreditCard' && (
        <>
          {isRemovingCard ? (
            <>
              <Div display="flex" justifyContent="center">
                <LoadingSpinner size="medium" />
              </Div>
              <Spacer space="x1" />
              <Text type="body" alignment="center">
                Removing Card
              </Text>
            </>
          ) : (
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <PaymentTab
                paymentType={'card'}
                brand={creditCardBrand(stripeSource.brand)}
              />
              <Div outset={{ left: 'x2' }}>
                <Text type="body">
                  Card Number: **** **** **** {stripeSource.last4}
                </Text>
                <Text type="body">
                  Exp: {stripeSource.expMonth}/{stripeSource.expYear}
                </Text>
              </Div>
              <Div outset={{ left: 'auto' }}>
                <Button
                  fill="#f5f6f7"
                  gap="x_5"
                  padding={'12px 16px'}
                  borderRadius="small"
                  title={{ content: 'Update Card', fontSize: '12px' }}
                  onClick={removeCard}
                />
              </Div>
            </Flex>
          )}
        </>
      )}
    </Div>
  )
}

export default OrderPaymentMethod
