import { Div, Image, Text } from '@sendoutcards/quantum-design-ui'
import React, { useContext } from 'react'
import { ProfileAvatar } from 'src/chrome'
import { CoachingContext } from 'src/coaching/context/CoachingContext'
import { Button } from 'src/design_system/components/Button/Button'
import { TraineeFragment } from 'src/legacy_graphql'
import {
  green,
  red,
  ReminderPill as ReminderPillAndColors,
  yellow,
} from './TraineePill'

interface Props {
  traineeInfo: TraineeFragment
}

const styles = {
  main: {
    height: '80px',
    borderRadius: '8px',
    alignItems: 'center',
    '@media (max-width: 1600px)': {
      flexDirection: 'column' as const,
      rowGap: '1rem',
      padding: '1rem 0',
      height: 'auto',
      alignItems: 'flex-start',
    },
  },
  info: {
    paddingLeft: '1.5rem',
    width: '35%',
  },
  progress: {
    width: 'max(360px, 45%)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 2rem',
  },
  contact: {
    width: '20%',
    margin: '0 1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 1600px)': {
      width: 'auto',
    },
  },
}

type ReminderPillAndColors = {
  shouldDisplayPill: boolean
  type?: 'warning' | 'danger' | 'completed'
  text?: string
  color?: string
}

export const TraineeCard: React.FC<Props> = props => {
  const { traineeInfo } = props
  const {
    state: { badgesEarned },
  } = useContext(CoachingContext)

  const featuredBadgeGraphic = badgesEarned?.[badgesEarned.length - 1]?.graphic

  const getPercentageCompleted = (numberOfVideosCompleted: number) => {
    const percentage = Math.round(
      Math.min((numberOfVideosCompleted / 7) * 100, 100),
    )
    return `${percentage}%`
  }

  const getPillInformation = (): ReminderPillAndColors => {
    if (traineeInfo.promptingsCoach?.dateTrainingCompleted) {
      const completed: ReminderPillAndColors = {
        shouldDisplayPill: true,
        type: 'completed',
        text: 'COMPLETED',
        color: green,
      }
      return completed
    }

    const currentDate = new Date()
    const expiryDate = new Date(
      traineeInfo.promptingsCoach?.dateTrainingExpires ?? '',
    )
    const dayInSenconds = 86_400_000
    const daysLeft = Math.round(
      (expiryDate.valueOf() - currentDate.valueOf()) / dayInSenconds,
    )

    if (daysLeft < 7) {
      const danger: ReminderPillAndColors = {
        shouldDisplayPill: true,
        type: 'danger',
        text: `${daysLeft} DAY${daysLeft === 1 ? '' : 'S'} LEFT`,
        color: red,
      }
      return danger
    } else if (daysLeft < 14) {
      const warning: ReminderPillAndColors = {
        shouldDisplayPill: true,
        type: 'warning',
        text: '2 WEEKS LEFT',
        color: yellow,
      }
      return warning
    }

    const doNotShowPill: ReminderPillAndColors = {
      shouldDisplayPill: false,
    }

    return doNotShowPill
  }

  const reminderInformation: ReminderPillAndColors = getPillInformation()

  return (
    <Div
      backgroundColor="foreground"
      position="relative"
      display="flex"
      css={styles.main}
    >
      <Div
        position="absolute"
        width="16px"
        height="100%"
        css={{ borderRadius: '8px' }}
        top="0"
        left="0"
        overflow="hidden"
      >
        <Div
          position="absolute"
          css={{
            backgroundColor: `rgb(${
              reminderInformation.color ?? '114, 126, 144'
            })`,
          }}
          width="4px"
          height="100%"
          top="0"
          left="0"
        />
      </Div>
      <Div css={styles.info}>
        <Div display="flex" alignItems="center">
          <Div position="relative">
            <ProfileAvatar
              name={`${traineeInfo.firstName} ${traineeInfo.lastName}`}
              image={traineeInfo.profileImageUrl ?? undefined}
              size={44}
              style={
                {
                  fontSize: '1.75rem',
                  color: '#fff',
                  letterSpacing: 1,
                } as React.CSSProperties
              }
            />
            {featuredBadgeGraphic && (
              <Div position="absolute" bottom="0" left="-10px">
                <Image
                  image={{ url: featuredBadgeGraphic }}
                  width="27px"
                  height="30px"
                  isActive={false}
                />
              </Div>
            )}
          </Div>
          <Div outset={{ left: 'x1' }}>
            <Text
              type="largeBody"
              weight="extraBold"
              color="primaryHeading"
              content={`${traineeInfo.firstName} ${traineeInfo.lastName}`}
              lineHeight={1}
            />
            <Text
              type="caption"
              content={`ID Number: ${traineeInfo.genealogyId}`}
              lineHeight={1.1}
            />
          </Div>
        </Div>
      </Div>
      <Div display="flex" css={styles.progress}>
        <Div>
          <Text
            type="footnote"
            color="primaryHeading"
            weight="bold"
            content="PROGRESS:"
            lineHeight={1}
          />
          <Text
            type="largeBody"
            content={getPercentageCompleted(
              traineeInfo.promptingsCoach?.trainingVideosCompleted ?? 0,
            )}
            weight="bold"
            style={{ color: '#686868' }}
            lineHeight={1.25}
          />
        </Div>
        <Div display="flex" flexDirection="column" alignItems="flex-start">
          <Text
            type="footnote"
            color="primaryHeading"
            weight="bold"
            content={
              traineeInfo.promptingsCoach?.dateTrainingCompleted
                ? 'COACHED ON'
                : 'COACH BY'
            }
            lineHeight={1}
          />
          <Text
            type="largeBody"
            content={
              traineeInfo.promptingsCoach?.dateTrainingCompleted ??
              traineeInfo.promptingsCoach?.dateTrainingExpires ??
              ''
            }
            weight="bold"
            style={{ color: '#686868' }}
            lineHeight={1.25}
          />
          {reminderInformation.shouldDisplayPill &&
            reminderInformation.text &&
            reminderInformation.type && (
              <ReminderPillAndColors
                type={reminderInformation.type}
                text={reminderInformation.text}
              />
            )}
        </Div>
      </Div>
      <Div css={styles.contact}>
        <Button
          title="Send a message"
          onClick={() => {
            window.location.href = `mailto:${traineeInfo.email}`
          }}
          textColor="#121212"
          textSize="0.7rem"
          textWeight={700}
          backgroundColor="#F5F6F7"
          icon={{ name: 'paper_plane', size: 13, color: '#121212' }}
        />
      </Div>
    </Div>
  )
}
