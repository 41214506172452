import { Operation } from '../../../__generated__/globalTypes'

import {
  ProductionInfoFragment,
  productionInfoFragmentString,
} from '../../fragments/__generated__/ProductionInfoFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { recipientFragmentString } from '../../fragments/__generated__/RecipientFragment'

import getProductionInfoRawString from '../GetProductionInfo.graphql'

const getProductionInfoString = [
  getProductionInfoRawString,
  productionInfoFragmentString,
  addressFragmentString,
  recipientFragmentString,
].join('\n\n')

export type GetProductionInfoData = {
  productionInfo: ProductionInfoFragment
}

export type GetProductionInfoVariables = {
  contactId: string
  orderId: string
}

export type GetProductionInfo = Operation<
  GetProductionInfoData,
  GetProductionInfoVariables
>

export const GetProductionInfo = ({
  contactId,
  orderId,
}: GetProductionInfoVariables): GetProductionInfo => ({
  query: getProductionInfoString,
  variables: {
    contactId,
    orderId,
  },
})
