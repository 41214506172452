import { Icon } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CustomArrowProps } from 'react-slick'

export const NextArrow: React.FC<CustomArrowProps> = props => {
  const { onClick, className } = props
  return (
    <>
      <div
        className={className}
        onClick={onClick}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          background: '#fff',
          width: '32px',
          height: '32px',
          position: 'absolute',
          borderRadius: '50px',
          right: '8px',
          cursor: 'pointer',
          zIndex: 1,
          boxShadow: 'rgba(0, 0, 0, 0.18) 0px 4px 8px',
        }}
      >
        <Icon name="rightChevron" size="medium" primaryColor="#000" />
      </div>
    </>
  )
}
