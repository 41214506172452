import { css } from '@emotion/core'
import React, { useContext, useRef } from 'react'
import {
  CoachingContext,
  CoachLevel,
} from 'src/coaching/context/CoachingContext'
import { CoachDetails } from '../CoachDetails/CoachDetails'
import { CoachingQualificationSection } from '../CoachingQualificationSection/CoachingQualificationSection'
import { CoachProgressCard } from '../CoachProgressCard/CoachProgressCard'
import {
  Div,
  Drawer,
  Flex,
  Icon,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  useActions,
  useMeasurements,
  useQueryParams,
  useState,
} from 'src/hooks'
import { PreviousArrow } from '../Carousel/components/PreviousArrow'
import { NextArrow } from '../Carousel/components/NextArrow'
import { CongratulationsBanner } from 'src/coaching/svg/CongratulationsBanner'
import { CongratulationsSilverCertified } from 'src/coaching/svg/CongratulationsSilverCertified'
import { Transition } from 'src/chrome'
import { Portal } from 'src/portal/portal'
import { CongratulationsGoldBanner } from 'src/coaching/svg/CongratulationsGoldBanner'
import { CongratulationsPlatinumBanner } from 'src/coaching/svg/CongratulationsPlatinumBanner'
import PlanUpsale from 'src/plan_upsale/PlanUpsale'
import { TrainingTracking } from '../TrainingTracking/TrainingTracking'
import {
  useByDesignLoginLink,
  useCoachingDashboardMarketingContent,
} from 'src/react_query'

export const CoachingDashboard = () => {
  const containerStyles = css`
    display: grid;
    width: 100%;
    grid-template-columns: minmax(200px, 1fr);
    padding: 24px;
    background-color: #f7f7f7;
    position: relative;
    --ms-overflow-style: none;
    scrollbar-width: none;
    --webkit-scrollbar {
      display: none;
    }
    grid-gap: 24px;
    @media (min-width: 1500px) {
      grid-template-columns: minmax(360px, 1fr);
    }
  `
  const { state, asyncActions } = useContext(CoachingContext)
  const queryParams = useQueryParams()
  const actions = useActions()
  const marketingQuery = useCoachingDashboardMarketingContent()
  const [isCoachDetailsDrawerOpen, setIsCoachDetailsDrawerOpen] = useState(true)
  const {
    isLoading: isCreatingBydesignLoginLink,
    data: byDesignLoginLink,
  } = useByDesignLoginLink()
  const {
    badgesInProgress,
    badgesEarned,
    coachDetails,
    currentCoachLevel,
  } = state

  const [currentBadgeInProgress] = badgesInProgress ?? []

  const allBadges = [...(badgesInProgress ?? []), ...(badgesEarned ?? [])]

  const allBadgesNotInProgress = allBadges.filter(
    badge => badge !== currentBadgeInProgress,
  )

  const carouselRef = useRef<HTMLDivElement>(null)
  const slide = (operation: '-' | '+') => {
    if (carouselRef.current) {
      // tslint:disable:no-object-mutation
      carouselRef.current.scrollLeft += +operation.concat('310')
    }
  }

  const { windowWidth } = useMeasurements()

  if (marketingQuery.isLoading) {
    return <LoadingSpinner size="medium" />
  }

  const keepPlayingProductPath = marketingQuery.data?.keepPlayingProductPath

  if (keepPlayingProductPath === undefined) {
    console.error('Missing keepPlayingProductPath')
  }

  const handleOnKeepPlaying = () => {
    window.open(`${byDesignLoginLink}/${keepPlayingProductPath?.url}`)
  }

  const defaultLockedLink = {
    title: 'Unlock this Content',
    onClick: handleOnKeepPlaying,
  }

  return (
    <div css={containerStyles}>
      {queryParams.showUpsale && (
        <Portal>
          <Div position="relative" zIndex={3000}>
            <PlanUpsale
              onClose={() => {
                actions.clearRouteArgs()
              }}
            />
          </Div>
        </Portal>
      )}
      {currentCoachLevel && (
        <div id="coaching_banner">
          <div
            css={css`
              height: 150px;
              border-radius: 16px;
              overflow: hidden;
            `}
          >
            {currentCoachLevel === CoachLevel.PROMPTINGS_PROCESS ? (
              <CongratulationsBanner />
            ) : currentCoachLevel === CoachLevel.SILVER ? (
              <CongratulationsSilverCertified />
            ) : currentCoachLevel === CoachLevel.GOLD ? (
              <CongratulationsGoldBanner />
            ) : (
              CoachLevel.PLATINUM && <CongratulationsPlatinumBanner />
            )}
          </div>
        </div>
      )}
      <div
        id="coaching_awards"
        css={css`
          display: grid;
          gap: 24px;
          grid-template-columns: 1fr;
          height: ${currentBadgeInProgress ? `1192px` : `608px`};
          @media (min-width: 1024px) {
            grid-template-columns: ${currentBadgeInProgress
              ? `minmax(360px, 1fr) minmax(244px, 1fr)`
              : `1fr`};
            height: 608px;
          }
          @media (min-width: 1600px) {
            grid-template-columns: ${currentBadgeInProgress
              ? `minmax(360px, 1fr) minmax(244px, 2fr)`
              : `1fr`};
          }
        `}
      >
        {currentBadgeInProgress && (
          <div id="coaching_current_level">
            <CoachProgressCard
              height="100%"
              width="100%"
              isLocked={currentBadgeInProgress.isLocked}
              graphicURL={currentBadgeInProgress.graphic}
              title={currentBadgeInProgress.name}
              description={currentBadgeInProgress.description}
              progressPercentage={currentBadgeInProgress
                .progress()
                .toPercentile()}
              action={
                currentBadgeInProgress.link?.title
                  ? {
                      onClick: () =>
                        window.open(currentBadgeInProgress.link?.url),
                      title: currentBadgeInProgress.link.title,
                    }
                  : undefined
              }
            />
          </div>
        )}
        <div
          id="coaching_your_progress"
          style={{
            background:
              'linear-gradient(90deg, rgba(237, 236, 236, 0.25) 0%, rgba(237, 236, 236, 0.5) 49.48%, rgba(237, 236, 236, 0.25) 100%)',
            borderRadius: '20px',
            overflow: 'auto',
          }}
        >
          <Div position="relative" width="100%" height="100%">
            <Text
              type="body"
              style={{ color: '#C0C0C0' }}
              content="YOUR PROGRESS"
              weight="bold"
              inset={{ top: 'x1', left: 'x3' }}
            />
            <Div position="absolute" top="50%" left="0px" zIndex={97}>
              <PreviousArrow
                onClick={() => {
                  slide('-')
                }}
              />
            </Div>
            <Div position="absolute" top="50%" right="0px" zIndex={97}>
              <NextArrow
                onClick={() => {
                  slide('+')
                }}
              />
            </Div>
            <Div
              display="grid"
              inset={{ top: 'x3', bottom: '1.5rem', horizontal: '3rem' }}
              style={{
                gridAutoColumns: '310px',
                gridAutoFlow: 'column dense',
                columnGap: '1rem',
                overflow: 'hidden',
                scrollBehavior: 'smooth',
              }}
              height="calc(100% - 3rem)"
              ref={carouselRef}
              position="relative"
            >
              {allBadgesNotInProgress.map((badge, index) => (
                <CoachProgressCard
                  key={index}
                  height="100%"
                  width="100%"
                  isLocked={badge.isLocked}
                  graphicURL={badge.graphic}
                  title={badge.name}
                  description={badge.description}
                  isMediumSize={true}
                  progressPercentage={badge.progress().toPercentile()}
                  action={
                    badge.isLocked
                      ? defaultLockedLink
                      : badge.link?.title
                      ? {
                          onClick: () => window.open(badge.link?.url),
                          title: badge.link.title,
                        }
                      : undefined
                  }
                />
              ))}
            </Div>
          </Div>
        </div>
      </div>
      <div
        id="coaching_metrics"
        css={css`
          display: grid;
          gap: 24px;
          height: 656px;
          grid-template-columns: 1fr;
          @media (min-width: 1024px) {
            height: 328px;
            grid-template-columns: minmax(320px, 1fr) minmax(244px, 1fr);
          }
          @media (min-width: 1600px) {
            grid-template-columns: minmax(360px, 1fr) minmax(244px, 2fr);
          }
        `}
      >
        <div
          id="coaching_badge_qualifications"
          css={css`
            grid-row: span 1;
            @media (max-width: 400px) {
              overflow-x: auto;
              min-width: 520px;
            }
          `}
        >
          {badgesInProgress && badgesInProgress.length > 0 && (
            <CoachingQualificationSection
              badges={allBadges?.map(badge => {
                return {
                  title: badge.name,
                  coachTitle:
                    badge.progress().toPercentile() >= 1
                      ? 'Complete'
                      : 'In Progress',
                  qualifications: badge.qualifications.map(qualification => ({
                    title: qualification.title,
                    isComplete: qualification.progress().toPercentile() === 1,
                    isLocked: badge.isLocked,
                    progressPercentage: qualification.progress().toPercentile(),
                    progressSteps:
                      qualification.progress().threshold > 1
                        ? qualification.progress().toIncremental()
                        : undefined,
                    link: qualification.link ?? undefined,
                  })),
                  isLocked: badge.isLocked,
                  graphicURL: badge.graphic,
                }
              })}
              defaultActiveBadgeIndex={
                badgesInProgress.length > 1
                  ? badgesInProgress.findIndex(
                      badge => badge === currentBadgeInProgress,
                    )
                  : 0
              }
            />
          )}
        </div>
        <div id="coaching_live_tracking_data">
          <TrainingTracking />
        </div>
      </div>
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          height: 100vh;
          z-index: 100;
        `}
      >
        {isCreatingBydesignLoginLink && (
          <Portal>
            <Div position="absolute" zIndex={1000}>
              <Transition />
            </Div>
          </Portal>
        )}
        <Drawer
          isOpen={isCoachDetailsDrawerOpen}
          openValue={windowWidth < 540 ? windowWidth : 540}
          location="right"
          onClick={() => setIsCoachDetailsDrawerOpen(!isCoachDetailsDrawerOpen)}
          zIndex={1}
          tabMode="dark"
        >
          <Flex
            flexDirection="column"
            outset={{ vertical: 'x1' }}
            position="relative"
          >
            <Div
              position="absolute"
              top={'0'}
              right={'0'}
              cursor="pointer"
              onClick={() => setIsCoachDetailsDrawerOpen(false)}
            >
              <Icon name="close" size="small" primaryColor={'default'} />
            </Div>
            {coachDetails &&
              (() => {
                const lastEarnedBadge = badgesEarned?.[badgesEarned.length - 1]
                return (
                  <CoachDetails
                    name={`${coachDetails.firstName} ${coachDetails.lastName}`}
                    id={coachDetails.genealogyId.toString()}
                    coachImage={coachDetails.imageUrl ?? ''}
                    featuredBadgeGraphic={lastEarnedBadge?.graphic}
                    coachingStatus={''}
                    memberSince={coachDetails.memberSince.year}
                    badgesEarnedGraphics={badgesEarned?.map(
                      badge => badge.graphic,
                    )}
                    coachActions={
                      coachDetails.isAuthUser
                        ? {
                            email: {
                              isPublic: coachDetails.isEmailPublic,
                              setIsPublic: asyncActions.setIsEmailPublic,
                            },
                            phoneNumber: {
                              isPublic: coachDetails.isPhonePublic,
                              setIsPublic: asyncActions.setIsPhonePublic,
                            },
                          }
                        : undefined
                    }
                    traineeActions={{
                      sendMail: coachDetails.isEmailPublic
                        ? () => window.open(`mailto:${coachDetails.email}`)
                        : null,
                      sendSMS: coachDetails.isPhonePublic
                        ? () => window.open(`sms:${coachDetails.phoneNumber}`)
                        : null,
                    }}
                  />
                )
              })()}
          </Flex>
        </Drawer>
      </div>
    </div>
  )
}
