import {
  Anchor,
  Button,
  Div,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import React, { FC } from 'react'
import { getPlans, PlanFragment } from 'src/legacy_graphql'
import { getIconFromPlan } from '../pricingTile/getPlanIcon'
import { useMemo, useQueries, useSelector, useState } from 'src/hooks'
import { AnimatePresence, motion } from 'framer-motion'
import { Button as DSButton } from 'src/design_system/components/Button/Button'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { useCheckout, useProductVariant } from 'src/react_query/queries/hooks'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { Transition } from 'src/chrome'

type PromoPlanTileType = {
  planId: string
  action: { title: string; onClick: (plan: PlanFragment) => void }
  extraContent?: string
  recurrence?: string
  maxWidth?: string
  minWidth?: string
  onPurchaseNow: () => void
}

export const PromoPlanTile: FC<PromoPlanTileType> = ({
  planId,
  action,
  extraContent,
  recurrence = '/mo',
  minWidth = '60%',
  maxWidth = '775px',
  onPurchaseNow,
}) => {
  const isTablet = useSelector(state => state.window.width <= 860)
  const [plans] = useQueries(getPlans())
  const plan = useMemo(() => plans.find(p => p.id === planId), [plans, planId])
  const { data: checkout } = useCheckout()

  const planDescriptions = getPlanDescriptions(plans)

  const variantIdOnPlan = plan
    ? planDescriptions[plan?.id]?.saleorVariantId
    : ''
  const { data } = useProductVariant(
    { sku: plan?.stripeId ?? '' },
    !!!variantIdOnPlan,
  )
  const variantId = variantIdOnPlan ?? data?.id

  const isDuplicateItem = checkout?.lines.some(
    lineItem => lineItem.variant.id === variantId,
  )

  const {
    addToCart,
    hasCheckoutError,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
  } = useHandleProductActions(plan?.stripeId ?? '')

  const [isPurchaseDrawerOpen, setIsPurchaseDrawerOpen] = useState(false)

  const responsiveStyles = {
    titleSize: isTablet ? '1.2rem' : '1.385rem',
    priceSize: isTablet ? '1.5rem' : '1.875rem',
    btnTitleSize: isTablet ? '.8rem' : '1rem',
    featuresSize: isTablet ? '.7rem' : '.89rem',
    cardPadding: isTablet ? 'x2' : 'x4',
    cardGap: isTablet ? 'x2_5' : 'x4',
  }

  if (!plan) return <></>

  return (
    <Flex
      position="relative"
      flexDirection="column"
      backgroundColor="foreground"
      borderRadius={'medium'}
      style={{
        borderTop: `4px solid ${plan.backgroundStartColor}`,
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
      }}
      inset={{ horizontal: 'x4', vertical: 'x3' }}
      justifyContent="center"
      alignItems="flex-start"
      minWidth={isTablet ? '225px' : minWidth}
      maxWidth={isTablet ? '350px' : maxWidth}
      flexWrap="wrap"
      overflow="hidden"
    >
      {(isUpdatingCheckout || isCreatingCheckout) && (
        <Transition message={'Creating checkout...'} />
      )}
      {hasCheckoutError && checkoutErrorMessage && (
        <AnimatePresence>
          <ToasterNotification
            backgroundColor={{
              swatch: 'danger',
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: 'information',
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: checkoutErrorMessage,
            }}
          />
        </AnimatePresence>
      )}
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isTablet ? 'center' : 'flex-start'}
        width="100%"
        flexWrap={isTablet ? 'wrap' : 'nowrap'}
      >
        <Flex
          alignItems="center"
          width="100%"
          rowGap="x1"
          columnGap="x1"
          order={isTablet ? 2 : 1}
        >
          {getIconFromPlan(plan, false)}
          <Text
            weight="bold"
            content={plan.title}
            type="body"
            style={{ color: plan.backgroundStartColor }}
          />
        </Flex>

        <Div
          order={isTablet ? 1 : 2}
          backgroundColor="#fff"
          borderRadius="circle"
          boxShadow="light"
          height="fit-content"
          inset={{ horizontal: 'x1_5' }}
          outset={{ bottom: 'auto' }}
          style={{ marginLeft: isTablet ? 0 : 'auto' }}
          minWidth="fit-content"
        >
          <Text
            type="body"
            content={'sample'}
            lineHeight={2}
            style={{
              fontSize: '.625rem',
              fontWeight: 700,
              color: plan.backgroundStartColor,
            }}
          />
        </Div>
      </Flex>
      <Spacer space="x1" />
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        rowGap="x3"
        columnGap="x3"
        alignItems="center"
        flexWrap="wrap"
      >
        <Flex flexDirection="column" maxWidth={isTablet ? '100%' : '60%'}>
          <Flex alignItems="center">
            <Text
              content={`$${(plan.price.amount / 100).toString()}`}
              type="title"
              color="primaryBody"
              weight="semiBold"
            />
            <Text
              content={recurrence}
              type="caption"
              style={{ color: '#6B7280' }}
            />
          </Flex>
          <Spacer space="x1" />
          {plan.subtitle && (
            <>
              <Text content={plan.subtitle} type="body" />
              <Spacer space="x1" />
            </>
          )}
          {extraContent && (
            <>
              <Text content={extraContent} type="caption" />
              <Spacer space="x1" />
            </>
          )}
        </Flex>
      </Flex>
      {isTablet && <Spacer space="x2" />}
      <Flex justifyContent={isTablet ? 'center' : 'flex-end'} width="100%">
        <Button
          title={action.title}
          onClick={() => setIsPurchaseDrawerOpen(true)}
          backgroundColorOverride={plan.backgroundStartColor}
          fullWidth={isTablet}
        />
      </Flex>
      <AnimatePresence>
        {isPurchaseDrawerOpen && (
          <motion.div
            initial={{
              y: 250,
              opacity: 0,
            }}
            animate={{
              y: 10,
              opacity: 1,
              transition: { type: 'spring', bounce: 0.25 },
            }}
            exit={{ y: 250, opacity: 0 }}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              background: 'fff',
              boxShadow: '0px -8px 8px 0px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              rowGap="x2"
              width="100%"
              height="100%"
              backgroundColor="rgba(230,230,230, 0.75)"
              inset={{
                top: 'x6',
                horizontal: 'x4',
                bottom: 'x4',
              }}
            >
              <DSButton
                title={isDuplicateItem ? 'Added to your Cart' : 'Add to Cart'}
                textColor="#fff"
                textWeight={500}
                background={plan.backgroundStartColor}
                width="100%"
                boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
                onClick={() => {
                  addToCart(variantId)
                }}
                textSize={responsiveStyles.btnTitleSize}
                icon={{
                  name: isDuplicateItem ? 'check_mark' : 'bag_plus',
                  color: '#FFF',
                  size: 15,
                }}
                isDisabled={isDuplicateItem}
              />
              <DSButton
                title="Purchase Now"
                textColor="hsl(0,0%,8.1%)"
                textWeight={500}
                backgroundColor="#fff"
                width="100%"
                boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
                onClick={() =>
                  isDuplicateItem
                    ? onPurchaseNow()
                    : addToCart(variantId, onPurchaseNow)
                }
                textSize={responsiveStyles.btnTitleSize}
                icon={{ name: 'shopping_cart_2', size: 16 }}
              />
              <div onClick={() => setIsPurchaseDrawerOpen(false)}>
                <Icon name="cancel" size={10} color="black" />
                <Anchor
                  title="Close"
                  isDecorated={true}
                  color="primaryHeading"
                  size="xSmall"
                  outset={{ left: 'x_5' }}
                />
              </div>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  )
}
