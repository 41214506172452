import { Route } from 'src/routes/Route'
import { CatalogSubroute } from 'src/catalog/routes/CatalogRoute'

export const ReplaceCampaignCardRoute = Route({
  path: '/cards/:cardId/replace',
  init: (cardId: string, subroute?: CatalogSubroute) => ({ cardId, subroute }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  subroute: CatalogSubroute,
})

export type ReplaceCampaignCardRoute = Route<typeof ReplaceCampaignCardRoute>
