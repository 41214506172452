import React, { FC } from 'react'
import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import { PlanFragment } from 'src/legacy_graphql'
import { usePlansSubscriptions, useSelector } from 'src/hooks'
import { offeredAffiliatePlanIds } from 'src/helpers/supportedUpsaleProducts'

type SubscriptionSelectionPillType = {
  text: string
  backgroundColor?: string
  textColor?: string
}

type SubscriptionSelectionTileProps = {
  plan: PlanFragment
  indicatorBackground?: string
  pill?: SubscriptionSelectionPillType
  onSelect: () => void
  isSelected?: boolean
  isPreviousPlan?: boolean
}

export const SubscriptionSelectionTile: FC<SubscriptionSelectionTileProps> = ({
  plan,
  onSelect,
  isSelected,
  pill,
  isPreviousPlan = false,
  indicatorBackground,
}) => {
  const { currentPlan, currentSubscription, newPlan } = usePlansSubscriptions()
  const isBasicPlan = plan.id === '1'
  const isActivePlan = currentPlan?.id === plan.id
  const isPlanSetToCancel =
    plan.id === currentPlan?.id && currentSubscription?.isSetToCancel
  const isDowngradeBasic =
    isBasicPlan && currentSubscription?.isSetToCancel && !newPlan
  const shouldShowPlanChangeStatus =
    isPlanSetToCancel ||
    isPreviousPlan ||
    newPlan?.id === plan.id ||
    isDowngradeBasic
  const isAnnualAffiliatePlan =
    plan.id === offeredAffiliatePlanIds.annualAffiliate.id
  const formatDate = (date: string) => {
    const [datePart] = date.split('T')
    const [year, month, day] = datePart.split('-')
    return `${month}/${day}/${year}`
  }

  const renewalDate = currentSubscription
    ? formatDate(currentSubscription?.currentPeriodEnd)
    : ''

  const planEndDate =
    currentSubscription?.isSetToCancel &&
    currentSubscription.currentPeriodEnd &&
    renewalDate

  const newPlanStartDate =
    newPlan?.id === plan.id &&
    currentSubscription?.currentPeriodEnd &&
    renewalDate

  const planStatusMessage =
    newPlan?.id === plan.id
      ? `Plan Starts: ${newPlanStartDate}`
      : planEndDate && plan.id !== '1'
      ? `Plan Ends: ${planEndDate}`
      : ''

  const textColor = isSelected ? 'inverseHeading' : 'primaryBody'
  const background = isSelected ? '#404040' : '#fff'

  const { width } = useSelector(state => state.window)
  const isMobile = width <= 542

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      style={{
        background,
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
        borderTop: isActivePlan ? '4px solid #6b7280' : '',
      }}
      inset="x1_5"
      outset={{ bottom: 'x1' }}
      borderRadius="medium"
      onClick={onSelect}
      cursor="pointer"
    >
      <Flex alignItems="center">
        <Div
          minWidth="3px"
          height="35px"
          style={{
            background: isSelected
              ? 'linear-gradient(161deg, #4ace9e 0%, #67e5dd 50%, #31d3a0 100%)'
              : indicatorBackground ??
                'linear-gradient(161deg, rgb(196, 113, 245), rgb(43 215 251) 100%)',
            borderRadius: '2px',
          }}
          outset={{ right: 'x1_5' }}
        />
        <Flex
          id="check-circle"
          minWidth="16px"
          borderColor="background"
          borderStyle="solid"
          borderWidth="bold"
          style={{ border: isSelected ? 'none' : '2px solid #D4D4D8' }}
          minHeight="16px"
          borderRadius="circle"
          inset="2px"
          justifyContent="center"
          alignItems="center"
          backgroundColor={isSelected ? 'foreground' : 'transparent'}
          outset={{ right: '12px' }}
        >
          {isSelected && (
            <Icon name="check" size="9px" primaryColor="primaryBodyText" />
          )}
        </Flex>
        <Flex flexDirection="column">
          <Text
            content={plan.title}
            type={isMobile ? 'caption' : 'body'}
            weight="semiBold"
            color={textColor}
          />
          {shouldShowPlanChangeStatus && (
            <Flex alignItems="center">
              <Icon
                name={
                  isPlanSetToCancel ||
                  newPlan?.id === plan.id ||
                  isDowngradeBasic
                    ? 'swap'
                    : 'award'
                }
                size={'xSmall'}
                primaryColor={isSelected ? 'inverseHeading' : 'primaryBodyText'}
              />
              <Text
                outset={{ left: 'x_5' }}
                type="footnote"
                weight="bold"
                content={planStatusMessage}
                style={{ color: isSelected ? '#FFF' : '#be9cdd' }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        style={{
          marginLeft: 'auto',
          boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
        }}
        alignItems="flex-end"
        justifyContent="center"
      >
        {!shouldShowPlanChangeStatus && pill && (
          <Flex
            justifyContent="center"
            alignItems="center"
            inset={{ vertical: 'x_25', horizontal: 'x1_5' }}
            backgroundColor={pill.backgroundColor ?? '#FFF'}
            borderRadius="circle"
            outset={{ horizontal: 'x2' }}
            width="fit-content"
          >
            <Text
              content={pill.text}
              type="footnote"
              weight="semiBold"
              style={{ color: pill.textColor ?? '#FFF' }}
            />
          </Flex>
        )}
        <Text
          content={`${plan.price.description.slice(0, -3)}`}
          type={'largeBody'}
          color={textColor}
          weight="bold"
          outset={{ right: 'x_25' }}
        />
        <Text
          color={textColor}
          type="footnote"
          content={isBasicPlan ? 'Free' : isAnnualAffiliatePlan ? '/yr' : '/mo'}
        />
      </Flex>
    </Flex>
  )
}
