import React from 'react'
import { Div } from '@sendoutcards/quantum-design-ui'
import { DefaultError, Transition } from 'src/chrome'
import { useAccount } from 'src/hooks'
import { CoachingDashboard } from '../components/CoachingDashboard/CoachingDashboard'
import { CoachingProvider } from '../context/CoachingContext'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { UpsaleBanner } from '../components/UpsaleBanners/UpsaleBanner'
import useCoachingPotentialBoost from 'src/hooks/useCoachingPotentialBoost'

const Coaching = () => {
  const { promptingsCoach } = useAccount()
  const coachingPotentialBoost = useCoachingPotentialBoost()
  const hasPromptingsCoach = !!promptingsCoach

  return (
    <>
      {coachingPotentialBoost.isTransitioning && <Transition />}
      {hasPromptingsCoach ? (
        <CoachingProvider>
          <CoachingDashboard />
        </CoachingProvider>
      ) : (
        <Div
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70%"
        >
          <Div maxWidth="680px">
            <UpsaleBanner
              title="Purchase today"
              description="and start acting, appreciating, and attracting prosperity with"
              upsaleItem={{
                title: 'The promptings Process',
                graphic: {
                  width: '80px',
                  height: '80px',
                  color: { gradientOne: '#008080', gradientTwo: '#50E4E4' },
                },

                description: 'Package',
              }}
              pricingStamp={{
                title: 'Unlimited user price',
                price: coachingPotentialBoost.shouldDisplay447
                  ? '$447'
                  : '$497',
              }}
              primaryAction={{
                title: 'Purchase Now',
                backgroundColor: '#008080',
                onClick: coachingPotentialBoost.handleOnGetStarted,
              }}
              secondaryAction={{
                title: 'More Info',
                backgroundColor: 'white',
                textColor: 'black',
                onClick: coachingPotentialBoost.handleOnMoreInfo,
              }}
            />
          </Div>
        </Div>
      )}
    </>
  )
}

export default suspenseBoundary({
  component: Coaching,
  unresolved: <Transition />,
  failure: DefaultError,
})
