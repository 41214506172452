import { SearchFilter } from 'src/chrome/FilteredSearchBar/types'
import { getAllGroups, getDetailedCountries } from 'src/legacy_graphql'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'

import { useCallback, useQueries, useState } from 'src/hooks'

import { DEFAULT_CONTACT_SEARCH, DEFAULT_GROUP_SEARCH } from './filters'

const RelationshipSharedHooks = (isGroup: boolean = false) => {
  const [search, setSearch] = useState<{
    filters: SearchFilter[]
    terms: string
    duplicateId?: string
  }>(isGroup ? DEFAULT_GROUP_SEARCH : DEFAULT_CONTACT_SEARCH)
  const [page, setPage] = useState(1)

  const [groups, detailedCountries] = useQueries(
    getAllGroups(),
    getDetailedCountries(),
  )

  const countries = formatCountriesAndRegions(detailedCountries)

  const setFilters = useCallback(
    filters => setSearch(x => ({ ...x, filters })),
    [],
  )
  const setTerms = useCallback(
    terms => setSearch(x => ({ ...x, duplicateId: undefined, terms })),
    [],
  )
  const setSearchDuplicate = useCallback(
    contact =>
      setSearch(x => ({
        ...x,
        duplicateId: contact.id,
        terms: `${contact.firstName} ${contact.lastName}`,
      })),
    [],
  )
  const clearSearch = useCallback(
    () => setSearch(isGroup ? DEFAULT_GROUP_SEARCH : DEFAULT_CONTACT_SEARCH),
    [isGroup],
  )

  return {
    groups,
    countries,
    search,
    setSearch,
    page,
    setPage,
    setFilters,
    clearSearch,
    setTerms,
    setSearchDuplicate,
  }
}

export default RelationshipSharedHooks
