import React from 'react'
import { useActions } from 'src/hooks'
import { ReplaceCampaignCardRoute } from '../../routes/ReplaceCampaignCardRoute'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'
import CardPicker from 'src/card_picker/CardPicker'
import { buildInput } from '../../utils'
import { CardActionsProps } from '../AddCampaignCard/AddCampaignCard'
import { Text } from '@sendoutcards/quantum-design-ui'
import { useUpdateCampaign } from 'src/react_query'

type Props = CardActionsProps & {
  reload: () => void
  route: ReplaceCampaignCardRoute
}

const ReplaceCampaignCard: React.FC<Props> = props => {
  const { editableCampaign, campaignRoute, resetRoute, reload, route } = props
  const actions = useActions()
  const updateCampaignMutation = useUpdateCampaign()
  const { cardId } = route

  const handleReplace = async (cardId: string, newCardId: string) => {
    const campaignInput = buildInput(editableCampaign)
    await updateCampaignMutation.mutateAsync({
      campaign: {
        ...campaignInput,
        lines: (campaignInput.lines || []).map(line => ({
          ...line,
          card: line.card === cardId ? newCardId : line.card,
        })),
      },
    })
    reload()
    resetRoute()
  }

  return (
    <CardPicker
      onAfterSave={newCardId => handleReplace(cardId, newCardId)}
      context={{
        headerText: `Campaign: ${editableCampaign.name}`,
        headerSubText: `Replacing card`,
        lines: editableCampaign.lines,
        activeCardId: cardId,
        footerLabel: () => (
          <Text type="caption" color="primaryHeading" weight="bold">
            {editableCampaign.lines.length} card
            {editableCampaign.lines.length > 1 && 's'}
          </Text>
        ),
      }}
      catalogSubroute={route.subroute}
      onClose={resetRoute}
      saveButtonLabel="Replace Card"
      onCatalogNavigate={subroute =>
        actions.openCampaigns(
          CampaignRoute(
            campaignRoute.campaignId,
            ReplaceCampaignCardRoute(cardId, subroute),
          ),
        )
      }
    />
  )
}

export default ReplaceCampaignCard
