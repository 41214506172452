import { AwardFragment } from 'src/graphql/generated/graphql'
import { Maybe } from 'src/legacy_graphql'

type Progress = {
  value: number
  toPercentile: () => number
  toIncremental: () => string
  threshold: number
}

export type QualificationContext = {
  progress: () => Progress
  title: string
  description: string
  graphic: string
  link?: { title: string; url: string } | null
}

export type CoachingBadgeType = {
  name: string
  description: string
  graphic: string
  qualifications: QualificationContext[]
  progress: () => Progress
  threshold: number
  isLocked: boolean
  link: Maybe<{
    title: string
    url: string
  }>
}

const getProgress = (threshold: number, value: number): Progress => {
  return {
    value,
    toIncremental: () => {
      return `${value} out of ${threshold}`
    },
    toPercentile: () => {
      if (threshold === 0) return 0 // prevent divide by 0 error
      return parseFloat((value / threshold).toFixed(2))
    },
    threshold,
  }
}

export const coachingBadgeFactory = (
  badge: AwardFragment,
): CoachingBadgeType => {
  return {
    name: badge.title,
    description: badge.description,
    isLocked: badge.isLocked,
    graphic: badge.graphicUrl,
    link: badge.link ?? null,
    qualifications: badge?.qualifications.map(qualification => ({
      title: qualification?.title ?? '',
      description: qualification?.description ?? '',
      graphic: qualification?.graphic ?? '',
      progress: () =>
        getProgress(
          qualification?.threshold ?? 0,
          qualification?.progress ?? 0,
        ),
      link: qualification?.link,
    })),
    threshold: badge.threshold,
    progress: () => {
      return getProgress(badge.threshold, badge.progress)
    },
  }
}
