import { Button, Flex, Separator, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { getPlans, PlanFragment } from 'src/legacy_graphql'
import {
  PlanDetail,
  PlanIdType,
} from 'src/pricing_page/components/pricingTile/PlanTiles'
import { SubscriptionSelectionTile } from './components/SubscriptionSelectionTile'
import { Features } from 'src/pricing_page/components/pricingTile/components/Tile'
import {
  useAccount,
  usePlansSubscriptions,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'
import { SubscriptionType } from 'src/user/fragments'
import { PricingSection } from 'src/saleor/components/price_breakdown/components/PricingSection'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { SubscriptionFeaturesList } from './components/SubscriptionFeaturesList'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import styles from './subscriptionSelection.module.scss'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'

type SubscriptionSelectionProps = {
  planIds: PlanIdType[]
  currentPlan: PlanFragment
  onSelectPlan: (plan: PlanFragment) => void
  onChange?: (plan: PlanFragment) => void
  isOpen: boolean
  buttonTitle?: string
  onClose: () => void
  isLoading?: boolean
  shouldHideCurrentPlan?: boolean
}

export const SubscriptionSelection: React.FC<SubscriptionSelectionProps> = ({
  planIds: subscriptionPlanIds,
  currentPlan,
  onSelectPlan,
  isOpen,
  buttonTitle,
  onClose,
  onChange,
  isLoading,
  shouldHideCurrentPlan = false,
}) => {
  const [isCloseHovering, setIsCloseHovering] = useState(false)
  const [isProceedHovering, setIsProceedHovering] = useState(false)
  // TODO: Refactor this component to take a prop to default a specific id in the subscriptionIds prop to the stagedPlan

  const [stagedPlan, setStagedPlan] = useState<PlanFragment | undefined>(
    shouldHideCurrentPlan ? undefined : currentPlan,
  )
  const account = useAccount()
  const [plans] = useQueries(getPlans())
  const { newPlan } = usePlansSubscriptions()
  const monthlySubscription = account.subscriptions.find(
    (sub: SubscriptionType) => sub.isRenewable,
  )
  const affiliateSubscription = account.subscriptions.find(
    (sub: SubscriptionType) => sub.id === '6',
  )
  const monthlySubscriptions = monthlySubscription ?? affiliateSubscription
  // const { isInPromptingsGracePeriod } = useSubscriptions()

  const planIdsExcludingCurrentPlan = subscriptionPlanIds.filter(
    id => id.id !== currentPlan.id,
  )

  const finalSubscriptionIdList = shouldHideCurrentPlan
    ? planIdsExcludingCurrentPlan
    : [...planIdsExcludingCurrentPlan, { id: currentPlan.id }]

  const { hasFeatureAccess } = useFeatureAccess([
    FullAccessFeatureType.PremiumSubscriptionOptions,
  ])

  const activeSubscriptions: PlanFragment[] = finalSubscriptionIdList
    .map(id =>
      plans?.find((plan: PlanFragment) => id.id === plan.id && plan.id !== '1'),
    )
    .filter(
      (plan: PlanFragment | undefined): plan is PlanFragment =>
        !!plan && (!hasFeatureAccess || plan.id !== '161'),
    )

  const getOrderFromDescription = (description: string | null) => {
    if (!description) return 0
    try {
      const parsedDescription = JSON.parse(description)
      return parsedDescription.order ?? 0
    } catch (error) {
      return 0
    }
  }

  const currentSubscription = monthlySubscriptions

  const isDowngraded =
    newPlan &&
    currentPlan &&
    (getOrderFromDescription(newPlan.description) ?? 0) <
      (getOrderFromDescription(currentPlan.description) ?? 0)

  const planDescriptionJson = stagedPlan?.description
    ? JSON.parse(stagedPlan.description)
    : ''

  const planFeatures: Features[] =
    planDescriptionJson?.details?.map((detail: PlanDetail) => {
      if (detail.innerDetails) {
        return {
          feature: detail.detail,
          subFeatures: detail.innerDetails.map((d: PlanDetail) => d.detail),
          isNotIncluded: false,
        }
      }
      return detail.detail
    }) || []
  const handleSelectPlan = () => {
    if (!stagedPlan) return
    onSelectPlan(stagedPlan)
  }
  const subscriptionAmount = stagedPlan?.price.amount ?? 0

  const convertAmountToCurrency = () => Math.round(subscriptionAmount / 100)

  const { width } = useSelector(state => state.window)
  const isMobile = width <= 542
  const isSmallMobile = width <= 440
  const isHeaderBreakPoint = width <= 700

  const isSelectedPackage = false // TODO: Wire this up with the task to select packages
  // TODO: If Current Subscription is not in the list of provided subscriptions show it above the selectable list
  const windowWidth = useSelector(state => state.window.width)
  const isBreakpoint = windowWidth < 950

  return (
    <>
      {isOpen && (
        <Flex
          id="modal-overlay"
          width="100vw"
          height="100vh"
          position="fixed"
          justifyContent="center"
          overflowY="scroll"
          alignItems="center"
          zIndex={3001}
          flexDirection="column"
          top="0px"
          left="0px"
          style={{
            background: 'rgba(145, 145, 145, 0.1)',
            backdropFilter: 'blur(20px)',
          }}
        >
          <Flex
            minWidth="360px"
            flexDirection="column"
            position="relative"
            borderRadius="20px"
            outset={{
              horizontal: isMobile ? 'x1' : 'x4',
              bottom: '74px',
              top: '8px',
            }}
          >
            <Flex
              height="36px"
              position="absolute"
              zIndex={1}
              top="9px"
              right="9px"
              width="36px"
              alignItems="center"
              justifyContent="center"
              backgroundColor="#F3F4F6"
              borderRadius="12px"
              cursor="pointer"
              onClick={onClose}
              onHoverStart={() => setIsCloseHovering(true)}
              onHoverEnd={() => setIsCloseHovering(false)}
            >
              <Icon
                name={'x'}
                size={isCloseHovering ? 17 : 14}
                fill={isCloseHovering ? '#D35655' : '#3E3E3F'}
              />
            </Flex>
            <Flex
              id="modal-header"
              backgroundColor="#fff"
              width="100%"
              position="sticky"
              top="0px"
              justifyContent="space-between"
              borderRadius={{ top: '20px' }}
              inset={{
                vertical: '16px',
                left: '32px',
                right: isHeaderBreakPoint ? '16px' : '24px',
              }}
            >
              <Flex
                id="subFeatureListContainer"
                width={isBreakpoint ? '100%' : '50%'}
                flexDirection="column"
                rowGap="16px"
              >
                <Text
                  inset={{
                    bottom: isSmallMobile ? 'x1' : undefined,
                  }}
                  type="subtitle"
                  content="Membership Options"
                  style={{
                    color: '#404040',
                    fontSize: isMobile ? 18 : 20,
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                  }}
                />
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              width="100%"
              position="relative"
              backgroundColor="#FCFCFC"
              overflow="scroll"
              inset={{
                top: isMobile ? '8px' : '20px',
                horizontal: isSmallMobile ? 'x1_5' : isMobile ? 'x2_5' : 'x6',
              }}
            >
              <Flex
                flexDirection="column"
                width="100%"
                justifyContent="center"
                alignItems="flex-start"
                outset={{
                  bottom: 'x2_5',
                  top: 'x1_5',
                }}
              >
                <Text
                  inset={{
                    bottom: isSmallMobile ? 'x1' : undefined,
                  }}
                  alignment="left"
                  type="body"
                  content="Enhance your Promptings! Explore the options you have with our Monthly Memberships."
                />
              </Flex>
              <Flex
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="stretch"
                columnGap="12px"
                outset={{ bottom: 'x3' }}
                flexWrap={isBreakpoint ? 'wrap' : 'nowrap'}
              >
                <Flex
                  width={isBreakpoint ? '50%' : '100%'}
                  flexDirection="column"
                  outset={{ bottom: isBreakpoint ? 'x3' : undefined }}
                  style={{ flex: 1, alignItems: 'stretch' }}
                  justifyContent={isBreakpoint ? 'initial' : 'flex-end'}
                >
                  {!shouldHideCurrentPlan && (
                    <>
                      <Text
                        type={'body'}
                        outset={{ bottom: 'x1' }}
                        style={{
                          color: '#D1D5DB',
                          fontSize: 12,
                          fontFamily: 'Montserrat',
                          fontWeight: 700,
                        }}
                      >
                        Current Plan:
                      </Text>
                      <SubscriptionSelectionTile
                        plan={currentPlan}
                        onSelect={() => setStagedPlan(currentPlan)}
                        pill={{
                          text: 'Current',
                          backgroundColor: '#6B7280',
                          textColor: '#FFFFFF',
                        }}
                        indicatorBackground={'#000'}
                      />
                    </>
                  )}
                  <Text
                    type={'body'}
                    outset={{ bottom: 'x1' }}
                    style={{
                      color: '#D1D5DB',
                      fontSize: 12,
                      fontFamily: 'Montserrat',
                      fontWeight: 700,
                    }}
                  >
                    Subscription Plans:
                  </Text>
                  <div className={styles.subList}>
                    {activeSubscriptions.map(plan => {
                      if (!plan.description || plan.id === currentPlan.id)
                        return null

                      const description = JSON.parse(plan.description)
                      const isCurrentPlan = currentPlan.id === plan.id
                      const isPreviousPlan = isDowngraded && isCurrentPlan
                      const planPillData = description.planPillData // Note the json should be of shape {text: string, backgroundColor: string, textColor: string}
                      const planFeatures: Features[] =
                        description?.details?.map((detail: PlanDetail) => {
                          if (detail.innerDetails) {
                            return {
                              feature: detail.detail,
                              subFeatures: detail.innerDetails.map(
                                (d: PlanDetail) => d.detail,
                              ),
                              isNotIncluded: false,
                            }
                          }
                          return detail.detail
                        }) || []

                      return (
                        planFeatures && (
                          <SubscriptionSelectionTile
                            key={plan.id}
                            plan={plan}
                            onSelect={() => {
                              setStagedPlan(plan)
                              onChange?.(plan)
                            }}
                            isSelected={plan.id === stagedPlan?.id}
                            isPreviousPlan={isPreviousPlan}
                            pill={
                              isCurrentPlan
                                ? {
                                    text: 'Current',
                                    backgroundColor: '#6B7280',
                                    textColor: '#FFFFFF',
                                  }
                                : planPillData && {
                                    text: planPillData.text,
                                    backgroundColor:
                                      planPillData.backgroundColor,
                                    textColor: planPillData.textColor,
                                  }
                            }
                            indicatorBackground={
                              description.indicatorBackground
                            }
                          />
                        )
                      )
                    })}
                  </div>
                </Flex>
                <Flex
                  id="subFeatureListContainer"
                  width={isBreakpoint ? '100%' : '50%'}
                  flexDirection="column"
                  rowGap="16px"
                >
                  <Flex
                    backgroundColor="#fff"
                    borderRadius="medium"
                    inset={{ vertical: 'x3', horizontal: 'x2' }}
                    style={{
                      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
                      flex: 1,
                      alignItems: 'stretch',
                      justifyContent: 'center',
                    }}
                  >
                    <SubscriptionFeaturesList features={planFeatures} />
                  </Flex>
                  {isSelectedPackage && (
                    <>
                      <PricingSection
                        hasInvertedBackground={true}
                        title="Total Today"
                        subtitle="(package)"
                      />
                      <Separator orientation="horizontal" />
                    </>
                  )}
                  <PricingSection
                    hasInvertedBackground={true}
                    title="Total Ongoing"
                    subtitle="(subscription)"
                    price={{
                      __typename: 'Money',
                      currency: stagedPlan?.price.currency ?? 'USD',
                      amount: convertAmountToCurrency(),
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              id="subscription_cta_container"
              justifyContent="flex-end"
              alignItems="center"
              columnGap="20px"
              position="sticky"
              style={{
                boxShadow: isBreakpoint
                  ? '0px 2px 16px 2px rgba(0, 0, 0, 0.04)'
                  : undefined,
              }}
              inset="x2"
              flexDirection="column"
              backgroundColor={isBreakpoint ? '#fff' : '#FCFCFC'}
              width="100%"
              bottom="0px"
            >
              <Separator orientation="horizontal" />
              <Flex
                columnGap="x2"
                justifyContent="flex-end"
                width="100%"
                inset={{ top: 'x2' }}
                alignItems="center"
              >
                <Flex onClick={onClose} cursor="pointer">
                  <Text
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 700,
                      color: '#404040',
                      fontSize: '14px',
                      alignItems: 'center',
                      textDecoration: 'underline',
                    }}
                    type="body"
                  >
                    Cancel
                  </Text>
                </Flex>
                <Flex
                  onHoverStart={() => setIsProceedHovering(true)}
                  onHoverEnd={() => setIsProceedHovering(false)}
                >
                  <Button
                    onClick={() => handleSelectPlan()}
                    disabled={
                      isLoading ||
                      !stagedPlan ||
                      (stagedPlan?.id === currentPlan.id &&
                        currentSubscription &&
                        !currentSubscription.isSetToCancel)
                    }
                    title={
                      isLoading
                        ? 'Setting up Subscription'
                        : stagedPlan?.id === currentPlan.id &&
                          currentSubscription?.isSetToCancel
                        ? 'Cancel Change'
                        : buttonTitle ?? 'Change Subscription'
                    }
                    backgroundColorOverride={
                      isProceedHovering ? '#23c3ff' : '#00AEEF'
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}
