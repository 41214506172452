import React from 'react'
import { useSelector, useState } from 'src/hooks'
import {
  FullAccessFeatureType,
  PlanFragment,
} from 'src/graphql/generated/graphql'
import { Dialog, Flex } from '@sendoutcards/quantum-design-ui'
import { UpsaleSuccess } from '../UpsaleSuccess'
import { UpsaleCheckout } from './UpsaleCheckout'
import { UpsaleTopBar } from '../shared/UpsaleTopbar'
import { Portal } from 'src/portal/portal'
import { planFragmentToLegacy } from 'src/graphql/compat'

export type DialogUpsaleCheckoutProps = {
  selectedItem: PlanFragment
  onClose: () => void
  featureAccessToGrant?: FullAccessFeatureType[]
}

export const DialogUpsaleCheckout: React.FC<DialogUpsaleCheckoutProps> = ({
  selectedItem,
  onClose,
  featureAccessToGrant,
}) => {
  const isDialogBreakpoint = useSelector(state => state.window.width < 649)
  const [shouldShowUpsale, setShouldShowUpsale] = useState(true)
  const [shouldShowPurchaseSuccess, setShouldShowPurchaseSuccess] = useState(
    false,
  )
  const [upsaleSuccessMsg, setUpsaleSuccessMsg] = useState(
    'Way to go! You have successfully upgraded to',
  )

  return (
    <Portal>
      <Dialog
        css={{ padding: '16px' }}
        maxHeight={isDialogBreakpoint ? 'undefined' : '850px'}
        isOpen={true}
        insetOverride="x0"
        borderRadius="large"
        pinned={isDialogBreakpoint ? 'bottom' : undefined}
        zIndex={3000}
        height={isDialogBreakpoint ? '95%' : undefined}
        width={
          shouldShowPurchaseSuccess ? (isDialogBreakpoint ? '100%' : '95%') : ''
        }
        maxWidth={shouldShowUpsale ? '1000px' : '630px'}
      >
        <UpsaleTopBar handlePrevStep={onClose} onClose={onClose} />
        <Flex
          minHeight="520px"
          position="relative"
          overflow="auto"
          style={{ borderRadius: '0 0 28px 28px' }}
        >
          <Flex minHeight="520px" maxHeight="1100px" width="100%">
            {shouldShowUpsale && (
              <UpsaleCheckout
                title="Amazing, what a great choice!"
                description="Review the information below and confirm your purchase."
                selectedItem={planFragmentToLegacy(selectedItem)}
                actionTitle="Add To Account"
                onPurchaseSuccess={successMsg => {
                  setShouldShowUpsale(false)
                  setUpsaleSuccessMsg(successMsg)
                  setShouldShowPurchaseSuccess(true)
                }}
                onCancel={onClose}
                featureAccessToGrant={featureAccessToGrant}
              />
            )}
            {shouldShowPurchaseSuccess && (
              <UpsaleSuccess
                title={upsaleSuccessMsg}
                productTitle={selectedItem.title}
                icon="smile_card_w_hearts"
                onClose={onClose}
              />
            )}
          </Flex>
        </Flex>
      </Dialog>
    </Portal>
  )
}
