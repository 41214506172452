import { Button, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import { BorderRadius } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/hocBaseUnion'
import React from 'react'
import { CompleteBadge } from './StatusBadges/CompleteBadge'
import { InCompleteBadge } from './StatusBadges/IncompleteBadge'
import { InProgressBadge } from './StatusBadges/InProgressBadge'

export type CoachingQualificationsType = {
  title: string
  isComplete: boolean
  borderRadius?: BorderRadius
  progressPercentage: string | number
  progressSteps?: string
  link?: {
    title: string
    url: string
  }
}

export const CoachingQualifications: React.FC<CoachingQualificationsType> = ({
  title,
  isComplete,
  borderRadius,
  progressPercentage,
  progressSteps,
  link,
}) => {
  const progress =
    typeof progressPercentage === 'string'
      ? parseInt(progressPercentage, 10) * 100
      : progressPercentage * 100

  const clampedProgress = Math.round(progress)

  const getProgressBadge = () => {
    if (clampedProgress === 0) {
      return <InCompleteBadge />
    }
    if (clampedProgress === 100) {
      return <CompleteBadge />
    }
    return <InProgressBadge />
  }

  const progressContent = isComplete
    ? 'completed'
    : progressSteps ?? 'incomplete'

  return (
    <Flex
      inset={{ horizontal: 'x2', vertical: 'x2_5' }}
      backgroundColor="foreground"
      width="100%"
      id="coachingQualificationsContainer"
      alignItems="center"
      borderRadius={borderRadius}
    >
      <Flex outset={{ right: 'x1' }}>{getProgressBadge()}</Flex>
      <Flex width="100%">
        <Text weight="semiBold" type="caption" content={title} />
      </Flex>
      {link && !isComplete ? (
        <Button
          size="xSmall"
          onClick={() => window.open(link.url)}
          backgroundColorOverride="#242424"
        >
          Complete
        </Button>
      ) : (
        <Flex width="100%" justifyContent="flex-end" alignItems="center">
          <Icon
            size="xSmall"
            primaryColor={isComplete ? 'success' : 'warning'}
            name={isComplete ? 'check' : 'information'}
          />

          <Text
            type="footnote"
            weight="bold"
            color={isComplete ? 'success' : 'warning'}
            content={progressContent.toUpperCase()}
          />
        </Flex>
      )}
    </Flex>
  )
}
