import React from 'react'

import { FilteredSearchBar } from 'src/chrome'
import { SearchFilter } from 'src/chrome/FilteredSearchBar/types'
import { Memoized } from 'src/hooks/dependencies'
import { Button } from 'src/design_system/molecules/button/Button'

import styles from '../../containers/contactManager.module.scss'
import { Icon, Spacer } from '@sendoutcards/quantum-design-ui'

type Props = {
  onOpenContacts: () => void
  onAdd: () => void
  filters: SearchFilter[]
  setFilters: Memoized<(filters: SearchFilter[]) => void>
  setTerms: Memoized<(terms: string) => void>
  clearSearch: Memoized<() => void>
  onImport: () => void
}

const ContactHeader: React.FC<Props> = props => {
  const {
    filters,
    onOpenContacts,
    onImport,
    setTerms,
    setFilters,
    clearSearch,
    onAdd,
  } = props

  return (
    <div className={styles.headerContainer}>
      <div className={styles.actionButtons}>
        <Button
          id={'import_csv_btn'}
          title={{
            content: 'Import Contacts',
            fontColor: '#e841b3',
            fontSize: '14px',
          }}
          padding="8px 25px"
          onClick={onImport}
          fill="#e841b3"
          borderRadius="10px"
          isOutlined={true}
        >
          <Icon name="contact" size="xSmall" primaryColor="#e841b3" />
        </Button>
        <Spacer orientation="horizontal" space="x2" />
        <Button
          id="view_contacts_btn"
          title={{
            content: 'View Contacts',
            fontColor: '#fff',
            fontSize: '14px',
          }}
          padding="8px 25px"
          onClick={onOpenContacts}
          fill="#e841b3"
          borderRadius="10px"
        >
          <Icon name="filterBy" size="xSmall" primaryColor="#fff" />
        </Button>
      </div>
      <div className={styles.searchBar}>
        <FilteredSearchBar
          filters={filters}
          className={styles.searchFilter}
          setTerms={setTerms}
          setFilters={setFilters}
          clearSearch={clearSearch}
          autocomplete={'off'}
        />
      </div>
      <div className={styles.addButton}>
        <Button
          id="add_new_group_btn"
          title={{
            content: 'Add Group',
            fontColor: '#fff',
            fontSize: '14px',
          }}
          padding="8px 25px"
          onClick={onAdd}
          fill="#e841b3"
          borderRadius="10px"
        >
          <Icon name="add" size="xSmall" primaryColor="#fff" />
        </Button>
      </div>
    </div>
  )
}

export default ContactHeader
