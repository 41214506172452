import React, { FC } from 'react'
import SuspenseBoundary, {
  SuspenseBoundaryProps,
} from 'src/chrome/SuspenseBoundary/SuspenseBoundary'

function suspenseBoundary<ComponentProps>({
  component: Component,
  ...suspenseProps
}: { component: FC<ComponentProps> } & SuspenseBoundaryProps) {
  const SuspenseBoundaryContainer: FC<ComponentProps> = props => (
    <SuspenseBoundary {...suspenseProps}>
      <Component {...props} />
    </SuspenseBoundary>
  )
  return SuspenseBoundaryContainer
}

export default suspenseBoundary
