import React, { FC } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
// @src imports
import { UiCard } from 'src/chrome'
import {
  Div,
  Flex,
  Select,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks'
import { useAccountQuery } from 'src/react_query'
import { AccountSettingsInput } from 'src/graphql/generated/graphql'

const StyledFormControlLabel = withStyles({
  label: {
    fontFamily: 'Montserrat',
    fontSize: '1em',
  },
})(FormControlLabel)

interface IEmailPreferencesProps {
  onToggle: (
    name: keyof Omit<AccountSettingsInput, 'defaultBackPanelId'>,
    value: boolean | number,
  ) => void
}
const defaultAdvanceDays = 21
const minimumDays = 8
const minimumDaysInternational = 15
const daysOptions = new Array(25).fill(null).map((_, i) => {
  return {
    label: `${i + 8}`,
    value: `${i + 8}`,
  }
})
const daysInternationalOptions = new Array(18).fill(null).map((_, i) => {
  return {
    label: `${i + 15}`,
    value: `${i + 15}`,
  }
})
const getInitialDaysValue = (index: number): number =>
  index >= 0 && index <= daysOptions.length
    ? index
    : defaultAdvanceDays - minimumDays

const EmailPreferences: FC<IEmailPreferencesProps> = props => {
  const { onToggle } = props

  const { data } = useAccountQuery({ suspense: true, isEnabled: true })

  const shouldReceivePromoEmails = data?.settings?.shouldReceivePromoEmails
  const shouldReceiveNotificationEmails =
    data?.settings?.shouldReceiveNotificationEmails
  const shouldReceiveReminderEmails =
    data?.settings?.shouldReceiveReminderEmails
  const shouldPrintRecipientCompany =
    data?.settings?.shouldPrintRecipientCompany

  const persistedSettings = data?.settings
  const remindersAdvanceNoticeDays =
    persistedSettings?.remindersAdvanceNoticeDays ?? defaultAdvanceDays
  const remindersAdvanceNoticeDaysIndex =
    remindersAdvanceNoticeDays - minimumDays
  const [selectedDays, setSelectedDays] = useState(
    getInitialDaysValue(remindersAdvanceNoticeDaysIndex),
  )

  const remindersAdvanceNoticeDaysInternational =
    persistedSettings?.remindersAdvanceNoticeDaysInternational ??
    defaultAdvanceDays
  const remindersAdvanceNoticeDaysInternationalIndex =
    remindersAdvanceNoticeDaysInternational - minimumDaysInternational
  const [selectedInternationalDays, setSelectedInternationalDays] = useState(
    getInitialDaysValue(remindersAdvanceNoticeDaysInternationalIndex),
  )

  const updateSelectedDays = (
    days: string,
    isInternational: boolean = false,
  ) => {
    const remindersAdvanceNoticeDaysNumber = parseInt(days, 10)
    onToggle(
      `${
        isInternational
          ? 'remindersAdvanceNoticeDaysInternational'
          : 'remindersAdvanceNoticeDays'
      }`,
      remindersAdvanceNoticeDaysNumber,
    )
  }

  return (
    <UiCard
      title={'Communication Preferences'}
      description="We may still send you important notifications about your account outside of your notification settings."
    >
      <Flex flexDirection="column">
        <Div
          inset={{ bottom: 'x1' }}
          outset={{ bottom: 'x1' }}
          maxWidth="400px"
        >
          <Text
            type="body"
            content="Card Sending Reminders"
            weight="bold"
            color="primaryHeading"
          />
          <Text
            type="caption"
            content="Choose the time frame you would like to be reminded to send cards to your contacts."
            color="primaryBody"
          />
        </Div>
        <Spacer space="x4" />
        <Text
          type="body"
          lineHeight={1.5}
          weight="bold"
          color="primaryHeading"
          style={{ textDecoration: 'underline' }}
          outset={{ bottom: 'x1' }}
        >
          US (Domestic) Contacts:
        </Text>
        <Div
          display="flex"
          flexDirection="row"
          alignContent="center"
          alignItems="center"
        >
          <Div>
            <Select
              onChange={index => {
                setSelectedDays(index)
                updateSelectedDays(daysOptions[index].value)
              }}
              options={daysOptions}
              selectedOptionIndex={selectedDays}
              type="text"
              dropDownMaxHeight="200px"
              selectWidth="43px"
            />
          </Div>
          <Div>
            <Text type="body" outset={{ left: '5px' }} lineHeight={1.5}>
              day advance notice for my birthday and anniversary reminders.
            </Text>
          </Div>
        </Div>
        <Spacer space="x2" />
        <Text
          type="body"
          lineHeight={1.5}
          weight="bold"
          color="primaryHeading"
          style={{ textDecoration: 'underline' }}
          outset={{ bottom: 'x1' }}
        >
          International Contacts:
        </Text>
        <Div
          display="flex"
          flexDirection="row"
          alignContent="center"
          alignItems="center"
        >
          <Div>
            <Select
              onChange={index => {
                setSelectedInternationalDays(index)
                updateSelectedDays(daysInternationalOptions[index].value, true)
              }}
              options={daysInternationalOptions}
              selectedOptionIndex={selectedInternationalDays}
              type="text"
              dropDownMaxHeight="200px"
              selectWidth="43px"
            />
          </Div>
          <Div>
            <Text type="body" outset={{ left: '5px' }} lineHeight={1.5}>
              day advance notice for my birthday and anniversary reminders.
            </Text>
          </Div>
        </Div>
        <Div
          width="100%"
          style={{ borderBottom: '2px solid #f7f7f7' }}
          outset={{ top: 'x3', bottom: 'x1' }}
        />
        <Spacer space="x1" />
        <Div
          inset={{ bottom: 'x1' }}
          outset={{ bottom: 'x1' }}
          maxWidth="400px"
        >
          <Text
            type="body"
            content="Marketing & Communication Emails"
            weight="bold"
            color="primaryHeading"
          />
          <Text
            type="caption"
            content="Select which marketing emails you would like to subscribe to."
            color="primaryBody"
          />
        </Div>
        <StyledFormControlLabel
          control={
            <Switch
              checked={shouldReceiveReminderEmails}
              onChange={() => {
                onToggle('receivesReminderEmails', !shouldReceiveReminderEmails)
              }}
              value=""
            />
          }
          label="Email me Anniversary and Birthday reminders"
        />
        <StyledFormControlLabel
          control={
            <Switch
              checked={shouldReceivePromoEmails}
              onChange={() => {
                onToggle('receivesPromoEmails', !shouldReceivePromoEmails)
              }}
              value=""
            />
          }
          label="Email me marketing and promotional information"
        />
        <Spacer space="x1" />
        <StyledFormControlLabel
          control={
            <Switch
              checked={shouldReceiveNotificationEmails}
              onChange={() => {
                onToggle(
                  'receivesNotificationEmails',
                  !shouldReceiveNotificationEmails,
                )
              }}
              value=""
            />
          }
          label="Email me notifications for processing cards"
        />
        <Div
          width="100%"
          style={{ borderBottom: '2px solid #f7f7f7' }}
          outset={{ top: 'x3', bottom: 'x1' }}
        />
        <Spacer space="x1" />
        <Div
          inset={{ bottom: 'x1' }}
          outset={{ bottom: 'x1' }}
          maxWidth="400px"
        >
          <Text
            type="body"
            content="Editor &amp; Print Settings"
            weight="bold"
            color="primaryHeading"
          />
          <Text
            type="caption"
            content="Select your primary print and default editor settings."
            color="primaryBody"
          />
        </Div>

        <Spacer space="x1" />
        <StyledFormControlLabel
          control={
            <Switch
              checked={shouldPrintRecipientCompany}
              onChange={() => {
                onToggle('printRecipientCompany', !shouldPrintRecipientCompany)
              }}
              value=""
            />
          }
          label="Print Recipient's company on the envelope"
        />
      </Flex>
    </UiCard>
  )
}

export default EmailPreferences
