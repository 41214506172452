import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useSelector } from 'src/hooks'
import { cover } from 'src/design_system/components/Frame/Images/cover'
import {
  ResponsiveColumn,
  ResponsiveRow,
  ResponsiveTable,
} from '../components/ResponsiveTable/ResponsiveTable'
import { activePlans, tableFeatures } from '../data/pointsConversionData'
import { FeatureSet } from '../data/type'

export const PointsConversionPage = () => {
  const isTabletOrSmaller = useSelector(state => state.window.width <= 768)
  const isTablet = useSelector(state => state.window.width < 950)
  const isMobile = useSelector(state => state.window.width <= 520)

  const planColumns = (Object.keys(activePlans) as Array<
    keyof typeof activePlans
  >).map(planKey => {
    return (
      <ResponsiveColumn
        key={planKey}
        backgroundColor={'#8B8B8B'}
        heading={planKey}
      >
        {(Object.keys(activePlans[planKey].tableFeatures) as Array<
          keyof FeatureSet
        >).map((featureKey, index) => {
          const feature = activePlans[planKey].tableFeatures[featureKey]

          const mainFeature =
            feature.main === 'excluded' ? (
              <Icon name="x" color="#e6525d" size={20} />
            ) : feature.main === 'included' ? (
              <Icon name="check" color="#20966f" />
            ) : feature.main === 'included_with_stamps' ? (
              <Icon name="double_check" color="#20966f" />
            ) : (
              <Text
                type="caption"
                content={
                  typeof feature.main === 'object'
                    ? feature.main.content
                    : feature.main
                }
                style={{ fontWeight: 600, color: '#606060' }}
              />
            )

          const secondaryFeature = feature.secondary?.content

          return (
            <ResponsiveRow
              key={featureKey}
              alignment="center"
              backgroundColor={index % 2 ? '#f8f8f8' : '#fcfcfc'}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {mainFeature}
                {secondaryFeature && (
                  <>
                    <Spacer space="x1" orientation="vertical" />
                    <Text
                      type="footnote"
                      content={secondaryFeature}
                      style={{
                        fontSize: '8px',
                        color: '#676767',
                      }}
                      whiteSpace="nowrap"
                    />
                  </>
                )}
              </div>
            </ResponsiveRow>
          )
        })}
      </ResponsiveColumn>
    )
  })

  const titleColumn = (
    <ResponsiveColumn hasTitleRows={true}>
      {Object.keys(tableFeatures).map((featureKey, index) => (
        <ResponsiveRow
          key={featureKey}
          backgroundColor={index % 2 ? '#f8f8f8' : '#fcfcfc'}
        >
          {tableFeatures[featureKey as keyof typeof tableFeatures]}
        </ResponsiveRow>
      ))}
    </ResponsiveColumn>
  )
  const columns = [titleColumn, ...planColumns]

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100vh',
          minHeight: '100%',
          backgroundImage: `url(${cover})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          padding: '0 24px',
        }}
      >
        {/* --------TitleSection---------- */}
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          inset={{ top: '88px', bottom: '140px' }}
          rowGap="20px"
        >
          <Text
            type="body"
            content={
              'Legacy point subscriptions will be\n going away starting September 1, 2023'
            }
            style={{
              fontWeight: 700,
              fontSize: isMobile
                ? '12px'
                : isTabletOrSmaller
                ? '15px'
                : isTablet
                ? '17px'
                : '20px',
              color: '#323232',
            }}
            whiteSpace="pre"
            alignment="center"
          />
          <Text
            type="title"
            content={'We still have great\n options for your card sending'}
            style={{
              fontWeight: 700,
              fontSize: isMobile
                ? '20px'
                : isTabletOrSmaller
                ? '30px'
                : isTablet
                ? '45px'
                : '54px',
              color: '#323232',
            }}
            inset={{ bottom: isTabletOrSmaller ? '' : '16px' }}
            whiteSpace="pre"
            alignment="center"
            lineHeight={1.2}
          />
          <Text
            type="subtitle"
            content="Choose now and receive a FREE gift"
            style={{
              fontWeight: isTabletOrSmaller ? 500 : 700,
              fontSize: isMobile
                ? '11px'
                : isTabletOrSmaller
                ? '15px'
                : isTablet
                ? '25px'
                : '30px',
              color: '#323232',
            }}
          />
        </Flex>
        {/* -------TableSection----- */}
        <Div
          inset={{ bottom: '200px', top: isMobile ? 'x6' : 'x3' }}
          width="100%"
          justifyContent="center"
          display="flex"
          flexWrap="wrap"
        >
          <Text
            alignment="center"
            type="footnote"
            content="* Incentives may change through time and different plans and packages."
            outset={{ bottom: 'x3' }}
          />
          <Flex
            width="100%"
            justifyContent="center"
            inset={{ bottom: 'x2' }}
            flexWrap="wrap"
          >
            <Flex inset={{ bottom: isMobile ? '' : '66px' }}>
              <Text
                type="title"
                content="Its easy to"
                style={{
                  color: '#3A89BC',
                  fontSize: isTabletOrSmaller ? '24px' : '36px',
                }}
                whiteSpace="nowrap"
              />
              <Div
                inset={{
                  top: isTabletOrSmaller ? 'x1' : 'x0',
                  left: 'x_75',
                  right: 'x_5',
                }}
              >
                <Icon
                  name="search"
                  color="#3A89BC"
                  size={isTabletOrSmaller ? 40 : 70}
                />
              </Div>
            </Flex>
            <Text
              type="title"
              content="see the benefits!"
              style={{
                color: '#3A89BC',
                fontSize: isTabletOrSmaller ? '24px' : '36px',
              }}
            />
          </Flex>
          <Flex overflowX="auto" inset={{ bottom: '20px' }}>
            <ResponsiveTable>{columns}</ResponsiveTable>
          </Flex>
          <Spacer space="x2" orientation="vertical" />
        </Div>
      </div>
    </>
  )
}
