import { Operation } from '../../../__generated__/globalTypes'

import getAvailableDailyHeartfeltString from '../GetAvailableDailyHeartfelt.graphql'

export type GetAvailableDailyHeartfeltData = {
  account: {
    __typename: 'Account'
    hasDailyHeartfeltSends: boolean
    dailyHeartfeltCap: {
      available: number
      cap: number
    }
  }
}

export type GetAvailableDailyHeartfelt = Operation<
  GetAvailableDailyHeartfeltData
>

export const GetAvailableDailyHeartfelt = (): GetAvailableDailyHeartfelt => ({
  query: getAvailableDailyHeartfeltString,
  variables: undefined,
})
