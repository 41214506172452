import React from 'react'

import { DefaultError, Dialog, SuspenseBoundary, Transition } from 'src/chrome'
import { Group } from 'src/enhanced_contact_manager/contactTypes'

import GroupAddCreate from './GroupAddCreate'
import styles from './groupAddCreate.module.scss'
import DialogContent from '@material-ui/core/DialogContent'

interface Props {
  isOpen: boolean
  contactsIds?: string[]
  groups?: Group[]
  initialMode: 'create' | 'select'
  onClose: () => void
  onCreateGroup: (group: Omit<Group, 'id'>) => void
  onSelect?: (group: Group, newMembers: string[]) => void
  isModeToggleOn?: boolean
}

const GroupAddCreateModal: React.FC<Props> = props => {
  const {
    isOpen,
    initialMode,
    contactsIds,
    groups,
    onClose,
    onCreateGroup,
    onSelect,
    isModeToggleOn,
  } = props

  return (
    <Dialog
      disableBackdropClick={false}
      open={isOpen}
      onClose={onClose}
      scroll={'paper'}
      style={{ transition: '300ms' }}
    >
      <DialogContent className={styles.modalContent}>
        <GroupAddCreate
          contactsIds={contactsIds}
          groups={groups}
          initialMode={initialMode}
          onClose={onClose}
          onCreateGroup={onCreateGroup}
          onSelect={onSelect}
          isModeToggleOn={isModeToggleOn}
        />
      </DialogContent>
    </Dialog>
  )
}

const GroupAddCreateModalWithTransition: React.FC<Props> = props => (
  <SuspenseBoundary
    unresolved={<Transition message={'Loading ...'} />}
    failure={DefaultError}
  >
    <GroupAddCreateModal {...props} />
  </SuspenseBoundary>
)

export default GroupAddCreateModalWithTransition
