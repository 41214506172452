import React from 'react'

import Editor from 'src/editor/containers/Editor/Editor'
import { OrderLine, Props as EditorProps } from 'src/editor/types'
import CardCatalog from 'src/catalog/containers/CardCatalog'
import { FullWindowModal } from 'src/chrome'
import { useState } from 'src/hooks'
import { CardFragment } from 'src/graphql/generated/graphql'

import { CatalogSubroute } from 'src/catalog/routes/CatalogRoute'
import { OrderApiType } from 'src/orders/api'

type Props = {
  onClose?: () => void
  onReload?: () => Promise<void>
  context: {
    headerText?: string
    headerSubText?: string
    lines?: OrderLine[]
    activeCardId?: string
    footerLabel: (card: CardFragment) => JSX.Element
  }
  editorSecondaryAction?: ({
    closeEditor,
  }: {
    closeEditor: () => void
  }) => EditorProps['secondaryAction']
  onAfterSave: (cardId: string) => Promise<void>
  saveButtonLabel: EditorProps['saveButtonLabel']
  saveButtonBackground?: EditorProps['saveButtonBackground']
  saveButtonColor?: EditorProps['saveButtonColor']
  onCatalogNavigate: (subroute?: CatalogSubroute) => void
  catalogSubroute: CatalogSubroute | undefined
  orderApi?: OrderApiType
  handleAddToOrder?: (cardId: string) => Promise<void>
  actionContext?: string
}

const CardPicker: React.SFC<Props> = props => {
  const {
    context,
    onClose,
    onAfterSave,
    saveButtonLabel,
    saveButtonBackground,
    saveButtonColor,
    onCatalogNavigate,
    catalogSubroute,
    editorSecondaryAction,
    orderApi,
    handleAddToOrder,
    onReload,
    actionContext,
  } = props

  const [cardId, setCardId] = useState<string | undefined>()

  const resetCardId = () => {
    setCardId(undefined)
  }

  if (cardId) {
    const secondaryAction = editorSecondaryAction
      ? editorSecondaryAction({
          closeEditor: resetCardId,
        })
      : undefined
    return (
      <FullWindowModal isReallyFull={true}>
        <Editor
          handleAddToOrder={handleAddToOrder}
          context={context}
          cardId={cardId}
          onAfterSave={() => onAfterSave(cardId)}
          onClose={resetCardId}
          onBackToCatalog={resetCardId}
          saveButtonLabel={saveButtonLabel}
          saveButtonBackground={saveButtonBackground}
          saveButtonColor={saveButtonColor}
          secondaryAction={secondaryAction}
          orderApi={orderApi}
          onReload={onReload}
        />
      </FullWindowModal>
    )
  }

  return (
    <CardCatalog
      onNavigate={onCatalogNavigate}
      context={context}
      onCancel={onClose}
      onSelect={setCardId}
      subroute={catalogSubroute}
      orderApi={orderApi}
      actionContext={actionContext}
    />
  )
}

export default CardPicker
