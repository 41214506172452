import React from 'react'

export const InCompleteBadge: React.FC = () => {
  return (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_555_45504)">
        <path
          d="M27.9859 17.932C28.2885 17.758 28.5613 17.5464 28.8 17.3057V22.222C28.8 23.4921 28.1188 24.6654 27.0137 25.3008L16.1855 31.5231C15.0808 32.1582 13.7188 32.1582 12.6145 31.5231L1.78632 25.3008C0.68122 24.6658 0 23.4921 0 22.222V17.3057C0.238705 17.5468 0.511907 17.7583 0.814054 17.932L12.4773 24.6342C13.6664 25.3186 15.1336 25.3186 16.3235 24.6342L27.9863 17.932H27.9859Z"
          fill="#303644"
        />
        <path
          d="M28.8 9.77738V16.3012C28.5613 16.5424 28.2885 16.7535 27.9859 16.9276L16.3231 23.6298C15.1336 24.3142 13.6664 24.3142 12.4769 23.6298L0.814054 16.928C0.511907 16.7539 0.238705 16.5424 0 16.3016V9.77778C0 8.50734 0.68122 7.3336 1.78632 6.69859L12.6145 0.476264C13.7192 -0.158755 15.0812 -0.158755 16.1859 0.476264L27.0141 6.69898C28.1192 7.334 28.8004 8.50734 28.8004 9.77738H28.8Z"
          fill="#F7A14D"
        />
        <g filter="url(#filter0_i_555_45504)">
          <path
            d="M26.2389 21.1936V10.8067C26.2389 9.81061 25.7048 8.89024 24.8384 8.39217L15.8009 3.19875C14.9341 2.70068 13.8663 2.70068 12.9995 3.19875L3.96203 8.39178C3.09524 8.88985 2.56152 9.81021 2.56152 10.8063V21.1932C2.56152 22.1893 3.09524 23.1097 3.96203 23.6078L12.9995 28.8012C13.8663 29.2993 14.9341 29.2993 15.8009 28.8012L24.8384 23.6078C25.7052 23.1097 26.2389 22.1893 26.2389 21.1932V21.1936Z"
            fill="white"
          />
        </g>
        <mask
          id="mask0_555_45504"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="25"
          height="28"
        >
          <path
            d="M26.2389 21.1936V10.8067C26.2389 9.81061 25.7048 8.89024 24.8384 8.39217L15.8009 3.19875C14.9341 2.70068 13.8663 2.70068 12.9995 3.19875L3.96203 8.39178C3.09524 8.88985 2.56152 9.81021 2.56152 10.8063V21.1932C2.56152 22.1893 3.09524 23.1097 3.96203 23.6078L12.9995 28.8012C13.8663 29.2993 14.9341 29.2993 15.8009 28.8012L24.8384 23.6078C25.7052 23.1097 26.2389 22.1893 26.2389 21.1932V21.1936Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_555_45504)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.4674 9C15.1332 9 15.673 9.53726 15.673 10.2V17.45C15.673 18.1127 15.1332 18.65 14.4674 18.65C13.8015 18.65 13.2617 18.1127 13.2617 17.45V10.2C13.2617 9.53726 13.8015 9 14.4674 9ZM13.2617 21.8C13.2617 21.1373 13.8015 20.6 14.4674 20.6H14.4819C15.1478 20.6 15.6876 21.1373 15.6876 21.8C15.6876 22.4627 15.1478 23 14.4819 23H14.4674C13.8015 23 13.2617 22.4627 13.2617 21.8Z"
            fill="#F7A14D"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_555_45504"
          x="2.56152"
          y="2.8252"
          width="23.6772"
          height="26.3496"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_555_45504"
          />
        </filter>
        <clipPath id="clip0_555_45504">
          <rect width="28.8" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
