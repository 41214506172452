import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { PPSVG, PPSVGProps } from './UpsaleItemSVGs/PPSVG'

export type UpsaleItemProps = {
  graphic?: PPSVGProps // Add type union of svgs in the future
  title: string
  titleSize?: string
  titleColor?: string
  description: string
  descriptionSize?: string
  descriptionColor?: string
  containerWidth?: number
}

export const UpsaleItem: React.FC<UpsaleItemProps> = props => {
  const {
    graphic,
    title,
    titleColor = '#fff',
    titleSize = '16px',
    description,
    descriptionColor = '#27afb0',
    descriptionSize = '48px',
    containerWidth,
  } = props
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection={containerWidth && containerWidth < 1000 ? 'column' : 'row'}
      outset={{
        bottom: containerWidth && containerWidth < 1000 ? 'x3' : undefined,
      }}
    >
      {graphic && (
        <Div inset={{ right: 'x1' }}>
          <PPSVG {...graphic} />
        </Div>
      )}
      <Div>
        <Text
          type="body"
          content={title.toUpperCase()}
          weight="bold"
          style={{
            color: titleColor,
            fontSize: titleSize,
            letterSpacing: '1px',
          }}
        />
        <Text
          type="body"
          content={description.toUpperCase()}
          style={{
            color: graphic?.color.gradientOne ?? descriptionColor,
            fontSize: descriptionSize,
            letterSpacing: '4px',
          }}
        />
      </Div>
    </Flex>
  )
}
