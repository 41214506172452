import { Flex } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import {
  planFragmentFromLegacy,
  planFragmentToLegacy,
} from 'src/graphql/compat'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'
import { getDetailedCountries, PlanFragment } from 'src/legacy_graphql'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { useAccount, useEffect, useQueries, useState } from 'src/hooks'
import { PlansDescription } from 'src/marketing/plans/types'
import { Portal } from 'src/portal/portal'
import { PlanIdType } from 'src/pricing_page/components/pricingTile/PlanTiles'
import {
  mapStripeSourceAddress,
  mapToCheckoutAddress,
} from 'src/pricing_page/utils'
import {
  useCreateCheckout,
  useProductVariant,
} from 'src/react_query/queries/hooks'
import { SubscriptionCheckout } from 'src/saleor/components/subscription_checkout/SubscriptionCheckout'
import { DialogUpsaleCheckout } from 'src/upsale/components/checkout/DialogUpsaleCheckout'
import { SubscriptionSelection } from './SubscriptionSelection'
import { SubscriptionType } from 'src/user/fragments'
import { offeredAffiliateStripeSkus } from 'src/helpers/supportedUpsaleProducts'

type AccountSubscriptionChangerProps = {
  subscriptionPlanIds: PlanIdType[]
  selectedItemCallback?: (item: PlanFragment) => void // If this is omitted the quick checkout will be used
  isOpen: boolean
  onClose: () => void
  featureAccessToGrant?: FullAccessFeatureType[]
  shouldHideCurrentPlan?: boolean
}

export const AccountSubscriptionChanger: FC<AccountSubscriptionChangerProps> = ({
  subscriptionPlanIds,
  isOpen,
  onClose,
  featureAccessToGrant,
  shouldHideCurrentPlan = false,
}) => {
  const account = useAccount()
  const currentPlan = planFragmentToLegacy(account.plan)
  const [detailedCountries] = useQueries(getDetailedCountries())
  const [selPlan, setSelPlan] = useState<PlanFragment | undefined>(currentPlan)
  const [checkoutView, setCheckoutView] = useState<'change' | 'new' | null>(
    null,
  )

  const affiliateSubscription = account?.subscriptions.find(
    (sub: SubscriptionType) =>
      offeredAffiliateStripeSkus.map(sku => sku.id).includes(sub.sku),
  )

  const saleorVariantId =
    selPlan && selPlan.description
      ? PlansDescription.fromPlansDescriptionFragment({
          __typename: 'PlansDescription',
          id: selPlan.id,
          description: selPlan.description,
        }).description.saleorVariantId
      : undefined

  const shouldQuery = selPlan ? !!selPlan.stripeId && !!!saleorVariantId : false
  const { data } = useProductVariant({ sku: selPlan?.stripeId }, shouldQuery)
  const variantId = saleorVariantId ?? data?.id
  const { mutate, isError, isLoading, ...mutation } = useCreateCheckout()

  const stripeBilling =
    account &&
    account.stripeSource &&
    account.stripeSource.__typename === 'CreditCard'
      ? account.stripeSource.billingAddress
      : null

  const stripeSourceAddress = mapStripeSourceAddress(stripeBilling ?? undefined)
  const handleOpenSubscriptionCheckout = async () => {
    if (currentPlan.id === '1' && variantId && !affiliateSubscription) {
      mutate(
        {
          input: {
            lines: [{ variantId, quantity: 1 }],
            channel: 'default-channel',
            email: account.email,
            billingAddress: stripeSourceAddress
              ? mapToCheckoutAddress(stripeSourceAddress, detailedCountries)
              : account?.shippingAddress?.address1
              ? mapToCheckoutAddress(account.shippingAddress, detailedCountries)
              : undefined,
            shippingAddress: account?.shippingAddress?.address1
              ? mapToCheckoutAddress(account.shippingAddress, detailedCountries)
              : undefined,
          },
        },
        {
          onSettled: data => {
            if (data?.checkout) {
              setCheckoutView('new')
            }
          },
        },
      )
    } else {
      setCheckoutView('change')
    }
  }

  const handleCloseSubscriptionCheckout = () => {
    setSelPlan(undefined)
    setCheckoutView(null)
    onClose()
  }

  const subscriptionCheckoutView = (plan: PlanFragment) => ({
    new: (
      <Portal>
        <SubscriptionCheckout
          isOpen={true}
          setIsOpen={() => setCheckoutView(null)}
        />
      </Portal>
    ),
    change: (
      <DialogUpsaleCheckout
        selectedItem={planFragmentFromLegacy(plan)}
        onClose={() => handleCloseSubscriptionCheckout()}
        featureAccessToGrant={featureAccessToGrant}
      />
    ),
  })

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        mutation.reset()
      }, 5000)
    }
  }, [mutation, isError])

  return (
    <Flex
      id="account_checkout_changer"
      style={{ zIndex: 3001 }}
      overflowY="scroll"
    >
      {selPlan && checkoutView ? (
        subscriptionCheckoutView(selPlan)[checkoutView]
      ) : (
        <SubscriptionSelection
          planIds={subscriptionPlanIds}
          isOpen={isOpen}
          onSelectPlan={() => handleOpenSubscriptionCheckout()}
          buttonTitle={'Yes, I confirm'}
          currentPlan={currentPlan}
          onClose={() => onClose()}
          onChange={plan => setSelPlan(plan)}
          isLoading={isLoading}
          shouldHideCurrentPlan={shouldHideCurrentPlan}
        />
      )}
      {isError && (
        <ToasterNotification
          backgroundColor={{
            swatch: 'danger',
            shade: '_500',
          }}
          icon={{
            size: 'xSmall',
            name: 'information',
            primaryColor: 'inverseHeadingText',
            iconContainerColor: { swatch: 'success', shade: '_400' },
          }}
          label={{
            color: 'inverseHeading',
            type: 'footnote',
            content: 'Could not add subscription to checkout',
          }}
        />
      )}
    </Flex>
  )
}
