import {
  Button,
  Card,
  Div,
  Flex,
  Icon,
  Image,
  Progress,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'

type CoachProgressCardType = {
  graphicURL?: string
  title: string
  description: string
  action?: {
    onClick: () => void
    title: string
  }
  progressPercentage: string | number
  color?: string
  isLocked: boolean
  width?: string
  height?: string
  isMediumSize?: boolean
  shouldBlur?: boolean
}

export const CoachProgressCard: React.FC<CoachProgressCardType> = ({
  graphicURL,
  title,
  description,
  action,
  progressPercentage,
  color = '#309B8C',
  isLocked,
  width = '340px',
  height,
  isMediumSize = false,
  shouldBlur = false,
}) => {
  const progress =
    typeof progressPercentage === 'string'
      ? parseInt(progressPercentage, 10) * 100
      : progressPercentage * 100

  const clampedProgress = Math.round(progress)

  const isComplete = clampedProgress === 100

  enum ProgressStatus {
    EARNED = 'Earned',
    UNEARNED = 'Not Started',
    IN_PROGRESS = 'In Progress',
  }

  const getProgressStatus = (): ProgressStatus => {
    return clampedProgress === 100
      ? ProgressStatus.EARNED
      : clampedProgress === 0
      ? ProgressStatus.UNEARNED
      : ProgressStatus.IN_PROGRESS
  }

  return (
    <Card
      width={width}
      height={height}
      inset={{ top: '12px', horizontal: '20px', bottom: '20px' }}
      overflow="hidden"
      backgroundColor="foreground"
      style={{ filter: shouldBlur ? 'blur(4px)' : undefined }}
    >
      {isLocked && (
        <Div
          width="100%"
          height="100%"
          backgroundColor="#dadada99"
          zIndex={1}
          position="absolute"
          top="0"
        >
          <Flex
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            columnGap="x1"
            inset={{ top: 'x7' }}
          >
            <Icon size="small" name="lock" primaryColor="primaryHeadingText" />
            <Text
              type="body"
              content="Locked"
              weight="bold"
              color="primaryHeading"
            />
          </Flex>
          {action && (
            <Div position="absolute" inset="x2_5" bottom="0px" width="100%">
              <Button
                fullWidth={true}
                size="medium"
                title={action?.title}
                onClick={action?.onClick}
                backgroundColorOverride={color}
              />
            </Div>
          )}
        </Div>
      )}
      <Flex
        height="100%"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        outset={{ top: 'x1' }}
      >
        <Flex flexDirection="column" alignItems="center">
          <Flex
            id="titleContainer"
            width="100%"
            outset={{ bottom: isMediumSize ? '1rem' : '26px' }}
            justifyContent="center"
          >
            <Text
              type="largeBody"
              color="primaryHeading"
              alignment="center"
              weight="bold"
            >
              {title}
            </Text>
          </Flex>
          <Flex
            id="badgeContainer"
            outset={{ bottom: isMediumSize ? '.5rem' : '2rem' }}
          >
            {graphicURL && (
              <Image
                isActive={false}
                image={{ url: graphicURL }}
                width={isMediumSize ? '118px' : '147px'}
                height={isMediumSize ? '132px' : '164px'}
              />
            )}
          </Flex>
          {!isLocked && (
            <Flex
              outset={{ bottom: isMediumSize ? '.5rem' : '2rem' }}
              id="progressContainer"
              flexDirection="column"
              width="90%"
            >
              <Flex
                flexDirection="row"
                justifyContent="center"
                inset={{ bottom: isMediumSize ? 'x_5' : 'x1' }}
                columnGap="x1"
                alignItems="center"
              >
                {isComplete && (
                  <Icon
                    name="check"
                    size={isMediumSize ? 'xSmall' : 'small'}
                    primaryColor="success"
                  />
                )}
                <Text
                  type={isMediumSize ? 'body' : 'largeBody'}
                  content={getProgressStatus().toUpperCase()}
                  color="success"
                  weight="bold"
                  style={{ color: isComplete ? '#44d5a4' : '#677789' }}
                />
              </Flex>
              <Progress percentage={clampedProgress} />
              <Text
                alignment="center"
                type="body"
                weight="bold"
              >{`${clampedProgress}% COMPLETED`}</Text>
            </Flex>
          )}
          <Flex outset={{ bottom: isMediumSize ? '.5rem' : '1.5rem' }}>
            <Text
              alignment="center"
              type={isMediumSize ? 'footnote' : 'caption'}
            >
              {description}
            </Text>
          </Flex>
        </Flex>
        {action && (
          <Button
            fullWidth={true}
            size="medium"
            title={action?.title}
            onClick={action?.onClick}
            backgroundColorOverride={color}
          />
        )}
      </Flex>
    </Card>
  )
}
