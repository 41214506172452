import React, { createContext } from 'react'
// import { getCoachingBadges, getPromptingsCoachDiscounts } from 'src/graphql'
import { useEffect, useMemo, useMutations, useReducer } from 'src/hooks'
import { CoachDetails, useCoach } from 'src/hooks/useCoach'
import { useGetEarnedBadges, useGetInProgressBadges } from 'src/react_query'
import {
  coachingBadgeFactory,
  CoachingBadgeType,
} from '../helpers/coachingBadgeFactory'

const initialState = {
  badgesInProgress: null,
  badgesEarned: null,
  coachDetails: null,
}

/**
 * TODO - Unfortunatley given time constraints we were unable to get the coach status added in the backend.
 * This hack requires the enum values are Equal to the award names. If the name changes in the backend, it will break.
 * We need to eventually get the coach status added to the Promptings Coach based on Bydesign metrics.
 */
export enum CoachLevel {
  PROMPTINGS_PROCESS = 'Promptings Process',
  SILVER = 'Silver Certified Coach',
  GOLD = 'Gold Certified Coach',
  PLATINUM = 'Platinum Certified Coach',
}

export type CoachingContextType = {
  badgesInProgress: CoachingBadgeType[] | null
  badgesEarned: CoachingBadgeType[] | null
  coachDetails: CoachDetails | null
  currentCoachLevel?: CoachLevel
}

type Action =
  | { type: 'SET_IS_EMAIL_PUBLIC'; isEmailPublic: boolean }
  | { type: 'SET_IS_PHONE_PUBLIC'; isPhonePublic: boolean }
  | {
      type: 'SET_EARNED_BADGES'
      badgesEarned: CoachingContextType['badgesEarned']
    }
  | {
      type: 'SET_IN_PROGRESS_BADGES'
      badgesInProgress: CoachingContextType['badgesInProgress']
    }

export const CoachingContext = createContext<{
  state: CoachingContextType
  dispatch: (action: Action) => void
  asyncActions: {
    setIsEmailPublic: (newIsPublic: boolean) => Promise<void>
    setIsPhonePublic: (newIsPublic: boolean) => Promise<void>
  }
}>({
  state: initialState,
  dispatch: () => {},
  asyncActions: {
    setIsEmailPublic: async (_newIsPublic: boolean) => {},
    setIsPhonePublic: async (_newIsPublic: boolean) => {},
  },
})

export const CoachingProvider: React.FC<{}> = props => {
  const { data: inProgressBadgeGroup } = useGetInProgressBadges()
  const { data: earnedBadgeGroup } = useGetEarnedBadges()

  const coach = useCoach()

  const inProgressBadges = useMemo(() => {
    if (inProgressBadgeGroup) {
      return inProgressBadgeGroup.awardGroups.flatMap(group =>
        group.awards.map(award => coachingBadgeFactory(award)),
      )
    }
    return []
  }, [inProgressBadgeGroup])

  const earnedBadges = useMemo(() => {
    if (earnedBadgeGroup) {
      return earnedBadgeGroup.awardGroups.flatMap(group =>
        group.awards.map(award => coachingBadgeFactory(award)),
      )
    }
    return []
  }, [earnedBadgeGroup])

  const lastEarnedBadge = earnedBadges[earnedBadges.length - 1]
    ?.name as CoachLevel

  const reducer = (
    state: CoachingContextType,
    action: Action,
  ): CoachingContextType => {
    switch (action.type) {
      case 'SET_EARNED_BADGES':
        return {
          ...state,
          badgesEarned: action.badgesEarned,
        }
      case 'SET_IN_PROGRESS_BADGES':
        return {
          ...state,
          badgesInProgress: action.badgesInProgress,
        }
      case 'SET_IS_EMAIL_PUBLIC':
        if (state.coachDetails) {
          return {
            ...state,
            coachDetails: {
              ...state.coachDetails,
              isEmailPublic: action.isEmailPublic,
            },
          }
        } else {
          return state
        }
      case 'SET_IS_PHONE_PUBLIC':
        if (state.coachDetails) {
          return {
            ...state,
            coachDetails: {
              ...state.coachDetails,
              isPhonePublic: action.isPhonePublic,
            },
          }
        }
        return state
      default:
        return state
    }
  }
  const mutations = useMutations()

  const setIsEmailPublic = async (newIsPublic: boolean) => {
    if (!coach?.isAuthUser) {
      // If coach is not auth user we will not update the state
      // Technically this UI should not be displayed when user is not auth user
      // Keeping this here as a safety precaution
      return
    }
    const promptingsCoach = await mutations.updateCoachPublicDisplayInfo({
      publicContactInfo: { isEmailPublic: newIsPublic },
    })
    dispatch({
      type: 'SET_IS_EMAIL_PUBLIC',
      isEmailPublic:
        promptingsCoach.updateCoachPublicInfoDisplay.promptingCoach
          .isEmailPublic,
    })
  }

  const setIsPhonePublic = async (newIsPublic: boolean) => {
    if (!coach?.isAuthUser) {
      // If coach is not auth user we will not update the state
      // Technically this UI should not be displayed when user is not auth user
      // Keeping this here as a safety precaution
      return
    }
    const promptingsCoach = await mutations.updateCoachPublicDisplayInfo({
      publicContactInfo: { isPhoneNumberPublic: newIsPublic },
    })
    dispatch({
      type: 'SET_IS_PHONE_PUBLIC',
      isPhonePublic:
        promptingsCoach.updateCoachPublicInfoDisplay.promptingCoach
          .isPhoneNumberPublic,
    })
  }

  const [state, dispatch] = useReducer(reducer, {
    badgesInProgress: null,
    badgesEarned: null,
    coachDetails: coach,
    currentCoachLevel: lastEarnedBadge,
  })

  useEffect(() => {
    dispatch({
      type: 'SET_IN_PROGRESS_BADGES',
      badgesInProgress: [...inProgressBadges].sort(
        (x, y) => Number(x.isLocked) - Number(y.isLocked),
      ),
    })
  }, [inProgressBadges])

  useEffect(() => {
    dispatch({
      type: 'SET_EARNED_BADGES',
      badgesEarned: earnedBadges,
    })
  }, [earnedBadges])

  return (
    <CoachingContext.Provider
      value={{
        state,
        dispatch,
        asyncActions: { setIsEmailPublic, setIsPhonePublic },
      }}
    >
      {props.children}
    </CoachingContext.Provider>
  )
}
