import { Operation } from '../../../__generated__/globalTypes'

import getUserCardTokensString from '../GetUserCardTokens.graphql'

export type GetUserCardTokensData = {
  account: {
    groupSends: number
    heartfeltSends: number
    heartfeltSystemSends: number
    scheduledSends: number
    systemSends: number
    cardPackageSends: number
    pointsTransitionSends: number
    employeeSends: number
    dailyHeartfeltSends: number
    cardSends: number
    giftSends: number
  }
}

export type GetUserCardTokens = Operation<GetUserCardTokensData>

export const GetUserCardTokens = (): GetUserCardTokens => ({
  query: getUserCardTokensString,
  variables: undefined,
})
