import React from 'react'

import {
  useAccount,
  useActions,
  useMutations,
  useQueryParams,
  useSubscriptions,
} from 'src/hooks'
import { getTotal } from 'src/helpers'
import Editor from 'src/editor/containers/Editor/Editor'
import { CreateOrderInput } from 'src/legacy_graphql'
import { bulkReturnAddress } from 'src/app/constants'
import removeUsersnap from 'src/helpers/removeUsersnap'
import omit from 'lodash/omit'

type Props = {
  cardId: string
}

const Card: React.FC<Props> = props => {
  const { cardId } = props

  const mutations = useMutations()
  const actions = useActions()
  const account = useAccount()
  const { userBulkCardCost: bulkCardCost } = useSubscriptions()
  const { bulkOrderPromo } = useQueryParams()

  const handleSaveAndSend = async () => {
    const bulkOrderArgs: CreateOrderInput = bulkOrderPromo
      ? {
          bulkUpgradeCardQty: 20,
          returnAddress: account.shippingAddress
            ? bulkReturnAddress
            : undefined,
          bulkUpgradeShippingAddress: account.shippingAddress
            ? omit(account.shippingAddress, '__typename')
            : undefined,
        }
      : {}
    const {
      createOrder: { order },
    } = await mutations.createOrder({
      order: { lines: [{ card: cardId }], ...bulkOrderArgs },
    })
    removeUsersnap()
    actions.openOrder(order.id)
  }

  const handleClose = () => {
    removeUsersnap()
    actions.openCatalog()
  }

  return (
    <Editor
      cardId={cardId}
      onAfterSave={handleSaveAndSend}
      onClose={handleClose}
      saveButtonLabel="Proceed to Cart"
      saveButtonBackground="#32b769"
      saveButtonColor="#FFFFFF"
      secondaryAction={{
        label: 'Save & Close Card Editor',
        onClick: handleClose,
      }}
      context={{
        headerText: 'Editing custom card',
        footerLabel: card => (
          <>
            Total:{' '}
            {getTotal(
              card.cost,
              bulkOrderPromo
                ? {
                    bulkCardCost,
                    paperType: bulkOrderPromo.paperType,
                  }
                : undefined,
            )}
          </>
        ),
      }}
    />
  )
}

export default Card
