import { Anchor, Button, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { PlanFragment } from 'src/legacy_graphql'
import { Button as DSButton } from 'src/design_system/components/Button/Button'
import { useSelector, useState } from 'src/hooks'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { Transition } from 'src/chrome'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { useProductVariant } from 'src/react_query/queries/hooks'

type AddOnTileProps = {
  plan: PlanFragment
  stripeId: string
  variantId?: string
  onPurchaseNow: () => void
}

export const AddOnTile = (props: AddOnTileProps) => {
  const { plan, onPurchaseNow } = props

  const isTablet = useSelector(state => state.window.width <= 820)

  const [isPurchaseDrawerOpen, setIsPurchaseDrawerOpen] = useState(false)

  const responsiveStyles = {
    titleSize: isTablet ? '1.2rem' : '1.385rem',
    priceSize: isTablet ? '1.5rem' : '1.875rem',
    btnTitleSize: isTablet ? '.8rem' : '1rem',
    featuresSize: isTablet ? '.7rem' : '.89rem',
    cardPadding: isTablet ? 'x2' : 'x4',
    cardGap: isTablet ? 'x2_5' : 'x4',
  }

  const {
    addToCart,
    isUpdatingCheckout,
    isCreatingCheckout,
    hasCheckoutError,
    checkoutErrorMessage,
  } = useHandleProductActions(plan?.stripeId)

  const { data } = useProductVariant({ sku: props.stripeId ?? '' })

  const [mainImage] = data?.media?.map(media => media.url) ?? []

  const variantId = props.variantId ?? data?.id

  return (
    <Flex
      position="relative"
      height="fit-content"
      flexDirection="column"
      minWidth="280px"
      borderRadius="medium"
      style={{
        border: '4px solid rgba(107, 114, 128, 0.08)',
      }}
      rowGap="x1"
      backgroundColor="#fff"
      overflow="hidden"
    >
      {isUpdatingCheckout ||
        (isCreatingCheckout && <Transition message={'Creating checkout...'} />)}
      {hasCheckoutError && checkoutErrorMessage && (
        <AnimatePresence>
          <ToasterNotification
            backgroundColor={{
              swatch: 'danger',
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: 'information',
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: checkoutErrorMessage,
            }}
          />
        </AnimatePresence>
      )}
      <Flex
        position="relative"
        justifyContent="center"
        style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
        backgroundColor="#F9FAFB"
        inset={{ vertical: '1rem' }}
        height="160px"
      >
        <Flex
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          inset={{ vertical: '.25rem', horizontal: '.75rem' }}
          style={{ boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04)' }}
          borderRadius="circle"
          backgroundColor="#fff"
          position="absolute"
          top="8px"
          right="8px"
        >
          <Text
            type="body"
            content="Add-on"
            style={{ fontSize: '.625rem', color: '#404040', fontWeight: 600 }}
          />
        </Flex>
        <Flex
          backgroundPosition="center"
          borderRadius="default"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          width="100%"
          height=""
          backgroundImage={mainImage}
        />
      </Flex>
      <Flex
        flexDirection="column"
        rowGap="x_75"
        inset={{ vertical: '.5rem', horizontal: '1.5rem' }}
      >
        <Text
          type="largeBody"
          content={data?.name}
          lineHeight={1.2}
          style={{ fontSize: '1rem', color: '#171717', fontWeight: 600 }}
        />
        {data?.parsedAttributes.description && (
          <Text
            type="largeBody"
            content={data.parsedAttributes.description}
            lineHeight={1.2}
            style={{ fontSize: '.75rem', color: '#171717', fontWeight: 600 }}
          />
        )}
        <Text
          type="largeBody"
          content={`Price: $${data?.pricing?.price?.gross.amount}`}
          style={{ fontSize: '.875rem', color: '#6B7280', fontWeight: 400 }}
        />
        <Flex justifyContent="center" alignItems="center">
          <Button
            size="small"
            title="Add to Cart"
            backgroundColorOverride="#404040"
            textColorOverride="#fff"
            onClick={() => setIsPurchaseDrawerOpen(true)}
          />
        </Flex>
      </Flex>
      <AnimatePresence>
        {isPurchaseDrawerOpen && (
          <motion.div
            initial={{
              y: 250,
              opacity: 0,
            }}
            animate={{
              y: 10,
              opacity: 1,
              transition: { type: 'spring', bounce: 0.25 },
            }}
            exit={{ y: 250, opacity: 0 }}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              background: 'fff',
              boxShadow: '0px -8px 8px 0px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              rowGap="x2"
              width="100%"
              height="100%"
              backgroundColor="rgba(230,230,230, 0.75)"
              inset={{
                top: 'x4',
                horizontal: 'x4',
                bottom: 'x4',
              }}
            >
              <DSButton
                title={'Add to Cart'}
                textColor="#fff"
                textWeight={500}
                background={plan.backgroundStartColor}
                width="100%"
                boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
                onClick={() => addToCart(variantId)}
                textSize={responsiveStyles.btnTitleSize}
                icon={{
                  name: 'bag_plus',
                  color: '#FFF',
                  size: 15,
                }}
              />
              <DSButton
                title="Purchase Now"
                textColor="hsl(0,0%,8.1%)"
                textWeight={500}
                backgroundColor="#fff"
                width="100%"
                boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
                onClick={() => addToCart(variantId, onPurchaseNow)}
                textSize={responsiveStyles.btnTitleSize}
                icon={{ name: 'shopping_cart_2', size: 16 }}
              />
              <div onClick={() => setIsPurchaseDrawerOpen(false)}>
                <Icon name="cancel" size={10} color="black" />
                <Anchor
                  title="Close"
                  isDecorated={true}
                  color="primaryHeading"
                  size="xSmall"
                  outset={{ left: 'x_5' }}
                />
              </div>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  )
}
