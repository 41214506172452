import {
  useByDesignLoginLink,
  useCoachingDashboardMarketingContent,
} from 'src/react_query'
import { useSubscriptions } from '.'

const useCoachingPotentialBoost = () => {
  const { hasUnlimitedHeartfeltSends } = useSubscriptions()
  const {
    isLoading: isLoginLinkLoading,
    data: byDesignLoginLink,
  } = useByDesignLoginLink()
  const marketingQuery = useCoachingDashboardMarketingContent()

  const keepPlayingProductPath = marketingQuery.data?.keepPlayingProductPath
  const boostPotentialMoreInfo = marketingQuery.data?.boostPotentialMoreInfo
  const boostPotentialUnltdProductPath =
    marketingQuery.data?.boostPotentialUnltdProductPath
  const boostPotentialLtdProductPath =
    marketingQuery.data?.boostPotentialLtdProductPath

  const handleOnGetStarted = async () => {
    const productPath = hasUnlimitedHeartfeltSends
      ? boostPotentialUnltdProductPath?.url
      : boostPotentialLtdProductPath?.url

    window.open(`${byDesignLoginLink}/${productPath}`)
  }

  const handleOnMoreInfo = () => {
    window.open(boostPotentialMoreInfo?.url, '_blank')
  }

  const handleOnKeepPlaying = async () => {
    window.open(`${byDesignLoginLink}/${keepPlayingProductPath?.url}`)
  }

  const shouldDisplay447 = hasUnlimitedHeartfeltSends

  const isTransitioning = isLoginLinkLoading || marketingQuery.isLoading

  return {
    /**
     * Use this property to handle transitions while the byDesignLoginLink gets loaded
     */
    isTransitioning,
    byDesignLoginLink,
    handleOnGetStarted,
    handleOnMoreInfo,
    handleOnKeepPlaying,
    /**
     * Unlimited Plan Users should have the $447 instead of the $497
     */
    shouldDisplay447,
  }
}

export default useCoachingPotentialBoost
