import xor from 'lodash/xor'
import { exportContactsCsvFrom } from 'src/app/api'
import {
  ContactFragment,
  DetailedGroupFragment,
  getPaginatedGroups,
  MinimalCampaignFragment,
} from 'src/legacy_graphql'
import {
  useAccount,
  useActions,
  useCallback,
  useMemo,
  useMutations,
  useQueries,
  useState,
} from 'src/hooks'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'
import { Group } from '../contactTypes'
import { DEFAULT_GROUP_SEARCH, generateContactFilterPayload } from './filters'
import RelationshipSharedHooks from './RelationshipSharedHooks'
import { Step, Steps } from './types'
import { Set } from 'immutable'
import { useCreateOrder } from 'src/react_query'

const Hooks = () => {
  const sharedHooks = RelationshipSharedHooks(true)
  const [selected, setSelected] = useState<string[]>([])

  const reset = useCallback(() => sharedHooks.setSearch(DEFAULT_GROUP_SEARCH), [
    sharedHooks,
  ])
  const [step, setStep] = useState<Step>({ type: Steps.View })
  const resetStep = () => setStep({ type: Steps.View })
  const [loading, setLoading] = useState(0)
  const actions = useActions()
  const account = useAccount()

  const [{ results: paginatedGroups }] = useQueries(
    getPaginatedGroups({
      search: sharedHooks.search.terms,
      page: sharedHooks.page,
      ...generateContactFilterPayload(sharedHooks.search.filters),
    }),
  )

  const mutations = useMutations()
  const createOrderMutation = useCreateOrder()
  const toggleSelectAll = () => {
    if (selected.length === sharedHooks.groups.length) {
      setSelected([])
    } else {
      setSelected(sharedHooks.groups.map(x => x.id))
    }
  }

  const toggleSelect = (group: Group) => {
    setSelected(x => xor(x, [group.id]))
  }

  const editGroup = async (group: Group) => {
    setStep({ type: Steps.EditGroup, group })
  }

  const updateGroup = (group: Group) => {
    mutations.updateGroup({
      group: {
        id: group.id,
        name: group.name,
        description: group.description,
      },
    })
    resetStep()
  }

  const selectionMap = useMemo(() => {
    const mutableMap: Record<string, true> = {}

    selected.forEach(x => (mutableMap[x] = true))
    return mutableMap
  }, [selected])

  const createGroup = (group: Omit<Group, 'id'>) => {
    const ids: string[] =
      group.members?.map((contact: ContactFragment | string) =>
        typeof contact == 'string' ? contact : contact.id,
      ) ?? []

    mutations.createGroup({
      group: {
        name: group.name,
        members: ids,
        description: group.description,
      },
    })
    actions.toggleIsNewGroupModalOpen()
  }

  const confirmDelete = () => setStep({ type: Steps.ConfirmDelete })

  const selectedGroups = useMemo(
    () => sharedHooks.groups.filter(contact => selectionMap[contact.id]),
    [selectionMap, sharedHooks.groups],
  )

  const sendCampaign = async (campaign: MinimalCampaignFragment) => {
    setLoading(x => x + 1)
    resetStep()

    try {
      const {
        createOrder: { order },
      } = await createOrderMutation.mutateAsync({
        order: {
          campaign: campaign.id,
          contactsFromGroups: selectedGroups.map(group => group.id),
        },
      })

      actions.createdOrder(order)
      actions.openOrder(order.id)
    } catch (error) {
      console.error(error?.toString() ?? 'Failed to send campaign.')
    } finally {
      setLoading(x => x - 1)
    }
  }

  const deleteSelectedGroups = async () => {
    selectedGroups.forEach(({ id }) => {
      mutations.deleteGroup({ id })
    })

    resetStep()
  }

  const sendCard = async (recipients?: string[]) => {
    setLoading(x => x + 1)

    try {
      const {
        createOrder: { order },
      } = await mutations.createOrder({
        order: { contactsFromGroups: selectedGroups.map(group => group.id) },
      })
      actions.openOrder(order.id, AddOrderCardRoute())
    } catch (error) {
      console.error(error?.toString() ?? 'Failed to send the card.')
    } finally {
      setLoading(x => x - 1)
    }
  }

  const manageMembers = async ({ id }: Group) => {
    setStep({ type: Steps.ManageMembers, groupId: id })
  }

  const selectCampaign = (recipients?: string[]) => {
    setStep({
      type: Steps.SelectCampaign,
      recipients,
    })
  }

  const confirmRemoveMembers = (
    group: DetailedGroupFragment,
    removed: string[],
  ) => {
    setStep({
      type: Steps.RemoveMembers,
      confirmed: false,
      removed,
      group,
    })
  }

  const removeMembers = (group: DetailedGroupFragment, removed: string[]) => {
    mutations.updateGroup({
      group: {
        ...group,
        members: xor(
          group.contacts.map(member => member.id),
          removed,
        ),
      },
    })

    resetStep()
  }

  const confirmExport = useCallback(() => {
    setStep({
      type: Steps.ConfirmExport,
      email: account.email,
    })
  }, [account])

  const exportContacts = async () => {
    setStep({
      type: Steps.ExportProgress,
    })
    await exportContactsCsvFrom(
      'groups',
      Set(selectedGroups.map(group => group.id)),
    )
  }

  return {
    step,
    setStep,
    paginatedGroups,
    allGroups: sharedHooks.groups,
    selected,
    toggleSelectAll,
    setPage: sharedHooks.setPage,
    setTerms: sharedHooks.setTerms,
    setFilters: sharedHooks.setFilters,
    clearSearch: sharedHooks.clearSearch,
    toggleSelect,
    allSelected: selected.length === sharedHooks.groups.length,
    selectionMap,
    openContact: () => {},
    viewContactHistory: () => {},
    isLoading: loading > 0,
    search: sharedHooks.search,
    resetStep,
    editGroup,
    updateGroup,
    createGroup,
    confirmDelete,
    deleteSelectedGroups,
    selectedGroups,
    selectCampaign,
    sendCampaign,
    sendCard,
    manageMembers,
    confirmRemoveMembers,
    removeMembers,
    page: sharedHooks.page,
    reset,
    actions,
    exportContacts,
    confirmExport,
  }
}

export default Hooks
