import React from 'react'
import { Button } from 'src/chrome'

interface Props {
  message: string
  onReset: () => void
}

const EmptySearch: React.FC<Props> = props => {
  const { message, onReset } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '1 1 auto',
      }}
    >
      <span>{message}</span>

      <Button
        title={'Clear Search'}
        buttonColor={'pink'}
        style={{ marginTop: 15 }}
        onClick={() => onReset()}
      />
    </div>
  )
}

export default EmptySearch
