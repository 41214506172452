import { Div } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CompleteBadge } from './CompleteBadge'
import { InCompleteBadge } from './IncompleteBadge'

export const InProgressBadge: React.FC = () => {
  return (
    <Div position="relative">
      <CompleteBadge />
      <Div position="absolute" top="9px">
        <InCompleteBadge />
      </Div>
    </Div>
  )
}
