import React, { useRef } from 'react'
import {
  Anchor,
  Button,
  Flex,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks/useState'
import useActions from 'src/hooks/useActions'
import {
  useCallback,
  useDebounce,
  useEmailValidation,
  useSponsorFromQueryParam,
} from 'src/hooks'
import { parseError } from 'src/utils/parseError'
import { RawCreateAccountForm } from './RawCreateAccountForm'
import ReCAPTCHA from 'react-google-recaptcha'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { EmailValidationStep, VerifyClient } from './VerifyClient'
import { ValidationError } from 'src/email_verification/VerifyEmail/ValidationFailureEmailVerification'
import { Portal } from 'src/portal/portal'
import { CheckoutFindMySponsor } from '../../checkout_find_my_sponsor/CheckoutFindMySponsor'
import { sendoutcardsPromotionsGenealogyId } from 'src/app/constants'
import { useCreateAccount } from 'src/react_query'
import { AccountFragment } from 'src/graphql/generated/graphql'

type InputType = {
  error: string | null | undefined
  value: string
}

type CreateAccountFormState = {
  email: InputType
  username: InputType
  password: InputType
  phoneNumber: InputType
  firstName: InputType
  lastName: InputType
}

interface Props {
  shouldNotAutoComplete?: boolean
  handleSuccessfullAccCreation: (account: AccountFragment) => void
  handleAlreadyUser: () => void
  shouldStackForm?: boolean
  isSponsorExpanded: boolean
  handleIsSponsorExpanded: (isExpanded: boolean) => void
}

export const BasicCreateAccountForm: React.FC<Props> = props => {
  const {
    shouldNotAutoComplete,
    handleSuccessfullAccCreation,
    handleAlreadyUser,
    shouldStackForm = false,
    isSponsorExpanded,
    handleIsSponsorExpanded,
  } = props
  const [createAccountFormState, setCreateAccountFormState] = useState<
    CreateAccountFormState
  >({
    email: { error: undefined, value: '' },
    username: { error: null, value: '' },
    password: { error: null, value: '' },
    phoneNumber: { error: null, value: '' },
    firstName: { error: null, value: '' },
    lastName: { error: null, value: '' },
  })

  const { hasSponsorQP, fetchSponsor, sponsor } = useSponsorFromQueryParam()

  const [universalError, setUniversalError] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [shouldVerifyAccount, setShouldVerifyAccount] = useState(false)
  const [isPhoneNumberTaken, setIsPhoneNumberTaken] = useState(false)
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false)

  const debouncedUsername = useDebounce(createAccountFormState.username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername.value)

  const debouncedEmail = useDebounce(createAccountFormState.email, 500)
  const emailValidation = useEmailValidation(debouncedEmail.value)
  const [validationFailure, setValidationFailure] = useState<ValidationError>()

  const hasRequiredFields = (
    username: string,
    password: string,
    email: string,
    phoneNumber: string,
    firstName: string,
    lastName: string,
  ) => {
    if (
      !username ||
      !password ||
      !email ||
      !phoneNumber ||
      !firstName ||
      !lastName
    ) {
      setCreateAccountFormState({
        ...createAccountFormState,
        password: {
          ...createAccountFormState.password,
          error: !password ? 'Password is required' : null,
        },
        email: {
          ...createAccountFormState.email,
          error: !email ? 'Email is required' : null,
        },
        username: {
          ...createAccountFormState.username,
          error: !username ? 'Username is required' : null,
        },
        phoneNumber: {
          ...createAccountFormState.phoneNumber,
          error: !phoneNumber ? 'Phone Number is required' : null,
        },
        firstName: {
          ...createAccountFormState.firstName,
          error: !firstName ? 'First Name is required' : null,
        },
        lastName: {
          ...createAccountFormState.lastName,
          error: !lastName ? 'Last Name is required' : null,
        },
      })
      return false
    }
    return true
  }

  const createAccountMutation = useCreateAccount()
  const actions = useActions()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [isCheckingPhoneNumber, setIsCheckingPhoneNumber] = useState(false)

  // TODO / NOTE: We will likely have to handle sponsor in a different component due designs
  // MAYBE we can portal it to the right side ...

  const [sponsorGenealogyId, setSponsorGenealogyId] = useState<string>()

  const handleIsCheckingPhoneNumber = useCallback((isChecking: boolean) => {
    setIsCheckingPhoneNumber(isChecking)
  }, [])

  const handleIsPhoneNumberTaken = useCallback((isTaken: boolean) => {
    setIsPhoneNumberTaken(isTaken)
  }, [])

  const handleIsPhoneNumberInvalid = useCallback((isInvalid: boolean) => {
    setIsPhoneNumberInvalid(isInvalid)
  }, [])

  const handleSelectedSponsor = (id: string) => {
    setSponsorGenealogyId(id)
  }

  const onSubmit = () => {
    setShouldVerifyAccount(true)
  }

  const onCreateAccount = async (
    username: string,
    password: string,
    email: string,
    phoneNumber: string,
    firstName: string,
    lastName: string,
    code: string,
  ) => {
    if (
      !hasRequiredFields(
        username,
        password,
        email,
        phoneNumber,
        firstName,
        lastName,
      )
    )
      return
    try {
      setIsLoading(true)
      setShouldVerifyAccount(false)

      const token = process.env.REACT_APP_RECAPTCHA_KEY
        ? await recaptchaRef?.current?.executeAsync()
        : undefined

      try {
        const { account, validation } = (
          await createAccountMutation.mutateAsync({
            account: {
              username,
              password,
              email,
              phoneNumber,
              firstName,
              lastName,
              sponsorGenealogyId: sponsorGenealogyId
                ? sponsorGenealogyId
                : sponsor
                ? sponsor.genealogyId.toString()
                : String(sendoutcardsPromotionsGenealogyId),
            },
            verificationCode: code,
            recaptchaToken: token,
          })
        ).createAccount

        if (
          validation &&
          validation.__typename === 'ValidateEmailVerificationCodeFailure'
        ) {
          setValidationFailure({
            type: validation.failureType,
            message: validation.message,
          })
          setShouldVerifyAccount(true)
        }

        if (account) {
          actions.updatedAccount(account)
          handleSuccessfullAccCreation?.(account)
        }
      } catch (error) {
        setUniversalError(parseError(error))
      }
    } catch (error) {
      setUniversalError(parseError(error))
    } finally {
      recaptchaRef.current?.reset()
      setIsLoading(false)
    }
  }

  return (
    <>
      {!shouldVerifyAccount && (
        <Flex
          backgroundColor="foreground"
          borderRadius="large"
          width="100%"
          flexDirection="column"
        >
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <Text
              type="largeBody"
              color="primaryBody"
              content="Step 1: "
              outset={{ right: 'x1' }}
            />
            <Text
              type="largeBody"
              color="primaryHeading"
              content="Account Information"
              weight="bold"
            />
          </div>
          <RawCreateAccountForm
            data={{
              email: createAccountFormState.email.value,
              username: createAccountFormState.username.value,
              password: createAccountFormState.password.value,
              phoneNumber: createAccountFormState.phoneNumber.value,
              firstName: createAccountFormState.firstName.value,
              lastName: createAccountFormState.lastName.value,
            }}
            isLoading={isLoading}
            setData={input => {
              if (isLoading) return
              setCreateAccountFormState({
                ...createAccountFormState,
                email: { value: input.email, error: null },
                username: {
                  value: input.username,
                  error: null,
                },
                password: {
                  value: input.password,
                  error: null,
                },
                phoneNumber: {
                  value: input.phoneNumber,
                  error: null,
                },
                firstName: {
                  value: input.firstName,
                  error: null,
                },
                lastName: {
                  value: input.lastName,
                  error: null,
                },
              })
            }}
            errors={{
              email: emailValidation.errorMessage,
              username:
                !usernameValidation.isValid &&
                createAccountFormState.username.value !== ''
                  ? 'This username is taken'
                  : undefined,
              password: createAccountFormState.password.error,
              phoneNumber: createAccountFormState.phoneNumber.error,
              firstName: createAccountFormState.firstName.error,
              lastName: createAccountFormState.lastName.error,
              universal: universalError,
            }}
            shouldNotAutoComplete={shouldNotAutoComplete}
            shouldStackForm={shouldStackForm}
            isLoadingEmailValidation={emailValidation.isLoading}
            isLoadingUsernameValidation={usernameValidation.isLoading}
            handleIsCheckingPhoneNumber={handleIsCheckingPhoneNumber}
            handleIsPhoneNumberTaken={handleIsPhoneNumberTaken}
            handleIsPhoneNumberInvalid={handleIsPhoneNumberInvalid}
          />
          <Portal
            attachToContainerId="checkout-find-my-sponsor-container"
            wrapperStyles={{ width: '100%' }}
          >
            <>
              {((hasSponsorQP && fetchSponsor && !fetchSponsor.isUnresolved) ||
                !hasSponsorQP) && (
                <CheckoutFindMySponsor
                  isExpanded={isSponsorExpanded}
                  setIsExpanded={handleIsSponsorExpanded}
                  onSelectedSponsor={handleSelectedSponsor}
                  presetSponsorId={sponsor?.id}
                />
              )}
              {fetchSponsor?.isUnresolved && <LoadingSpinner size="large" />}
            </>
          </Portal>
          <Flex
            outset={{ top: 'auto' }}
            inset={{ top: 'x3' }}
            justifyContent="flex-end"
            alignItems="center"
            columnGap="x2"
          >
            <Anchor
              title="Already a User?"
              onClick={handleAlreadyUser}
              isDecorated={true}
            />
            <div style={{ width: '50%' }}>
              {process.env.REACT_APP_RECAPTCHA_KEY && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                />
              )}
              <Button
                fullWidth={true}
                disabled={
                  isLoading ||
                  !emailValidation.isValid ||
                  emailValidation.isLoading ||
                  !usernameValidation.isValid ||
                  usernameValidation.isLoading ||
                  isCheckingPhoneNumber ||
                  isPhoneNumberTaken ||
                  isPhoneNumberInvalid ||
                  !createAccountFormState.firstName.value ||
                  !createAccountFormState.lastName.value ||
                  !createAccountFormState.email.value ||
                  !createAccountFormState.username.value ||
                  !createAccountFormState.password.value ||
                  !createAccountFormState.phoneNumber.value
                }
                size={shouldStackForm ? 'medium' : 'small'}
                title={isLoading ? 'Creating Account' : 'Create Account'}
                backgroundColorOverride="#00aeef"
                onClick={onSubmit}
              />
            </div>
          </Flex>
        </Flex>
      )}
      {shouldVerifyAccount && (
        <VerifyClient
          onClose={() => {
            setShouldVerifyAccount(false)
          }}
          email={createAccountFormState.email.value}
          confirm={{
            onSubmit: async code => {
              onCreateAccount(
                createAccountFormState.username.value,
                createAccountFormState.password.value,
                createAccountFormState.email.value,
                createAccountFormState.phoneNumber.value,
                createAccountFormState.firstName.value,
                createAccountFormState.lastName.value,
                code,
              )
            },
          }}
          initialStep={
            validationFailure ? EmailValidationStep.ValidationError : undefined
          }
          validationFailure={validationFailure}
        />
      )}
    </>
  )
}
