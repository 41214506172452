import { useCallback, useState } from '.'

export type Gradient = {
  fromColor: string
  toColor: string
  direction: string
}

export const useRandomGradient = (
  colorValues: string[],
  directions: string[],
) => {
  const getRandomGradient = useCallback(() => {
    const shuffledColors = [...colorValues].sort(() => 0.5 - Math.random())
    const direction = directions[Math.floor(Math.random() * directions.length)]

    return {
      fromColor: shuffledColors[0],
      toColor: shuffledColors[1],
      direction: direction,
    }
  }, [colorValues, directions])

  const [gradient] = useState(getRandomGradient())

  return gradient
}
