import React from 'react'
// @src imports

import Orders from 'src/orders/containers/Orders'
// import Pricing from 'src/marketing/plans/containers/Pricing'
import { CampaignsRouter } from 'src/campaigns/components'
import Dashboard from 'src/dashboard/containers/Dashboard/Dashboard'
import CatalogPage from 'src/catalog/containers/CatalogPage'
import GroupManager from 'src/contacts/containers/GroupManager'
import ImportManager from 'src/import-contacts'
import GiftStore from 'src/gift_store/containers/GiftStore'
import { InvalidMagicLink } from 'src/chrome'
import { InvalidMagicLinkRoute } from 'src/routes/InvalidMagicLinkRoute'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { RelationshipManagerRoute } from 'src/contacts/routes/RelationshipManagerRoute'
import { GroupsRoute } from 'src/contacts/routes/GroupsRoute'
import { ImportContactsRoute } from 'src/contacts/routes/ImportContactsRoute'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import { CampaignsRoute } from 'src/campaigns/routes/CampaignsRoute'
import { GiftStoreRoute } from 'src/gift_store/routes/GiftStoreRoute'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { useSelector } from 'src/hooks'
import Card from 'src/card'
import { CardRoute } from 'src/card/routes/CardRoute'
import { FourOFourRoute } from 'src/404/routes/404Route'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
import Promo from 'src/promo/components/Promo'
import FourOFour from 'src/404/404'
import { CertifiedCoachingRoute } from 'src/coaching/routes/CoachingCertificationRoute'
import { CertifiedCoaching } from 'src/coaching/components/CertifiedCoaching/CertifiedCoaching'
import { PointsConversionRoute } from 'src/points_conversion/routes/PointsConversionRoute'
import { PointsConversionPage } from 'src/points_conversion/pages/PointsConversionPage'
import ContactManagerSelector from 'src/contacts/containers/ContactManagerSelector'
import { PricingPage } from 'src/pricing_page/PricingPage'
import { ConfirmPaymentRoute } from 'src/saleor/routes/ConfirmPaymentRoute'
import { ConfirmPaymentPage } from 'src/saleor/pages/ConfirmPaymentPage'
import { ConfirmOrderPage } from 'src/saleor/pages/ConfirmOrderPage'
import { ConfirmOrderRoute } from 'src/saleor/routes/ConfirmOrderRoute'

const Router: React.FC = () => {
  const route = useSelector(state => state.route)

  switch (route.subroute.path) {
    case InvalidMagicLinkRoute.path:
      return <InvalidMagicLink route={route.subroute} />
    case PricingRoute.path:
      return <PricingPage route={route.subroute} />
    case CatalogRoute.path:
      return <CatalogPage route={route.subroute} />
    case GroupsRoute.path:
      return <GroupManager />
    case RelationshipManagerRoute.path:
      return <ContactManagerSelector route={route.subroute} />
    case ImportContactsRoute.path:
      return <ImportManager />
    case OrdersRoute.path:
      return <Orders route={route.subroute} />
    case CampaignsRoute.path:
      return <CampaignsRouter route={route.subroute} />
    case GiftStoreRoute.path:
      return <GiftStore route={route.subroute} />
    case DashboardRoute.path:
      return <Dashboard route={route.subroute} />
    case CardRoute.path:
      return <Card cardId={route.subroute.cardId} />
    case PromoRoute.path:
      return <Promo />
    case FourOFourRoute.path:
      return <FourOFour />
    case CertifiedCoachingRoute.path:
      return <CertifiedCoaching />
    case PointsConversionRoute.path:
      return <PointsConversionPage />
    case ConfirmPaymentRoute.path:
      return <ConfirmPaymentPage />
    case ConfirmOrderRoute.path:
      return <ConfirmOrderPage route={route.subroute} />
  }
}

export default Router
