import moment from 'moment'
import { Set } from 'immutable'

import {
  ContactSearchFilter,
  DateSearchFilter,
  GroupHistorySearchFilter,
  SearchFilter,
  SearchFilterType,
} from 'src/chrome/FilteredSearchBar/types'
import {
  CardHistoryEditCardRoute,
  CardHistoryResendCardRoute,
} from 'src/dashboard/routes/CardHistoryRoute'

import { ProductionRecipient, Props, Section, Step, Steps } from './types'

import {
  useActions,
  useCallback,
  useEffect,
  useFlagWithUseQueries,
  useMutations,
  useQueries,
  useReload,
  useSelector,
  useState,
} from 'src/hooks'
import {
  useCreateCard,
  useGetOrCreateEditableCardForRecipient,
  useMarketingContent,
} from 'src/react_query'
import {
  CardStatus,
  CreateContactInput,
  ProductionInfoFragment,
  RecipientFragment,
} from 'src/graphql/generated/graphql'
import { getPaginatedRecipients, getSentCards } from 'src/legacy_graphql'
import {
  cardStatusToLegacy,
  productionInfoFragmentFromLegacy,
} from 'src/graphql/compat'
import { selectableCards } from 'src/helpers'

export const PAGE_SIZE = 20

const filtersFactory = (filters?: SearchFilter[]): SearchFilter[] => {
  const contactFilter = filters?.find(
    (filter): filter is ContactSearchFilter =>
      filter.type === SearchFilterType.CONTACT && filter.name === 'Recipient',
  )
  const dateFilter = filters?.find(
    (filter): filter is DateSearchFilter =>
      filter.type === SearchFilterType.DATERANGE && filter.name === 'Recipient',
  )
  const groupHistoryFilter = filters?.find(
    (filter): filter is GroupHistorySearchFilter =>
      filter.type === SearchFilterType.GROUP_HISTORY && filter.name === 'Group',
  )

  return [
    {
      type: SearchFilterType.GROUP_HISTORY,
      name: 'Group',
      value: groupHistoryFilter?.value,
    },
    {
      type: SearchFilterType.CONTACT,
      name: 'Recipient',
      value: contactFilter?.value,
    },
    {
      type: SearchFilterType.DATERANGE,
      name: 'Date',
      value: dateFilter?.value,
    },
  ]
}

export default function Hooks(props: Props) {
  const { initialSection, initialFilters } = props

  const actions = useActions()

  const [filters, setFilters] = useState(filtersFactory(initialFilters))
  const mutations = useMutations()
  const getOrCreateRecipientCardMutation = useGetOrCreateEditableCardForRecipient()
  const { mutateAsync: createCard } = useCreateCard()
  const [step, setStep] = useState<Step>({ type: Steps.Idle })
  const resetStep = () => setStep({ type: Steps.Idle })
  const showError = (message: string) => setStep({ type: Steps.Error, message })
  const [section, setSection] = useState<Section>(initialSection ?? 'All')
  const [currentPage, setCurrentPage] = useState(1)
  const [selected, setSelected] = useState(Set())
  const [terms, setTerms] = useState('')
  const reload = useReload()
  const filterValues = filters.reduce(
    (
      accum: {
        startDate?: moment.Moment
        endDate?: moment.Moment
        contactIds: string[]
      },
      filter,
    ) => {
      if (filter.type === SearchFilterType.CONTACT && filter.value) {
        return {
          ...accum,
          contactIds: [
            ...accum.contactIds,
            ...filter.value.map(contact => contact.id),
          ],
        } // change if we want to have more than one filter value per type
      }

      if (filter.type === SearchFilterType.DATERANGE && filter.value) {
        return {
          ...accum,
          startDate: filter.value.start,
          endDate: filter.value.end,
        }
      }

      if (filter.type === SearchFilterType.GROUP_HISTORY && filter.value) {
        return {
          ...accum,
          contactIds: [
            ...accum.contactIds,
            ...filter.value.contacts.map(contact => contact.id),
          ],
        }
      }

      return accum
    },
    {
      startDate: undefined,
      endDate: undefined,
      contactIds: Array<string>(),
    },
  )

  const getStatusForRequest = (
    radioSelected: string,
  ): CardStatus | undefined => {
    const selected = radioSelected.toUpperCase()
    switch (selected) {
      case 'FULFILLED':
        return CardStatus.Fulfilled
      case 'HELD':
        return CardStatus.Held
      case 'PRINTING':
        return CardStatus.AwaitingFulfillment
      case 'PENDING':
        return CardStatus.Pending
      case 'REJECTED':
        return CardStatus.Rejected
      case 'PAYMENT_ERROR':
        return CardStatus.PaymentError
      default:
        return undefined
    }
  }

  const context = section === 'Payment Error' ? 'paused' : 'sent'

  const { newCardEditor: canShowNewCardEditor } = useFlagWithUseQueries()
  const { data: marketingCopy } = useMarketingContent()

  const [legacyCardHistory, recipientHistory] = useQueries(
    context === 'sent'
      ? getSentCards({
          page: currentPage,
          search: terms,
          contacts: filterValues.contactIds,
          status: cardStatusToLegacy(getStatusForRequest(section)),
          startDate: filterValues.startDate?.format('YYYY-MM-DD'),
          endDate: filterValues.endDate?.format('YYYY-MM-DD'),
        })
      : undefined,
    context === 'paused'
      ? getPaginatedRecipients({
          paymentFailures: true,
          page: currentPage,
          search: terms,
          contacts: filterValues.contactIds,
        })
      : undefined,
  )
  const cardHistory = legacyCardHistory
    ? {
        count: legacyCardHistory.count,
        pageSize: legacyCardHistory.pageSize,
        results: legacyCardHistory.results.map(
          productionInfoFragmentFromLegacy,
        ),
      }
    : undefined

  const run = async (task: () => Promise<void>, message: string) => {
    setStep({
      type: Steps.Progress,
      message,
    })

    try {
      await task()
    } catch (error) {
      showError(error?.toString() ?? `Failed to run task.`)
    } finally {
      resetStep()
    }
  }

  const editSendDate = (info: ProductionInfoFragment) => {
    setStep({ type: Steps.SetDate, info })
  }

  const saveSendDate = async (startDate?: moment.Moment) => {
    if (step.type !== Steps.SetDate) return
    if (!startDate) return

    run(async () => {
      await mutations.updateProductionInfo({
        input: {
          dateToSend: startDate.format('YYYY-MM-DD'),
          productionId: step.info.id,
        },
      })
    }, 'Updating Send Date...')
  }

  const editRecipient = (recipient: RecipientFragment) => {
    setStep({ type: Steps.EditRecipient, recipient })
  }

  const saveRecipient = async (
    address: CreateContactInput,
    updateContactToo: boolean,
    userVerified: boolean,
  ) => {
    if (step.type !== Steps.EditRecipient || !step.recipient.address) {
      return
    }

    run(async () => {
      const recipient = {
        ...step.recipient,
        address: {
          ...step.recipient.address,
          firstName: address.firstName || '',
          lastName: address.lastName || '',
          company: address.companyName || '',
          address1: address.address1 || '',
          address2: address.address2 || '',
          city: address.city || '',
          state: address.state || '',
          postalCode: address.postalCode || '',
          country: address.country || '',
        },
      }

      await mutations.updateRecipient({
        recipient,
        updateContactToo,
        userOverrideInvalidAddress: userVerified,
      })
    }, 'Updating Recipient...')
  }

  const viewCard = async (recipient: ProductionRecipient) => {
    setStep({ type: Steps.View, recipient })

    try {
      if (!recipient.card) return
      const {
        getOrCreateEditableCardForRecipient: { card },
      } = await getOrCreateRecipientCardMutation.mutateAsync({
        recipientId: recipient.id,
        productionId: recipient.productionId,
      })
      setStep({
        type: Steps.View,
        recipient,
        card: {
          id: card.id,
          type: card.type,
          insidePreviewUrl: card.insidePreviewUrl,
          isHorizontal: card.isHorizontal,
          isNewEditorCard: card.isNewEditorCard,
          frontPreviewUrl: card.frontPreviewUrl,
          flapPreviewUrl: card.flapPreviewUrl,
          backPreviewUrl: card.backPreviewUrl,
          panels: card.panels,
        },
      })
    } catch (error) {
      showError(error?.toString() ?? 'Failed to create card for recipient.')
    }
  }

  const isMobile = useSelector(state => state.window.isMobile)

  const handleOrderResentCard = async (cardId: string) => {
    setStep({ type: Steps.Progress, message: 'Creating your order...' })

    try {
      const result = await mutations.createOrder({
        order: {
          lines: [
            {
              card: cardId,
            },
          ],
        },
      })

      actions.openOrder(result.createOrder.order.id)
    } catch (error) {
      showError(error?.toString() ?? 'Failed to create order from sent card.')
    }
  }

  const goToIndex = () => {
    actions.openCardHistory(section, filters)
  }

  const onResendCard = async (id: string, shouldCreateOnNewEditor: boolean) => {
    setStep({ type: Steps.Progress, message: 'Building copy of card...' })

    try {
      const result = await createCard({
        card: id,
        isResend: true,
        isNewEditorCard: shouldCreateOnNewEditor,
      })

      if (result.createCard.card) {
        actions.openCardHistory(
          section,
          filters,
          CardHistoryResendCardRoute(result.createCard.card.id),
        )
      }
      resetStep()
    } catch (error) {
      showError(error?.toString() ?? 'Failed to create card from resend.')
    }
  }

  const onConfirmCancel = () => {
    switch (context) {
      case 'paused':
        return onConfirmRetry()
      case 'sent':
        return onConfirmCancelPending()
    }
  }

  const onConfirmCancelPending = () => {
    setStep({
      type: Steps.CancelCards,
      userConfirmed: false,
      ids: [...selected.values()],
    })
  }

  const onCancelPending = async (ids: string[]) => {
    setStep({ type: Steps.CancelCards, ids, userConfirmed: true })

    try {
      const result = await mutations.cancelPendingCards({ productionIds: ids })
      reload(
        getSentCards({
          page: currentPage,
          search: terms,
          contacts: filterValues.contactIds,
          status: cardStatusToLegacy(getStatusForRequest(section)),
          startDate: filterValues.startDate?.format('YYYY-MM-DD'),
          endDate: filterValues.endDate?.format('YYYY-MM-DD'),
        }),
      )

      if (result.cancelPendingCards.refundInfo) {
        setStep({
          type: Steps.CancelCardsSummary,
          ids,
          refundInfo: result.cancelPendingCards.refundInfo,
        })
      }
      setSelected(Set())
    } catch (error) {
      resetStep()
      showError('Sorry, could not cancel cards, please try again later')
    }
  }

  const onConfirmRetry = () => {
    setStep({
      type: Steps.RetryFailedCards,
      ids: [...selected],
      userConfirmed: false,
    })
  }

  const onRetry = async () => {
    setStep({
      type: Steps.RetryFailedCards,
      ids: [...selected],
      userConfirmed: true,
    })

    try {
      await mutations.sendPausedRecipients({
        recipientIds: Array.from(selected),
      })
      setSelected(Set())
    } catch (error) {
      showError('There was an error canceling a card, please try again later')
    }
  }

  const handleEditCard = async (recipientId: string) => {
    const {
      getOrCreateEditableCardForRecipient: {
        card: { id },
      },
    } = await getOrCreateRecipientCardMutation.mutateAsync({ recipientId })
    actions.openCardHistory(section, filters, CardHistoryEditCardRoute(id))
  }

  const handleCardUpdated = async () => {
    setStep({ type: Steps.Idle })
    actions.openCardHistory(section, filters)
  }

  const displayedData = (() => {
    switch (context) {
      case 'paused':
        return recipientHistory
      case 'sent':
        return cardHistory
    }
  })()

  const isSelectAllChecked = (() => {
    if (context !== 'sent' || !cardHistory) {
      return false
    }
    if (cardHistory.count === 0) {
      return false
    }

    return cardHistory.results
      .filter(selectableCards)
      .map(card => card.id)
      .every(id => selected.has(id))
  })()

  const handleSelect = (id: string) => {
    const newSet = selected.has(id) ? selected.delete(id) : selected.add(id)
    setSelected(newSet)
  }

  const handleSelectAll = () => {
    const originalSelection = selected

    if (isSelectAllChecked) {
      setSelected(Set())
      return
    }
    const selectable: Array<{ id: string }> | undefined =
      context === 'sent'
        ? cardHistory?.results.filter(selectableCards)
        : recipientHistory?.results
    const historyIds = selectable?.map(item => item.id) ?? []
    const newSelection = originalSelection.union(Set(historyIds))

    setSelected(newSelection)
  }

  const selectName = isSelectAllChecked ? 'Deselect All' : 'Select All'

  const clearSearch = useCallback(() => {
    setTerms('')
    setFilters(filtersFactory(initialFilters))
  }, [initialFilters])

  useEffect(() => {
    setSelected(Set())
  }, [context])

  useEffect(() => {
    setCurrentPage(1)
  }, [filters, terms])

  return {
    isSelectAllChecked,
    cardHistory,
    context: context as typeof context,
    currentPage,
    displayedData,
    editRecipient,
    editSendDate,
    filters,
    handleEditCard,
    handleSelect,
    handleSelectAll,
    isMobile,
    marketingCopy,
    onCancelPending,
    onConfirmCancel,
    onConfirmRetry,
    onResendCard,
    onRetry,
    recipientHistory,
    resetStep,
    saveRecipient,
    saveSendDate,
    section,
    selected,
    selectName: selectName as typeof selectName,
    setCurrentPage,
    setFilters,
    setSection,
    setTerms,
    clearSearch,
    step,
    viewCard,
    handleCardUpdated,
    handleOrderResentCard,
    goToIndex,
    canShowNewCardEditor,
  }
}
