import { Button, Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useActions, useSelector } from 'src/hooks'
import { image404base64 } from './404Image'
import { FourOFourRoute } from './routes/404Route'

type FourOFourProps = {
  route?: FourOFourRoute
}

const FourOFour: React.FC<FourOFourProps> = () => {
  const actions = useActions()
  const isMobile = useSelector(state => state.window.isMobile)
  return (
    <Div
      inset={{
        top: '7%',
        left: isMobile ? '0' : '15%',
        bottom: isMobile ? '85px' : undefined,
      }}
      height={isMobile ? '100vh' : undefined}
      style={{ backgroundColor: 'white' }}
    >
      <Div position="relative">
        <Div
          outset={{ horizontal: isMobile ? 'x4' : undefined }}
          position="relative"
          zIndex={1}
        >
          <Div outset={{ left: 'x2_5' }}>
            <Text
              type="title"
              color="primaryBody"
              style={{ color: '#c0c0c0' }}
              weight="thin"
            >
              404 ERROR
            </Text>
          </Div>
          <Text
            type="heroTitle"
            color="primaryBrand"
            weight="regular"
            lineHeight={1}
            style={{ fontSize: isMobile ? '5rem' : '7rem' }}
          >
            Oops!
          </Text>
          <Spacer space={isMobile ? 'x4' : 'x8'} />
          <Div maxWidth="420px">
            <Text
              type={isMobile ? 'largeBody' : 'subtitle'}
              color="primaryHeading"
              weight="regular"
            >
              We couldn’t find the page you are looking for. The page is no
              longer available or is currently under maintenance.
            </Text>
            <Spacer space={isMobile ? 'x2' : 'x4'} />
            <Text
              type={isMobile ? 'largeBody' : 'subtitle'}
              color="primaryHeading"
              weight="regular"
            >
              Click one of the options below to get back to your card sending!
            </Text>
          </Div>
          <Spacer space={isMobile ? 'x3' : 'x7'} />
        </Div>
        <div
          style={{
            position: isMobile ? 'relative' : 'absolute',
            top: isMobile ? '0' : '-2px',
            left: isMobile ? '30px' : '380px',
          }}
        >
          <img
            src={image404base64}
            alt="Page not found"
            style={{ maxWidth: isMobile ? '100%' : undefined }}
          />
        </div>
        <Div
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'center' : undefined}
        >
          <Button
            type="primary"
            fontWeight="semiBold"
            backgroundColorOverride="#703A96"
            onClick={() => actions.openCatalog()}
          >
            Card Catalog
          </Button>
          <Spacer
            space="x3"
            orientation={isMobile ? 'vertical' : 'horizontal'}
          />
          <Button
            type="primary"
            fontWeight="semiBold"
            backgroundColorOverride="#32ABE4"
            onClick={() => actions.openAccount()}
          >
            My Account
          </Button>
        </Div>
      </Div>
    </Div>
  )
}

export default FourOFour
