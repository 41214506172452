import { Div, Flex, Image, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

export type CoachingGoalsProps = {
  title: string
  coachTitle: string
  isActive: boolean
  graphicURL?: string
}

export const CoachingGoals: React.FC<CoachingGoalsProps> = props => {
  const { title, coachTitle, isActive, graphicURL } = props
  return (
    <Flex
      width="100%"
      display="flex"
      flexDirection={'row'}
      alignItems="center"
      backgroundColor={isActive ? 'foreground' : '#e3e3e3'}
      borderColor={isActive ? '' : '#e3e3e3'}
      borderStyle={isActive ? 'none' : 'solid'}
      borderWidth="thin"
      boxShadow={isActive ? '' : 'mediumLight'}
      borderRadius={{ top: 'default' }}
      inset={'x2'}
      columnGap="x1"
    >
      {graphicURL && (
        <Image
          width="40px"
          height="45px"
          isActive={false}
          image={{ url: graphicURL }}
        />
      )}
      {isActive && (
        <Div inset={{ left: 'x1_5' }}>
          <Text
            type="caption"
            color="primaryBody"
            content={title}
            lineHeight={1.5}
            alignment={'left'}
          />
          <Text
            type="body"
            weight="extraBold"
            color="primaryHeading"
            content={coachTitle}
          />
        </Div>
      )}
    </Flex>
  )
}
