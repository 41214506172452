import useAccount from './useAccount'
import useQueries from './useQueries'
import { CoachFragment, getCoach } from 'src/legacy_graphql'
import { AccountFragment } from 'src/graphql/generated/graphql'

export type CoachDetails = {
  firstName: string
  lastName: string
  memberSince: {
    year: number
    month: number
    day: number
  }
  email: string
  phoneNumber: string
  isEmailPublic: boolean
  isPhonePublic: boolean
  isAuthUser: boolean
  genealogyId: number
  imageUrl: string | null
}

export const useCoach = () => {
  const account = useAccount()
  const [coach] = useQueries(
    account.promptingsCoach?.trainerId
      ? getCoach({ slug: `${account?.promptingsCoach?.trainerId}` })
      : undefined,
  )

  const { promptingsCoach } = account

  if (!promptingsCoach) {
    // Not apart of coaching program...
    return null
  }

  const mapCoach = (coach: AccountFragment | CoachFragment): CoachDetails => {
    const parseCreatedAt = (dateJoined: string) => {
      const date = new Date(dateJoined)
      return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDay(),
      }
    }

    if (coach.__typename === 'Account') {
      return {
        firstName: coach.firstName,
        lastName: coach.lastName,
        memberSince: parseCreatedAt(coach.dateJoined),
        email: coach.email,
        phoneNumber: '',
        isEmailPublic: !!promptingsCoach?.isEmailPublic,
        isPhonePublic: !!promptingsCoach?.isPhoneNumberPublic,
        genealogyId: coach.genealogyId,
        imageUrl: coach.profileImageUrl,
        isAuthUser: true,
      }
    } else {
      return {
        firstName: coach.firstName,
        lastName: coach.lastName,
        memberSince: parseCreatedAt(coach.dateJoined),
        email: coach.email,
        phoneNumber: coach.formattedPhoneNumber,
        isEmailPublic: coach.promptingsCoach?.isEmailPublic ?? false,
        isPhonePublic: coach.promptingsCoach?.isPhoneNumberPublic ?? false,
        genealogyId: coach.genealogyId,
        imageUrl: coach.storefront?.mugshot?.url ?? null,
        isAuthUser: false,
      }
    }
  }

  if (promptingsCoach.dateCoachingPurchased) {
    return mapCoach(account)
  }

  return coach ? mapCoach(coach) : null
}
