import {
  UpdateProductionInfoInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  ProductionInfoFragment,
  productionInfoFragmentString,
} from '../../fragments/__generated__/ProductionInfoFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { recipientFragmentString } from '../../fragments/__generated__/RecipientFragment'

import updateProductionInfoRawString from '../UpdateProductionInfo.graphql'

const updateProductionInfoString = [
  updateProductionInfoRawString,
  productionInfoFragmentString,
  addressFragmentString,
  recipientFragmentString,
].join('\n\n')

export type UpdateProductionInfoData = {
  updateProductionInfo: {
    productionInfo: ProductionInfoFragment
  }
}

export type UpdateProductionInfoVariables = {
  input: UpdateProductionInfoInput
}

export type UpdateProductionInfo = Operation<
  UpdateProductionInfoData,
  UpdateProductionInfoVariables
>

export const UpdateProductionInfo = ({
  input,
}: UpdateProductionInfoVariables): UpdateProductionInfo => ({
  query: updateProductionInfoString,
  variables: {
    input: UpdateProductionInfoInput(input),
  },
})
