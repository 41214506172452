import { CardOption } from 'src/catalog/components/CardOptions/CardOptions'
import {
  AddressInput,
  CardPaperType,
  CardType,
} from 'src/graphql/generated/graphql'
import { AddressFragment } from 'src/legacy_graphql'

// if a new card type is added, it needs to be added here
// and then the logic in the getPaperTypes fcn below might
// need to change
const paperTypes: CardOption<CardPaperType>[] = [
  {
    value: CardPaperType.Std,
    label: 'Standard',
    gradient: '',
    icon: 'STANDARDPAPER',
    iconSize: 30,
    viewBox: '0 0 25 32',
  },
  {
    value: CardPaperType.Hvy,
    label: 'Satin',
    gradient: '',
    icon: 'FLATPANEL',
    iconSize: 30,
    viewBox: '0 0 25 32',
    upgrade: '50¢',
  },
  {
    value: CardPaperType.Pre,
    label: 'Pearl',
    gradient: '',
    icon: 'PEARLPAPER',
    iconSize: 30,
    viewBox: '0 0 24 32',
    upgrade: '50¢',
  },
]

// get a list of all the paper types that are possible
// by looking at the card type
export const getPaperTypes = (cardType: CardType) => {
  switch (cardType) {
    case CardType.Postcard:
      return paperTypes.filter(paper => paper.value === CardPaperType.Std)
    case CardType.TwoPanelBig:
      return paperTypes.map(paper =>
        paper.upgrade ? { ...paper, upgrade: '$1.50' } : paper,
      )
    default:
      return paperTypes
  }
}

// Product item category IDs
export const ACCOUNT_TOOLS_ID = '22'
export const RP_TOOLS_IDS = ['24', '25']

export const bulkReturnAddress: AddressInput = {
  firstName: '',
  lastName: 'SendOutCards',
  company: 'SendOutCards',
  address1: '5533 W Hammerfest Dr',
  address2: 'Ste 306',
  city: 'South Jordan',
  state: 'UT',
  postalCode: '84009',
  country: 'United States',
}

export const emptyShippingAddress: AddressFragment = {
  __typename: 'Address',
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  company: '',
  city: '',
  state: '',
  country: 'United States',
  postalCode: '',
}

// 84109 is the user id for the 9070 account
export const sendoutcardsPromotionsUserId = '84109'
export const sendoutcardsPromotionsGenealogyId = 9070
export const audereOrphanUserId = '21266879'
export const audereOrphanGenalogyId = 726110
export const orphanSponsorsUserIds = [
  sendoutcardsPromotionsUserId,
  audereOrphanUserId,
]
export const orphanSponsorsGenealogyIds = [
  sendoutcardsPromotionsGenealogyId,
  audereOrphanGenalogyId,
]
