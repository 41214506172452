import { css } from '@emotion/core'
import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useState } from 'src/hooks'
import {
  CoachingQualifications,
  CoachingQualificationsType,
} from '../coachingQualifications'
import { QualificationTab } from './components/QualificationTab'

export type QualificationSectionType = {
  graphicURL?: string
  qualifications: CoachingQualificationsType[]
  title: string
  isLocked: boolean
  coachTitle: string
}

type QualificationSectionProps = {
  badges: QualificationSectionType[]
  defaultActiveBadgeIndex: number
}

export const CoachingQualificationSection: React.FC<QualificationSectionProps> = props => {
  const { badges, defaultActiveBadgeIndex } = props

  const [activeBadgeIndex, setActiveBadgeIndex] = useState(
    defaultActiveBadgeIndex,
  )
  const sortedQualifications = [
    ...badges[activeBadgeIndex].qualifications,
  ].sort((a, b) => Number(a.isComplete) - Number(b.isComplete))

  return (
    <Div>
      <Text
        content={'Qualifications To Become a Certified COACH'.toUpperCase()}
        type="body"
        style={{ color: '#C0C0C0' }}
        weight="bold"
        outset={{ left: 'x1', bottom: 'x2' }}
      />
      <Flex flexDirection="row" width="100%" justifyContent="flex-start">
        {badges.map((badge, index) => {
          return (
            <QualificationTab
              key={index}
              graphicURL={badge.graphicURL}
              onClick={() => setActiveBadgeIndex(index)}
              title={badge.title}
              coachTitle={badge.coachTitle}
              isActive={activeBadgeIndex === index}
              isLocked={badge.isLocked}
            />
          )
        })}
      </Flex>
      <Div
        backgroundColor="background"
        borderRadius="default"
        position="relative"
      >
        {badges[activeBadgeIndex].isLocked && (
          <div
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              backdrop-filter: blur(4px);
              position: absolute;
              top: 0;
              left: 0;
            `}
          >
            <Div zIndex={1}>
              <Icon name="lock" size="large" primaryColor="warning" />
            </Div>
          </div>
        )}
        {sortedQualifications.map((qualification, index) => {
          return (
            <CoachingQualifications
              key={index}
              title={qualification.title}
              isComplete={qualification.isComplete}
              link={qualification.link}
              borderRadius={{
                topRight: index === 0 ? 'default' : 'none',
                bottom:
                  index === badges[activeBadgeIndex].qualifications.length - 1
                    ? 'default'
                    : 'none',
              }}
              progressPercentage={qualification.progressPercentage}
              progressSteps={qualification.progressSteps}
            />
          )
        })}
      </Div>
    </Div>
  )
}
