import { css } from '@emotion/core'
import { Div, Spacer, Text, Toggle } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { getPrompitingsCoachTrainees } from 'src/legacy_graphql'
import { useQueryFutures, useState } from 'src/hooks'
import { TraineeCard } from './components/TraineeCard'

interface Props {
  title?: string
  subtitle?: string
}

export const TrainingTracking: React.FC<Props> = props => {
  const [prompitingsCoachTrainees] = useQueryFutures(
    getPrompitingsCoachTrainees(),
  )

  const [shouldShowCompleted, setShouldShowCompleted] = useState(true)

  const toggleCompletedTrainees = () => {
    setShouldShowCompleted(!shouldShowCompleted)
  }

  return (
    <Div width="100%" height="100%" overflow="auto">
      <Div display="flex" justifyContent="space-between">
        <Text
          content="PEOPLE I'M TRAINING"
          type="body"
          style={{ color: '#C0C0C0' }}
          weight="bold"
          outset={{ left: 'x1', bottom: 'x2' }}
        />
        <Div display="flex" columnGap="x1">
          <Text
            content="Show completed"
            type="caption"
            weight="bold"
            style={{ color: '#C0C0C0' }}
          />
          <Toggle defaultValue={true} callBack={toggleCompletedTrainees} />
          <Spacer space="x1" orientation="horizontal" />
        </Div>
      </Div>
      <Div
        css={css`
          display: grid;
          gap: 8px;
          grid-template-columns: 1fr;
          overflow: auto;
          scrollbar-width: thin;
          align-content: start;
          @media (min-width: 1024px) {
            height: 416px;
          }
        `}
      >
        {prompitingsCoachTrainees.value &&
          prompitingsCoachTrainees.value
            .filter(trainee => {
              const currentDate = new Date()
              const expireyDate = new Date(
                trainee.promptingsCoach?.dateTrainingExpires ?? '',
              )
              // Keep trainees that have completed coaching
              // Remove trainees who's expirey time has passed
              return trainee.promptingsCoach?.dateTrainingCompleted
                ? true
                : expireyDate >= currentDate
            })
            .map((trainee, index) => {
              if (
                !shouldShowCompleted &&
                trainee.promptingsCoach?.dateTrainingCompleted
              )
                return undefined
              return <TraineeCard traineeInfo={trainee} key={index} />
            })}
      </Div>
    </Div>
  )
}
