import React from 'react'

export const CompleteBadge: React.FC = () => {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_125_15987)">
        <path
          d="M31.0955 20.174C31.4317 19.9782 31.7348 19.7402 32 19.4694V25.0003C32 26.4291 31.2431 27.7491 30.0152 28.4639L17.9839 35.464C16.7565 36.1784 15.2431 36.1784 14.0161 35.464L1.9848 28.4639C0.756911 27.7495 0 26.4291 0 25.0003V19.4694C0.265227 19.7406 0.568785 19.9786 0.904505 20.174L13.8636 27.714C15.1849 28.4839 16.8151 28.4839 18.1372 27.714L31.0959 20.174H31.0955Z"
          fill="#303644"
        />
        <path
          d="M32 10.9998V18.3391C31.7348 18.6104 31.4317 18.848 31.0955 19.0438L18.1368 26.5838C16.8151 27.3537 15.1849 27.3537 13.8632 26.5838L0.904505 19.0442C0.568785 18.8484 0.265227 18.6104 0 18.3396V11.0002C0 9.57101 0.756911 8.25055 1.9848 7.53615L14.0161 0.536042C15.2435 -0.178355 16.7569 -0.178355 17.9844 0.536042L30.0156 7.5366C31.2435 8.25099 32.0004 9.57101 32.0004 10.9998H32Z"
          fill="#8CC671"
        />
        <path
          d="M29.1543 23.8428V12.1576C29.1543 11.0369 28.5609 10.0015 27.5982 9.44119L17.5566 3.59859C16.5935 3.03826 15.407 3.03826 14.4439 3.59859L4.40225 9.44075C3.43915 10.0011 2.84613 11.0365 2.84613 12.1571V23.8423C2.84613 24.963 3.43915 25.9984 4.40225 26.5587L14.4439 32.4013C15.407 32.9617 16.5935 32.9617 17.5566 32.4013L27.5982 26.5587C28.5613 25.9984 29.1543 24.963 29.1543 23.8423V23.8428Z"
          fill="white"
        />
        <mask
          id="mask0_125_15987"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="3"
          width="28"
          height="30"
        >
          <path
            d="M29.1543 23.8428V12.1576C29.1543 11.0369 28.5609 10.0015 27.5982 9.44119L17.5566 3.59859C16.5935 3.03826 15.407 3.03826 14.4439 3.59859L4.40225 9.44075C3.43915 10.0011 2.84613 11.0365 2.84613 12.1571V23.8423C2.84613 24.963 3.43915 25.9984 4.40225 26.5587L14.4439 32.4013C15.407 32.9617 16.5935 32.9617 17.5566 32.4013L27.5982 26.5587C28.5613 25.9984 29.1543 24.963 29.1543 23.8423V23.8428Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_125_15987)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.5006 12.9142C23.8833 13.3191 23.8677 13.96 23.4659 14.3457L14.0888 23.3457C13.7008 23.7181 13.091 23.7181 12.703 23.3457L8.68424 19.4885C8.28243 19.1028 8.26692 18.462 8.6496 18.057C9.03227 17.6521 9.66822 17.6365 10.07 18.0221L13.3959 21.2143L22.0802 12.8793C22.482 12.4936 23.1179 12.5093 23.5006 12.9142Z"
            fill="#8CC671"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_125_15987">
          <rect width="32" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
