import {
  Button,
  Checkbox,
  Div,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { ProfileAvatar } from 'src/chrome'
import { useAccount, useSelector } from 'src/hooks'
import CertificationIcon from './certificationIcon'

interface CoachDetailsType {
  name: string
  id: string
  coachImage: string
  featuredBadgeGraphic?: string
  coachingStatus: string
  memberSince: number
  badgesEarnedGraphics?: string[]
  coachActions?: {
    email: {
      isPublic: boolean
      setIsPublic: (isPublic: boolean) => void
    }
    phoneNumber: {
      isPublic: boolean
      setIsPublic: (isPublic: boolean) => void
    }
  }
  traineeActions?: {
    sendMail: (() => void) | null
    sendSMS: (() => void) | null
  }
}

export const CoachDetails: React.FC<CoachDetailsType> = props => {
  const {
    name,
    id,
    coachImage,
    featuredBadgeGraphic,
    coachingStatus,
    memberSince,
    badgesEarnedGraphics,
    coachActions,
    traineeActions,
  } = props

  const account = useAccount()
  const { isMobile } = useSelector(state => state.window)

  // Custom Component Colors
  const lightGray = { color: '#C0C0C0' }
  const mediumGray = { color: '#A3A3A3' }
  const darkPurple = { color: '#363239' }
  const lightPurple = '#9245B0'
  const titlePurple = { color: lightPurple }
  const barPurple = { backgroundColor: lightPurple }

  const hasContactInfo =
    coachActions || traineeActions?.sendMail || traineeActions?.sendSMS

  return (
    <Div height="100%">
      <Div
        display="flex"
        justifyContent="space-between"
        outset={{ bottom: 'x2', horizontal: 'x2' }}
      >
        {coachActions ? (
          <Flex flexDirection="column">
            <Text
              type="body"
              style={lightGray}
              content="MY INFORMATION"
              weight="bold"
            />
            <Div display="flex" alignItems="center">
              <Icon
                name="information"
                size="xSmall"
                primaryColor="default"
                secondaryColor="primaryBrand"
              />
              <Spacer space="x_5" orientation="horizontal" />
              <Text
                type="footnote"
                style={{ ...mediumGray, fontSize: '0.5rem' }}
                content="Your trainees will see this information on their account page."
                weight="bold"
              />
            </Div>
          </Flex>
        ) : (
          <Text
            type="body"
            style={lightGray}
            content="YOUR COACH"
            weight="bold"
          />
        )}
      </Div>
      <Div
        inset="x4"
        borderRadius="medium"
        style={{ backgroundColor: '#FFF' }}
        height="calc(100% - 3rem)"
      >
        <Div display="flex" justifyContent="flex-start">
          <Div position="relative" display="flex" alignItems="center">
            <Div>
              <ProfileAvatar
                name={name}
                image={coachImage}
                size={85}
                style={
                  {
                    fontSize: '2.8rem',
                    color: '#fff',
                    letterSpacing: 1,
                  } as React.CSSProperties
                }
                isEditable={false}
              />
            </Div>
            {featuredBadgeGraphic && (
              <Div position="absolute" bottom="15px" right="-8px">
                <Image
                  image={{ url: featuredBadgeGraphic }}
                  width="32px"
                  height="36px"
                  isActive={false}
                />
              </Div>
            )}
          </Div>
          <Spacer space="x4" orientation="horizontal" />
          <Div>
            <Text
              type="title"
              style={darkPurple}
              content={name}
              weight="bold"
            />
            <Text
              type="largeBody"
              content={`Coach ID: ${id}`}
              weight="semiBold"
              style={darkPurple}
            />
            <Div display="flex" alignItems="center">
              <CertificationIcon />
              <Spacer space="x_5" orientation="horizontal" />
              <Text
                type="body"
                weight="semiBold"
                style={mediumGray}
                content={coachingStatus}
              />
            </Div>
            <Text
              type="footnote"
              style={{ color: '#A7A4A4' }}
              content={`Member since ${memberSince}`}
            />
          </Div>
        </Div>
        <Spacer space="x5" />
        <Div
          inset="x3"
          style={{ backgroundColor: '#F9F9F9', borderRadius: '8px' }}
        >
          <Text
            type="body"
            weight="bold"
            style={titlePurple}
            content="Badges Earned"
          />
          <Div
            width="60px"
            height="2px"
            style={barPurple}
            outset={{ bottom: 'x2' }}
          />
          <Div
            display="grid"
            style={{
              gridTemplateColumns: isMobile
                ? '1fr 1fr 1fr 1fr'
                : '1fr 1fr 1fr 1fr 1fr',
              gridAutoFlow: 'row',
              rowGap: '12px',
            }}
          >
            {badgesEarnedGraphics?.map((graphic, index) => {
              return (
                <Image
                  key={`${graphic}-${index}`}
                  image={{ url: graphic }}
                  width="70px"
                  height="78px"
                  isActive={false}
                />
              )
            })}
          </Div>
        </Div>
        {hasContactInfo && (
          <>
            <Spacer space="x3" />
            <Div>
              <Text
                type="body"
                weight="bold"
                style={titlePurple}
                content="Contact info"
              />
              <Div width="100px" height="2px" style={barPurple} />
              <Spacer space="x1_5" />
              <Div
                display="flex"
                justifyContent="flex-start"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile && traineeActions ? 'center' : 'initial'}
                rowGap="x2"
                flexWrap="wrap"
              >
                {coachActions && (
                  <>
                    <Div>
                      <Text type="footnote" style={lightGray} content="Email" />
                      <Div position="relative" left="-13px">
                        <Checkbox
                          id={'cb-email'}
                          isChecked={coachActions.email.isPublic}
                          onChange={coachActions.email.setIsPublic}
                          label={account.email}
                        />
                      </Div>
                    </Div>
                    <Div>
                      <Text
                        type="footnote"
                        style={lightGray}
                        content="Phone Number"
                      />
                      <Div position="relative" left="-13px">
                        <Checkbox
                          id={'cb-phone'}
                          isChecked={coachActions.phoneNumber.isPublic}
                          onChange={coachActions.phoneNumber.setIsPublic}
                          label={account.phoneNumber}
                        />
                      </Div>
                    </Div>
                  </>
                )}
                {traineeActions?.sendMail && (
                  <Button
                    type="primary"
                    backgroundColorOverride={lightPurple}
                    onClick={traineeActions.sendMail}
                    title="Send me an email"
                  />
                )}
                {isMobile && traineeActions?.sendSMS && (
                  <>
                    <Spacer
                      space="x2"
                      orientation={isMobile ? 'vertical' : 'horizontal'}
                    />
                    <Button
                      type="primary"
                      backgroundColorOverride={lightPurple}
                      onClick={traineeActions.sendSMS}
                      title="SMS Message"
                    />
                  </>
                )}
              </Div>
            </Div>
          </>
        )}
      </Div>
    </Div>
  )
}
