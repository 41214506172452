import {
  Maybe,
  CardStatus,
  SentCardSendType,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  ProductionInfoFragment,
  productionInfoFragmentString,
} from '../../fragments/__generated__/ProductionInfoFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { recipientFragmentString } from '../../fragments/__generated__/RecipientFragment'

import getSentCardsRawString from '../GetSentCards.graphql'

const getSentCardsString = [
  getSentCardsRawString,
  productionInfoFragmentString,
  addressFragmentString,
  recipientFragmentString,
].join('\n\n')

export type GetSentCardsData = {
  paginatedSentCards: {
    sentCards: ProductionInfoFragment[]
    count: number
  }
}

export type GetSentCardsVariables = {
  offset: number
  limit: number
  search?: Maybe<string>
  contacts?: Maybe<string[]>
  status?: Maybe<CardStatus>
  startDate?: Maybe<string>
  endDate?: Maybe<string>
  sendType?: Maybe<SentCardSendType>
}

export type GetSentCards = Operation<GetSentCardsData, GetSentCardsVariables>

export const GetSentCards = ({
  offset,
  limit,
  search,
  contacts,
  status,
  startDate,
  endDate,
  sendType,
}: GetSentCardsVariables): GetSentCards => ({
  query: getSentCardsString,
  variables: {
    offset,
    limit,
    search,
    contacts,
    status,
    startDate,
    endDate,
    sendType,
  },
})
