import React, { Suspense } from 'react'
import {
  Anchor,
  Button,
  Div,
  Flex,
  Input,
  LoadingSpinner,
  Spacer,
  Text,
  Tooltip,
} from '@sendoutcards/quantum-design-ui'
import { useDebounce, usePersistedUserData, useState } from 'src/hooks'
import { SponsorFragment } from 'src/legacy_graphql'
import SponsorSearchResult from 'src/onboarding/components/SponsorSearchResult'
import {
  sendoutcardsPromotionsGenealogyId,
  sendoutcardsPromotionsUserId,
} from 'src/app/constants'

const marketingCopy = {
  affiliate: {
    title: 'Who introduced you to Promptings or any of its six companies?',
    body:
      'We provide compensation based on the sharing efforts of our Affiliates. Please find the Affiliate who shared our products with you to ensure they are given credit.',
    searchPlaceHolder: 'Search for your sponsor',
    anchorText: 'No one referred me',
  },
  customer: {
    title: 'Who showed you this product?',
    body:
      'We celebrate and give back to the people who invite others to experience our product.',
    searchPlaceHolder: 'Search for your friend',
    anchorText: 'I found your website on my own',
  },
}

type FindMySponsorProps = {
  onSelectedSponsor: (sponsorId: string, sponsorGenId: string) => void
  onKeepMyCurrentSponsor?: () => void
  isSponsorRequired?: true
  isAffiliateJoining?: boolean
  shouldHideHeading?: boolean
}

const FindMySponsor = (props: FindMySponsorProps) => {
  const {
    onSelectedSponsor,
    onKeepMyCurrentSponsor,
    isSponsorRequired,
    isAffiliateJoining,
    shouldHideHeading,
  } = props
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const [selectedSponsor, setSelectedSponsor] = useState<SponsorFragment>()
  const [isHovering, setIsHovering] = useState(false)
  const { account } = usePersistedUserData()

  const { title, body, searchPlaceHolder, anchorText } = isAffiliateJoining
    ? marketingCopy.affiliate
    : marketingCopy.customer

  const handleNoSponsor = () =>
    onSelectedSponsor(
      sendoutcardsPromotionsUserId,
      sendoutcardsPromotionsGenealogyId.toString(),
    )

  return (
    <Flex flexDirection={'column'} width={'100%'} height={'100%'}>
      {!shouldHideHeading && (
        <>
          <Text type="subtitle" weight="bold">
            {title}
          </Text>
          <Spacer space="x1" />
          <Text type="body" weight="regular">
            {body}
          </Text>
          <Spacer space="x2" />
        </>
      )}
      <Div width={'100%'}>
        <Input
          type="search"
          onChange={setSearch}
          value={search}
          icon={'search'}
          placeholder={searchPlaceHolder}
          isFullWidth={true}
        />
      </Div>
      <Spacer space="x2" />
      <Div width="100%" maxHeight="310px" overflow="auto">
        <Suspense
          fallback={
            <Flex
              justifyContent="center"
              alignItems="center"
              height="205px"
              width="100%"
              overflow="hidden"
            >
              <LoadingSpinner size="large" />
            </Flex>
          }
        >
          <SponsorSearchResult
            search={debouncedSearch}
            selectedSponsor={selectedSponsor}
            setSelectedSponsor={setSelectedSponsor}
          />
        </Suspense>
      </Div>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Spacer space="x2" />
        <Div>
          <Flex
            onHoverStart={() => setIsHovering(true)}
            onHoverEnd={() => setIsHovering(false)}
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Button
              title={
                search.length === 0
                  ? 'Search for a Sponsor'
                  : selectedSponsor
                  ? `Choose ${selectedSponsor.firstName}`
                  : `Choose a Sponsor`
              }
              onClick={() =>
                onSelectedSponsor(
                  selectedSponsor?.id ?? '',
                  selectedSponsor?.genealogyId.toString() ?? '',
                )
              }
              disabled={
                !selectedSponsor ||
                (account && selectedSponsor.id === account.id)
              }
              fullWidth={true}
              hover={true}
              type={'primary'}
            />
            {isHovering && account && selectedSponsor?.id === account.id && (
              <Flex
                animate={isHovering ? 'hoverOn' : 'hoverOff'}
                initial={'hoverOff'}
                transition={{ ease: 'easeIn', duration: 0.2 }}
                bottom="50px"
                position="absolute"
                zIndex={50}
              >
                <Tooltip title="" direction="bottom">
                  <Text type="footnote">
                    Sorry! You can't select yourself as a sponsor.
                  </Text>
                </Tooltip>
              </Flex>
            )}
          </Flex>
          <Spacer space="x2" />
          {account &&
            account.sponsor &&
            account.sponsor.genealogyId !== sendoutcardsPromotionsGenealogyId &&
            onKeepMyCurrentSponsor && (
              <Button
                title={`Keep ${account.sponsor.firstName} ${account.sponsor.lastName} as my
                  sponsor`}
                onClick={() => onKeepMyCurrentSponsor()}
                fullWidth={false}
                hover={true}
                outlined={true}
                type={'secondary'}
              />
            )}
        </Div>
        <Spacer space="x2" />
        {!isSponsorRequired &&
          (account === undefined ||
            account.sponsor?.genealogyId ===
              sendoutcardsPromotionsGenealogyId) && (
            <Anchor
              id="no-sponsor-button"
              size="small"
              color="primaryBody"
              isDecorated={true}
              onClick={() => handleNoSponsor()}
            >
              {anchorText}
            </Anchor>
          )}
      </Flex>
    </Flex>
  )
}

export default FindMySponsor
