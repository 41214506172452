import React from 'react'
import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer'
import { SaleorOrderFragment } from 'src/saleor_graphql/generated/graphql'

export const PDFDocument = (order: SaleorOrderFragment) => {
  const styles = StyleSheet.create({
    page: {
      width: '100%',
      flexDirection: 'column',
      rowGap: '16px',
      padding: 30,
      backgroundColor: '#FFF',
    },
    header: {
      fontSize: 20,
      marginBottom: 10,
      color: '#059669',
    },
    bodyText: {
      fontSize: 16,
      color: '#171717',
    },
    orderNumber: {
      fontSize: 14,
      color: '#171717',
      paddingTop: 8,
    },
    label: {
      color: '#000',
      fontWeight: 700,
      paddingTop: 16,
    },
    products: {
      color: '#171717',
      fontWeight: 700,
      fontSize: 16,
      marginTop: 10,
      marginBottom: 10,
    },
    totals: {
      width: '100%',
      textAlign: 'right',
      marginTop: 4,
      marginBottom: 4,
    },
  })
  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.header}>
          Awesome! Way to go, your Order is Confirmed
        </Text>
        <Text style={styles.bodyText}>
          Hi, {order?.user?.firstName} {order?.user?.lastName}. Thank you for
          your order, and don't worry! We have sent you all the details to your
          email along with the invoice of this order.
        </Text>
        <Text style={styles.label}>Order No.</Text>
        <Text style={styles.orderNumber}>{order.id}</Text>
        <Text style={styles.label}>Purchased Product(s):</Text>
        {order.lines.map((line, index) => (
          <Text key={index} style={styles.products}>
            {line.variant?.name} - Quantity: {line.quantity} - Total: $
            {line.variant?.pricing?.price?.gross.amount}
          </Text>
        ))}
        <Text style={styles.totals}>Tax: ${order.total.tax.amount}</Text>
        <Text style={styles.totals}>
          Total Price: ${order.total.gross.amount}
        </Text>
      </Page>
    </Document>
  )
}
