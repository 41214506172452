import {
  Button,
  ConfirmDialog,
  Div,
  Flex,
  Input,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { List } from 'immutable'
import React from 'react'
import { useDebounce, useEffect, useSelector, useState } from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'
import { AffiliateFormInfo } from 'src/marketing/plans/components/AffiliateForm/AffiliateForm'

interface IdentificationNumberProps {
  affiliateInfo: AffiliateFormInfo
  onFinishAccount: (info: AffiliateFormInfo) => void
  onBack: (info: AffiliateFormInfo) => void
  updateIdentificationNumber?: Memoized<(info: AffiliateFormInfo) => void>
}

interface PaymentMethodState {
  govId1: string
  govId2: string
  company: string
}

const formatGovID = (govID: string, type: 'EIN' | 'SSN'): string => {
  const idString = govID.replaceAll('-', '')
  if (type === 'EIN' && idString.length > 2) {
    return List(idString).insert(2, '-').join('')
  } else if (type === 'SSN' && idString.length > 5) {
    return List(idString).insert(3, '-').insert(6, '-').join('')
  } else if (type === 'SSN' && idString.length > 3) {
    return List(idString).insert(3, '-').join('')
  } else {
    return govID
  }
}

const IdenfiticationNumber: React.FC<IdentificationNumberProps> = props => {
  const {
    affiliateInfo,
    onFinishAccount,
    onBack,
    updateIdentificationNumber,
  } = props

  const isMinimal = !!updateIdentificationNumber
  const [details, setDetails] = useState<PaymentMethodState>({
    govId1: affiliateInfo?.govId1 ?? '',
    govId2: affiliateInfo?.govId2 ?? '',
    company: affiliateInfo?.user.company ?? '',
  })

  const [errorMessage, setErrorMessage] = useState<string>()

  const [hasCompany, setHasCompany] = useState(false)

  const debouncedAffiliateInfo = useDebounce(details, 500)

  useEffect(() => {
    updateIdentificationNumber?.({
      ...affiliateInfo,
      govId1: debouncedAffiliateInfo.govId1,
      govId2: debouncedAffiliateInfo.govId2,
      user: {
        ...affiliateInfo.user,
        company: debouncedAffiliateInfo.company,
      },
    })
  }, [debouncedAffiliateInfo, updateIdentificationNumber, affiliateInfo])

  useEffect(() => {
    setHasCompany(affiliateInfo.user.company !== '')
  }, [setHasCompany, affiliateInfo])

  const isUS = affiliateInfo.user.country === 'United States'

  const formattedGovID1 = isUS
    ? formatGovID(details.govId1, hasCompany ? 'EIN' : 'SSN')
    : details.govId1

  const onBack2 = () => {
    onBack({
      ...affiliateInfo,
      govId1: details.govId1,
      govId2: details.govId2,
      user: {
        ...affiliateInfo.user,
        company: details.company,
      },
    })
  }

  const onSubmit = () => {
    if (isDisabled) {
      return
    }
    if (affiliateInfo.user.country !== 'United States' && !details.govId2) {
      setErrorMessage(
        'As an International Affiliate, a second form of government ID is required. Please add the Gov Id 2 and try again.',
      )
      return
    }
    if (
      affiliateInfo.user.country !== 'United States' &&
      details.govId2 === details.govId1
    ) {
      setErrorMessage(
        'As an International Affiliate, a second form of government ID should be different from the first form of goverment ID.',
      )
      return
    }
    onFinishAccount({
      ...affiliateInfo,
      govId1: formattedGovID1,
      govId2: details.govId2,
      user: {
        ...affiliateInfo.user,
        company: hasCompany ? details.company : '',
      },
    })
    return
  }

  const handleChange = (
    name: keyof PaymentMethodState,
    value: string | boolean,
  ) => {
    setDetails({ ...details, [name]: value })
  }

  const onDialogClose = () => {
    setErrorMessage(undefined)
  }

  const isMobile = useSelector(state => state.window.isMobile)

  const isDisabled = isUS
    ? hasCompany
      ? !details.govId1 || !details.company
      : !details.govId1 || details.govId1.length !== 9
    : !details.govId1 || !details.govId2

  const instructions = isUS
    ? hasCompany
      ? 'Enter the EIN associated with your business for tax purposes.'
      : 'Enter your Social Security Number for tax purposes.'
    : 'Provide two forms of identification This can include your driver’s license, passport, health card, etc.'

  const placeholder1 = isUS
    ? hasCompany
      ? 'Employer Identification Number'
      : 'Social Security Number'
    : 'Government ID 1'

  return (
    <Div data-step="id-number-step">
      <Text type={isMinimal ? 'caption' : 'body'}>{instructions}</Text>
      <Spacer space="x2" />
      <Flex width="100%" flexDirection="column">
        <Input
          onChange={govId1 =>
            handleChange(
              'govId1',
              isUS ? govId1.replaceAll(/[^0-9]/g, '').slice(0, 9) : govId1,
            )
          }
          type="text"
          name="govId1"
          value={formattedGovID1}
          placeholder={placeholder1}
          focusColor="primaryBrand"
          isFullWidth={true}
        />
        <Spacer space={'x1'} />
        {!isUS && (
          <>
            <Input
              onChange={govId2 => handleChange('govId2', govId2)}
              type="text"
              name="govId2"
              value={details.govId2}
              placeholder={'Government ID 2'}
              focusColor="primaryBrand"
              isFullWidth={true}
            />
            <Spacer space="x1" />
          </>
        )}
        {hasCompany && isUS && (
          <>
            <Input
              onChange={company => handleChange('company', company)}
              type="text"
              name="company"
              value={details.company}
              placeholder="Company Name"
              focusColor="primaryBrand"
              isFullWidth={true}
            />
            <Spacer space="x1" />
          </>
        )}
        {isUS && (
          <Flex width="100%" flexDirection="row" justifyContent="flex-start">
            <Button
              type="shadow"
              title={
                hasCompany ? 'Set Up as an Individual' : 'Set Up as a Company'
              }
              onClick={() => setHasCompany(!hasCompany)}
            />
          </Flex>
        )}
      </Flex>
      <ConfirmDialog
        title={'Card Error'}
        description={errorMessage ?? 'There was an error. Please try again.'}
        isOpen={!!errorMessage}
        accept={{ title: 'Ok', onClick: onDialogClose }}
      />
      {!isMinimal && (
        <>
          <Flex width="100%" flexDirection="column">
            <Spacer space={isMobile ? 'x1' : 'x4'} />
            <Div width="100%">
              <Button
                fullWidth={true}
                type={'primary'}
                onClick={() => onSubmit()}
                title="Continue"
                disabled={isDisabled}
              />
            </Div>
            <Spacer space={isMobile ? 'x1' : 'x2'} />
            <Div width="100%" id="back_btn">
              <Button
                fullWidth={true}
                type={'accent'}
                outlined={true}
                onClick={() => onBack2()}
                title={'Back to Profile Setup'}
              />
            </Div>
          </Flex>
          <Spacer space="x2" />
        </>
      )}
    </Div>
  )
}

export default IdenfiticationNumber
