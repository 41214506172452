import React from 'react'
import {
  Anchor,
  ConfirmDialog,
  Dialog,
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  useActions,
  useCallback,
  useSelector,
  useState,
  useSubscriptions,
  useVertical,
} from 'src/hooks'
import { UiCard } from 'src/chrome'

import { LEGACY_SITE_URL } from 'src/app/api'
import { ButtonColorChoices } from 'src/theme/buttonColors'
import { SubscriptionType } from 'src/user/fragments'
import { CurrentPlanCard } from './components/CurrentPlanCard'
import { PaymentSettingsCard } from './components/PaymentSettings/PaymentSettingsCard'
import { FullAccessFeatureType, Label } from 'src/graphql/generated/graphql'
import {
  useAccountQuery,
  useCancelStripeSubscription,
  useUpdateAccount,
} from 'src/react_query'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { AccountSubscriptionChanger } from 'src/SubscriptionChanger/AccountSubscriptionChanger'
import { Addons } from 'src/dashboard/components'
import {
  defaultSubscriptionIds,
  offeredAffiliateStripeSkus,
  premiereSubscriptionIds,
} from 'src/helpers/supportedUpsaleProducts'
/**
 *
 * TODO:
 *  1. My Subscriptions Component
 * [] - New Designs for My Subscriptions
 * [] - Wire up route
 */

export const PlansAndPaymentView = () => {
  const [isChangingSubscription, setIsChangingSubscription] = useState(false)
  const [isChangingAffiliate, setIsChangingAffiliate] = useState(false)

  const [subscriptionCancelationId, setSubscriptionCancelationId] = useState<
    string | undefined
  >(undefined)
  const [
    isRenewAffiliateConfirmDialogueOpen,
    setIsRenewAffiliateConfirmDialogueOpen,
  ] = useState(false)
  const [isShowingChangeCancelation, setIsShowingChangeCancelation] = useState(
    false,
  )
  const {
    mutate: cancelSubscription,
    isLoading,
  } = useCancelStripeSubscription()
  const vertical = useVertical()
  const isMobile = useSelector(state => state.window.isMobile)
  const actions = useActions()

  const updateAccountMutation = useUpdateAccount()
  // const queryParams = useQueryParams()
  const { data: account, isLoading: isAccountLoading } = useAccountQuery()

  const changeSubscription = useCallback(
    async (subscriptionId: string, shouldCancel?: boolean) => {
      const sub = {
        id: subscriptionId,
        cancelAtPeriodEnd: shouldCancel ?? false,
      }

      try {
        const {
          updateAccount: { account: updatedAccount },
        } = await updateAccountMutation.mutateAsync({
          account: {},
          subscription: sub,
        })
        actions.updatedAccount(updatedAccount)
      } catch (error) {
        console.error(error?.toString() ?? 'Failed to change subscription.')
      } finally {
        setIsChangingSubscription(false)
      }
    },
    [actions, updateAccountMutation],
  )

  const currentPlan = account?.plan

  const currentPlanSubscription = account?.subscriptions.find(
    (sub: SubscriptionType) => sub.sku === currentPlan?.stripeId,
  )

  const currentAffiliateSubscription = account?.subscriptions.find(
    (sub: SubscriptionType) =>
      offeredAffiliateStripeSkus.map(sku => sku.id).includes(sub.sku),
  )

  const { newPlan, isInPromptingsGracePeriod } = useSubscriptions()
  const { hasFeatureAccess } = useFeatureAccess([
    FullAccessFeatureType.PremiumSubscriptionOptions,
  ])

  // TODO: Write description based on currentPlan id
  // const allSubscriptions = [
  //   ...defaultSubscriptionIds,
  //   ...premiereSubscriptionIds,
  // ]
  // console.log(allSubscriptions)

  const subscriptionIds = hasFeatureAccess
    ? [...defaultSubscriptionIds, ...premiereSubscriptionIds]
    : defaultSubscriptionIds

  const changePlanId =
    newPlan && subscriptionIds.find(sub => sub.id === newPlan.id)

  if (isAccountLoading) {
    return <LoadingSpinner size="large" />
  }
  return account ? (
    <Flex width="100%" flexDirection="row" flexWrap="wrap">
      <Flex flexDirection={'column'} width="100%">
        <Text
          type="largeBody"
          weight="bold"
          color="primaryHeading"
          content="My Plans"
          style={{ letterSpacing: '.3px', marginLeft: 4 }}
        />
        <Spacer space="x2_5" />
        <Flex
          flexDirection="column"
          maxWidth={'800px'}
          outset={{ bottom: 'x3' }}
          rowGap="x3"
        >
          {currentPlan && !currentPlanSubscription && (
            <CurrentPlanCard
              title={currentPlan.title}
              price={currentPlan.price.description}
              changeAction={{
                title: 'Change Plan',
                onClick: () => setIsChangingSubscription(true),
              }}
              newPlan={changePlanId ? newPlan : undefined}
            />
          )}
          {currentPlan && currentPlanSubscription && (
            <CurrentPlanCard
              title={currentPlan.title}
              price={currentPlan.price.description}
              currentSubscription={currentPlanSubscription}
              changeAction={{
                title: 'Change Plan',
                onClick: () => setIsChangingSubscription(true),
              }}
              renewAction={
                currentPlanSubscription.isSetToCancel
                  ? {
                      title: 'Cancel Scheduled Change',
                      onClick: () => setIsShowingChangeCancelation(true),
                    }
                  : undefined
              }
              cancelAction={
                !currentPlanSubscription.isSetToCancel || newPlan
                  ? {
                      title: 'Cancel',
                      onClick: () =>
                        setSubscriptionCancelationId(
                          currentPlanSubscription.id,
                        ),
                    }
                  : undefined
              }
              newPlan={changePlanId ? newPlan : undefined}
            />
          )}

          {currentAffiliateSubscription && (
            <CurrentPlanCard
              title={currentAffiliateSubscription.name}
              price={`$${(currentAffiliateSubscription.price / 100).toFixed()}`}
              currentSubscription={currentAffiliateSubscription}
              recurrence={
                currentAffiliateSubscription.sku === 'GS0001' ? 'month' : 'year'
              }
              changeAction={
                currentAffiliateSubscription.sku === 'GS00001' ||
                currentAffiliateSubscription.sku === '14757'
                  ? {
                      title: 'Upgrade & Save',
                      onClick: () => setIsChangingAffiliate(true),
                    }
                  : undefined
              }
              renewAction={
                currentAffiliateSubscription.isSetToCancel &&
                currentAffiliateSubscription?.sku !== 'GS00016'
                  ? {
                      title: 'Cancel Scheduled Change',
                      onClick: () =>
                        setIsRenewAffiliateConfirmDialogueOpen(true),
                      isDisabled: isInPromptingsGracePeriod,
                    }
                  : undefined
              }
              cancelAction={
                !currentAffiliateSubscription.isSetToCancel
                  ? {
                      title: 'Cancel',
                      onClick: () =>
                        setSubscriptionCancelationId(
                          currentAffiliateSubscription.id,
                        ),
                    }
                  : undefined
              }
            />
          )}
          <PaymentSettingsCard />
          {/* <PurchasesCard /> */}
        </Flex>
        <UiCard
          title={'My Account Purchases'}
          actions={[
            ...(account.isAffiliate
              ? [
                  {
                    title: 'Promptings PRO',
                    id: 'gs_pro_btn',
                    onClick: () => {
                      window.open(account.promptingProLoginUrl, '_blank')
                    },
                  },
                ]
              : []),

            ...(!account.labels.includes(Label.ShouldHideRevolutionOffice)
              ? [
                  {
                    title: account.isAffiliate
                      ? 'Revolution Office'
                      : 'Invoice History',
                    id: 'revolution_office_btn',
                    onClick: () => {
                      window.open(`${LEGACY_SITE_URL}/bd/backoffice`, '_blank')
                    },
                  },
                ]
              : []),
          ]}
          buttonColor={vertical.id as ButtonColorChoices}
        >
          <Addons />

          <AccountSubscriptionChanger
            shouldHideCurrentPlan={true}
            subscriptionPlanIds={[{ id: '165' }]}
            isOpen={isChangingAffiliate}
            onClose={() => setIsChangingAffiliate(false)}
          />

          <AccountSubscriptionChanger
            subscriptionPlanIds={subscriptionIds}
            isOpen={isChangingSubscription}
            onClose={() => setIsChangingSubscription(false)}
          />
          {isMobile && (
            <Flex flexDirection="row" justifyContent="center">
              <Anchor
                isDecorated={true}
                color="anchorBlue"
                onClick={() => actions.openPricing()}
              >
                Pricing
              </Anchor>
            </Flex>
          )}
        </UiCard>
        <ConfirmDialog
          isOpen={isRenewAffiliateConfirmDialogueOpen}
          title={'Renew your Affiliate subscription'}
          description={
            'Add the Affiliate subscription of $14/month to my account so I can start my business today.'
          }
          accept={{
            title: 'Confirm renewal',
            onClick: () => {
              changeSubscription(currentAffiliateSubscription?.id ?? '', false)
              cancelSubscription(
                { id: currentAffiliateSubscription?.id ?? '' },
                {
                  onSuccess(data) {
                    // TODO: React Query Refactor, once redux account is removed, invalidate the account query here
                    actions.updatedAccount(
                      data.cancelStripeSubscription.account,
                    )
                  },
                  onError(error) {
                    console.log(error)
                  },
                },
              )
              setIsRenewAffiliateConfirmDialogueOpen(false)
              setIsChangingSubscription(false)
            },
          }}
          decline={{
            title: 'Cancel',
            onClick: () => {
              setIsRenewAffiliateConfirmDialogueOpen(false)
            },
          }}
          zIndex={9000}
          primaryAction={'accept'}
          onClose={() => setIsRenewAffiliateConfirmDialogueOpen(false)}
        />
      </Flex>
      <ConfirmDialog
        isOpen={!!subscriptionCancelationId}
        title="Cancel Subscription"
        description="Are you sure you want to cancel this subscription?"
        decline={{
          title: 'Keep Subscription',
          onClick: () => setSubscriptionCancelationId(undefined),
        }}
        accept={{
          title: 'Cancel Subscription',
          onClick: () => {
            if (!subscriptionCancelationId) return
            changeSubscription(subscriptionCancelationId, true)
            setSubscriptionCancelationId(undefined)
          },
        }}
        zIndex={1}
      />
      <ConfirmDialog
        isOpen={isShowingChangeCancelation}
        title={`Keep my ${currentPlan?.price.description} ${currentPlan?.title} Subscription`}
        description="Click below to keep your plan and continue utilizing the features you enjoy."
        decline={{
          title: 'Close',
          onClick: () => setIsShowingChangeCancelation(false),
        }}
        accept={{
          title: 'Keep Current Plan!',
          onClick: () => {
            changeSubscription(currentPlanSubscription?.id ?? '', false)
            setIsShowingChangeCancelation(false)
          },
        }}
        primaryAction="accept"
        zIndex={1}
      />
      <Dialog isOpen={isLoading}>
        <Text
          type="largeBody"
          content={'Confirming your Subscription Changes...'}
        />
      </Dialog>
    </Flex>
  ) : (
    <Text type="body" content="login to view account" />
  )
}
