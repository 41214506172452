import React from 'react'
import CardPicker from 'src/card_picker/CardPicker'
import { useActions } from 'src/hooks'
import { AddCampaignCardRoute } from '../../routes/AddCampaignCardRoute'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'
import { buildInput } from '../../utils'
import { CampaignFragment } from 'src/graphql/generated/graphql'
import { useUpdateCampaign } from 'src/react_query'

export interface CardActionsProps {
  editableCampaign: CampaignFragment
  campaignRoute: CampaignRoute
  resetRoute: () => void
}

const AddCampaignCard: React.FC<
  CardActionsProps & { route: AddCampaignCardRoute }
> = props => {
  const { editableCampaign, campaignRoute, resetRoute, route } = props

  const actions = useActions()
  const { mutateAsync: updateCampaign } = useUpdateCampaign()
  const handleAdd = async (cardId: string) => {
    if (editableCampaign) {
      const campaignInput = buildInput(editableCampaign)

      await updateCampaign({
        campaign: {
          ...campaignInput,
          lines: (campaignInput.lines || []).concat([
            {
              card: cardId,
              giftVariation: undefined,
              sendDelay: null,
            },
          ]),
        },
      })
    }
    resetRoute()
  }

  return (
    <CardPicker
      onAfterSave={handleAdd}
      context={{
        headerText: `Campaign: ${editableCampaign?.name}`,
        headerSubText: `Adding card #${
          editableCampaign?.lines && editableCampaign?.lines?.length + 1
        }`,
        lines: editableCampaign?.lines,
        footerLabel: () => (
          <b>
            {editableCampaign?.lines.length} card
            {editableCampaign?.lines &&
              editableCampaign.lines.length > 1 &&
              's'}
          </b>
        ),
      }}
      catalogSubroute={route.subroute}
      saveButtonLabel="Add to campaign"
      onClose={resetRoute}
      onCatalogNavigate={subroute =>
        actions.openCampaigns(
          CampaignRoute(
            campaignRoute.campaignId,
            AddCampaignCardRoute(subroute),
          ),
        )
      }
    />
  )
}

export default AddCampaignCard
