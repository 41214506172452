import React from 'react'
import {
  Alert,
  Confirm,
  DefaultError,
  ProgressModal,
  SuspenseBoundary,
  Transition,
} from 'src/chrome'

import { useActions, useSelector } from 'src/hooks'
import {
  ContactActionBar,
  GroupAddCreateModal,
  GroupEditForm,
  GroupMembers,
  Groups,
  SelectCampaignModal,
} from '../components'
import GroupHeader from '../components/GroupHeader/GroupHeader'
import styles from './contactManager.module.scss'
import { GroupsRoute } from '../routes/GroupsRoute'
import { Steps } from './types'
import EmptySearch from '../components/EmptySearch/EmptySearch'
import RelationshipManagerCore from './RelationshipManagerCore'
import Hooks from './GroupHooks'
import { ConfirmDialog } from '@sendoutcards/quantum-design-ui'

const GroupManagerContent: React.FC<{}> = () => {
  const hooks = Hooks()
  const { step } = hooks

  const actions = useActions()

  const route = useSelector(state => state.route)
  const isNewGroupModalOpen =
    route.subroute.path === GroupsRoute.path
      ? route.subroute.isNewGroupModalOpen
      : null

  return (
    <RelationshipManagerCore
      wrapperId="contact_manager"
      isLoading={hooks.isLoading}
      paginationSetPage={hooks.setPage}
      paginationCurrent={hooks.page}
      paginationTotalCount={hooks.allGroups.length}
    >
      <GroupHeader
        onAdd={hooks.actions.toggleIsNewGroupModalOpen}
        onOpenContacts={() => actions.openRelationshipManager()}
        filters={hooks.search.filters}
        setTerms={hooks.setTerms}
        setFilters={hooks.setFilters}
        clearSearch={hooks.clearSearch}
        onImport={actions.openContactsImporter}
      />
      <ContactActionBar
        title="Groups"
        totalCount={hooks.allGroups.length}
        selectedCount={hooks.selected.length}
        isViewingGroups={true}
        onToggleSelectAll={hooks.toggleSelectAll}
        onSendCard={hooks.sendCard}
        onViewGroups={actions.openRelationshipManager}
        onStartCampaign={hooks.selectCampaign}
        onDelete={hooks.confirmDelete}
        onExport={hooks.confirmExport}
        onManageMembers={hooks.manageMembers}
        onEditGroup={hooks.editGroup}
        selectedGroups={hooks.selectedGroups}
      />

      {isNewGroupModalOpen && (
        <GroupAddCreateModal
          isOpen={true}
          onClose={hooks.actions.toggleIsNewGroupModalOpen}
          initialMode="create"
          onCreateGroup={hooks.createGroup}
          contactsIds={[]}
          groups={[]}
        />
      )}

      {step.type === Steps.ManageMembers && (
        <GroupMembers
          onClose={hooks.resetStep}
          onRemove={hooks.confirmRemoveMembers}
          groupId={step.groupId}
        />
      )}

      {step.type === Steps.RemoveMembers &&
        (step.confirmed ? (
          <Transition message="Removing contacts..." />
        ) : (
          <Confirm
            title={'Remove contacts from group?'}
            message={'Are you sure you want to remove the selected contacts?'}
            confirmTitle={'Remove'}
            onConfirm={() => hooks.removeMembers(step.group, step.removed)}
            onDecline={hooks.resetStep}
          />
        ))}

      {step.type === Steps.EditGroup && (
        <GroupEditForm
          title={'Modify Group'}
          onClose={hooks.resetStep}
          group={step.group}
          onSubmit={hooks.updateGroup}
        />
      )}

      {step.type === Steps.SelectCampaign && (
        <SelectCampaignModal
          isOpen={true}
          onClose={hooks.resetStep}
          onSubmit={hooks.sendCampaign}
        />
      )}

      {step.type === Steps.ConfirmDelete && (
        <Confirm
          title={`Delete ${Object.keys(hooks.selectedGroups).length} groups`}
          message={'Are you sure you want to delete the selected groups?'}
          confirmTitle={'Delete'}
          onConfirm={hooks.deleteSelectedGroups}
          onDecline={hooks.resetStep}
        />
      )}

      {step.type === Steps.DeleteProgress && (
        <ProgressModal
          size={100}
          isOpen={true}
          currentCount={step.deleted}
          totalCount={Object.keys(hooks.selectedGroups).length || undefined}
          errorTitle={'The following groups could not be deleted'}
          errorList={step.errors}
          message={'groups deleted successfully'}
          onClose={step.done ? hooks.resetStep : undefined}
          isDone={step.done}
        />
      )}
      <div className={styles.body}>
        {step.type === Steps.ExportProgress && (
          <Alert
            isOpen={true}
            onClose={hooks.resetStep}
            type={'info'}
            timeout={7000}
            title={'Exporting to CSV'}
            message={'Your contacts will begin downloading shortly.'}
          />
        )}

        {step.type === Steps.ConfirmExport && (
          <ConfirmDialog
            isOpen={step.type === Steps.ConfirmExport}
            title="Export contacts"
            description="Your CSV file will be downloaded by the browser. If you selected a large number of contacts, it will take a few seconds for the download to begin."
            accept={{ title: 'Export', onClick: () => hooks.exportContacts() }}
            decline={{ title: 'Cancel', onClick: hooks.resetStep }}
          />
        )}

        {hooks.paginatedGroups.length > 0 ? (
          <div className={styles.tableContainer}>
            <Groups
              isEveryGroupSelected={hooks.allSelected}
              groups={hooks.paginatedGroups}
              onSelectAll={hooks.toggleSelectAll}
              selectedGroups={hooks.selectionMap}
              onSelect={hooks.toggleSelect}
              onEditGroup={hooks.editGroup}
              onViewGroup={hooks.manageMembers}
            />
          </div>
        ) : (
          <EmptySearch message="No groups found" onReset={hooks.reset} />
        )}
      </div>
    </RelationshipManagerCore>
  )
}

const GroupManager: React.FC<{}> = props => (
  <SuspenseBoundary
    unresolved={<Transition message={'Loading groups...'} />}
    failure={DefaultError}
  >
    <GroupManagerContent {...props} />
  </SuspenseBoundary>
)

export default GroupManager
