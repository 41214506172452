import React from 'react'
import { css } from '@emotion/core'
import { withTheme } from 'emotion-theming'

import { ColorChoices } from 'src/theme/colors'
import theme from 'src/theme/theme'
import { Text } from '@sendoutcards/quantum-design-ui'

// types __________________________________________________
type PillTabsProps = {
  tabs: string[]
  currentIndex: number
  handleClick: (name: string) => void
  size?: 'small' | 'large'
  selectedColor?: ColorChoices
  selectedFontColor?: ColorChoices
}

const fontSizes = {
  small: 12,
  large: 16,
}

const wrapper = css({
  display: 'inline-flex',
  borderRadius: '15px',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,.16)',
  '> div:first-child': {
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
  },
  '> div:last-child': {
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
  },
})
const tabStyles = (
  props: PillTabsProps,
  currentIndex: number,
  tabIndex: number,
  tabsLength: number,
) => {
  const borderRightStyles = tabIndex !== tabsLength - 1 ? borderRight : {}
  const selectedStyles = currentIndex === tabIndex ? selected(props) : {}
  return css({
    ...tab(props),
    ...borderRightStyles,
    ...selectedStyles,
  })
}
const tab = (props: PillTabsProps): React.CSSProperties => ({
  fontSize: fontSizes[props.size ? props.size : 'large'],
  fontWeight: 500,
  color: 'rgba(0, 0, 0, 0.5)',
  backgroundColor: '#fff',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 400ms',
  padding: '4px 20px',
})
const borderRight: React.CSSProperties = {
  borderRight: '1px solid #e7e7e7',
}

const selected = (props: PillTabsProps): React.CSSProperties => ({
  backgroundColor:
    theme.colors[props.selectedColor ? props.selectedColor : 'selected'],
  color:
    theme.colors[props.selectedFontColor ? props.selectedFontColor : 'white'],
})

// component ______________________________________________
const PillTabs: React.FC<PillTabsProps> = props => {
  const {
    currentIndex,
    tabs,
    handleClick,
    /* eslint-disable-next-line */
    selectedColor = 'selected',
    /* eslint-disable-next-line */
    selectedFontColor = 'white',
    /* eslint-disable-next-line */
    size = 'large',
    ...rest
  } = props
  return (
    <div css={wrapper} {...rest}>
      {tabs.map((name, tabIndex) => {
        const styles = tabStyles(props, currentIndex, tabIndex, tabs.length)
        return (
          <div
            key={tabIndex + name}
            css={styles}
            onClick={() => handleClick(name)}
          >
            <Text
              type="caption"
              color={currentIndex === tabIndex ? 'accent' : 'primaryBody'}
            >
              {name}
            </Text>
          </div>
        )
      })}
    </div>
  )
}

export default withTheme(PillTabs)
