import { Operation } from '../../../__generated__/globalTypes'

import getAvailableUnlimitedDailyHeartfeltString from '../GetAvailableUnlimitedDailyHeartfelt.graphql'

export type GetAvailableUnlimitedDailyHeartfeltData = {
  account: {
    __typename: 'Account'
    hasUnlimitedHeartfeltSends: boolean
    heartfeltDailyCap: {
      available: number
      cap: number
    }
  }
}

export type GetAvailableUnlimitedDailyHeartfelt = Operation<
  GetAvailableUnlimitedDailyHeartfeltData
>

export const GetAvailableUnlimitedDailyHeartfelt = (): GetAvailableUnlimitedDailyHeartfelt => ({
  query: getAvailableUnlimitedDailyHeartfeltString,
  variables: undefined,
})
