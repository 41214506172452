import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { SaleorAddressFragment } from 'src/saleor_graphql/generated/graphql'

type BillingAdressCardProps = {
  address?: SaleorAddressFragment
  onEdit?: () => void
  onSelect?: () => void
}

export const BillingAddressCard = (props: BillingAdressCardProps) => {
  const { address, onEdit, onSelect } = props

  const background = address?.isDefaultBillingAddress ? '#cbd5e0' : '#ffffff'
  return (
    <Flex
      width="100%"
      onClick={onSelect}
      style={{
        cursor: 'pointer',
        padding: '1rem',
        background,
        borderRadius: '16px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Flex
        style={{ flexDirection: 'row', gap: '0.75rem', alignItems: 'center' }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          inset="1rem"
          backgroundColor="#F3E8FF"
          style={{ borderRadius: '12px' }}
        >
          <Icon name="pin" size={25} color="#000" />
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          <Text
            type="body"
            lineHeight={1.3}
            style={{ color: '#404040', fontSize: '16px', fontWeight: 700 }}
          >
            {address?.streetAddress1}
          </Text>
          <Text type="body" style={{ color: '#6B7280', fontSize: '12px' }}>
            {address?.city}, {address?.countryArea} {address?.postalCode}
          </Text>
        </Flex>
      </Flex>
      {onEdit && (
        <Flex
          onClick={onEdit}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.25rem',
            cursor: 'pointer',
          }}
        >
          <Icon name="edit" color="#000000" size={12} />
          <Text type="body" style={{ color: '#000000', fontSize: '10px' }}>
            edit
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
