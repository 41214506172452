import { Icon } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CustomArrowProps } from 'react-slick'

export const PreviousArrow: React.FC<CustomArrowProps> = props => {
  const { onClick, className, currentSlide } = props
  return (
    <>
      {currentSlide !== 0 && (
        <div
          className={className}
          onClick={onClick}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#fff',
            margin: 'auto',
            width: '32px',
            height: '32px',
            position: 'absolute',
            borderRadius: '50px',
            left: '8px',
            cursor: 'pointer',
            zIndex: 1,
            boxShadow: 'rgba(0, 0, 0, 0.18) 0px 4px 8px',
          }}
        >
          <Icon name="leftChevron" size="large" primaryColor="#000" />
        </div>
      )}
    </>
  )
}
