import { Operation } from '../../../__generated__/globalTypes'

import getAddPaymentSettingsDailyCapString from '../GetAddPaymentSettingsDailyCap.graphql'

export type GetAddPaymentSettingsDailyCapData = {
  account: {
    addPaymentSettingsDailyCap: {
      available: number
      cap: number
    }
  }
}

export type GetAddPaymentSettingsDailyCap = Operation<
  GetAddPaymentSettingsDailyCapData
>

export const GetAddPaymentSettingsDailyCap = (): GetAddPaymentSettingsDailyCap => ({
  query: getAddPaymentSettingsDailyCapString,
  variables: undefined,
})
