import React from 'react'

export type PPSVGProps = {
  color: { gradientOne: string; gradientTwo: string }
  width: string
  height: string
}

export const PPSVG: React.FC<PPSVGProps> = ({ color, width, height }) => {
  const gradientId = `gradient_${color.gradientOne}_${color.gradientTwo}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 74 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.3482 9.61151C64.1488 0.00797063 51.5452 -2.8842 42.1965 3.15273C38.6334 5.45377 36.058 8.71887 34.5785 12.4124C29.9049 24.0724 25.6252 36.3975 21.1631 48.2108C24.434 48.068 26.8204 46.9796 28.5854 45.48C30.7907 43.6061 32.0242 41.0921 32.8003 38.9762L33.0727 38.2529L35.9601 30.6026L35.9654 30.6118L36.7825 28.4245L39.7467 20.4462C40.4355 18.5935 41.1785 16.1285 42.0445 14.4225C43.0096 12.5222 44.4653 10.8506 46.3704 9.62077C52.2432 5.82802 60.16 7.64505 64.0537 13.678C67.9473 19.711 66.3435 27.676 60.4721 31.4674C56.2664 34.1837 51.011 34.0224 46.9363 31.4952L44.2154 38.6232C50.5245 42.0152 58.3289 42.0139 64.646 37.9342C73.9946 31.8972 76.5476 19.2164 70.3482 9.61284V9.61151Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.38715 50.775C9.58652 60.3799 22.1902 63.2708 31.5388 57.2338C35.1019 54.9328 37.6774 51.6664 39.1568 47.9741C43.8305 36.3142 48.1102 23.9891 52.5723 12.1757C49.3014 12.3185 46.915 13.4069 45.15 14.9066C42.9447 16.7791 41.7111 19.2944 40.9351 21.4103L40.6614 22.1337L37.7739 29.784L37.7686 29.7747L36.9515 31.962L33.9874 39.9403C33.2986 41.793 32.5555 44.2581 31.6896 45.964C30.7244 47.8643 29.2688 49.5359 27.3636 50.7658C21.4908 54.5585 13.574 52.7415 9.68039 46.7085C5.78678 40.6756 7.3905 32.7105 13.262 28.9191C17.4676 26.2028 22.723 26.3642 26.7977 28.8913L29.5186 21.7634C23.2095 18.3713 15.4051 18.3727 9.08809 22.4524C-0.26055 28.4893 -2.81354 41.1702 3.38583 50.7737L3.38715 50.775Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="49.1406"
          y1="-15.3759"
          x2="-18.3852"
          y2="111.255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color.gradientOne} />
          <stop offset="1" stop-color={color.gradientTwo} />
        </linearGradient>
      </defs>
    </svg>
  )
}
