import { Avatar, Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

export type InfoAvatarType = {
  firstName: string
  lastName: string
  profileImage?: string
  coachName: string
}

export const InfoAvatar: React.FC<InfoAvatarType> = ({
  firstName,
  lastName,
  profileImage,
  coachName,
}) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      columnGap="x1"
    >
      <Avatar
        size="small"
        avatarImage={profileImage}
        initials={
          firstName || lastName
            ? `${firstName && firstName[0].toUpperCase()}${
                lastName && lastName[0].toUpperCase()
              }`
            : undefined
        }
      />
      <Div>
        <Text
          type="footnote"
          alignment="center"
          content={'Your coach is'.toUpperCase()}
          color="inverseHeading"
        />
        <Text
          type="largeBody"
          content={coachName}
          isItalic={true}
          alignment="center"
          style={{
            fontSize: '15px',
            fontWeight: 800,
            color: '#6b9bdf',
          }}
          lineHeight={1.2}
        />
      </Div>
    </Flex>
  )
}
