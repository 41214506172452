import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Button } from 'src/design_system/components/Button/Button'
import { gratitudeAffiliateBundleVariantIds } from 'src/helpers/supportedUpsaleProducts'
import { useSelector } from 'src/hooks'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'

type HardCodedGratitudeBundleTileProps = {
  isOnCart: boolean
  isOptionDisabled?: boolean
}

export const HardCodedGratitudeBundleTile: React.FC<HardCodedGratitudeBundleTileProps> = ({
  isOnCart = false,
  isOptionDisabled = false,
}) => {
  const isTablet = useSelector(state => state.window.width <= 820)
  const isMobile = useSelector(state => state.window.width <= 520)
  const tileMinWidth = isMobile ? 270 : isTablet ? 330 : 350
  const responsiveStyles = {
    titleSize: isTablet ? '1.2rem' : '1.385rem',
    priceSize: isTablet ? '1.5rem' : '1.875rem',
    btnTitleSize: isTablet ? '.8rem' : '1rem',
    featuresSize: isTablet ? '.7rem' : '.89rem',
    cardPadding: isTablet ? 'x2' : 'x4',
    cardGap: isTablet ? 'x2_5' : 'x4',
  }
  const variantIds = gratitudeAffiliateBundleVariantIds

  const { addToCart } = useHandleProductActions('')

  const onAddToCart = () =>
    addToCart(
      variantIds.map(value => {
        return { variantId: value, quantity: 1 }
      }),
    )

  const planFeatures = [
    '$37 Monthly Plus Membership (First month free with bundle)',
    'Annual Affiliate',
  ]

  return (
    <Flex
      cursor={'pointer'}
      flexDirection="column"
      rowGap={responsiveStyles.cardGap}
      inset={responsiveStyles.cardPadding}
      minWidth={`${tileMinWidth}px`}
      style={{
        background: '#fff',
        borderRadius: '.75rem',
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
        borderTop: `3px solid  ${isOptionDisabled ? 'gray' : 'blue'} `,
        transition: 'ease 0.3s',
        paddingBottom: '24px',
      }}
      position="relative"
      overflow="hidden"
    >
      {/*Pill <Flex justifyContent="space-between" alignItems="center">
        <Icon name="contact" primaryColor={'secondaryBrand'} size={50} />
        <Div
          backgroundColor="#fff"
          borderRadius="circle"
          boxShadow="light"
          height="fit-content"
          inset={{ horizontal: 'x_75' }}
          outset={{ bottom: 'auto' }}
        >
          <Text
            type="body"
            content={'Featured affiliate plan'}
            lineHeight={2}
            style={{
              fontSize: '.625rem',
              fontWeight: 700,
              color: '#277AD4',
            }}
          />
        </Div>
      </Flex> */}
      <Flex flexDirection="column">
        <Text
          type="body"
          content={'Bundle Package'}
          lineHeight={1.3}
          color={'primaryBody'}
          style={{
            fontSize: '0.85rem',
            fontWeight: 500,
            lineHeight: '2.185',
          }}
        />
        <Flex flexDirection="row" alignItems="center" columnGap="x_5">
          <Text
            type="body"
            content={'Casual Affiliate Bundle'}
            color={'primaryHeading'}
            style={{
              fontSize: `${responsiveStyles.titleSize}`,
              fontWeight: 700,
            }}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="row" columnGap="x_75">
        <Text
          type="body"
          content={'$99.00'}
          color={'primaryHeading'}
          style={{
            fontSize: `${responsiveStyles.priceSize}`,
            fontWeight: 500,
          }}
        />
        {/* <Text
          type="body"
          content={priceSubtext}
          style={{
            fontSize: '.875rem',
            fontWeight: 500,
            color: isActive ? '#fcfcfc66' : '#6B7280',
          }}
        /> */}
      </Flex>
      <Flex flexDirection="column" rowGap="x_5">
        {planFeatures.map((feature, index) => (
          <Flex key={index} flexDirection="column">
            <Flex flexDirection="row" alignItems="flex-start" columnGap="x_5">
              <Div
                width="fit-content"
                height="fit-content"
                inset={{ top: 'x_5' }}
              >
                <Icon name="check" primaryColor={'#059669'} size={'xSmall'} />
              </Div>
              <Text
                type="body"
                content={feature}
                color={'primaryBody'}
                style={{
                  fontSize: `${responsiveStyles.featuresSize}`,
                  fontWeight: 500,
                }}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Flex
        width="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        outset={{ top: 'auto' }}
      >
        {!isOnCart && (
          <Button
            title={
              isOptionDisabled
                ? 'Not Eligible for Purchase'
                : 'Purchase Casual Affiliate Bundle'
            }
            textColor={'#FFF'}
            backgroundColor="#277AD4"
            width="100%"
            boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
            onClick={onAddToCart}
            textSize={responsiveStyles.btnTitleSize}
            isDisabled={isOptionDisabled}
          />
        )}
        {isOnCart && (
          <Button
            title="Added to Cart!"
            textColor="rgba(248, 113, 113, 1)"
            textWeight={500}
            backgroundColor="#F9FAFB"
            width="100%"
            boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
            textSize={responsiveStyles.btnTitleSize}
            icon={{ name: 'trash', size: 16, color: '#F87171' }}
          />
        )}
      </Flex>
    </Flex>
  )
}
