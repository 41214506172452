import { useCreateAffiliate } from 'src/react_query'
import { useActions, useCallback, useEffect, useState } from '.'
import usePersistedUserData from './usePersistedUserData'
import { VerticalId } from 'src/graphql/generated/graphql'

const useConvertToRep = () => {
  const { account } = usePersistedUserData()
  const actions = useActions()
  const createAffiliateMutation = useCreateAffiliate()

  const [hasSetToConvert, setHasSetToConvert] = useState(false)

  const accType = account?.type
  const username = account?.username

  const createAff = useCallback(async () => {
    if (!account) return
    setHasSetToConvert(true)
    const {
      createDistributor: { account: updatedAccount },
    } = await createAffiliateMutation.mutateAsync({
      account: { username: username },
      govId1: '',
      affiliate: false,
      verticalId: VerticalId.Sendoutcards,
      queryParams: {
        redirect_uri: undefined,
      },
    })

    if (updatedAccount) {
      actions.updatedAccount(updatedAccount)
    }
  }, [account, actions, createAffiliateMutation, username])

  useEffect(() => {
    if (!hasSetToConvert && accType === 'C' && username && username !== '') {
      createAff()
    }
  }, [
    accType,
    username,
    actions,
    hasSetToConvert,
    setHasSetToConvert,
    createAff,
  ])
}

export default useConvertToRep
