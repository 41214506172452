import { DetailedGroupFragment, GroupFragment } from 'src/legacy_graphql'
import { Group } from '../contactTypes'

export enum Steps {
  View,
  Add,
  EditGroup,
  EditContact,
  ConfirmDelete,
  DeleteProgress,
  ConfirmShare,
  ShareProgress,
  AddToGroup,
  CreateOrder,
  SelectCampaign,
  ConfirmExport,
  ExportProgress,
  ManageMembers,
  RemoveMembers,
  Success,
}

interface ViewStep {
  type: Steps.View
}

interface AddStep {
  type: Steps.Add
}

interface EditGroupStep {
  type: Steps.EditGroup
  group: Group
}

interface EditContactStep {
  type: Steps.EditContact
  contactId: string
  groups: GroupFragment[] | undefined
}

interface ConfirmDeleteStep {
  type: Steps.ConfirmDelete
}

interface DeleteProgressStep {
  type: Steps.DeleteProgress
  deleted: number
  errors: string[]
  done: boolean
}

interface ConfirmShareStep {
  type: Steps.ConfirmShare
}

interface ShareProgressStep {
  type: Steps.ShareProgress
  deleted: number
  errors: string[]
  done: boolean
}

interface AddToGroupStep {
  type: Steps.AddToGroup
}

interface CreateOrderStep {
  type: Steps.CreateOrder
}

interface SelectCampaignStep {
  type: Steps.SelectCampaign
  recipients?: string[]
}

interface ConfirmExportStep {
  type: Steps.ConfirmExport
  email: string
}

interface ExportProgressStep {
  type: Steps.ExportProgress
}

interface ManageMembersStep {
  type: Steps.ManageMembers
  groupId: string
}

interface RemoveMembersStep {
  type: Steps.RemoveMembers
  removed: string[]
  confirmed: boolean
  group: DetailedGroupFragment
}

interface SuccessStep {
  type: Steps.Success
}

export type Step =
  | ViewStep
  | AddStep
  | EditGroupStep
  | EditContactStep
  | DeleteProgressStep
  | ConfirmDeleteStep
  | ConfirmShareStep
  | ShareProgressStep
  | AddToGroupStep
  | CreateOrderStep
  | SelectCampaignStep
  | ConfirmExportStep
  | ExportProgressStep
  | ManageMembersStep
  | RemoveMembersStep
  | SuccessStep
