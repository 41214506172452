import React from 'react'
import EnhancedContactManager from 'src/enhanced_contact_manager/containers/EnhancedContactManager'
import { useAccount } from 'src/hooks'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { RelationshipManagerRoute } from '../routes/RelationshipManagerRoute'
import ContactManager from './ContactManager'
import { LockedFeatureModal } from 'src/LockedFeatureModal/LockedFeatureModal'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'

type ContactManagerSelectorProps = {
  route: RelationshipManagerRoute | undefined
}

const ContactManagerSelector: React.FC<ContactManagerSelectorProps> = props => {
  const { route } = props
  const account = useAccount()
  const featureAccessArray = [FullAccessFeatureType.ContactManager]

  const { hasFeatureAccess } = useFeatureAccess(featureAccessArray)

  if (!hasFeatureAccess)
    return (
      <LockedFeatureModal
        actions={{
          mainAction: {
            execute: () => console.log('clicked main action'),
            title: 'Unlock This & More Features',
          },
          secondaryAction: {
            execute: () => console.log('closed'),
            title: 'Not Now',
          },
        }}
        textContent={{
          title: 'Unlock Me!',
          subTitle: 'The Contact Manager is a Full System Feature.',
          description:
            'Upgrade your account from a Basic account to a Monthly Membership or any of our other plans to access this great feature!',
        }}
        image={{
          url:
            'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/locked-feature-modal/image-6.png',
          height: '88%',
          width: '88%',
          right: '0px',
          bottom: '-48px',
        }}
        featureAccessToGrant={featureAccessArray}
      />
    )

  return (
    <>
      {account.hasFullAccessContactManager ? (
        <EnhancedContactManager route={route} />
      ) : (
        <ContactManager route={route} />
      )}
    </>
  )
}

export default ContactManagerSelector
