import React from 'react'
import { useAccount, useEffect } from 'src/hooks'

export const CertifiedCoaching = () => {
  const account = useAccount()

  useEffect(() => {
    const redirectUrl = account.promptinguLoginUrl

    if (redirectUrl) {
      window.location.href = redirectUrl
    } else {
      window.location.pathname = 'dashboard/coaching'
    }
  }, [account.promptinguLoginUrl])

  return <></>
}
