import React from 'react'

import {
  Alert,
  Confirm,
  DefaultError,
  Modal,
  ProgressModal,
  Transition,
} from 'src/chrome'
import { useActions, useEffect, useMutations, useState } from 'src/hooks'

import {
  AddressFormModal,
  ContactActionBar,
  ContactHeader,
  ContactInfo,
  Contacts,
  GroupAddCreateModal,
  SelectCampaignModal,
  ShareContact,
  Success,
} from '../components'
import { CreateContactInput } from 'src/legacy_graphql'
import styles from './contactManager.module.scss'
import { Step, Steps } from './types'
import Hooks from './ContactHooks'
import suspenseBoundary from '../../chrome/SuspenseBoundary/suspenseBoundaryHOC'
import EmptySearch from '../components/EmptySearch/EmptySearch'
import RelationshipManagerCore from './RelationshipManagerCore'
import RelationshipSharedHooks from './RelationshipSharedHooks'
import { ConfirmDialog } from '@sendoutcards/quantum-design-ui'
import { RelationshipManagerRoute } from 'src/contacts/routes/RelationshipManagerRoute'
import { EditContactRoute } from 'src/contacts/routes/EditContactRoute'

type EnhancedContactManagerProps = {
  route: RelationshipManagerRoute | undefined
}

const EnhancedContactManager: React.FC<EnhancedContactManagerProps> = props => {
  const { route } = props

  const [initialContactId, setInitialContactId] = useState(
    route?.subroute?.path === EditContactRoute.path
      ? route.subroute.contactId
      : undefined,
  )
  const [defaultValue, setDefaultValue] = useState('')

  const hooks = Hooks()
  const sharedHooks = RelationshipSharedHooks()
  const mutations = useMutations()
  const { setStep, step } = hooks

  const isNewContactModalOpen =
    route?.path === RelationshipManagerRoute.path
      ? route.isNewContactModalOpen
      : null

  const actions = useActions()
  const selectedContactIds = hooks.selectedContacts.map(contact => contact.id)
  const selectedContact = hooks.paginatedContacts
    ? hooks.paginatedContacts.find(
        contact => contact.id === selectedContactIds[0],
      )
    : undefined

  const createNewContact = async (newContact: CreateContactInput) => {
    actions.toggleIsNewContactModalOpen()
    await mutations.createContactWithSpouseAndChildren({
      contact: newContact,
    })
    const contact = `${newContact.firstName} ${newContact.lastName}`
    hooks.setTerms(contact)
    setDefaultValue(contact)
  }

  const onFindDup = () => {
    if (!selectedContact) {
      return
    }
    const contactFullName = `${selectedContact?.firstName} ${selectedContact?.lastName}`
    setDefaultValue(contactFullName)
    hooks.setSearchDuplicate(selectedContact)
    hooks.toggleSelect(selectedContact)
  }

  useEffect(() => {
    if (initialContactId) {
      const currentStep: Step = {
        type: Steps.EditContact,
        contactId: initialContactId,
        groups: sharedHooks.groups,
      }
      setStep(currentStep)
      setInitialContactId(undefined)
      return
    }
  }, [initialContactId, setStep, sharedHooks])

  console.log('novo')

  return (
    <RelationshipManagerCore
      wrapperId="contact_manager"
      isLoading={hooks.isLoading}
      paginationSetPage={hooks.setPage}
      paginationCurrent={hooks.page}
      paginationTotalCount={hooks.contactsCount}
    >
      <ContactHeader
        onAdd={actions.toggleIsNewContactModalOpen}
        onOpenGroups={actions.openGroups}
        filters={hooks.search.filters}
        setTerms={hooks.setTerms}
        setFilters={hooks.setFilters}
        clearSearch={hooks.clearSearch}
        onImport={actions.openContactsImporter}
        defaultValue={defaultValue}
        clearDefaultValue={() => setDefaultValue('')}
      />

      <ContactActionBar
        title="Contacts"
        totalCount={hooks.contactsCount}
        selectedCount={hooks.selectedContacts.length}
        isViewingGroups={false}
        onToggleSelectAll={hooks.toggleSelectAll}
        onSendCard={() => hooks.sendCard(undefined)}
        onViewGroups={actions.openGroups}
        onStartCampaign={() => hooks.selectCampaign(undefined)}
        onAddToGroup={hooks.addToGroup}
        onDelete={hooks.confirmDelete}
        onShare={hooks.confirmShare}
        onExport={hooks.confirmExport}
        handleEditContact={
          selectedContactIds.length > 1
            ? undefined
            : () => hooks.editContact(selectedContactIds[0])
        }
        handleHistoryClick={
          selectedContactIds.length > 1 ? undefined : hooks.viewHistory
        }
        onFindDup={onFindDup}
        contact={selectedContact}
      />
      <div className={styles.body}>
        {step.type === Steps.EditContact &&
          (() => {
            const { contactId, groups } = step

            return groups ? (
              <Modal
                title=""
                onClose={hooks.doneEditing}
                bodyStyles={{
                  maxHeight: 800,
                }}
                bodyChildren={
                  <ContactInfo
                    contactId={contactId}
                    groups={groups}
                    handleSendCardClick={() => hooks.sendCard([contactId])}
                    handleCampaignClick={() =>
                      hooks.selectCampaign([contactId])
                    }
                    goToContact={hooks.editContact}
                  />
                }
              />
            ) : (
              <Transition message="Loading contact" />
            )
          })()}

        {step.type === Steps.ConfirmDelete && (
          <Confirm
            title={`Delete ${hooks.selectedContacts.length} contacts`}
            message={'Are you sure you want to delete the selected contacts?'}
            confirmTitle={'Delete'}
            onConfirm={hooks.deleteSelectedContacts}
            onDecline={hooks.resetStep}
          />
        )}
        {isNewContactModalOpen && (
          <AddressFormModal
            contact={undefined}
            close={actions.toggleIsNewContactModalOpen}
            showAddSpouse={true}
            showAddChildren={true}
            showAddGroup={true}
            onSubmit={createNewContact}
            groups={hooks.groups}
          />
        )}

        {step.type === Steps.DeleteProgress && (
          <ProgressModal
            size={100}
            isOpen={true}
            currentCount={step.deleted}
            totalCount={hooks.selectedContacts.length || undefined}
            errorTitle={'The following contacts could not be deleted'}
            errorList={step.errors}
            message={'contacts deleted successfully'}
            onClose={step.done ? hooks.resetStep : undefined}
            isDone={step.done}
          />
        )}

        {step.type === Steps.ConfirmShare && (
          <ShareContact
            contactsIds={hooks.selectedContacts.map(contact => contact.id)}
            onClose={hooks.resetStep}
            onSubmit={hooks.shareSelectedContacts}
          />
        )}

        {step.type === Steps.SelectCampaign && (
          <SelectCampaignModal
            isOpen={true}
            onClose={hooks.resetStep}
            onSubmit={campaign => hooks.sendCampaign(campaign, step.recipients)}
          />
        )}
        {step.type === Steps.AddToGroup &&
          (hooks.groups ? (
            <GroupAddCreateModal
              isOpen={true}
              onClose={hooks.resetStep}
              onCreateGroup={hooks.createGroup}
              onSelect={hooks.addSelectedContactsToGroup}
              initialMode="select"
              contactsIds={hooks.selectedContacts.map(contact => contact.id)}
              groups={hooks.groups}
            />
          ) : (
            <Transition message="Loading groups..." />
          ))}

        {step.type === Steps.CreateOrder && (
          <Transition message={'Creating order...'} />
        )}

        {step.type === Steps.ExportProgress && (
          <Alert
            isOpen={true}
            onClose={hooks.resetStep}
            type={'info'}
            timeout={7000}
            title={'Exporting to CSV'}
            message={'Your contacts will begin downloading shortly.'}
          />
        )}

        {step.type === Steps.ConfirmExport && (
          <ConfirmDialog
            isOpen={step.type === Steps.ConfirmExport}
            title="Export contacts"
            description="Your CSV file will be downloaded by the browser. If you selected a large number of contacts, it will take a few seconds for the download to begin."
            accept={{ title: 'Export', onClick: () => hooks.exportContacts() }}
            decline={{ title: 'Cancel', onClick: hooks.resetStep }}
          />
        )}

        {step.type === Steps.ShareProgress && (
          <ProgressModal
            size={100}
            isOpen={true}
            currentCount={step.deleted}
            totalCount={hooks.selectedContacts.length || undefined}
            errorTitle={'The following contacts could not be shared'}
            errorList={step.errors}
            message={'contacts shared successfully'}
            onClose={step.done ? hooks.resetStep : undefined}
            isDone={step.done}
          />
        )}

        {step.type === Steps.Success && <Success onClose={hooks.resetStep} />}

        {(
          hooks.search.duplicateId
            ? hooks.paginatedContacts.length > 1
            : hooks.paginatedContacts.length
        ) ? (
          <div className={styles.tableContainer}>
            <Contacts
              isEveryContactSelected={hooks.isEveryContactSelected}
              contacts={hooks.paginatedContacts}
              goToContact={hooks.editContact}
              handleSelectAllClick={hooks.toggleSelectAll}
              onSelect={hooks.toggleSelect}
              selectedContacts={hooks.selectionMap}
              originalContactId={hooks.search.duplicateId}
            />
          </div>
        ) : (
          <EmptySearch message="No contacts found" onReset={hooks.reset} />
        )}
      </div>
    </RelationshipManagerCore>
  )
}

export default suspenseBoundary({
  component: EnhancedContactManager,
  unresolved: <Transition message={'Loading contacts...'} />,
  failure: DefaultError,
})
