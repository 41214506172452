import React from 'react'
import {
  Button,
  Card,
  Div,
  Flex,
  Icon,
  InfoBlock,
  Input,
  Separator,
  Spacer,
  Span,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  useDebounce,
  useEmailValidation,
  useQueries,
  useQueryParams,
  useSelector,
  useState,
} from 'src/hooks'
import { getSponsor, getSponsors, SponsorFragment } from 'src/legacy_graphql'
import FindMySponsor from './FindMySponsor'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { sanitizeUsername } from 'src/helpers/sanitizeUsername'
import { SponsorCard } from 'src/onboarding/components/SponsorCard'
import { sendoutcardsPromotionsUserId } from 'src/app/constants'
import { VerifyPhoneNumberSuccessFragment } from 'src/graphql/generated/graphql'

interface FinishAccountState {
  email: string
  username: string
  password: string
}

const FinishMyAccount = (props: {
  finishAccount: (
    success: VerifyPhoneNumberSuccessFragment,
    email: string,
    username: string,
    password: string,
    sponsorId: string,
  ) => void
  success: VerifyPhoneNumberSuccessFragment
}) => {
  const { finishAccount, success } = props
  const [values, setValues] = useState<FinishAccountState>({
    email: success.verifiedAccount.email,
    username: success.verifiedAccount.username,
    password: '',
  })
  const queryParams = useQueryParams()
  const [isPickingSponsor, setIsPickingSponsor] = useState(false)
  const [sponsorId, setSponsorId] = useState(
    success.verifiedAccount.sponsor?.id ?? sendoutcardsPromotionsUserId,
  )
  const { password, username, email } = values
  const account = useSelector(state => state.user)

  const affiliate =
    !account.controlData.isLoggedIn && queryParams.sponsor
      ? queryParams.sponsor
      : null

  const [fetchSponsor] = useQueries(
    affiliate
      ? getSponsors({ search: affiliate })
      : sponsorId
      ? getSponsor({ id: sponsorId })
      : undefined,
  )
  const sponsor = Array.isArray(fetchSponsor) ? fetchSponsor[0] : fetchSponsor

  const onSelectedSponsor = (id: string) => {
    setSponsorId(id)
    setIsPickingSponsor(false)
  }

  const handleChange = (name: keyof FinishAccountState, value: string) => {
    const sanitizedValue = name === 'username' ? sanitizeUsername(value) : value
    setValues({ ...values, [name]: sanitizedValue })
  }
  const debouncedUsername = useDebounce(values.username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername)

  const debouncedEmail = useDebounce(values.email, 500)
  const emailValidation = useEmailValidation(debouncedEmail)

  const handleFinishAccount = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    finishAccount(success, email, username, password, sponsorId)
  }

  return (
    <>
      {isPickingSponsor ? (
        <FindMySponsor onSelectedSponsor={onSelectedSponsor} />
      ) : (
        <>
          <form onSubmit={handleFinishAccount} autoComplete="on">
            <Icon
              name={'socLogo'}
              size="medium"
              primaryColor={'primaryBrand'}
            />
            <Spacer space="x2" />
            <Text type="title" weight="bold">
              Finish Your Account
            </Text>
            <Spacer space="x2" />
            <Text type="footnote">
              <p style={{ lineHeight: '1.25' }}>
                Looks like you've already sent a card with us!
              </p>
              <p style={{ lineHeight: '1.25' }}>
                Let's finish setting up your account.
              </p>
            </Text>
            <Spacer space="x2" />
            <Div width="100%">
              <Input
                onChange={email => handleChange('email', email)}
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                icon="envelope"
                message={
                  emailValidation.errorMessage && email
                    ? {
                        type: 'danger',
                        content: emailValidation.errorMessage,
                        icon: 'information',
                      }
                    : undefined
                }
                isFullWidth={true}
                isLoading={emailValidation.isLoading}
              />
              <Spacer space="x1" />
              <Input
                onChange={username => handleChange('username', username)}
                type="text"
                name="username"
                value={username}
                placeholder="Username"
                icon="user"
                message={
                  !usernameValidation.isValid &&
                  !!values.username &&
                  !usernameValidation.isLoading
                    ? { type: 'danger', content: 'This username is taken' }
                    : undefined
                }
                isLoading={usernameValidation.isLoading}
                isFullWidth={true}
              />
              <Spacer space="x1" />
              <Input
                onChange={password => handleChange('password', password)}
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                message={{
                  type: 'danger',
                  content: '',
                }}
                icon="lock"
                isFullWidth={true}
              />
              <Spacer space="x1" />
              {sponsor && sponsorId !== sendoutcardsPromotionsUserId ? (
                <SponsorCard
                  sponsor={sponsor as SponsorFragment}
                  onClick={
                    affiliate ? undefined : () => setIsPickingSponsor(true)
                  }
                  isActive={true}
                />
              ) : (
                <Div cursor="pointer">
                  <Card
                    width="100%"
                    borderRadius="medium"
                    inset="x2"
                    onClick={() => setIsPickingSponsor(true)}
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex alignItems="center">
                        <Span
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          inset={{ right: 'x1' }}
                        >
                          <Icon
                            name="check"
                            primaryColor="default"
                            secondaryColor="background"
                            size="medium"
                          />
                        </Span>
                        <InfoBlock
                          size="small"
                          heading={'Who Showed you this product?'}
                          caption="Optional"
                        />
                      </Flex>
                      <Icon
                        name="information"
                        primaryColor="default"
                        size="small"
                      />
                    </Flex>
                  </Card>
                </Div>
              )}
            </Div>
            <div style={{ marginTop: '16px' }} />
            <Button
              title={'Create My Account'}
              onClick="submitForm"
              fullWidth={true}
              disabled={
                !values.password ||
                !values.username ||
                !values.email ||
                email !== debouncedEmail ||
                emailValidation.isLoading ||
                !emailValidation.isValid ||
                username !== debouncedUsername ||
                usernameValidation.isLoading ||
                !usernameValidation.isValid
              }
            />
            <Spacer space="x2" />
            <Separator orientation="horizontal" dashed={true} caption="Or" />
            <Spacer space="x2" />
            <Button
              title={'Learn more about our mission'}
              onClick="submitForm"
              fullWidth={true}
              type={'secondary'}
            />
          </form>
        </>
      )}
    </>
  )
}

export default FinishMyAccount
