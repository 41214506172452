import React from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { useEffect, useQueryParams, useState } from 'src/hooks'
import { useCompleteCheckout } from 'src/react_query/queries/hooks'
import {
  Button,
  Flex,
  List,
  ListItem,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'

export const ConfirmPaymentPage = () => {
  const queryParams = useQueryParams()
  const stripe = useStripe()
  const mutation = useCompleteCheckout()
  const [isLoadingPaymentIntent, setIsLoadingPaymentIntent] = useState(false)
  const isLoading = isLoadingPaymentIntent && mutation.isLoading
  const containerStyles = {
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const redirect = (path: string) => {
    window.location.href = `/${path}`
  }

  const createOrderAndRedirect = () => {
    // Attaches the stripe payment intent to the order metadata
    const metadata = queryParams.payment_intent
      ? [{ key: 'stripe.payment_intent', value: queryParams.payment_intent }]
      : undefined

    mutation.mutate(
      { metadata },
      {
        onSuccess: data => {
          // TODO where to route if this fails?
          if (data?.order?.id) {
            redirect(`order-confirmation/${data?.order?.id}`)
          }
        },
      },
    )
  }

  useEffect(() => {
    if (queryParams.payment_intent_client_secret) {
      ;(async () => {
        setIsLoadingPaymentIntent(true)
        try {
          if (queryParams.payment_intent_client_secret) {
            const paymentIntent = await stripe?.retrievePaymentIntent(
              queryParams.payment_intent_client_secret,
            )

            if (paymentIntent?.paymentIntent?.status === 'succeeded') {
              createOrderAndRedirect()
            }
          }
        } catch (err) {
          console.error(err)
        } finally {
          setIsLoadingPaymentIntent(true)
        }
      })()
    }
  }, [queryParams.payment_intent_client_secret, stripe]) // eslint-disable-line

  if (!queryParams.payment_intent_client_secret || !queryParams.checkout) {
    return (
      <div style={{ ...containerStyles, flexDirection: 'column', gap: '16px' }}>
        <Text type="title">Payment could not be Processed</Text>
        <Button
          outlined={true}
          backgroundColorOverride="#000000"
          onClick={() =>
            (window.location.href = `/pricing?checkout=${queryParams.checkout}`)
          }
        >
          Return to Pricing
        </Button>
      </div>
    )
  }

  return (
    <div style={containerStyles}>
      {isLoading ? (
        <Flex flexDirection={'column'} style={{ gap: '16px' }}>
          <Text
            content={'Finalizing your Order...'}
            type="title"
            weight="bold"
          />
          <LoadingSpinner size="large" />
        </Flex>
      ) : mutation.data?.errors && mutation.data.errors?.length > 0 ? (
        <Flex flexDirection="column" alignItems={'center'}>
          <Text
            content={'There was a problem creating your order:'}
            type="title"
            color="danger"
            weight="semiBold"
          />
          <List listStyle="disc" orientation="vertical" alignment="center">
            {mutation.data?.errors.map(error => (
              <ListItem key={error.code} orientation="vertical">
                <Text content={`${error.message}`} type="largeBody" />
              </ListItem>
            ))}
          </List>
          <Button
            onClick={() =>
              (window.location.href = `/pricing?checkout=${queryParams.checkout}`)
            }
            backgroundColorOverride="#000000"
          >
            Return to Pricing
          </Button>
        </Flex>
      ) : (
        <Text type="title">Finalizing Order</Text>
      )}
    </div>
  )
}
