import React from 'react'

import get from 'lodash/get'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import {
  Checkbox,
  Div,
  Flex,
  Icon,
  Input,
  LoadingSpinner,
  Select,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useAccount, useEffect, useState } from 'src/hooks'
import { Portal } from 'src/portal/portal'
import {
  EmailValidationStep,
  VerifyClient,
} from 'src/dashboard/components/VerifyClient'
import { ValidationError } from 'src/email_verification/VerifyEmail/ValidationFailureEmailVerification'
import { AddressFragment } from 'src/legacy_graphql'
import { useValidateEmailVerification } from 'src/react_query'
import { NO_STATE_SELECTION } from 'src/contacts/components/AddressForm/AddressForm'
import useCountryAndRegions from 'src/hooks/useCountryAndRegions'
import { Token } from '@stripe/stripe-js'

export type CCAddress = {
  name: string
  postalCode: string
  line1: string
  line2: string
  city: string
  state: string
  country: string
}

interface StripeCardFormProps {
  onSuccess: (token: Token, address: CCAddress) => void
  onError: (msg: string) => void
  isCCFormComplete: (isComplete: boolean) => void
  handleIsLoading: (isLoading: boolean) => void
  shouldValidateEmail?: boolean
  returnAddress?: AddressFragment
  overrideChildContCSS?: string
  overrideCountryOptions?: { value: string; label: string }[]
}

const StripeCardForm: React.FC<StripeCardFormProps> = props => {
  const {
    onError,
    onSuccess,
    children,
    isCCFormComplete,
    handleIsLoading,
    shouldValidateEmail = false,
    returnAddress,
    overrideChildContCSS,
    overrideCountryOptions,
  } = props

  const [isCCNumberComplete, setIsCCNumberComplete] = useState(false)
  const [isExpComplete, setIsExpComplete] = useState(false)
  const [isCvcComplete, setIsCvcComplete] = useState(false)

  const [address, setAddress] = useState<CCAddress>({
    name: '',
    postalCode: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: 'United States',
  })

  const [shouldPrefillAddress, setShouldPrefillAddress] = useState(false)

  const [shouldVerifyEmail, setShouldVerifyEmail] = useState(false)
  const [validationFailure, setValidationFailure] = useState<ValidationError>()
  const [event, setEvent] = useState<React.FormEvent<HTMLFormElement>>()

  const validateEmailMutation = useValidateEmailVerification()
  const account = useAccount()
  const stripe = useStripe()
  const elements = useElements()

  const {
    countries,
    regions,
    isRegionsUnavailable,
    generatedStateOptions,
    generatedCountryOptions,
  } = useCountryAndRegions(address.country)

  const countryOptions = overrideCountryOptions ?? generatedCountryOptions

  const isAddressComplete = (address: CCAddress) =>
    !!address.name &&
    !!address.line1 &&
    !!address.city &&
    (isRegionsUnavailable || !!address.state) &&
    !!address.country &&
    !!address.postalCode

  const onSubmit = async (
    formEvent: React.FormEvent<HTMLFormElement>,
    validationCode?: string,
  ) => {
    if (formEvent) {
      formEvent.preventDefault()
    }

    if (!stripe || !elements) {
      return
    }

    if (shouldValidateEmail) {
      if (!validationCode) {
        setEvent(formEvent)
        setShouldVerifyEmail(true)
        return
      } else {
        setShouldVerifyEmail(false)
      }

      try {
        const {
          validateEmailVerificationCode,
        } = await validateEmailMutation.mutateAsync({
          email: account.email,
          code: validationCode ?? '',
        })

        if (
          validateEmailVerificationCode &&
          validateEmailVerificationCode.__typename ===
            'ValidateEmailVerificationCodeFailure'
        ) {
          setValidationFailure({
            type: validateEmailVerificationCode.failureType,
            message: validateEmailVerificationCode.message,
          })
          setShouldVerifyEmail(true)
          return
        }
      } catch (error) {
        console.log('Something went wrong: ', error)
        return
      }
    }

    handleIsLoading(true)

    const cardNumberElement = elements.getElement(CardNumberElement)

    if (cardNumberElement) {
      const { error, token } = await stripe.createToken(cardNumberElement, {
        name: address.name,
        address_zip: address.postalCode,
        address_line1: address.line1,
        address_line2: address.line2,
        address_city: address.city,
        address_state: isRegionsUnavailable ? '' : address.state,
        address_country: address.country,
      })

      if (error) {
        handleIsLoading(false)
        const message = get(
          error,
          'message',
          'There has been an error adding your card.',
        )

        return onError(message)
      }
      if (token) {
        return onSuccess(token, address)
      }
    }
  }

  const handleAddressFormOnChange = (
    eventKey: keyof typeof address,
    value: string,
  ) => {
    const newAddress = { ...address, [eventKey]: value }
    setAddress(newAddress)
    isCCFormComplete(
      isCCNumberComplete &&
        isExpComplete &&
        isCvcComplete &&
        isAddressComplete(newAddress),
    )
  }

  const stripeElementWrapperProps = {
    borderRadius: '12px',
    backgroundColor: 'background',
    maxHeight: '50px',
    inset: { horizontal: 'x2', vertical: '13px' },
  }
  const stripeElementWithIconsProps = {
    style: {
      display: 'grid',
      gridTemplateColumns: '24px auto',
      columnGap: '8px',
    },
  }
  const stripeElementProps = {
    style: {
      base: {
        fontFamily: "'Montserrat',sans-serif",
        fontWeight: '500',
        color: '#6f8394',
        lineHeight: '24px',
      },
    },
  }

  return (
    <form id="addCreditCardForm" onSubmit={onSubmit}>
      {shouldVerifyEmail && (
        <Portal>
          <VerifyClient
            onClose={() => {
              setShouldVerifyEmail(false)
            }}
            email={account.email}
            confirm={{
              onSubmit: async code => {
                if (event) {
                  onSubmit(event, code)
                }
              },
            }}
            initialStep={
              validationFailure
                ? EmailValidationStep.ValidationError
                : undefined
            }
            validationFailure={validationFailure}
          />
        </Portal>
      )}
      <Div
        alignItems="center"
        css={{
          gridTemplateColumns: '1fr 1fr',
          display: 'grid',
          gap: '16px',
        }}
      >
        <Div style={{ gridColumn: 'span 2' }}>
          <Text
            type="caption"
            content="Credit Card Information"
            outset={{ left: 'x1' }}
            color="primaryHeading"
            weight="semiBold"
          />
        </Div>
        <Div
          {...stripeElementWrapperProps}
          css={{
            gridColumn: 'span 2',
          }}
        >
          <CardNumberElement
            onChange={e => {
              setIsCCNumberComplete(e.complete)
              isCCFormComplete(
                e.complete &&
                  isExpComplete &&
                  isCvcComplete &&
                  isAddressComplete(address),
              )
            }}
            options={{
              showIcon: true,
              placeholder: 'Credit Card Number',
              style: stripeElementProps.style,
            }}
          />
        </Div>
        <Div {...stripeElementWrapperProps} {...stripeElementWithIconsProps}>
          <Icon name="campaigns" size="small" primaryColor="primaryBodyText" />
          <CardExpiryElement
            onChange={e => {
              setIsExpComplete(e.complete)
              isCCFormComplete(
                e.complete &&
                  isCCNumberComplete &&
                  isCvcComplete &&
                  isAddressComplete(address),
              )
            }}
            options={{
              style: stripeElementProps.style,
              placeholder: 'EXP',
            }}
          />
        </Div>
        <Div {...stripeElementWrapperProps} {...stripeElementWithIconsProps}>
          <Icon name="lock" size="small" primaryColor="primaryBodyText" />
          <CardCvcElement
            onChange={e => {
              setIsCvcComplete(e.complete)
              isCCFormComplete(
                e.complete &&
                  isCCNumberComplete &&
                  isExpComplete &&
                  isAddressComplete(address),
              )
            }}
            options={{
              style: stripeElementProps.style,
              placeholder: 'CVC',
            }}
          />
        </Div>
        <Div
          css={{
            gridColumn: 'span 2',
          }}
        >
          <Input
            isFullWidth={true}
            type="text"
            placeholder="Holder Name"
            value={address.name}
            onChange={value => handleAddressFormOnChange('name', value)}
          />
        </Div>
        <Div style={{ gridColumn: 'span 2' }}>
          <Text
            type="caption"
            content="Billing Address"
            outset={{ left: 'x1' }}
            color="primaryHeading"
            weight="semiBold"
          />
        </Div>
        <Div
          css={{
            display: 'grid',
            gap: '16px',
            gridColumn: 'span 2',
            '@media (max-width: 1055px)': {
              gridTemplateColumns: '1fr',
              gridColumn: 'span 2',
            },
          }}
        >
          <Input
            isFullWidth={true}
            type="text"
            placeholder="Address Line 1"
            value={address.line1}
            onChange={value => handleAddressFormOnChange('line1', value)}
          />
          <Input
            isFullWidth={true}
            type="text"
            placeholder="Address Line 2"
            value={address.line2}
            onChange={value => handleAddressFormOnChange('line2', value)}
          />
          <Input
            isFullWidth={true}
            type="text"
            placeholder="City"
            value={address.city}
            onChange={value => handleAddressFormOnChange('city', value)}
          />
        </Div>
        <Div
          css={{
            gridTemplateColumns: '1fr 1fr 1fr',
            gridColumn: 'span 2',
            display: 'grid',
            gap: '16px',
            '@media (max-width: 1055px)': {
              gridColumn: 'span 2',
              gridTemplateColumns: '1fr 1fr',
            },
          }}
        >
          {!isRegionsUnavailable && (
            <Div>
              <Select
                selectedOptionIndex={
                  regions &&
                  [NO_STATE_SELECTION, ...regions]?.indexOf(address.state) !==
                    -1
                    ? regions &&
                      [NO_STATE_SELECTION, ...regions]?.indexOf(address.state)
                    : 0
                }
                onChange={selectedIndex => {
                  const selectedState =
                    generatedStateOptions.map(option => option.label)[
                      selectedIndex
                    ] || NO_STATE_SELECTION
                  handleAddressFormOnChange('state', selectedState)
                }}
                alignment="center"
                options={generatedStateOptions}
                type="text"
                dropDownMaxHeight="250px"
                isDropDownAbove={true}
                focusColor={
                  address.state && address.state !== NO_STATE_SELECTION
                    ? 'danger'
                    : undefined
                }
                message={
                  address.state && address.state !== NO_STATE_SELECTION
                    ? undefined
                    : { type: 'danger', content: 'this field is required' }
                }
              />
            </Div>
          )}
          <Div>
            <Input
              isFullWidth={true}
              id="postal"
              type="text"
              placeholder="Zip Code"
              value={address.postalCode}
              onChange={value => handleAddressFormOnChange('postalCode', value)}
            />
          </Div>
          <Div
            css={{
              '@media (max-width: 1055px)': {
                gridColumn: 'span 2',
              },
            }}
          >
            <Select
              selectedOptionIndex={
                countries?.indexOf(address.country) !== -1
                  ? countries?.indexOf(address.country)
                  : undefined
              }
              onChange={selectedIndex => {
                const country = countries?.[selectedIndex] || ''
                handleAddressFormOnChange('country', country)
              }}
              alignment="center"
              options={countryOptions}
              type="text"
              dropDownMaxHeight="250px"
              isDropDownAbove={true}
            />
          </Div>
          {returnAddress && (
            <Flex justifyContent="flex-start" outset={{ left: '-13px' }}>
              <Checkbox
                onChange={shouldPrefill => {
                  const {
                    postalCode,
                    address1,
                    address2,
                    city,
                    state,
                    country,
                  } = returnAddress
                  setShouldPrefillAddress(shouldPrefill)
                  const addressAsRAddress = {
                    name: address.name,
                    postalCode: shouldPrefill ? postalCode : '',
                    line1: shouldPrefill ? address1 : '',
                    line2: shouldPrefill ? address2 : '',
                    city: shouldPrefill ? city : '',
                    state: shouldPrefill ? state : '',
                    country: shouldPrefill ? country : '',
                  }
                  setAddress(addressAsRAddress)
                  isCCFormComplete(
                    isCCNumberComplete &&
                      isExpComplete &&
                      isCvcComplete &&
                      isAddressComplete(addressAsRAddress),
                  )
                }}
                isChecked={shouldPrefillAddress}
                type="primary"
                label="Same as Return Address"
              />
            </Flex>
          )}
          <Div
            css={{
              gridColumn: overrideChildContCSS ?? 'span 2',
              '@media (max-width: 1055px)': {
                gridColumn: 'span 1',
              },
            }}
          >
            <Flex justifyContent="flex-end">{children}</Flex>
          </Div>
        </Div>
      </Div>
    </form>
  )
}

interface Props {
  isCCFormComplete: (isComplete: boolean) => void
  onSuccess: (token: Token, address: CCAddress) => void
  onError: (error: string) => void
  isLoading?: boolean
  shouldValidateEmail?: boolean
  returnAddress?: AddressFragment
  overrideChildContCSS?: string
  overrideCountryOptions?: { value: string; label: string }[]
}

// TODO: Re-visit overrideChildContCSS prop to better handle children elements within CCForm
const CreditCardForm: React.FC<Props> = props => {
  const {
    isCCFormComplete,
    children,
    onError,
    onSuccess,
    isLoading,
    shouldValidateEmail = false,
    returnAddress,
    overrideChildContCSS,
    overrideCountryOptions,
  } = props

  const [isAddingCard, setIsAddingCard] = useState(isLoading)

  const handleIsLoading = (isLoading: boolean) => {
    setIsAddingCard(isLoading)
  }

  useEffect(() => {
    setIsAddingCard(isLoading)
  }, [isLoading])

  return (
    <div>
      {isAddingCard && (
        <>
          <Div display="flex" justifyContent="center" alignContent="center">
            <LoadingSpinner size="medium" />
          </Div>
          <Spacer space="x1" />
          <Text type="body" alignment="center">
            Adding Payment Method
          </Text>
        </>
      )}
      <div style={{ display: isAddingCard ? 'none' : 'initial' }}>
        <StripeCardForm
          onSuccess={onSuccess}
          onError={onError}
          isCCFormComplete={isCCFormComplete}
          handleIsLoading={handleIsLoading}
          shouldValidateEmail={shouldValidateEmail}
          returnAddress={returnAddress}
          overrideChildContCSS={overrideChildContCSS}
          overrideCountryOptions={overrideCountryOptions}
        >
          {children}
        </StripeCardForm>
      </div>
    </div>
  )
}

export default CreditCardForm
