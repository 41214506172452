import React from 'react'
import { DefaultError, Pagination, Transition } from 'src/chrome'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import AnnouncementBanners from 'src/marketing/AnnouncementBanners/AnnouncementBanners'
import styles from './contactManager.module.scss'

interface Props {
  wrapperId?: string
  isLoading: boolean
  paginationSetPage: (page: number) => void
  paginationTotalCount: number
  paginationCurrent: number
}

const RelationshipManagerCore: React.FC<Props> = props => {
  const {
    wrapperId = '',
    isLoading,
    paginationSetPage,
    paginationTotalCount,
    paginationCurrent,
    children,
  } = props

  return (
    <div id={wrapperId} className={styles.managerContainer}>
      {isLoading && <Transition message="One Moment..." />}
      <AnnouncementBanners />

      {children}
      <div className={styles.pagination}>
        <Pagination
          onPageChange={paginationSetPage}
          count={paginationTotalCount}
          pagesToShow={5}
          pageSize={20}
          currentPage={paginationCurrent}
        />
      </div>
    </div>
  )
}

export default suspenseBoundary({
  component: RelationshipManagerCore,
  unresolved: <Transition />,
  failure: DefaultError,
})
