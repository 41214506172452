import { useMemo, useQueries } from 'src/hooks'
import { getPlans, PlanFragment } from 'src/legacy_graphql'
import { getPlanDescriptions } from './getPlanDescriptions'

type PlanIdType = {
  id: string
}

export const usePlanManagement = (planIds: PlanIdType[]) => {
  const [plans] = useQueries(getPlans())

  const planDescriptions = useMemo(() => getPlanDescriptions(plans), [plans])

  const activePlans = useMemo(() => {
    return planIds
      .map(id => plans.find(plan => id.id === plan.id))
      .filter((plan): plan is PlanFragment => !!plan)
  }, [plans, planIds])

  return { plans, planDescriptions, activePlans }
}
