import { Route } from 'src/routes/Route'
import { CatalogSubroute } from 'src/catalog/routes/CatalogRoute'

export const AddOrderCardRoute = Route({
  path: '/cards/add',
  init: (subroute?: CatalogSubroute) => ({ subroute }),
  fromPath: () => ({}),
  subroute: CatalogSubroute,
})

export type AddOrderCardRoute = Route<typeof AddOrderCardRoute>
