import {
  Anchor,
  Button,
  Checkbox,
  ConfigurationCard,
  ConfirmDialog,
  Div,
  Flex,
  HStack,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { getAffiliateJoinMarketingContent } from 'src/legacy_graphql'
import {
  useActions,
  usePersistedUserData,
  useQueries,
  useQueryParams,
  useSelector,
  useState,
} from 'src/hooks'
import { AffiliateFormInfo } from 'src/marketing/plans/components/AffiliateForm/AffiliateForm'
import { EditCard, PaymentTab } from 'src/payments/components'
import { creditCardBrand } from 'src/payments/components/CardLogo/CreditCardLogo'
import { FlexRow } from 'src/styled'
import IdentificationNumber from './IdentificationNumber'
import { Token } from '@stripe/stripe-js'
import { useUpdateAccount } from 'src/react_query'
import { AccountInput } from 'src/graphql/generated/graphql'

interface PaymentMethodProps {
  affiliateInfo: AffiliateFormInfo
  onFinishAccount: (info: AffiliateFormInfo) => void
  onBack: (info: AffiliateFormInfo) => void
  shouldHideAffiliateFields?: true
  isFreeAffiliateSignup?: boolean
  isNewProAffiliate?: boolean
}

interface PaymentMethodState {
  accepted: boolean
  stripeToken?: Token
}

const freeAffiliateAgreement =
  'I agree to the Affiliate Agreement, Compensation Plan, and Terms and Conditions. I understand this trial does not provide me with all business building tools and will need to upgrade to an Affiliate Pro at $14/month.'

const PaymentMethod: React.FC<PaymentMethodProps> = props => {
  const {
    affiliateInfo,
    onFinishAccount,
    onBack,
    shouldHideAffiliateFields,
    isFreeAffiliateSignup,
    isNewProAffiliate,
  } = props

  const { account, controlData } = usePersistedUserData()
  const [marketingContent] = useQueries(getAffiliateJoinMarketingContent())
  const { mutateAsync: updateAccountMutation } = useUpdateAccount()
  const actions = useActions()
  const { removeQueryParams } = useQueryParams()

  const monthlyPaidAffiliateAgreement = account?.hasFullAccessFreeAffiliate
    ? marketingContent.agreementFreeAffiliate.content
    : marketingContent.agreement.content

  const [details, setDetails] = useState<PaymentMethodState>({
    accepted: affiliateInfo?.accepted ?? false,
    stripeToken: affiliateInfo?.stripeToken ?? undefined,
  })
  const [errorMessage, setErrorMessage] = useState<string>()
  const [updatedAffiliateInfo, setUpdatedAffiliateInfo] = useState<
    AffiliateFormInfo
  >(affiliateInfo)

  const isFreeSignup = isFreeAffiliateSignup && !isNewProAffiliate
  const card =
    details.stripeToken?.card ??
    (account?.stripeSource?.__typename === 'CreditCard'
      ? { last4: account.stripeSource.last4, brand: account.stripeSource.brand }
      : undefined)

  const onSubmit = () => {
    if (isDisabled) {
      return
    }
    onFinishAccount({
      ...updatedAffiliateInfo,
      accepted: details.accepted,
      stripeToken: details.stripeToken,
    })
    return
  }

  const handleChange = (
    name: keyof PaymentMethodState,
    value: string | boolean,
  ) => {
    setDetails({ ...details, [name]: value })
  }

  const updateAccount = async (account: AccountInput) => {
    const result = await updateAccountMutation({ account })
    actions.updatedAccount(result.updateAccount.account)
  }

  const submitCard = (token: Token) => {
    if (account) {
      updateAccount({ stripeToken: token.id })
    }
    setDetails({ ...details, stripeToken: token })
  }

  const removeCard = () => {
    if (account && account.stripeSource?.__typename === 'CreditCard') {
      updateAccount({ stripeToken: null })
    }
    setDetails({ ...details, stripeToken: undefined })
  }

  const onDialogClose = () => {
    setErrorMessage(undefined)
  }

  const isMobile = useSelector(state => state.window.isMobile)

  const isDisabled = !details.accepted || (!card && !isFreeAffiliateSignup)

  const getBackButton = () => {
    return {
      title: controlData.isLoggedIn
        ? 'Back to Account'
        : !shouldHideAffiliateFields && !isNewProAffiliate
        ? 'Back to Identity Information'
        : 'Back to Create Account Form',
      onClick: controlData.isLoggedIn
        ? () => {
            removeQueryParams()
            actions.openAccount()
          }
        : () =>
            onBack({
              ...updatedAffiliateInfo,
              accepted: details.accepted,
              stripeToken: details.stripeToken,
            }),
    }
  }

  return (
    <Div data-step="payment-method-step">
      {!shouldHideAffiliateFields ? (
        <>
          <Flex
            width="100%"
            flexDirection="column"
            data-mktcontent="configurationCard:priceCost"
          >
            <Spacer space="x2" />
            <ConfigurationCard
              configurationId="14"
              title="Monthly Affiliate Fee"
              cost={
                isFreeSignup
                  ? '12 months free'
                  : account?.hasFullAccessFreeAffiliate
                  ? marketingContent.priceCostFreeAffiliate.content
                  : marketingContent.priceCost.content
              }
            />
            {isNewProAffiliate && (
              <>
                <Spacer space="x4" />
                <IdentificationNumber
                  affiliateInfo={affiliateInfo}
                  onFinishAccount={onFinishAccount}
                  onBack={onBack}
                  updateIdentificationNumber={setUpdatedAffiliateInfo}
                />
                <Spacer space="x4" />
              </>
            )}
            <Spacer space={'x4'} />
            {isFreeSignup ? null : card ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <PaymentTab
                  paymentType={'card'}
                  last4={card.last4}
                  brand={creditCardBrand(card.brand)}
                />
                <Button
                  fullWidth={false}
                  type="secondary"
                  title="Remove Card"
                  onClick={() => removeCard()}
                />
              </div>
            ) : (
              <EditCard
                onSuccess={submitCard}
                onError={setErrorMessage}
                isSubmitButtonVisible={true}
                isAddingCard={false}
              />
            )}
            <Spacer space={'x4'} />
            <FlexRow>
              <Div outset={{ top: 'x1' }}>
                <Checkbox
                  onChange={accepted => handleChange('accepted', accepted)}
                  isChecked={details.accepted}
                  type="primary"
                />
              </Div>
              <Text type="caption" data-mktcontent="agreement">
                {isFreeSignup
                  ? freeAffiliateAgreement
                  : monthlyPaidAffiliateAgreement}
              </Text>
            </FlexRow>
            <Spacer space={'x2'} />
            <Div inset={{ left: 'x6', right: 'x4' }}>
              <HStack justify="space-between" gap="x9">
                <Anchor
                  href="https://www.sendoutcards.com/resources/documents/578/Promptings_Affiliate_Agreement_Documents_August_2022.pdf"
                  title="Agreement"
                  color="anchorBlue"
                  size="xSmall"
                />
                <Anchor
                  href="https://www.sendoutcards.com/resources/documents/573/Promptings_CompensationPlanDetails_August2022_v1.0.0.pdf"
                  title="Comp Plan Details"
                  color="anchorBlue"
                  size="xSmall"
                />
              </HStack>
            </Div>
            <Spacer space={'x4'} />
          </Flex>
        </>
      ) : (
        <Flex width="100%" flexDirection="column">
          <Text type="subtitle">Enter Card Information</Text>
          <Spacer space="x2" />
          {card ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <PaymentTab
                paymentType={'card'}
                last4={card.last4}
                brand={creditCardBrand(card.brand)}
              />
              <Button
                fullWidth={false}
                type="secondary"
                title="Remove Card"
                onClick={() => removeCard()}
              />
            </div>
          ) : (
            <EditCard
              onSuccess={submitCard}
              onError={setErrorMessage}
              isSubmitButtonVisible={true}
              isAddingCard={false}
            />
          )}
        </Flex>
      )}
      <Spacer space={'x2'} />
      <ConfirmDialog
        title={'Card Error'}
        description={
          errorMessage ?? 'There was an error with your card. Please try again.'
        }
        isOpen={!!errorMessage}
        accept={{ title: 'Ok', onClick: onDialogClose }}
      />
      <Flex width="100%" flexDirection="column">
        {!shouldHideAffiliateFields && (
          <>
            <Spacer space={isMobile ? 'x1' : 'x4'} />
            <Div width="100%" id="finish_affiliate_account_btn">
              <Button
                fullWidth={true}
                type={'primary'}
                onClick={() => onSubmit()}
                title="Finish Joining"
                disabled={isDisabled}
              />
            </Div>
          </>
        )}
        <Spacer space={isMobile ? 'x1' : 'x2'} />
        <Div width="100%" id="back_btn">
          <Button
            fullWidth={true}
            type={'accent'}
            outlined={true}
            onClick={getBackButton().onClick}
            title={getBackButton().title}
          />
        </Div>
      </Flex>
      <Spacer space="x2" />
    </Div>
  )
}

export default PaymentMethod
