import memoize from 'memoize-one'
import shallowEqual from 'shallowequal'

import {
  SearchFilter,
  SearchFilterType,
} from 'src/chrome/FilteredSearchBar/types'

export const generateContactFilterPayload = memoize(
  (filters: SearchFilter[]) => {
    let mutableBirthdayStartDate: string | undefined
    let mutableBirthdayEndDate: string | undefined
    let mutableAnniversaryStartDate: string | undefined
    let mutableAnniversaryEndDate: string | undefined
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let mutableShowSpouseAndChildren = true
    let mutableHasNoGroup
    let mutableCompanyName
    let mutableContacts: string[] = []
    let mutableGroups: string[] = []

    filters.forEach(filter => {
      if (
        filter.type === SearchFilterType.BOOLEAN &&
        filter.name === 'Exclude Spouse/Child'
      ) {
        if (filter.value === undefined) return
        mutableShowSpouseAndChildren = !filter.value
        return
      }

      if (
        filter.type === SearchFilterType.BOOLEAN &&
        filter.name === 'Show Unassigned'
      ) {
        if (filter.value === undefined) return
        mutableHasNoGroup = filter.value
        return
      }

      if (
        filter.type === SearchFilterType.DATERANGE &&
        filter.name === 'Anniversary'
      ) {
        if (filter.value === undefined) return
        mutableAnniversaryStartDate = filter.value.start.format('YYYY-MM-DD')
        mutableAnniversaryEndDate = filter.value.end.format('YYYY-MM-DD')
        return
      }

      if (
        filter.type === SearchFilterType.DATERANGE &&
        filter.name === 'Birthday'
      ) {
        if (filter.value === undefined) return
        mutableBirthdayStartDate = filter.value.start.format('YYYY-MM-DD')
        mutableBirthdayEndDate = filter.value.end.format('YYYY-MM-DD')
        return
      }

      if (
        filter.type === SearchFilterType.CONTACT &&
        filter.name === 'Contact'
      ) {
        if (filter.value === undefined) return
        mutableContacts = mutableContacts.concat(
          filter.value.map(contact => contact.id),
        )
        return
      }

      if (
        filter.type === SearchFilterType.COMPANY &&
        filter.name === 'Company'
      ) {
        if (filter.value === undefined) return
        mutableCompanyName = filter.value
        return
      }

      if (filter.type === SearchFilterType.GROUP && filter.name === 'Group') {
        if (filter.value === undefined) return
        mutableGroups = mutableGroups.concat(
          filter.value.map(contact => contact.id),
        )
        return
      }

      throw new Error(`unknown filter: ${filter.name}`)
    })

    const payload = {
      contacts: mutableContacts.length > 0 ? mutableContacts : undefined,
      groups: mutableGroups.length > 0 ? mutableGroups : undefined,
      birthdayStartDate: mutableBirthdayStartDate,
      birthdayEndDate: mutableBirthdayEndDate,
      anniversaryStartDate: mutableAnniversaryStartDate,
      anniversaryEndDate: mutableAnniversaryEndDate,
      showSecondaryContacts: mutableShowSpouseAndChildren,
      hasNoGroup: mutableHasNoGroup,
      companyName: mutableCompanyName,
    }

    return payload
  },
  shallowEqual,
)

const SIZE = 'small'
const COLOR = '#F063BD'

const COMMON_FILTERS: SearchFilter[] = [
  {
    name: 'Contact',
    type: SearchFilterType.CONTACT,
    icon: {
      svg: 'user',
      size: SIZE,
      color: COLOR,
    },
    value: undefined,
  },
  {
    name: 'Group',
    type: SearchFilterType.GROUP,
    icon: {
      svg: 'groups',
      size: SIZE,
      color: COLOR,
    },
    value: undefined,
  },
]

const CONTACT_FILTERS: SearchFilter[] = COMMON_FILTERS.concat([
  {
    name: 'Anniversary',
    type: SearchFilterType.DATERANGE,
    icon: {
      svg: 'calendar',
      size: SIZE,
      color: COLOR,
    },
    value: undefined,
  },
  {
    name: 'Birthday',
    type: SearchFilterType.DATERANGE,
    icon: {
      svg: 'calendar',
      size: SIZE,
      color: COLOR,
    },
    value: undefined,
  },
  {
    name: 'Company',
    type: SearchFilterType.COMPANY,
    icon: {
      svg: 'home',
      size: SIZE,
      color: COLOR,
    },
    value: undefined,
  },
  {
    name: 'Exclude Spouse/Child',
    type: SearchFilterType.BOOLEAN,
    icon: {
      svg: 'minus',
      size: SIZE,
      color: COLOR,
    },
    value: undefined,
  },
  {
    name: 'Show Unassigned',
    type: SearchFilterType.BOOLEAN,
    icon: {
      svg: 'groups',
      size: SIZE,
      color: COLOR,
    },
    value: undefined,
  },
])

export const DEFAULT_CONTACT_SEARCH = { filters: CONTACT_FILTERS, terms: '' }
export const DEFAULT_GROUP_SEARCH = { filters: COMMON_FILTERS, terms: '' }
