import { Route } from 'src/routes/Route'
import { CatalogSubroute } from 'src/catalog/routes/CatalogRoute'

export const ReplaceOrderCardRoute = Route({
  path: '/card/:cardId/replace',
  init: (cardId: string, subroute?: CatalogSubroute) => ({
    cardId,
    subroute,
  }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  subroute: CatalogSubroute,
})

export type ReplaceOrderCardRoute = Route<typeof ReplaceOrderCardRoute>
