import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'

import { useRandomGradient } from 'src/hooks/useRandomGradient'

export type Creditor = 'visa' | 'google' | 'apple'

type PaymentMethodProps = {
  brand?: string
  ccLastFour?: string
}

export const PaymentMethod = (props: PaymentMethodProps) => {
  const { brand, ccLastFour } = props

  const creditorLogo = {
    apple: 'apple_pay_minified',
    google: 'google_pay',
    visa: 'visa',
  }

  function isSupportedCreditorLogo(brand?: string) {
    return brand === 'visa' || brand === 'google' || brand === 'apple'
  }

  const gradientColors = [
    '#FC8181',
    '#FCD34D',
    '#C3DAFE',
    '#22D3EE',
    '#F87171',
    '#0EA5E9',
    '#A855F7',
    '#DB2777',
    '#14B8A6',
    '#34D399',
  ]

  const gradientDirections = [
    'to top',
    'to bottom',
    'to left',
    'to right',
    'to top right',
    'to top left',
    'to bottom right',
    'to bottom left',
  ]

  const gradient = useRandomGradient(gradientColors, gradientDirections)

  return (
    <Flex
      width="100%"
      style={{
        background: `linear-gradient(${gradient.direction}, ${gradient.fromColor}, ${gradient.toColor})`,
        width: '5rem',
        height: '3rem',
        borderRadius: '0.375rem',
        padding: '0.375rem 0.5rem',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Div
            style={{
              width: '1rem',
              height: '0.775rem',
              borderRadius: '0.125rem',
              background: '#fbbf24',
            }}
          />
          <Div style={{ alignSelf: 'flex-end', justifySelf: 'flex-start' }}>
            <Text
              type="body"
              lineHeight={1.3}
              style={{
                fontSize: '0.6875rem',
                fontWeight: 500,
                color: '#ffffff',
              }}
            >
              {ccLastFour}
            </Text>
          </Div>
        </Div>
        {isSupportedCreditorLogo(brand) && (
          <Icon
            name={creditorLogo[brand as Creditor] as IconType}
            fill="#ffffff"
            size={16}
          />
        )}
      </Div>
    </Flex>
  )
}
