import { PlanFragment } from 'src/legacy_graphql'
import { PlansDescription } from 'src/marketing/plans/types'

export const getPlanDescriptions = (plans: PlanFragment[]) => {
  return Object.fromEntries(
    plans.flatMap(plan => {
      if (plan.description) {
        const id = plan.id
        const description = PlansDescription.fromPlansDescriptionFragment({
          __typename: 'PlansDescription',
          id: plan.id,
          description: plan.description,
        }).description
        return [[id, description]]
      }
      return []
    }),
  )
}
